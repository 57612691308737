import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLoading } from "redux/slice/loadingSlice";
import LoginContainer from "./LoginContainer";
import SigninForm from "./SigninForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { selectConstVar } from "redux/slice/permissionSlice";


export default function Login() {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(true);
  const constVar = useSelector(selectConstVar);

  useEffect(() => {
    dispatch(setLoading(false));
  }, [])
  return (
    <LoginContainer constVar={constVar}>
      {isLogin
        ? <SigninForm toForgotPassword={() => setIsLogin(false)} />
        : <ForgotPasswordForm toLogin={() => setIsLogin(true)} />
      }
    </LoginContainer>
  );
}
