import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';

import Select from "Common/Select";
import CustCheckbox from 'Common/Checkbox';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import CustTableCell from "Common/TableCell";

import CommonContainer from 'EDMS/CommonContainer';
import { tableConfig, dummyData } from './config';
import { useStyles } from "../style";

export default function UnlockDocument() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const userOptions = [
    {
      label: "All Users",
      value: "all",
    }
  ];
  function renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {tableConfig.map((column, idx) =>
            <TableCell className={classes.headerCell} key={idx}>{column.label}</TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
  function renderTableBody() {
    return (
      <TableBody>
        {dummyData.map((row, idx) => {
          return (
            <TableRow key={idx}>
              <CustTableCell><CustCheckbox onChange={(value) => console.log(value)} /></CustTableCell>
              {tableConfig.filter(item => item.id !== "placeholder").map((col, colIdx) =>
                <CustTableCell key={colIdx}>{row[col.id]}</CustTableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <CommonContainer>
      <SectionTitle
        title={`Unlock Document`}
        extraComponents={
          <div className={classes.buttonsContainer}>
            <Select
              defaultValue={"all"}
              options={userOptions}
              handleChange={() => console.log('change')}
            />
          </div>
        }
      />
      <div className={classes.body}>
        <Table>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </div>
    </CommonContainer>
  );
}