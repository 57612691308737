import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import queryString from 'query-string'
import PropTypes from "prop-types";
import {
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Drawer,
  Grid
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import Loading from "Common/Loading";
import { setNotification } from "redux/slice/notificationSlice";
import arrayHelper from "utils/arrayHelper";
import typeHelper from "utils/typeHelper";
import { documentHelper, documentsHelper, innoDocHelper, contractHelper, relatedDocHelper, riskHelper, edmsFileHelper } from "helpers";
import Select from "Common/Select";
import SectionTitle from "Common/SectionTitle";
import SelectionArea from "../DocumentAddRelatedDocument/SelectionArea";
import SelectionAction from "../DocumentAddRelatedDocument/SelectionAction";
import Reminder from "../DocumentAddRelatedDocument/Reminder";
import SelectionConfirm from "../DocumentAddRelatedDocument/SelectionConfirm";

import CloseIcon from "static/icons/close.svg";
import { typeOptions } from "./config";
import { useStyles } from "./style";

function DocumentAddRelatedDocumentV2({
  handleClose,
  isExternal = false,
  reload,
  currentDocType,
  searchFilter,
}) {
  const dispatch = useDispatch();
  const { docId, type, contractId } = useParams();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [selectedRisc, setSelectedRisc] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState([]);
  const [selectedSiteDiary, setSelectedSiteDiary] = useState([]);
  const [selectedSafety, setSelectedSafety] = useState([]);
  const [selectedCleansing, setSelectedCleansing] = useState([]);
  const [selectedLabour, setSelectedLabour] = useState([]);
  const [selectedFileMeta, setSelectedFileMeta] = useState([]);
  const [selectedRisk, setSelectedRisk] = useState([]);
  const [selectedType, setSelectedType] = useState(searchFilter ? searchFilter[0].value : "risc");
  const [stage, setStage] = useState("select");
  const [reminder, setReminder] = useState(false);
  const [count, setCount] = useState(0);
  const { search } = useLocation();
  const [contractNo, setContractNo] = useState(null);

  //For External
  const [externalContractId, setExternalContractId] = useState(null);
  const [externalContractNo, setExternalContractNo] = useState(null);
  const [externalError, setExternalError] = useState(null);
  const [currentDoc, setCurrentDoc] = useState(null);

  useEffect(() => {
    if (isExternal) {
      const values = queryString.parse(search);
      if (values.token) {
        sessionStorage.accessToken = values.token;
        const contractNo = decodeURIComponent(contractId);
        setContractNo(contractNo);
        initExternal(contractNo);
        setExternalContractNo(contractNo);
      }
    } else {
      init(contractId);
    }
  }, []);

  async function initExternal(contractNo) {
    const contractId = await getContractBy(contractNo);
    if (contractId) await getCurrentDoc(type, docId, undefined, contractId);
    goToStage("confirm")
  }

  async function init(contractId) {
    getContract(contractId);
    getCurrentRelatedDoc(type, docId);
  }

  async function loadCurrentRelatedDoc(data, type, objectKey, setSelected) {
    try {
      if (data[objectKey] && data[objectKey].length > 0) {
        if (type === "edms") {
          const result = await Promise.all(data[objectKey].filter(id => id).map(id => edmsFileHelper.getFileMeta(id)));
          const flatten = result.map(item => typeHelper.extractDocumentObject(item, type));
          setSelected(flatten);
        } else if (type === "risk-register") {
          const result = await Promise.all(data[objectKey].filter(id => id).map(id => riskHelper.getRiskRegister(id)));
          const flatten = result.map(item => typeHelper.extractDocumentObject(item, type));
          setSelected(flatten);
        } else {
          // dwss
          const result = await Promise.all(data[objectKey].filter(id => id).map(id => documentHelper.getDocument({ type, id })));
          const flatten = result.map(item => typeHelper.extractDocumentObject(item, type));
          setSelected(flatten);
        }

      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getCurrentDoc(cType, guid, id, cId) {
    try {
      const variable = {
        guid,
        id,
        contractId: cId ?? contractId,
      }
      const result = await documentHelper.getDocumentByQuery({ type: cType, variable });
      if (result.error) throw new Error(result.error);
      const resultObj = typeHelper.extractDocumentObject(result, cType);
      setCurrentDoc(resultObj);

      await loadCurrentRelatedDoc(resultObj, "risc", "riscRelatedDocuments", setSelectedRisc);
      await loadCurrentRelatedDoc(resultObj, "site-diary", "siteDiaryRelatedDocuments", setSelectedSiteDiary);
      await loadCurrentRelatedDoc(resultObj, "safety", "safetyRelatedDocuments", setSelectedSafety);
      await loadCurrentRelatedDoc(resultObj, "cleansing", "cleansingRelatedDocuments", setSelectedCleansing);
      await loadCurrentRelatedDoc(resultObj, "labour", "labourRelatedDocuments", setSelectedLabour);
      await loadCurrentRelatedDoc(resultObj, "photo", "photoRelatedDocuments", setSelectedPhoto);

    } catch (e) {
      console.log(e);
    }
  }

  async function getCurrentRelatedDoc(type, id) {
    const camelType = typeHelper.convertHyphenToCamelType(currentDocType);
    console.log(camelType)
    const result = await relatedDocHelper.getRelatedDocuments({ docType: camelType, docId: id });
    const resultObj = result.related_document.relatedDocuments;
    setCurrentDoc(resultObj);

    await loadCurrentRelatedDoc(resultObj, "risc", "riscId", setSelectedRisc);
    await loadCurrentRelatedDoc(resultObj, "site-diary", "siteDiaryId", setSelectedSiteDiary);
    await loadCurrentRelatedDoc(resultObj, "safety", "safetyId", setSelectedSafety);
    await loadCurrentRelatedDoc(resultObj, "cleansing", "cleansingId", setSelectedCleansing);
    await loadCurrentRelatedDoc(resultObj, "labour", "labourId", setSelectedLabour);
    await loadCurrentRelatedDoc(resultObj, "photo", "photoId", setSelectedPhoto);
    await loadCurrentRelatedDoc(resultObj, "edms", "fileMetaId", setSelectedFileMeta);
    await loadCurrentRelatedDoc(resultObj, "risk-register", "riskRegisterId", setSelectedRisk);

  }

  async function getContract(contractId) {
    const result = await contractHelper.getContract(contractId);
    setContractNo(result.contract.contractNo);
  }

  async function getContractBy(contractNo) {
    try {
      const result = await contractHelper.getContractByNo({ variable: { contractNo } });
      if (result.error) throw new Error('Cannot get contract');

      setExternalContractId(result.id)
      return result.id;
    } catch (e) {
      setExternalError(`Your Contract ID doesn't exist`)
      console.log(e)
    }
  }

  useEffect(() => {
    getCount(selectedType);
  }, [selectedType, externalContractId])

  async function getCount(cType) {
    const postType = typeHelper.getCurrentDocumentsType(cType);
    const filterVariable = { items: 1, page: 1, contractId: (isExternal && !!externalContractId) ? externalContractId : contractId };
    const result = await documentsHelper.getDocuments({ type: postType, variable: filterVariable });
    setCount(result.count);
  }

  const handleModalClose = () => {
    if (
      selectedPhoto.length > 0 ||
      selectedRisc.length > 0 ||
      selectedSiteDiary.length > 0 ||
      selectedSafety.length > 0 ||
      selectedCleansing.length > 0 ||
      selectedLabour.length > 0 ||
      selectedFileMeta.length > 0 ||
      selectedRisk.length > 0
    ) {
      setReminder(true);
    } else {
      handleClose();
    }
  };

  const returnSessionTitle = () => {
    switch (stage) {
      case "select":
        return "Add Related Document";
      case "confirm":
        return "Selected Documents";
      default:
        return "";
    }
  };

  const returnSelectedData = () => {
    switch (selectedType) {
      case "photo":
        return selectedPhoto;
      case "risc":
        return selectedRisc;
      case "site-diary":
        return selectedSiteDiary;
      case "safety":
        return selectedSafety;
      case "cleansing":
        return selectedCleansing;
      case "labour":
        return selectedLabour;
      case "risk-register":
        return selectedRisk
      default:
        return selectedFileMeta;
    }
  };

  const handleRiscSelect = (data) => {
    if (selectedRisc.find((item) => !!item && item.id === data.id)) {
      setSelectedRisc(selectedRisc.filter((item) => item.id !== data.id));
    } else {
      setSelectedRisc([...selectedRisc, data]);
    }
  };
  const handlePhotoSelect = (data) => {
    if (selectedPhoto.find((item) => !!item && item.id === data.id)) {
      setSelectedPhoto(selectedPhoto.filter((item) => item.id !== data.id));
    } else {
      setSelectedPhoto([...selectedPhoto, data]);
    }
  };
  const handleSiteDiarySelect = (data) => {
    if (selectedSiteDiary.find((item) => !!item && item.id === data.id)) {
      setSelectedSiteDiary(selectedSiteDiary.filter((item) => item.id !== data.id));
    } else {
      setSelectedSiteDiary([...selectedSiteDiary, data]);
    }
  };
  const handleSafetySelect = (data) => {
    if (selectedSafety.find((item) => !!item && item.id === data.id)) {
      setSelectedSafety(selectedSafety.filter((item) => item.id !== data.id));
    } else {
      setSelectedSafety([...selectedSafety, data]);
    }
  };
  const handleCleansingSelect = (data) => {
    if (selectedCleansing.find((item) => !!item && item.id === data.id)) {
      setSelectedCleansing(selectedCleansing.filter((item) => item.id !== data.id));
    } else {
      setSelectedCleansing([...selectedCleansing, data]);
    }
  };
  const handleLabourSelect = (data) => {
    if (selectedLabour.find((item) => !!item && item.id === data.id)) {
      setSelectedLabour(selectedLabour.filter((item) => item.id !== data.id));
    } else {
      setSelectedLabour([...selectedLabour, data]);
    }
  };
  const handleFileMetaSelect = (data) => {
    if (selectedFileMeta.find((item) => !!item && item.id === data.id)) {
      setSelectedFileMeta(selectedFileMeta.filter((item) => item.id !== data.id));
    } else {
      setSelectedFileMeta([...selectedFileMeta, data]);
    }
  };
  const handleRiskSelect = (data) => {
    if (selectedRisk.find((item) => !!item && item.id === data.id)) {
      setSelectedRisk(selectedRisk.filter((item) => item.id !== data.id));
    } else {
      setSelectedRisk([...selectedRisk, data]);
    }
  };

  const returnHandleSelect = () => {
    switch (selectedType) {
      case "photo":
        return handlePhotoSelect;
      case "risc":
        return handleRiscSelect;
      case "site-diary":
        return handleSiteDiarySelect;
      case "safety":
        return handleSafetySelect;
      case "cleansing":
        return handleCleansingSelect;
      case "labour":
        return handleLabourSelect;
      case "risk-register":
        return handleRiskSelect;
      default:
        return handleFileMetaSelect;
    }
  };

  const returnHandleMixTableSelect = (data) => {
    let type = 'photo';
    if (data.fileType) type = data.fileType.toLowerCase();
    else if (data.docType) type = "edms";
    else if (data.riskIdReference) type = "risk-register";

    if (type === "photo") return handlePhotoSelect(data);
    else if (type === "risc") return handleRiscSelect(data);
    else if (type === "site-diary" || type === "site diary") return handleSiteDiarySelect(data);
    else if (type === "ssr" || type === "safety-inspection") return handleSafetySelect(data);
    else if (type === "cic" || type === "cleansing-inspection") return handleCleansingSelect(data);
    else if (type === "lrr" || type === "labour") return handleLabourSelect(data);
    else if (type === "edms") return handleFileMetaSelect(data);
    else if (type === "risk-register") return handleRiskSelect(data);
    else return handleFileMetaSelect(data);
  }

  const goToStage = (target) => {
    setStage(target);
  };

  const returnAllSelectedData = () => {
    let tempData = [];
    // tempData = tempData.concat(selectedPhoto);
    // console.log(selectedRisc, selectedSiteDiary, selectedSafety, selectedCleansing, selectedLabour)
    tempData = tempData.concat(selectedRisc);
    tempData = tempData.concat(selectedSiteDiary);
    tempData = tempData.concat(selectedSafety);
    tempData = tempData.concat(selectedCleansing);
    tempData = tempData.concat(selectedLabour);
    return tempData;
  }

  const handleConfirm = async () => {
    try {
      let riscArr = [];
      let siteDiaryArr = [];
      let safetyArr = [];
      let cleansingArr = [];
      let labourArr = [];
      let photoArr = [];
      let fileMetaArr = [];
      let riskArr = [];
      const docType = typeHelper.getCurrentType(type);

      if (selectedRisc) {
        riscArr = selectedRisc.map(value => value && value.id);
        riscArr = arrayHelper.uniq(riscArr);
      }
      if (selectedSiteDiary) {
        siteDiaryArr = selectedSiteDiary.map(value => value && value.id);
        siteDiaryArr = arrayHelper.uniq(siteDiaryArr);
      }
      if (selectedSafety) {
        safetyArr = selectedSafety.map(value => value && value.id);
        safetyArr = arrayHelper.uniq(safetyArr);
      }
      if (selectedCleansing) {
        cleansingArr = selectedCleansing.map(value => value && value.id);
        cleansingArr = arrayHelper.uniq(cleansingArr);
      }
      if (selectedLabour) {
        labourArr = selectedLabour.map(value => value && value.id);
        labourArr = arrayHelper.uniq(labourArr);
      }
      if (selectedPhoto) {
        photoArr = selectedPhoto.map(value => value && value.id);
        photoArr = arrayHelper.uniq(photoArr);
      }
      if (selectedFileMeta) {
        fileMetaArr = selectedFileMeta.map(value => value && value.id);
        fileMetaArr = arrayHelper.uniq(fileMetaArr);
      }
      if (selectedRisk) {
        riskArr = selectedRisk.map(value => value && value.id);
        riskArr = arrayHelper.uniq(riskArr);
      }

      const postVariable = {
        riscRelatedDocuments: riscArr,
        siteDiaryRelatedDocuments: siteDiaryArr,
        photoRelatedDocuments: photoArr,
        safetyRelatedDocuments: safetyArr,
        labourRelatedDocuments: labourArr,
        cleansingRelatedDocuments: cleansingArr,
      };
      let postVariableV2 = {
        [`${typeHelper.convertHyphenToCamelType(currentDocType)}Id`]: docId,
        "relatedDocuments": {
          "fileMetaId": fileMetaArr,
          "riskMeetingId": [],
          "riskRegisterId": riskArr,
          "riscId": riscArr,
          "siteDiaryId": siteDiaryArr,
          "safetyId": safetyArr,
          "labourId": labourArr,
          "cleansingId": cleansingArr,
          "photoId": photoArr,
        }
      };

      if (!isExternal) {
        setLoading(true);
        // setLoading(false);
        await relatedDocHelper.postRelatedDocuments(postVariableV2);
        dispatch(setNotification({ type: "success", message: "Successfully add related documents" }));
        handleClose();
        reload();
      } else {
        setLoading(true);
        let contractNo = externalContractNo === "DC/2018/06" ? "" : externalContractNo.replaceAll('/', '');
        let resultObject = {
          risc_related_documents: riscArr,
          site_diary_related_documents: siteDiaryArr,
          photo_related_documents: photoArr,
          safety_related_documents: safetyArr,
          cleansing_related_documents: cleansingArr,
          labour_related_documents: labourArr,
          contractId: contractNo,
        };
        if (currentDoc) {
          await documentHelper.patchRelatedDocument({ type: docType, id: docId, variable: postVariable });
        }
        await innoDocHelper.returnDoc({
          contractNo,
          variable: {
            encryptedKey: "YXzkQ2jIE5v/c902WeGCigqTlL8faRg35NmDBCLtISc=",
            dockey: docId,
            result: JSON.stringify(resultObject),
          }
        });
        window.close();
      }

    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  function handleSelectedTypeChange(e) {
    setSelectedType(e.target.value)
  }

  const returnMainContent = () => {
    switch (stage) {
      case "select":
        return (
          <>
            <Grid container>
              <Grid item xs={2}>
                <Select
                  value={selectedType}
                  handleChange={handleSelectedTypeChange}
                  options={searchFilter ? searchFilter : typeOptions}
                  label="Type"
                />
              </Grid>
            </Grid>
            <br />
            {(isExternal && externalError)
              && externalError}
            <SelectionArea
              selectedData={returnSelectedData()}
              handleSelect={returnHandleSelect()}
              count={count}
              selectedType={selectedType}
              externalContractId={externalContractId}
              contractNo={contractNo}
            />
            <br />
          </>
        );
      case "confirm":
        return (
          <SelectionConfirm
            numberOfDoc={selectedPhoto.length + selectedRisc.length + selectedSiteDiary.length
              + selectedCleansing.length + selectedSafety.length + selectedLabour.length + selectedFileMeta.length + selectedRisk.length}
            backStage={() => setStage("select")}
            handleSelect={returnHandleMixTableSelect}
            data={returnAllSelectedData()}
            fileMetaData={selectedFileMeta}
            riskData={selectedRisk}
            photos={selectedPhoto}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={returnSessionTitle()}
          extraComponents={
            !isExternal && <IconButton onClick={handleModalClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <DialogContent>
        {returnMainContent()}
      </DialogContent>
      <Loading isLoading={isLoading} />
      <DialogActions>
        <SelectionAction stage={stage} goToStage={goToStage} handleConfirm={handleConfirm} />
      </DialogActions>
      <Drawer
        anchor="bottom"
        open={reminder}
        classes={{
          paperAnchorBottom: classes.drawer,
        }}
      >
        <Reminder
          cancelDrawer={() => setReminder(false)}
          confirmLeave={handleClose}
        />
      </Drawer>
    </>
  );
}

DocumentAddRelatedDocumentV2.propTypes = {
  handleClose: PropTypes.func,
};

export default DocumentAddRelatedDocumentV2;