import moment from "moment";
import MarkunreadIcon from '@material-ui/icons/Markunread';
import DraftsIcon from '@material-ui/icons/Drafts';
import MessageCell from "EDMSRegisterDocument/MessageCell";

export const tableConfig = [
  { id: "logNumber", label: "Barcode" },
  { id: "letterDate", label: "Date", type: "Date" },
  { id: "receiveDate", label: "Receive/ Dispatch", type: "Date" },
  { id: "subject", label: "Subject", },
  { id: "fileNumber", label: "File No.", },
  { id: "fromCompany", label: "From", },
  { id: "toCompany", label: "To" },
  { id: "letterRef", label: "Letter Ref.", },
  { id: "repliedBy", label: "Replied By", },
  { id: "docType", label: "Type" },
  { id: "replyTo", label: "Reply To" },
  { label: "" },
];

export const riskRegisterTableConfig = [
  { id: "", label: "" },
  { id: "riskIdReference", label: "Risk ID Ref" },
  { id: "issueDate", label: "Date of Issue / Receipt of EW", type: "Date" },
  { id: "issuedBy", label: "Issued by DSD / AECOM / Contractor (Ref No.)", },
  { id: "incidentNumber", label: "Incident No." },
  { id: "description", label: "Description", type: "NoFilter" },
  { id: "potentialRisks", label: "Potential Problem(s) and the Risk(s)", type: "NoFilter" },
  { id: "impact", label: "Impact", },
  { id: "affectedWorkSection", label: "Affected Work Section", },
  { id: "affectedKeyDate", label: "Affected Key Date" },
  {
    id: "riskRegisterStatus", label: "Status", type: "Select",
    options: [
      {
        label: 'Open',
        value: "Open"
      },
      {
        label: 'Closed',
        value: "Closed"
      },
    ]
  },
  { id: "riskCommon", label: "Description / Potential Problem(s) and the Risk(s) / Action to be taken to avoid/reduce the risk(s)", hidden: true, size: 12 },
  { label: "" },
];

export const riskReductionMeetingTableConfig = [
  { id: "date", label: "Meeting Date", type: "Date" },
  { id: "riskIdReference", label: "Risk ID Ref.", hidden: true },
  { id: "incidentNumber", label: "Incident No.", hidden: true },
  {
    id: "startTime", label: "Time",
    render: (item) => {
      const start = moment(item.startTime, 'H:mm:ss').format('H:mm');
      const end = moment(item.endTime, 'H:mm:ss').format('H:mm');
      return `${start} - ${end}`;
    }
  },
  { id: "venue", label: "Venue", },
  { id: "riskMeetingReferenceNumber", label: "Meeting No.", },
  { id: "riskCommon", label: "Description / Potential Problem(s) and the Risk(s) / Action to be taken to avoid/reduce the risk(s)", hidden: true, size: 12 },
  // { id: "letterRef", label: "Letter Ref.", },
  { label: "" },
];

export const earlyWarningTableConfig = [
  {
    label: "Contractor Ref No.",
    id: "contractorReferenceNumber",
  },
  {
    label: "Date of Issue / Receipt of EW",
    id: "dateOfIssue",
    type: "Date",
  },
  {
    label: "Subject / Description of Risk",
    id: "subject",
  },
  {
    label: "Condition of Contract Clause(s)",
    id: "conditionsOfContractClause",
    type: "NoFilter",
    hidden: true,
    options: [
      {
        label: "Increase the total of the Prices",
        id: "conditionsOfContractClause.increaseTheTotalOfThePrices",
      },
      {
        label: "Delay Completion",
        id: "conditionsOfContractClause.delayCompletion",
      },
      {
        label: "Delay meeting a Key Date",
        id: "conditionsOfContractClause.delayMeetingAKeyDate",
      },
      {
        label: "Impair the performance",
        id: "conditionsOfContractClause.impairThePerformance",
      },
    ]
  },
  {
    label: "Require for Change",
    id: "requestForChange",
    type: "Select",
    options: [
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      }
    ],
    render: (item) => {
      return item.requestForChange ? "Yes" : "No";
    }
  },
  {
    label: "Early Warning Meeting Required",
    id: "meetingRequired",
    type: "Select",
    options: [
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      }
    ],
    render: (item) => {
      return item.requestForChange ? "Yes" : "No";
    }
  },
  {
    label: "Urgency",
    id: "urgency",
  },
  {
    label: "Nature of Change",
    id: "natureOfChange",
  },
  {
    label: "Type of Change",
    id: "typeOfChange",
  },
  {
    label: "Matter Giving Rise To Notification",
    id: "matterGivingRiseToNotification",
    hidden: true,
  },
  { label: "" },
];

export const updateDocumentListConfig = [
  { id: "logNumber", label: "Barcode" },
  { id: "subject", label: "Subject", },
  { id: "registerBy", label: "Register By", },
  { id: "project", label: "Project", },
  { label: "" },
  { label: "" },
];

export const viewDocumentListInConfig = [
  { id: "logNumber", label: "Barcode" },
  { id: "subject", label: "Subject", },
  { id: "letterDate", label: "Date", type: "Date" },
  { id: "letterRef", label: "Your Ref.", },
  { id: "docType", label: "Type" },
  { id: "receiveDate", label: "Receive Date", type: "Date" },
  { id: "fileType", label: "File Type", },
  { label: "" },
  { label: "" },
];
export const viewDocumentListOutConfig = [
  { id: "logNumber", label: "Barcode" },
  { id: "subject", label: "Subject", },
  { id: "letterDate", label: "Date", type: "Date" },
  { id: "letterRef", label: "Our Ref.", },
  { id: "docType", label: "Type" },
  { id: "receiveDate", label: "Dispatch Date", type: "Date" },
  { id: "fileType", label: "File Type", },
  { label: "" },
  { label: "" },
];

export const checkReportConfig = [
  { id: "logNumber", label: "Barcode" },
  { id: "fileRef", label: "File Ref.", },
  { id: "letterDate", label: "Letter Date", type: "Date" },
  { id: "receiveIssueDate", label: "Receive/Issue Date", type: "Date" },
  { id: "subject", label: "Subject", },
  { id: "docType", label: "Doc Type" },
  { id: "ourRef", label: "Our Ref.", },
  { id: "yourRef", label: "Your Ref.", },
  { id: "fromCompany", label: "From", },
  { id: "toCompany", label: "To" },
  { id: "filedDate", label: "Filed Date", type: "Date" },
  { id: "enclosure", label: "Enclosure", },
];

export const actionReportConfig = [
  { id: "actionDate", label: "Action Date", type: "Date" },
  { id: "logNumber", label: "Barcode" },
  { id: "subject", label: "Subject", },
  { id: "docType", label: "Doc Type" },
  { id: "dueDate", label: "Due Date", type: "Date" },
  { id: "requestedBy", label: "Requested By" },
  { id: "toBeDoneBy", label: "To Be Done By" },
];

export const incidentConfig = [
  { id: "incidentNumber", label: "Incident No." },
  { id: "issueDate", label: "Issue Date", type: "Date" },
  { id: "subject", label: "Subject", },
  { id: "location", label: "Location", },
  { id: "createdAt", label: "Created Date", type: "Date" },
  { id: "createdBy", label: "Created By" },
  { label: "" },
  { label: "" },
];

export const safetyPerformanceConfig = [
  { id: "reportPeriodFrom", label: "Reporting Month", type: "Month", isDate: true },
  {
    id: "revisionNumber", label: "Revision No.", render: (item) => {
      return item.revisionNumber.toString();
    }
  },
  { id: "createdAt", label: "Created On", type: "Date" },
  { id: "createdBy", label: "Created By", },
  {
    id: "status", label: "Status", type: "Select",
    options: [
      {
        label: "",
        value: null,
      },
      {
        label: 'Reported',
        value: "Reported"
      },
      {
        label: 'Endorsed',
        value: "Endorsed"
      },
      {
        label: 'Supervisor Verified',
        value: "Supervisor Verified"
      },
      {
        label: 'Supervisor counter-verified',
        value: "Supervisor counter-verified"
      },
      {
        label: 'Closed - PM verified',
        value: "Closed"
      },
    ]
  },
  { id: "assignedTo", label: "Assigned To", },
  { id: "comment", label: "Comments", },
  { id: "updatedAt", label: "Updated On", type: "Date" },
  { id: "updatedBy", label: "Updated By" },
  { label: "" },
  { label: "" },
];

export const siConfig = [
  { id: "incidentNumber", label: "Incident No." },
  { id: "siNumber", label: "NCE/Comm/SI No." },
  {
    id: "type", label: "Document Type", type: "Select",
    options: [
      {
        label: "NCE",
        value: "NCE"
      },
      {
        label: "Communication",
        value: "Communication",
      },
      {
        label: "SI",
        value: "SI"
      },
    ]
  },
  {
    id: "contractCovered", label: "Contract Covered",
    isBoolean: true,
    type: "Select",
    options: [
      {
        label: "Yes",
        value: "1"
      },
      {
        label: "No",
        value: "0",
      }
    ]
  },
  { id: "issueDate", label: "Issue Date", type: "Date" },
  { id: "createdAt", label: "Create Date", type: "Date" },
  { id: "subject", label: "Subject", },
  { id: "location", label: "Location", },
  { id: "commerceDate", label: "Commerce Date", type: "Date" },
  { id: "completionDate", label: "Completion Date", type: "Date" },
  { id: "reasonForSi", label: "Reason for SI" },
  { id: "createdBy", label: "Created By" },
  { id: "documentCheckedBy", label: "Checked By" },
  { label: "" },
  { label: "" },
];

export const bimConfig = [
  { id: "fileId", label: "File ID" },
  { id: "submissionDatetime", label: "Submission Date", type: "Datetime" },
  { id: "fileStatus", label: "Status", },
  { label: "" },
  { label: "" },
];

export const bimConfig2 = [
  { id: "fileId", label: "File ID" },
  { id: "createTime", label: "Created Date", type: "Datetime" },
  { id: "fileStatus", label: "Status", },
  { label: "" },
  { label: "" },
];

export const bimProjectStatusConfig = [
  { id: "displayName", label: "Model" },
  { id: "createTime", label: "Started at", type: "Datetime" },
  { id: "createUserName", label: "Author", },
  { id: "extractionState", label: "Publishing State", },
  { id: "lastModifiedTime", label: "Completed at", type: "Datetime" },
  { id: "path", label: "Path", },
];

export const bimFolderConfig = [
  { id: "displayName", label: "Name" },
  { id: "versionNumber", label: "Version", },
  { id: "lastModifiedTime", label: "Last Updated", type: "Datetime" },
  { id: "lastModifiedUserName", label: "Updated By", },
];

export const inboxActionConfig = [
  {
    id: "seen", label: "Seen", hideLabel: true, type: "Read",
    render: (item) => {
      return item.seen ? <DraftsIcon /> : <MarkunreadIcon />
    },
    options: [
      {
        label: "Seen",
        value: 1,
      },
      {
        label: "Unread",
        value: 0,
      }
    ]
  },
  { id: "logNumber", label: "Log Number" },
  { id: "fromCompany", label: "Company", },
  { id: "letterDate", label: "Doc Date", type: "Date" },
  { id: "receiveDate", label: "Receive", type: "Date" },
  {
    id: "inOut", label: "In/Out", type: "Select",
    options: [
      {
        label: "In",
        value: "in",
      },
      {
        label: "Out",
        value: "out",
      }
    ]
  },
  { id: "subject", label: "Subject", },
  { id: "dueDate", label: "Due Date", type: "Date" },
  {
    id: "status", label: "Status", type: "Select",
    options: [
      {
        label: "Outstanding",
        value: "Outstanding",
      },
      {
        label: "Processing",
        value: "Processing",
      },
      {
        label: "Finished",
        value: "Finished",
      },
    ]
  },
  {
    id: "content", label: "Message", width: "400px",
    render: (item, reload) => {
      return <MessageCell data={item} reload={reload} />
    }
  },
  { label: "" },
];

export const inboxInfoConfig = [
  {
    id: "seen", label: "Seen", hideLabel: true, type: "Read",
    render: (item) => {
      return item.seen ? <DraftsIcon /> : <MarkunreadIcon />
    },
    options: [
      {
        label: "Seen",
        value: 1,
      },
      {
        label: "Unread",
        value: 0,
      }
    ]
  },
  { id: "logNumber", label: "Log Number" },
  { id: "fromCompany", label: "Company", },
  { id: "letterDate", label: "Doc Date", type: "Date" },
  { id: "receiveDate", label: "Receive", type: "Date" },
  { id: "inOut", label: "In/Out", },
  { id: "subject", label: "Subject", },
  { id: "createdAt", label: "Received", type: "Date" },
  {
    id: "content", label: "Message", width: "450px",
    render: (item, reload) => {
      return <MessageCell data={item} reload={reload} />
    }
  },
  { label: "" },
];