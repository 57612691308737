import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import SectionTitle from 'Common/SectionTitle';

import { contractDetailsConfig } from './config';
import { useStyles } from "../style";

export default function ContractDetails({ data, reportPeriodFrom }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  function renderRowContent(row, data) {
    if (row.render) return row.render(reportPeriodFrom);
    return data?.[row.id] ?? "-";
  }

  return (
    <div className={classes.section}>
      <SectionTitle title={`A. Contract Details`} fontStyle={theme.typography.h5} />
      <div style={{margin: "12px 0 24px 0"}}>
        {contractDetailsConfig.map((row, index) =>
          <Grid container spacing={1} style={{ paddingLeft: 24 }} key={index}>
            <Grid item xs={12} sm={6} className={classes.infoTitle}>{row.label} :</Grid>
            <Grid item xs={12} sm={6}>{renderRowContent(row, data)}</Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}