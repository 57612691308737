import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

import SectionTitle from 'Common/SectionTitle';
import DocumentTable from "Common/Documents/DocumentsTable";
import { setLoading } from 'redux/slice/loadingSlice';
import edmsFileHelper from 'helpers/edmsFileHelper';

import { tableConfig } from '../CommonDocumentList/config';
import EDMSContainer from '../CommonContainer';

export default function EDMSFolder() {
  const [folderDetails, setFolderDetails] = useState({});
  const { contractId, folderId } = useParams();
  const [isRestricted, setIsRestricted] = useState(null);

  useEffect(() => {
    getDirectory();
  }, [folderId])

  async function getDirectory() {
    try {
      const variable = { fileId: folderId }
      const result = await edmsFileHelper.getFileMetas(variable);
      if (result.error) throw new Error(result.error);
      setFolderDetails(result);
      setIsRestricted(null);
    } catch (e) {
      console.log(e);
      setIsRestricted(e.message);
    }
  }

  return (
    <EDMSContainer>
      <SectionTitle title={folderDetails.code ?? ""} />
      {isRestricted
        ? isRestricted
        : <DocumentTable
          data={folderDetails.meta_data ?? []}
          fieldsData={tableConfig}
          tableType="edms"
          hidePdfBtn
        />}
    </EDMSContainer>
  );
}