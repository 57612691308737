export const DE03SafetyOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Environmental Inspection Checklist",
    value: "Environmental Inspection Checklist",
  },
  {
    label: "Notice of Safety for Lifting Operation",
    value: "Notice of Safety for Lifting Operation",
  },
  {
    label: "Rainstom_Checklist",
    value: "Rainstom_Checklist",
  },
  {
    label: "Safety Inspection Checklist",
    value: "Safety Inspection Checklist",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const DE04SafetyOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Mosquito Control Checklist",
    value: "Mosquito Control Checklist",
  },
  {
    label: "Site Safety Inspection Checklist",
    value: "Site Safety Inspection Checklist",
  },
  {
    label: "Weekly Environmental Inspection Checklist",
    value: "Weekly Environmental Inspection Checklist",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const DC06SafetyOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Anti-Mosquito and Site Cleanliness Daily Checklist",
    value: "Anti-Mosquito and Site Cleanliness Daily Checklist",
  },
  {
    label: "Anti-Mosquito and Site Cleanliness Weekly Checklist",
    value: "Anti-Mosquito and Site Cleanliness Weekly Checklist",
  },
  {
    label: "Daily Safety Inspection / Checklist",
    value: "Daily Safety Inspection / Checklist",
  },
  {
    label: "Inspection Checklist for Heatstroke Prevention",
    value: "Inspection Checklist for Heatstroke Prevention",
  },
  {
    label: "Notice of Safety Deficiencies",
    value: "Notice of Safety Deficiencies",
  },
  {
    label: "Notice of Safety for Lifting Operation",
    value: "Notice of Safety for Lifting Operation",
  },
  {
    label: "Safety Checklist (TESTING)",
    value: "Safety Checklist (TESTING)",
  },
  {
    label: "Safety Inspection Checklist (Trial)",
    value: "Safety Inspection Checklist (Trial)",
  },
  {
    label: "Weekly Safety Inspection / Checklist",
    value: "Weekly Safety Inspection / Checklist",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const DC07SafetyOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Daily Safety Inspection / Checklist",
    value: "Daily Safety Inspection / Checklist",
  },
  {
    label: "Notice of Safety Deficiencies",
    value: "Notice of Safety Deficiencies",
  },
  {
    label: "Others",
    value: "Others",
  },
]