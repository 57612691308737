import axios from './apiHelper';

const bimHelper = {
  getProjectStatus: async (variables) => {
    try {
      const result = await axios.get(`/bim-files-items`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getDocumentManagementDirectory: async (variables) => {
    try {
      const result = await axios.get(`/bim-files`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  }, 
  getBimFileCount: async (variables) => {
    try {
      const result = await axios.get(`/bim-file-count`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get bim file count`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getBimFileLineChart: async (variables) => {
    try {
      const result = await axios.get(`/bim-file-linechart`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get bim file count`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRISCs: async (variables) => {
    try {
      const result = await axios.get(`/riscs`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafeties: async (variables) => {
    try {
      const result = await axios.get(`/safeties`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getCleansings: async (variables) => {
    try {
      const result = await axios.get(`/cleansings`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
};

export default bimHelper;