import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  navigationLink: {
    display: "inline-block",
    "&:hover": {
      color: theme.palette.primaryLight
    }
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  groupItem: {
    fontSize: 16,
    cursor: "pointer",
    display: "block",
  },
  group: {
    transition: "max-height .15s ease-out",
    overflow: "hidden",
  },
  subGroup: {
    marginLeft: 12,
  },
  collapsed: {
    maxHeight: 0,
  },
  expanded: {
  },
  btnControl: {
    textAlign: "left",
    display: "inline-block",
    width: "12px",
    "&:hover": {
      color: theme.palette.primaryLight,
    }
  },
  groupTitle: {
    fontWeight: 600,
    fontSize: 16,
    cursor: "pointer",
    display: "inline-block",
  },
}));
