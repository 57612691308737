import { useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Drawer, useTheme } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { bimProjectStatusConfig } from 'EDMS/CommonDocumentList/config';
import bimHelper from 'helpers/bimHelper';
import IconButton from "Common/IconButton";
import CommonDocumentList from 'EDMS/CommonDocumentList';
import FilterPage from 'Common/FilterPage';
import SectionTitle from 'Common/SectionTitle';
import CommonContainer from 'EDMS/CommonContainer';

import { useStyles } from "./style";

export default function BIMProjectStatus() {
  const theme = useTheme();
  const { contractId } = useParams();
  const classes = useStyles(theme);
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [filter, setFilter] = useState({
  });
  const extraFilter = { type: "items", contractId: contractId };

  async function handleFilterChange(e) {
    setFilter(e);
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  function getUpdatedDate() {
    let dateTime = moment().set("hour", 23);
    dateTime.set("minute", 0);
    if (moment() < dateTime) {
      dateTime.add("day", -1);
    }
    return dateTime.format("YYYY-MM-DD HH:mm");
  }

  return (
    <CommonContainer>
      <SectionTitle title="BIM - Project Status"
        extraComponents={<div>
          <span style={{ fontWeight: 600, marginRight: 8 }}>Last Updated Time: {getUpdatedDate()}</span>
          <IconButton
            icon={<FilterListIcon />}
            onClick={handleOpenFilter}
          />
        </div>}
      />

      <CommonDocumentList
        tableType={"bimStatus"}
        useFilter={useFilter}
        filter={filter}
        getDataApi={bimHelper.getProjectStatus}
        defaultOrderBy="lastModifiedTime"
        responseKey={"bim_file"}
        hideGoToButton
        extraFilter={extraFilter}
      />

      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={bimProjectStatusConfig} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter }} />
      </Drawer>
    </CommonContainer>
  );
};