import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import clsx from 'clsx';

import { folderMenuOptions } from 'config/rightClickMenuOptions';
import { useStyles } from "./style";

export default function FolderRow({ folder, setAnchorEl, setMenuOptions }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();

  useEffect(() => {
    async function init() {
      try {
        // const result = await edmsFileHelper.getFileGroups(contractId);
      } catch (e) {
        console.log(e);
      }
    }
    init();
  }, []);

  async function onContextMenu(e, id) {
    e.preventDefault();
    setMenuOptions(await folderMenuOptions(handleClose, history, contractId, id));
    setAnchorEl(e.target);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function redirectToFolder(id) {
    history.push(`/contract/${contractId}/edms/folder/${id}`);
  }

  return (
    <div
      onClick={() => redirectToFolder(folder.id)}
      onContextMenu={(e) => onContextMenu(e, folder.id)}
      className={clsx(classes.folderRow)}
    >
      <div className={classes.folderInfo}>
        <div className={classes.code}>{folder.code}</div>
        <div style={{ margin: "0 4px" }}>-</div>
        <div>{folder.name}</div>
      </div>
      <div className={classes.folderRemarks}>
        {folder.labels.map((label, idx) => <div key={idx} className={classes.codeLabel}>{label.code}</div>)}
      </div>
    </div>
  );
}