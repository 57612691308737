import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useLocation } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import Modal from "Common/Modal";
import { riskHelper } from "helpers";
import { setNotification } from "redux/slice/notificationSlice";
import fieldHelper from "utils/fieldHelper";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import Loading from "Common/Loading";
import AddAttachmentsForm from "./AddAttachmentsForm";
import CloseIcon from "static/icons/close.svg";

import { earlyWarningFieldConfig } from "./config";
import { useStyles } from "./style";
import ConfirmButton from "Common/ConfirmButton";

export default function EarlyWarningAddForm({ mode, handleClose, reload, enableRedirection, data }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [pendingAttachments, setPendingAttachments] = useState({});
  const [modal, setModal] = useState({ type: "", open: false, data: null });

  const { register, handleSubmit, control, formState: { errors }, setError, clearErrors } = useForm({
    defaultValues: {
      ...data,
      conditionsOfContractClause: data?.conditionsOfContractClause,
      requestForChange: mode !== "Add" ? (data?.requestForChange ? "1" : "0") : "",
      meetingRequired: mode !== "Add" ? (data?.meetingRequired ? "1" : "0") : "",
    }
  })

  const sp = new URLSearchParams(location.search);

  async function onSubmit(data) {
    try {
      setLoading(true);
      let docId = data.id ?? null;
      let result;
      const hasAttachments = pendingAttachments.files && pendingAttachments?.files?.length > 0;
      const contractClause = Object.values(data.conditionsOfContractClause).filter(item => item);
      if (contractClause.length === 0) {
        setLoading(false);

        setError('conditionsOfContractClause', { type: 'required', message: 'Conditions of Contract Clause is Required' });
        return;
      }
      clearErrors("conditionsOfContractClause");
      let requestVariables = {
        contractId,
        ...data,
        meetingRequired: Boolean(Number(data.meetingRequired)),
        requestForChange: Boolean(Number(data.requestForChange)),
      };
      if (mode === "Add") {
        result = await riskHelper.createEarlyWarning(requestVariables);
        if (result.error) throw new Error(result.error);
        docId = result.risk_early_warning.id;
        dispatch(setNotification({ type: "success", message: "New Early Warning Notification Created" }));
      } if (mode === "Assign") {
        requestVariables = { contractId, riskIdReference: data.riskIdReference };
        result = await riskHelper.editEarlyWarning({ id: data.id, variables: requestVariables });
        dispatch(setNotification({ type: "success", message: "Successfully Assign Risk ID Reference" }));
      } else {
        result = await riskHelper.editEarlyWarning({ id: data.id, variables: requestVariables });
        dispatch(setNotification({ type: "success", message: "Early Warning Notification Updated" }));
      }

      if (hasAttachments) {
        const result = await Promise.all([pendingAttachments.attachments.map(
          (item, idx) => riskHelper.uploadEarlyWarningAttachment({ id: docId, variables: { file: pendingAttachments.files[idx] } }))
        ]);
        reload();
      }

      if (reload && !hasAttachments) {
        reload();
      } else {
        if (enableRedirection) history.push(`/contract/${contractId}/risk-register/early-warning/${result.risk_early_warning.id}`);
        else {
          const updated = sp.has('updated') ? sp.get('updated') : 1;
          history.push({ pathname: location.pathname, search: `risk-register?tab=early-warning&updated=${Number(updated) + 1}` });
        }
      }
      handleClose();
      setLoading(false);
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function handleModalClose() {
    setModal({ type: modal.type, open: false, data: null });
  }

  async function handleDelete() {
    try {
      // const result = await incidentHelper.deleteIncident(data.id);
      // if (result.error) throw new Error(result.error);
      // const updated = sp.has('updated') ? sp.get('updated') : 1;
      // history.push({ pathname: location.pathname, search: `tab=incident&updated=${Number(updated) + 1}` });
      // dispatch(setNotification({ type: "success", message: "Successfully delete incident" }));
      // handleClose();
    } catch (error) {
      console.log(error);
      if (error.message) dispatch(setNotification({ type: "error", message: error.message }))
    }
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={mode === "Assign" ? `Edit Early Warning Notification` : `${mode} Early Warning Notification`}
          extraComponents={<IconButton onClick={handleClose}>
            <img src={CloseIcon} className={classes.Icon} alt={"close"} />
          </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3} className={classes.section}>
            {earlyWarningFieldConfig.map((inputField, idx) => {
              if (mode !== "Assign" && inputField.name === "riskIdReference") return <Grid item xs={4} key={idx}></Grid>
              return fieldHelper.renderField({ ...inputField, disabled: (mode === "Assign" && inputField.name !== "riskIdReference") },
                idx, register, control, errors, { size: inputField.size }, clearErrors)
            })
            }
          </Grid>
          {mode !== "Assign" &&
            <div style={{ textAlign: "end", marginTop: 16 }}>
              <Button children={"Add Reference Documents"} backgroundColor="#70BAD3" type="button" width="200px" onClick={() => setModal({ type: "Add", open: true, data: pendingAttachments })} />
            </div>
          }
        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          {/* {canChange && <ConfirmButton children={"Delete"} type="button" message="Confirm to Delete?" onClick={handleDelete} />} */}
          {<Button children={mode === "Add" ? "Add" : "Save"} backgroundColor="#70BAD3" type="submit" />}
        </DialogActions>

      </form>

      {mode !== "Assign" &&
        < Modal open={modal.open} handleClose={handleModalClose}>
          <AddAttachmentsForm data={pendingAttachments} setAttachments={setPendingAttachments} handleClose={handleModalClose} />
        </Modal>
      }
    </>
  );
}