import { useParams, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Drawer } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { tableConfig } from "../CommonDocumentList/config";
import IconButton from "Common/IconButton";
import FilterPage from 'Common/FilterPage';
import typeHelper from 'utils/typeHelper';
import CommonDocumentList from '../CommonDocumentList';
import { useStyles } from "./style";
import SectionTitle from 'Common/SectionTitle';

export default function EDMSDocumentList({ type, selectedData, handleSelect }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { contractId } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
  });

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };
  function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  return (
    <>
      <SectionTitle
        title={handleSelect ? "" : `${typeHelper.getTypeTitle(type)} List`}
        extraComponents={
          <div style={{ marginLeft: "16px" }}>
            <IconButton
              icon={<FilterListIcon />}
              onClick={handleOpenFilter}
            />
          </div>
        }
      />
      <CommonDocumentList
        type={type}
        useFilter={useFilter}
        filter={filter}
        page={page}
        selectedData={selectedData}
        handleSelect={handleSelect}
      />
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={tableConfig} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter, setPage }} />
      </Drawer>
    </>
  );
}