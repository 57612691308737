import PropTypes from "prop-types";
import { ResponsivePie } from '@nivo/pie';
import { animated } from '@react-spring/web';

import { chartTheme } from '../config';
import { useStyles } from '../style';

function PieChart({
  data,
  id = "id",
  value = "value",
  margin = { top: 30, right: 30, bottom: 30, left: 30 },
  innerRadius = 0,
  colors = ['#3D66E9', '#70BAD3', "#B9C8F7", '#181935', '#08636e', '#83BD95', '#5FB59A'],
  enableArcLabels = true,
  enableArcLinkLabels = true,
  enablePercentageLabel = false,
  sortByValue = true,
  legends = [
    {
      anchor: 'bottom-left',
      direction: 'column',
      justify: false,
      translateX: 0,
      translateY: 20,
      itemsSpacing: 0,
      itemWidth: 100,
      itemHeight: 18,
      itemOpacity: 0.9,
      itemTextColor: '#fff',
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 18,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemTextColor: '#fff'
          }
        }
      ]
    }
  ],
  overlay = "default",
}) {
  const props = { margin };
  const classes = useStyles(props);
  const total = enablePercentageLabel ? getTotalValue() : "";
  function getTotalValue() {
    let total = data.reduce((total, item) => {
      return total + (isNaN(item[value]) ? 0 : Number(item[value]));
    }, 0);

    return total;
  }
  function handlePercentageLabel(e) {
    const label = isNaN(e.value) ? 'N/A' : (Number(e.value) / total * 100).toFixed(2) + "%";
    return `${label}`;
  }

  function customLabel(e) {
    const { label, style } = e;
    return <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
      <text
        textAnchor="middle"
        dominantBaseline="central"
        fill={style.textColor}
        style={{
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        {label}
      </text>
    </animated.g>;
  }

  function customTooltip(e) {
    const { datum } = e;
    return <div className={classes.toolTipBackground}>
      <div key={datum.id} className={classes.tooltipItem}>
        <div>
          <span className={classes.dot} style={{ background: datum.color }}></span>
          <span className={classes.groupName}>{datum.label}</span >
        </div>
        <div className={classes.groupText}>{datum.value}</div>
      </div>
    </div>;
  }
  return (
    <div className={classes.root}>
      <ResponsivePie
        sortByValue={sortByValue}
        data={data}
        value={value}
        id={id}
        margin={margin}
        innerRadius={innerRadius}
        colors={colors}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ theme: 'background' }}
        enableArcLabels={enableArcLabels}
        enableArcLinkLabels={enableArcLinkLabels}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabel={enablePercentageLabel ? handlePercentageLabel : true}
        arcLabelsSkipAngle={10}
        arcLabelsComponent={customLabel}
        arcLabelsTextColor="#FFF"
        // legendLabel={(e)=> {console.log(e); return '123'} }
        legends={legends}
        // valueFormat={customValueFormat}
        tooltip={customTooltip}
        theme={chartTheme}
      />
      <div className={classes.overlay}>
        {overlay === "default"
          ? <>
            <span className={classes.overlayText}>Total</span>
            <span className={classes.overlayTitle}>{total}</span>
          </>
          : overlay
        }
      </div >
    </div >
  );
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  margin: PropTypes.object,
  innerRadius: PropTypes.number,
  colors: PropTypes.oneOfType([PropTypes.array, PropTypes.func, PropTypes.object]),
  enableArcLabels: PropTypes.bool,
  enableArcLinkLabels: PropTypes.bool,
  enablePercentageLabel: PropTypes.bool,
  legends: PropTypes.arrayOf(PropTypes.object),
  overlay: PropTypes.node,
};

export default PieChart;