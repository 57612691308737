import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect, Fragment } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';

import { permissionHelper } from 'helpers';
import SectionTitle from 'Common/SectionTitle';
import Pagination from "Common/Pagination";
import Loading from 'Common/Loading';
import EllipseButton from 'Common/EllipseButton';

import CommonContainer from 'EDMS/CommonContainer';
import { reportIncomingConfig, reportOutgoingConfig, dummyData } from './config';
import { useStyles } from "./style";

export default function EDMSFolderSummary({ }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isRestricted, setIsRestricted] = useState();
  const [data, setData] = useState([]);
  const [isShowAll, setIsShowAll] = useState(false);
  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      setIsLoading(true);
      setData(dummyData);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsRestricted(e.message);
      setIsLoading(false);
    }
  }

  function renderTableCell(column, row) {
    if (column.render) {
      return column.render(row);
    }
    return row[column.id] ?? "-";
  }

  function renderTableHeader(type) {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.headerCell} colSpan={16} align="center" style={{ background: "#2F3441" }}>{type} Outstanding</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.headerCell} colSpan={7} align="center">Incoming Document</TableCell>
          <TableCell className={classes.headerCell} style={{ width: "1%" }}></TableCell>
          <TableCell className={classes.headerCell} colSpan={8} align="center">Outgoing Document</TableCell>
        </TableRow>
        <TableRow>
          {reportIncomingConfig.map((column, idx) =>
            <TableCell style={{ width: column.size }} className={classes.headerCell} key={idx}>{column.label}</TableCell>
          )}
          <TableCell className={classes.headerCell}></TableCell>
          {reportOutgoingConfig.map((column, idx) =>
            <TableCell style={{ width: column.size }} className={classes.headerCell} key={idx}>{column.label}</TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }

  function renderTableBody(type) {
    return (
      <TableBody>
        {data.map((row, idx) => {
          return (
            <TableRow>
              {reportIncomingConfig.map((column, idx) =>
                <TableCell className={classes.tableCell} key={idx}>{type === "Incoming" && renderTableCell(column, row)}</TableCell>
              )}
              <TableCell className={classes.headerCell} style={{ width: "1%" }}></TableCell>
              {reportOutgoingConfig.map((column, idx) =>
                <TableCell className={classes.tableCell} key={idx}>{type === "Outgoing" && renderTableCell(column, row)}</TableCell>
              )}
            </TableRow>
          )
        })}
      </TableBody>
    );
  }

  return (
    <CommonContainer>
      <Loading isLoading={isLoading} />
      <SectionTitle
        title="Correspondence Report"
        extraComponents={<div className={classes.buttonsContainer}>
          <EllipseButton onClick={() => alert('WIP')} label={`Export`} />
        </div>}
      />
      <div className={classes.body}>
        {isRestricted && <div style={{ color: "white" }}>{isRestricted}</div>}
        <Table>
          {renderTableHeader("Incoming")}
          {renderTableBody("Incoming")}
          {renderTableHeader("Outgoing")}
          {renderTableBody("Outgoing")}
        </Table>

      </div>
    </CommonContainer>
  );
}