import { Fragment } from "react";
import { useFieldArray } from "react-hook-form";
import { Grid, IconButton } from "@material-ui/core";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import fieldHelper from "utils/fieldHelper";
import { useStyles } from "./style";

export default function ParticipantsFieldArray({ control, register, name, errors, defaultValue }) {
  const classes = useStyles();
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${name}`,
    keyName: "_id",
  });

  return (
    <div style={{ width: '100%', marginTop: 12 }}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3} className={classes.label}>Present</Grid>
        <Grid item xs={3} className={classes.label}>Designation</Grid>
        <Grid item xs={3} className={classes.label}>Organization</Grid>
        <Grid item xs={3}></Grid>
        {fields.length > 0 &&
          fields.map((item, i) => {
            return (
              <Fragment key={item._id}>
                {fieldHelper.renderField(
                  { label: "Present", name: `${name}[${i}].present`, isRequired: true, hideLabel: true, default: defaultValue?.[i]?.present ?? "" },
                  0, register, control, errors, { size: 3 })
                }
                {fieldHelper.renderField(
                  { label: "Designation", name: `${name}[${i}].designation`, isRequired: true, hideLabel: true, default: defaultValue?.[i]?.designation ?? "" },
                  1, register, control, errors, { size: 3 })
                }
                {fieldHelper.renderField(
                  { label: "Organization", name: `${name}[${i}].organization`, isRequired: true, hideLabel: true, default: defaultValue?.[i]?.organization ?? "" },
                  2, register, control, errors, { size: 3 })
                }

                <Grid item xs={1} sm={1} style={{ textAlign: "end" }}>
                  <div style={{ height: "100%" }}>
                    <IconButton
                      color="default"
                      component="span"
                      onClick={() => {
                        remove(i);
                      }}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Fragment>
            )
          })}
        <Grid item xs={10} sm={10} style={{ textAlign: "end" }}>
          <IconButton
            color="default"
            component="span"
            onClick={() => {
              append({ present: "", designation: "", organization: "" });
            }}>
            <AddCircleRoundedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}