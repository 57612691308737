import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Divider, Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';

import SectionTitle from 'Common/SectionTitle';
import Collapse from "Common/Accordion";
import { activityConfig } from './config';
import { useStyles } from "./style";
import React from 'react';

export default function DocumentActivity({ activities, padding = "unset", margin = "unset" }) {
  const theme = useTheme();
  const props = { theme, padding, margin };
  const classes = useStyles(props);
  if (!activities && (!activities.length || activities.length === 0)) return "No Activities"

  function renderAccordionHeader(data) {
    const sumOfPlant = Array.isArray(data.plant)
      ? (calculateSumOf(data.plant, 'plant_working_count') ?? 0) + (calculateSumOf(data.plant, 'plant_idle_count') ?? 0)
      : "0";
    const sumOfLabour = Array.isArray(data.labour)
      ? calculateSumOf(data.labour, 'labour_count')
      : "0";
    return (
      <div style={{ padding: "8px 0px 16px 16px", width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} style={{ width: "10%" }}>Location</TableCell>
              <TableCell className={classes.headerCell} style={{ width: "20%" }}>Sub-location</TableCell>
              <TableCell className={classes.headerCell} style={{ width: "50%" }}>Activity</TableCell>
              <TableCell className={classes.headerCell} style={{ width: "10%" }}>Labour / People</TableCell>
              <TableCell className={classes.headerCell} style={{ width: "10%" }}>Plant / Equipment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>{renderTableValue(data.location)}</TableCell>
              <TableCell className={classes.tableCell}>{renderTableValue(data.sub_location)}</TableCell>
              <TableCell className={classes.tableCell}>{renderTableValue(data.activity)}</TableCell>
              <TableCell className={classes.tableCell}>{sumOfLabour}</TableCell>
              <TableCell className={classes.tableCell}>{sumOfPlant}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  function calculateSumOf(data, key) {
    try {
      return data.reduce(function (accumulator, currentValue) {
        return accumulator + Number(currentValue[key])
      }, 0)
    } catch (e) {
      console.log(e);
    }
  }

  function renderActivityRow(rowData, row, data) {
    if (typeof (rowData) === typeof ({})) {
      // console.log(rowData)
      if (row.id === "labour") return renderLabourPlantRow(data);
      else if (row.id === "plant") return <></>;
    }
    return <><Grid item xs={2}>{row.label}: </Grid><Grid item xs={8}>{rowData}</Grid></>
  }
  function renderLabourPlantRow(data) {
    return <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={4} className={classes.tableCell}>Labour</TableCell>
          <TableCell colSpan={6} className={classes.tableCell}>Equipment</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableCell}>Trade</TableCell>
          <TableCell className={classes.tableCell}>Code</TableCell>
          <TableCell className={classes.tableCell}>No</TableCell>
          <TableCell className={classes.tableCell}>Working Hour</TableCell>
          <TableCell className={classes.tableCell}>Type</TableCell>
          <TableCell className={classes.tableCell}>ID</TableCell>
          <TableCell className={classes.tableCell}>Working No.</TableCell>
          <TableCell className={classes.tableCell}>Idling No.</TableCell>
          <TableCell className={classes.tableCell}>Idling Code</TableCell>
          <TableCell className={classes.tableCell}>Working Hour</TableCell>
        </TableRow>
        {data.labour.map((record, idx) =>
          <TableRow key={idx}>
            <TableCell className={classes.tableCell}>{renderTableValue(record.labour_type)}</TableCell>
            <TableCell className={classes.tableCell}>{renderTableValue(record.labour_code)}</TableCell>
            <TableCell className={classes.tableCell}>{renderTableValue(record.labour_count)}</TableCell>
            <TableCell className={classes.tableCell}>{renderTableValue(record.labour_hours)}</TableCell>

            <TableCell className={classes.tableCell}>{renderTableValue(data.plant[idx].plant_type)}</TableCell>
            <TableCell className={classes.tableCell}>{renderTableValue(data.plant[idx].plant_working_id)}</TableCell>
            <TableCell className={classes.tableCell}>{renderTableValue(data.plant[idx].plant_working_count)}</TableCell>
            <TableCell className={classes.tableCell}>{renderTableValue(data.plant[idx].plant_idle_count)}</TableCell>
            <TableCell className={classes.tableCell}>{"-"}</TableCell>
            <TableCell className={classes.tableCell}>{renderTableValue(data.plant[idx].plant_working_hours)}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  }

  function renderTableValue(value) {
    if (!value || value === "") return "-";
    else return value;
  }

  // <div className={classes.header}>{item.activity}</div>

  return (
    <div className={classes.root}>
      <SectionTitle title={"Activity"} />
      <div style={{ marginTop: "16px" }}>
        {activities.map((item, idx) =>
          <Collapse
            key={idx}
            iconBorder
            header={renderAccordionHeader(item)}
            detail={<div className={classes.column}>
              {activityConfig.map((row, rowIdx) =>
                row.id !== "plant" &&
                <React.Fragment key={rowIdx}>
                  <Grid container className={classes.row}>
                    {renderActivityRow(item[row.id], row, item)}
                  </Grid>

                  <Divider />

                </React.Fragment>
              )}
            </div>}
            defaultExpanded={false}
          />

        )}
      </div>
    </div>
  );
}

DocumentActivity.propTypes = {
  activities: PropTypes.array.isRequired,
  padding: PropTypes.string,
  margin: PropTypes.string,
};