import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: props => props.padding,
  },
  container: {
    alignItems: "center",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
  },
  chartFilter: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "end",
  }
}));