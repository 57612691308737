import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: theme.palette.text.primary,
  },
  message: {
    marginRight: "30px"
  },
  avatar: {
    cursor: "pointer",
    background: theme.palette.primaryBlueLight
  }
}));
