import axios from "./innoHelper";


const innoDocHelper = {
  returnDoc: async ({ contractNo, variable }) => {
    try {
      const result = await axios.post(`innoshare${contractNo}/webapi/api/blockchain/documentreturn`, variable);

      if (!result.data) {
        throw new Error("Fail to post returndoc");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default innoDocHelper;