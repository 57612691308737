export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    link: "dashboard"
  },
  {
    label: "Search",
    key: 1,
    link: "search"
  },
  // {
  //   label: "Risk Register",
  //   key: 2,
  //   link: "list"
  // },
];

export const summaryTableConfig = [
  { id: null, label: "" },
  { id: "label", label: "Document Type" },
  { id: "value", label: "No. of Records" },
];

