import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import { authHelper, riskHelper } from 'helpers';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import DocumentHeader from 'Document/DocumentHeader';
import InfoItem from 'Common/InfoItem';

import { useStyles } from "../style";
import DocumentAttachment from 'Document/DocumentAttachment';
import EarlyWarningAddForm from 'RiskRegister/EarlyWarningAddForm';
import Modal from 'Common/Modal';
import EarlyWarningDisplay from './EarlyWarningDisplay';

export default function EarlyWarningDetailsView() {
  const contractData = useSelector(selectContractData);
  const { docId } = useParams();
  const location = useLocation();
  const [permission, setPermission] = useState();
  const [modalOpen, setModalOpen] = useState({ open: false, type: null });
  const sp = new URLSearchParams(location.search);
  const searchParam = sp.get("tab");
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState({});

  useEffect(() => {
    try {
      initData();
    } catch (e) {
      console.log(e)
    }
  }, [docId])

  async function initData() {
    try {
      const result = await riskHelper.getEarlyWarning(docId);
      if (!result.risk_early_warning) throw new Error("Data not found");
      setData(result.risk_early_warning);
      const permissionResult = await authHelper.checkGroupPermission();
      setPermission(permissionResult);
    } catch (e) {
      console.log(e);
    }
  }

  function renderContent() {
    return <EarlyWarningDisplay data={data} />
  }

  function isAECOM() {
    return !data?.riskIdReference && permission.aecom;
  }

  function isAbleToEdit() {
    const isContractor = permission.contractor;
    if (data.riskIdRef || isContractor) return true;  // is &&
    else return false;
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "attachments":
        return <DocumentAttachment hideAdd data={(data && data.documents) ?? {}} reload={initData} moduleType={"edms"} />;
      default:
        return (
          <>
            <DocumentHeader data={data} type={"early-warning"} contractData={contractData} padding="0px 0px 20px 0" />
            <div className={classes.body}>
              <SectionTitle title="Early Warning Notification"
                extraComponents={
                  <div className={classes.buttonsContainer}>
                    {isAbleToEdit() && <EllipseButton label="Edit" margin={"0 8px 0 0"} onClick={() => setModalOpen({ open: true, type: "Edit" })} />}
                    {isAECOM() && <EllipseButton label="+ Assign Risk ID Ref" onClick={() => setModalOpen({ open: true, type: "Assign" })} />}
                  </div>
                }
              />
              {renderContent()}
            </div>

            <Modal open={modalOpen.open} handleClose={() => setModalOpen({ open: false, type: null })}>
              <EarlyWarningAddForm mode={modalOpen.type} data={data} reload={initData} handleClose={() => setModalOpen({ open: false, type: null })} />
            </Modal>
          </>
        );
    }
  }

  return (
    <div className={classes.root}>
      {
        (!!searchParam && searchParam !== "basic-info")
        && <DocumentHeader data={data} type={"early-warning"} contractData={contractData} padding="0px 0px 20px 0" />
      }
      {returnDocumentContent()}
    </div>
  );
}