export const docInOutOptions = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "In",
    value: "in"
  },
  {
    label: "out",
    value: "out"
  },
];

export const reportTypeOptions = [
  {
    label: "Unscanned",
    value: "unscanned"
  },
];

export const actionReportTypeOptions = [
  {
    label: "Action",
    value: "action"
  },
]