import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { useTheme } from "@material-ui/core/styles";
import { AppBar, Drawer, Toolbar, Divider, CssBaseline, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import NavbarContent from 'NavbarContent';
import ProfileMenu from 'Common/ProfileMenu';
import { useStyles } from "./style";

export default function Home({ pageType, currentPage, children, overrideGoBack }) {
  const history = useHistory();
  const location = useLocation();
  const atHome = location.pathname === "/" ? true : false;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(true);
  const username = localStorage.getItem('username') || sessionStorage.getItem('username');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") setOpen(false);
    else if (location.state === "overview") setOpen(true);
  }, [location.pathname]);

  function handleDrawerOpen() {
    setOpen(true);
  }
  function handleDrawerClose() {
    setOpen(false);
  }

  function goHome() {
    history.push('/');
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" className={open ? `${classes.appBarShift} ${classes.appBar}` : `${classes.appBar}`}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.rowItem}>
            {atHome
              ? null
              : <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={`${classes.menuButton} ${open ?? classes.hide}`}
              >
                <MenuIcon />
              </IconButton>
            }
            <img className={classes.logo} src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" onClick={goHome} />
          </div>
          <div className={classes.rowItem}>
            <Divider className={classes.divider} orientation="vertical" flexItem />
            <ProfileMenu name={username ?? "User"} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} className={classes.icon}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <NavbarContent pageType={pageType} overrideCurrentPageName={currentPage} overrideGoBack={overrideGoBack} />
      </Drawer>
      <main className={open ? `${classes.content} ${classes.contentShift}` : classes.content}>
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}

Home.propTypes = {
  children: PropTypes.any,
};
