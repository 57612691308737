import { useState, useEffect } from 'react';
import { Grid, useTheme } from '@material-ui/core';

import { bimHelper, contractHelper, dashboardHelper, riskHelper } from 'helpers';
import BoxItem from 'Common/BoxItem';

import { useStyles } from "./style";

export default function ContractsOverview({ parentTab, type, contracts }) {
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);
  const [data, setData] = useState([]);
  const [edmsData, setEDMSData] = useState([]);

  useEffect(() => {
    initData();
  }, [contracts])

  async function initData() {
    try {
      const sortedContract = sortByContract(contracts, 'asc');
      if (parentTab === "risk") {
        if (type.id === "risk-meeting") {
          const result = await Promise.all(sortedContract.map(contract => riskHelper.getRiskMeetings({ contractId: contract.id })))
          const data = result.map(record => record.count);
          const concat = sortedContract.map((contract, idx) => {
            return {
              ...contract,
              count: data[idx],
            }
          })
          setData(concat);
        } else {
          const result = await Promise.all(sortedContract.map(contract => riskHelper.getRiskRegisters({ contractId: contract.id })))
          const data = result.map(record => record.count);
          const concat = sortedContract.map((contract, idx) => {
            return {
              ...contract,
              count: data[idx],
            }
          })
          setData(concat);
        }
      } else if (parentTab === "bim") {
        const result = await Promise.all(sortedContract.map(contract => bimHelper.getBimFileCount({ contractId: contract.id })));
        const concat = sortedContract.map((contract, idx) => {
          return {
            ...contract,
            count: result[idx][type.id]
          }
        })
        setData(concat);
      } else if (parentTab === "photo") {
        const result = await Promise.all(sortedContract.map(contract => dashboardHelper.getPhotoDashboard({ route: "total-photos", variable: { contractId: contract.id } })));
        const concat = sortedContract.map((contract, idx) => {
          return {
            ...contract,
            count: result[idx][type.id]
          }
        })
        setData(concat);
      }
      else {
        const paramType = (parentTab === "incident" || parentTab === "safety") ? "edms" : parentTab
        const result = await contractHelper.getContractsTypeCount({ type: paramType, fileType: type.id });
        const contractShouldDisplay = sortedContract.map((item => item.id));
        const resultShouldDisplay = result.contract.filter(item => contractShouldDisplay.includes(item.id))
        let hash = new Map();

        sortedContract.concat(resultShouldDisplay).forEach(function (obj) {
          hash.set(obj.id, Object.assign(hash.get(obj.id) || {}, obj))
        });
        let a3 = Array.from(hash.values());
        setData(a3);
      }


    } catch (e) {
      console.log(e)
    }
  }

  function sortByContract(array, ordering) {
    try {
      if (array.length > 0) {
        let a = array.sort(function (a, b) {

          var x = a.contractNo.split('/');
          var y = b.contractNo.split('/');

          if (ordering === 'asc') return ((x[2] < y[2]) ? -1 : ((x[2] > y[2]) ? 1 : 0));
          else return ((x[2] < y[2]) ? -1 : ((x[2] < y[2]) ? 1 : 0));
        });
        return a;
      }
      return array;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.body} spacing={2}>
        {data.map((item, idx) =>
          item &&
          <Grid item xs={12} sm={4} md={2} key={idx}>
            <BoxItem onHover={false} background="#B9B9B9" padding={"5px 0"}>
              <div className={classes.column}>
                <span className={classes.title}>Contract</span>
                <span className={classes.title}>{item.contractNo}</span>
                <span className={classes.content}>{item.count ?? 0}</span>
              </div>
            </BoxItem>
          </Grid>
        )}
      </Grid>
    </div >
  );
};

ContractsOverview.propTypes = {
}