import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from "moment";

import decryptHelper from 'utils/decryptHelper';
import SectionTitle from 'Common/SectionTitle';
import InfoItem from 'Common/InfoItem';

import LabourTable from './labourTable';
import { useStyles } from "./style";
import React from 'react';

export default function DocumentDecrypted({ data, padding = "unset", margin = "unset", pdf }) {
  const { type } = useParams();
  const theme = useTheme();
  const props = { theme, padding, margin };
  const classes = useStyles(props);

  function renderContent(record, row) {
    if (!record && typeof record !== "boolean") return "-"
    if (row.render) return row.render(record);
    if (row.type === "Boolean") if (record) return "Yes"; else return "No";
    if (row.type === "BooleanReverse") if (record) return "No"; else return "Yes";
    if (row.type === "Datetime") return moment(record).format('yyyy-MM-DD HH:mm');
    if (row.type === "Date") return moment(record).format('yyyy-MM-DD');
    if (typeof (record) === typeof ([])) {
      return record.join(",");
    }
    return record;
  }

  return (
    <div className={classes.container}>
      <SectionTitle title={"Details"} fontStyle={theme.typography.h4} />
      <Grid container spacing={5} className={classes.row}>
        <Grid item xs={12} md={6} className={classes.pdfWrapper}>
          {pdf
            ?
            <iframe
              aria-label={"decrpytedDoc"}
              type="application/pdf"
              src={pdf}
              width="100%"
              height="800"
            />
            : <div className={classes.notFound}></div >
          }
        </Grid>
        <Grid item sm={12} md={6} className={classes.details}>
          {decryptHelper.selectdecryptedRows(type ?? "photo").map((row, idx) =>
            <React.Fragment key={idx}>
              <InfoItem title={row.name} content={renderContent(data[row.key], row)} flexDirection={"row"} />
              <Divider className={classes.divider} />
            </React.Fragment>
          )}
          {type === "labour"
            && <LabourTable data={data} />
          }
        </Grid>
      </Grid>
    </div>
  );
}

DocumentDecrypted.propTypes = {
  data: PropTypes.object.isRequired,
  padding: PropTypes.string,
  margin: PropTypes.string,
  pdf: PropTypes.any,
};