import { useState } from 'react';
import { useLocation } from 'react-router';
import { Route, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';

export default function PrivateRoute({ children, ...rest }) {
  const location = useLocation();
  const [isAuthenticated] = useState(
    localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken")
  );

  return (
    <Route {...rest} render={() => {
      return isAuthenticated
        ? children
        : <Redirect to={{
          pathname: '/login',
          state: { referrer: location }
        }} />
    }} />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
};