import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useTheme } from '@material-ui/core/styles';
import { Drawer } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';

import { authHelper, documentsHelper } from "helpers";
import tableHelper from "utils/tableHelper";

import SiteDiaryAdd from "SiteDiary/SiteDiaryAdd";
import Loading from "Common/Loading";
import SiteDiaryTable from "Common/Documents/DocumentsTable";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/EllipseButton";
import IconButton from "Common/IconButton";
import Pagination from "Common/Pagination";
import FilterPage from "Common/FilterPage";
import Modal from "Common/Modal";

import { useStyles } from "./style";
import { siteDiaryConfig } from "./config";

export default function SiteDiaryForm({ selectedData, handleSelect, externalContractId }) {
  const { contractId } = useParams();
  const theme = useTheme();
  const props = { theme, isSelect: !!handleSelect }
  const classes = useStyles(props);
  const noFilter = {}

  const [data, setData] = useState([])
  const [page, setPage] = useState(1);
  const [size, _] = useState(10);
  const [count, setCount] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState({
    orderBy: "updatedAt",
    sortDirection: "desc"
  })
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRestricted, setIsRestricted] = useState();

  useEffect(() => {
    try {
      setIsLoading(true)
      if (useFilter) getPageData(page, size, filter, sort);
      else getPageData(page, size, noFilter, sort);
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }, [useFilter, filter, sort, page])

  async function getPageData(cPage, cSize, cFilter, cSort) {
    try {
      let filterVariable = { ...cFilter };
      filterVariable.page = cPage;
      filterVariable.items = cSize;
      filterVariable.orderBy = `${cSort.orderBy}.${cSort.sortDirection}`
      filterVariable.contractId = externalContractId ?? contractId;
      const filterKeys = Object.keys(cFilter);
      filterKeys.forEach((key) => {
        if (Array.isArray(cFilter[key])) {
          filterVariable[key] = tableHelper.handleDateTimeFilter(filterVariable[key])
        }
      })

      const result = await documentsHelper.getDocuments({ type: "site-diarys", variable: filterVariable });
      if (result.error) throw new Error(result.error);
      if (!result.site_diary) throw new Error({ error: "site diary data not found" });
      setData(result.site_diary);
      setCount(result.count);
      setIsRestricted(null);
      setIsLoading(false);
      return result;
    } catch (e) {
      if (e.message) setIsRestricted(e.message);
      setIsLoading(false);
      console.log(e);
    }
  };

  const maxPage = () => {
    return Math.ceil(count / 10);
  }

  function handleAddForm() {
    setModalOpen(true);
  };

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  async function handlePageChange(_, target) {
    setIsLoading(true);
    setPage(target);
    setIsLoading(false);
  };

  function handleRequestSort(event, property) {
    const isAsc = sort.orderBy === property && sort.sortDirection === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSort({
      orderBy: property,
      sortDirection: direction,
    });
  };

  function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  function reload() {
    console.log('reload')
    if (useFilter) getPageData(page, size, filter, sort);
    else getPageData(page, size, noFilter, sort);
  }

  function resetFilter() {
    setFilter({})
  }

  return (
    <div className={classes.root}>
      <Loading isLoading={isLoading} />
      <SectionTitle
        title={handleSelect ? "" : "Site Diary List"}
        extraComponents={
          <IconButton
            icon={<FilterListIcon />}
            onClick={handleOpenFilter}
          />
        }
      />
      <div style={{ marginTop: "16px" }}>
        {tableHelper.renderFilterBy(filter, siteDiaryConfig, resetFilter)}
      </div>
      {isRestricted ??
        <SiteDiaryTable
          data={data}
          handleSort={handleRequestSort}
          orderBy={sort.orderBy}
          sortDirection={sort.sortDirection}
          fieldsData={siteDiaryConfig}
          selectedData={selectedData}
          handleSelect={handleSelect}
        />
      }
      {data.length > 0 && <Pagination count={maxPage()} page={page} onChange={handlePageChange} />}
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={siteDiaryConfig} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter }} />
      </Drawer>
      {/* <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
        <SiteDiaryAdd handleClose={() => setModalOpen(false)} contract={contract} reload={reload} />
      </Modal> */}
    </div >
  );
};

SiteDiaryForm.propTypes = {
  data: PropTypes.array
};