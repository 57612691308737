import { fileStatusOptions, sourceOptions } from "config/selectOptions";

export const fieldConfig = [
  { id: "fileId", label: "File ID" },
  { id: "submissionDatetime", label: "Submission Date", isDate: true },
  { id: "updatedAt", label: "Last Updated", isDate: true },
  { id: "fileStatus", label: "Status", type: "Select", options: fileStatusOptions },
  { id: "fileSource", label: "Source", type: "Select", options: sourceOptions },
  { id: "totalLabour", label: "Total Labour", type: "NoFilter" },
  { id: null, label: "" },
  { id: null, label: "" }
];