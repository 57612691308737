import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { useStyles } from "../style";

export default function SafetyFootnote() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Grid container spacing={1} style={{ margin: "2em 0" }}>
        <Grid item xs={12}>Note:-</Grid>
        <Grid item xs={12}>#2 Specified trades are set out in this particular Specifications on Site Safety</Grid>
        <Grid item xs={12}>#3 To be reported half yearly</Grid>
        <Grid item xs={12}>#4 "accident frequency rate for reportable accidents" shall be calculated as the number of reportable accidents per 100,000 man-hours worked</Grid>
        <Grid item xs={12}>#5 Measurement is not made for any period with less than 12 months</Grid>
        <Grid item xs={12}>#6 To be reported once for each year</Grid>
        <Grid item xs={12}>#7 Level 1 assessment refers to the assessment sheet of Considerate Contractors Site Award Scheme (CCSAS) for CCSA and OEMPA respectively with level 1 being the highest rating for each item</Grid>
        <Grid item xs={12}>#8 To be reported six months after completion date or Completion Date or an earlier or later date as notified by the Project Manager</Grid>
      </Grid>

      <Grid container spacing={1} style={{ margin: "2em 0" }}>
        <Grid item xs={12}>
          Important:- If there is any false information, including mis-reporting, late reporting or under reporting of accidents, in the Monthly Report, if considered appropriate,
          the Project Manager shall reflect the mis-reporting in the rating of the relevant items in the Score Card for assessment of the Contractor's site safety performance.
        </Grid>
      </Grid>
    </>
  );
}