export const summaryTableConfig = [
  { id: null, label: "" },
  { id: "inspectionType", label: "Inspection Type" },
  { id: "count", label: "No. of Records" },
];

export const typeSummaryListData = [
  {
    type: "Daily Safety Inspection / Checklist",
    noOfRecords: 9
  },
  {
    type: "Safety Checklist (TESTING)",
    noOfRecords: 4
  },
  {
    type: "Notice of Safety Deficiencies",
    noOfRecords: 2
  },
  {
    type: "Notice of Safety for Lifting Operation",
    noOfRecords: 2
  },
  {
    type: "Safety Inspection Checklist (Trial)",
    noOfRecords: 0
  },
  {
    type: "Anti-Mosquito and Site Cleanliness Daily Checklist",
    noOfRecords: 0
  }
]

export const typeSummaryData =  [
  {
    date: "2020-02-05",
    value: [
      {
        id: "Daily Safety Inspection / Checklist",
        label: "Daily Safety Inspection / Checklist",
        value: 0
      },
      {
        id: "Safety Checklist (TESTING)",
        label: "Safety Checklist (TESTING)",
        value: 0
      },
      {
        id: "Notice of Safety Deficiencies",
        label: "Notice of Safety Deficiencies",
        value: 0
      },
      {
        id: "Notice of Safety for Lifting Operation",
        label: "Notice of Safety for Lifting Operation",
        value: 0
      },
      {
        id: "Safety Inspection Checklist (Trial)",
        label: "Safety Inspection Checklist (Trial)",
        value: 0
      },
      {
        id: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        label: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        value: 0
      },
    ],
  },
  {
    date: "2020-06-07",
    value: [
      {
        id: "Daily Safety Inspection / Checklist",
        label: "Daily Safety Inspection / Checklist",
        value: 0
      },
      {
        id: "Safety Checklist (TESTING)",
        label: "Safety Checklist (TESTING)",
        value: 0
      },
      {
        id: "Notice of Safety Deficiencies",
        label: "Notice of Safety Deficiencies",
        value: 0
      },
      {
        id: "Notice of Safety for Lifting Operation",
        label: "Notice of Safety for Lifting Operation",
        value: 0
      },
      {
        id: "Safety Inspection Checklist (Trial)",
        label: "Safety Inspection Checklist (Trial)",
        value: 0
      },
      {
        id: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        label: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        value: 0
      },
    ],
  },
  {
    date: "2020-10-05",
    value: [
      {
        id: "Daily Safety Inspection / Checklist",
        label: "Daily Safety Inspection / Checklist",
        value: 0
      },
      {
        id: "Safety Checklist (TESTING)",
        label: "Safety Checklist (TESTING)",
        value: 0
      },
      {
        id: "Notice of Safety Deficiencies",
        label: "Notice of Safety Deficiencies",
        value: 0
      },
      {
        id: "Notice of Safety for Lifting Operation",
        label: "Notice of Safety for Lifting Operation",
        value: 0
      },
      {
        id: "Safety Inspection Checklist (Trial)",
        label: "Safety Inspection Checklist (Trial)",
        value: 0
      },
      {
        id: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        label: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        value: 0
      },
    ],
  },
  {
    date: "2020-12-01",
    value: [
      {
        id: "Daily Safety Inspection / Checklist",
        label: "Daily Safety Inspection / Checklist",
        value: 0
      },
      {
        id: "Safety Checklist (TESTING)",
        label: "Safety Checklist (TESTING)",
        value: 0
      },
      {
        id: "Notice of Safety Deficiencies",
        label: "Notice of Safety Deficiencies",
        value: 0
      },
      {
        id: "Notice of Safety for Lifting Operation",
        label: "Notice of Safety for Lifting Operation",
        value: 0
      },
      {
        id: "Safety Inspection Checklist (Trial)",
        label: "Safety Inspection Checklist (Trial)",
        value: 0
      },
      {
        id: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        label: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        value: 0
      },
    ],
  },
  {
    date: "2021-02-01",
    value: [
      {
        id: "Daily Safety Inspection / Checklist",
        label: "Daily Safety Inspection / Checklist",
        value: 5
      },
      {
        id: "Safety Checklist (TESTING)",
        label: "Safety Checklist (TESTING)",
        value: 2
      },
      {
        id: "Notice of Safety Deficiencies",
        label: "Notice of Safety Deficiencies",
        value: 1
      },
      {
        id: "Notice of Safety for Lifting Operation",
        label: "Notice of Safety for Lifting Operation",
        value: 0
      },
      {
        id: "Safety Inspection Checklist (Trial)",
        label: "Safety Inspection Checklist (Trial)",
        value: 0
      },
      {
        id: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        label: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        value: 0
      },
    ],
  },
  {
    date: "2021-05-01",
    value: [
      {
        id: "Daily Safety Inspection / Checklist",
        label: "Daily Safety Inspection / Checklist",
        value: 4
      },
      {
        id: "Safety Checklist (TESTING)",
        label: "Safety Checklist (TESTING)",
        value: 2
      },
      {
        id: "Notice of Safety Deficiencies",
        label: "Notice of Safety Deficiencies",
        value: 1
      },
      {
        id: "Notice of Safety for Lifting Operation",
        label: "Notice of Safety for Lifting Operation",
        value: 0
      },
      {
        id: "Safety Inspection Checklist (Trial)",
        label: "Safety Inspection Checklist (Trial)",
        value: 0
      },
      {
        id: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        label: "Anti-Mosquito and Site Cleanliness Daily Checklist",
        value: 0
      },
    ],
  },
]
export const missData = [
  {
    "quarter": "2020Q1",
    "NC": 65,
    "Near Miss": 40
  },
  {
    "quarter": "2020Q2",
    "NC": 90,
    "Near Miss": 30
  },
  {
    "quarter": "2020Q3",
    "NC": 120,
    "Near Miss": 90
  },
  {
    "quarter": "2020Q4",
    "NC": 20,
    "Near Miss": 10
  }
]

export const trendData = [
  {
    id: "DE/2018/06",
    data: [
      {
        x: "2020Q1",
        y: 4
      },
      {
        x: "2020Q2",
        y: 5
      },
      {
        x: "2020Q3",
        y: 8
      },
      {
        x: "2020Q4",
        y: 7
      },
    ]
  },
  {
    id: "DE/2018/07",
    data: [
      {
        x: "2020Q1",
        y: 3
      },
      {
        x: "2020Q2",
        y: 3
      },
      {
        x: "2020Q3",
        y: 3
      },
      {
        x: "2020Q4",
        y: 3
      },
    ]
  },
  {
    id: "DE/2018/03",
    data: [
      {
        x: "2020Q1",
        y: 2
      },
      {
        x: "2020Q2",
        y: 3
      },
      {
        x: "2020Q3",
        y: 3
      },
      {
        x: "2020Q4",
        y: 2
      },
    ]
  },
  {
    id: "DE/2018/04",
    data: [
      {
        x: "2020Q1",
        y: 2
      },
      {
        x: "2020Q2",
        y: 2
      },
      {
        x: "2020Q3",
        y: 2
      },
      {
        x: "2020Q4",
        y: 1
      },
    ]
  },
]

export const statusData = [
  {
    "quarter": "2020Q1",
    "Completed": 60,
    "Incomplete": 30,
    "Outstanding": 10
  },
  {
    "quarter": "2020Q2",
    "Completed": 65,
    "Incomplete": 15,
    "Outstanding": 20
  },
  {
    "quarter": "2020Q3",
    "Completed": 65,
    "Incomplete": 15,
    "Outstanding": 20
  },
  {
    "quarter": "2020Q4",
    "Completed": 75,
    "Incomplete": 15,
    "Outstanding": 20
  }
]

export const accidentRate = [
  {
    "quarter": "2020Q1",
    "DC/2018/06": 0.04,
    "DC/2018/07": 0.03,
    "DE/2018/03": 0.02,
    "DE/2018/04": 0.02
  },
  {
    "quarter": "2020Q2",
    "DC/2018/06": 0.05,
    "DC/2018/07": 0.03,
    "DE/2018/03": 0.03,
    "DE/2018/04": 0.02
  },
  {
    "quarter": "2020Q3",
    "DC/2018/06": 0.08,
    "DC/2018/07": 0.03,
    "DE/2018/03": 0.03,
    "DE/2018/04": 0.02
  },
  {
    "quarter": "2020Q4",
    "DC/2018/06": 0.07,
    "DC/2018/07": 0.03,
    "DE/2018/03": 0.02,
    "DE/2018/04": 0.01
  }

]



export const safetyFormData = [
  {
    date: "2021-06-01",
    value: 3,
  },
  {
    date: "2021-06-02",
    value: 3,
  },
  {
    date: "2021-06-03",
    value: 6,
  },
  {
    date: "2021-06-04",
    value: 3,
  },
  {
    date: "2021-06-05",
    value: 4,
  },
  {
    date: "2021-06-06",
    value: 5,
  },
  {
    date: "2021-06-07",
    value: 2,
  },
];

export const pieData = [
  {
    id: 'safety',
    label: "Safety Form",
    value: 1105
  },
  {
    id: 'nonCompliaNCe',
    label: "Non-CompliaNCe",
    value: 24
  },
  {
    id: 'urgent',
    label: "Urgent RISC Form",
    value: 17
  }
];
