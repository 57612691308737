import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectContractData } from 'redux/slice/contractSlice';

import { setLoading } from 'redux/slice/loadingSlice';
import { documentHelper } from 'helpers';

import DocumentHistoryLog from 'Document/DocumentHistoryLog';
import DocumentHeader from 'Document/DocumentHeader';
import DocumentDecrypted from 'Document/DocumentDecrypted';
import DocumentAttachment from "Document/DocumentAttachment";
import DocumentRelatedDocumentV2 from 'Document/DocumentRelatedDocumentV2';

import { useStyles } from "./style";

export default function CleansingDocument() {
  const dispatch = useDispatch();
  const contractData = useSelector(selectContractData);
  const { docId, type } = useParams();
  const [docType, _] = useState("cleansing")
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const theme = useTheme();
  const classes = useStyles(theme);
  const [doc, setDoc] = useState({});
  const [pdf, setPdf] = useState();

  useEffect(() => {
    try {
      dispatch(setLoading(true));
      initData();
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e)
    }
  }, [docId])


  async function initData() {
    const result = await Promise.all([
      documentHelper.getDocument({ type: "cleansing", id: docId }),
    ])
    setDoc(result[0]);
    dispatch(setLoading(false));
  }

  useEffect(() => {
    async function getPreview() {
      try {
        const typeDocuments = doc.cleansing.cleansingDocument;
        if (typeDocuments.length > 0) {
          const result = await documentHelper.decryptDocument(typeDocuments[typeDocuments.length - 1].documentId);
          if (result.error) {
            throw new Error({ message: result.error });
          }
          const file = new Blob(
            [result],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);//Open the URL on new Window
          setPdf(fileURL);
        }
      } catch (e) {
        setPdf(null);
      }
    }
    if (doc.cleansing) {
      getPreview();
    }
  }, [doc])

  console.log(doc);

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "history-log":
        return (
          <DocumentHistoryLog data={doc[docType] ?? {}} />
        );
      case "attachments":
        return <DocumentAttachment data={doc[docType] ? doc[docType].cleansingDocument : []} reload={initData} />;
      case "related-documents":
        return <DocumentRelatedDocumentV2 data={doc[docType]} componentType={docType} />;
      default:
        return <DocumentDecrypted data={(doc[docType]) ?? {}} pdf={pdf} padding="20px 0 40px 0" />
    }
  }

  return (
    <div className={classes.root}>
      <DocumentHeader data={doc[docType] ?? {}} padding="0px 0px 20px 0" contractData={contractData} type={docType} />
      {returnDocumentContent()}
    </div>
  );
}