import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { authHelper } from 'helpers';
import TabPanel from 'Common/TabPanel';
import LinkTabs from 'Common/LinkTabs';
import { selectUserPermission } from 'redux/slice/permissionSlice';
import permissionUtils from 'utils/permissionUtils';

import IncidentDashboard from './IncidentDashboard';
import IncidentList from './IncidentList';
import SIList from './SIList';
import { useStyles } from "./style";
import { tabData } from './config';
import CommonContainer from 'EDMS/CommonContainer';

export default function Incident() {
  const location = useLocation();
  const sp = new URLSearchParams(location.search);
  const tabsName = sp.has('tab') ? sp.get("tab") : null;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [activeTab, setActiveTab] = useState(determineTabs());
  const [groupPermission, setGroupPermission] = useState({});
  const accessPermission = useSelector(selectUserPermission);
  const editPermission = permissionUtils.checkPermission(accessPermission, "incident", "edit");
  const siEditPermission = groupPermission.contractor && editPermission;
  const incidentEditPermission = groupPermission.aecom && editPermission;

  function determineTabs() {
    if (tabsName === "incident") return 1
    else if (tabsName === "si") return 2
    else return 0
  }

  useEffect(() => {
    async function initData() {
      setActiveTab(tabsName === "incident" ? 1 : tabsName === "main-contractor" ? 2 : 0);
      setGroupPermission(await authHelper.checkGroupPermission());
    }
    initData();
  }, [tabsName])

  const returnContent = () => {
    switch (tabsName) {
      case "incident":
        return <IncidentList editPermission={incidentEditPermission} />
      case "main-contractor":
        return <SIList editPermission={siEditPermission} />
      default:
        return <IncidentDashboard />
    }
  }

  return (
    <CommonContainer>
      <div className={classes.body}>
        <LinkTabs data={tabData} activeTab={activeTab} />
        {tabData.map((item, index) =>
          <TabPanel key={index} value={activeTab} index={index}>
            {returnContent()}
          </TabPanel>
        )}
      </div>
    </CommonContainer>
  );
};