import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgoround: theme.palette.primaryDark,
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  body: {
    marginTop: "32px",
  },
}));
