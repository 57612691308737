import { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm, useFieldArray } from "react-hook-form";
import { Table, TableRow, TableBody, TableCell, useTheme, IconButton } from "@material-ui/core";
import Delete from '@material-ui/icons/Delete';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { setNotification } from 'redux/slice/notificationSlice';
import { setLoading } from 'redux/slice/loadingSlice';
import CustTableCell from "Common/TableCell";
import TableHeader from 'Common/TableHeader';
import fieldHelper from 'utils/fieldHelper';
import { authHelper, incidentHelper } from 'helpers';
import tableHelper from 'utils/tableHelper';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import Modal from "Common/Modal";
import PreviewComponent from 'Common/PreviewComponent';
import ConfirmPopup from 'Common/ConfirmPopup';

import { incidentSIConfig, siDocumentConfig, siDocumentLeftConfig, siDocumentRightConfig, mainContColumnConfig } from './config';
import { useStyles } from "../style";
import AddAttachmentsForm from 'Incident/AddAttachmentsForm';


export default function SIListDetailsTable({ data, reload, incidentNumber, type }) {
  const { docId, contractId } = useParams();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState({ targetId: null, message: "", type: null });
  const [expandedSIRow, setExpandedSIRow] = useState({});
  const [expandedSIData, setExpandedSIData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState({ open: false, data: {} });
  const [permission, setPermission] = useState({});
  const theme = useTheme();
  const classes = useStyles(theme);
  incidentSIConfig[0] = { id: "siNumber", label: `${type} ${type === "Communication" ? "Nr" : "No."}` };

  const { register, handleSubmit, control, formState: { errors }, reset, formState: { isDirty, dirtyFields }, } = useForm({
    defaultValues: {
      si_docs: expandedSIData ?? []
    },
  });

  const { fields, } = useFieldArray({
    control,
    name: "si_docs",
    keyName: "key"
  });

  async function onSubmit(formData, status) {
    try {
      dispatch(setLoading(true));
      let requestVariables;
      requestVariables = formData.si_docs.map((record, idx) => {
        if (dirtyFields?.si_docs?.[idx]) {
          return {
            id: record.id,
            aecomComment: record.aecomComment,
            noComment: record.noComment,
            recordOnly: record.recordOnly,
            mainContComment: record.mainContComment
          }
        }
        else {
          return {}
        }
      });
      await incidentHelper.updateSIDocsComment(requestVariables);
      if (reload) reloadWithSIDocuments();
      dispatch(setLoading(false));
      handleClosePopup({ targetId: null, message: "", type: null, data: null });
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      dispatch(setLoading(false));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function handleExpandedSIRowChange(row) {
    if (expandedSIRow.id === row.id) {
      if (permission === "aecom" || permission === "chunwo") reset({ si_docs: [] });
      setExpandedSIRow({});
    }
    else {
      setExpandedSIData([]);
      setExpandedSIRow(row)
    };
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (expandedSIRow.id) {
      getSIDocuments();
    }
  }, [expandedSIRow])

  useEffect(() => {
    if (data.length > 0 && expandedSIRow.id) {
      const reloadTarget = data.find(item => item.id === expandedSIRow.id);
      if (reloadTarget) setExpandedSIRow(reloadTarget);
      else setExpandedSIRow({});
    }
  }, [data])

  async function init() {
    setPermission(await authHelper.checkGroupPermission());
  }

  async function reloadWithSIDocuments() {
    await reload();
    await getSIDocuments();
  }

  async function getSIDocuments() {
    try {
      const result = await incidentHelper.getSiteInstructionDocuments({ siteInstructionId: expandedSIRow.id, orderBy: "createdAt.asc" });
      if (permission.aecom || permission.contractor) reset({ si_docs: result.site_instruction_document ?? [] });

      setExpandedSIData(result.site_instruction_document ?? []);
    } catch (error) {
      console.log(error);
    }
  }

  function renderCustTableCell(item, field, idx) {
    return field.id && <TableCell className={classes.cell} key={idx} style={{ maxWidth: 200 }} onClick={() => handleExpandedSIRowChange(item)}>
      {tableHelper.renderLabel(field, item)}
    </TableCell>
  }

  function handleGotoDetails(item) {
    const newWindow = window.open(`/contract/${contractId}/main-contractor/document/${item.id}`, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  function renderSIDocumentDetails(siRowDetails, idx) {
    return <Fragment key={idx}>
      <TableRow>
        <TableCell colSpan={siDocumentConfig.length} style={{ border: 0 }}>
          <SectionTitle
            title='Attachment'
            extraComponents={<div className={classes.buttonsContainer}>{renderButtons(siRowDetails)}</div>}
          />
        </TableCell>
      </TableRow>
      <TableHeader fields={siDocumentConfig} withoutHead />
      {expandedSIData.map((siDocRow, docIdx) =>
        renderSIDocumentsTableRow(siDocRow, docIdx, expandedSIRow.status)
      )}
    </Fragment>
  }

  function handleClickConfirm(e, data) {
    setAnchorEl(e.currentTarget);
    setPopup(data);
  }
  function handleClosePopup() {
    setPopup({ ...popup, targetId: null, type: null, data: null })
  }

  function renderSIDocumentsTableRow(siRowDetails, idx) {
    return <TableRow key={idx}>
      <TableCell style={{ maxWidth: 10 }}>{idx + 1}</TableCell>
      {siDocumentLeftConfig.map((field, idx) => renderCustTableCell(siRowDetails, field, idx))}
      <TableCell>
        <div style={{ display: "flex" }}>
          <PreviewComponent id={siRowDetails.documentId} />
          {!siRowDetails.checkedBy &&
            <IconButton onClick={(e) => handleClickConfirm(e, { targetId: siRowDetails.id, message: "Confirm to Delete the document?", type: "delete" })}>
              <Delete />
            </IconButton>
          }
        </div>
      </TableCell>
      {(permission.aecom && fields.length > 0)
        &&
        <Fragment key={fields[idx].key}>
          {siDocumentRightConfig.map((column, columnIdx) => column.isInput
            ? <TableCell key={columnIdx} style={column.isTextField && { minWidth: 240 }}>
              {fieldHelper.renderField({ ...column, name: `si_docs.[${idx}].${column.id}`, label: null, hideLabel: true, disabled: siRowDetails.mainContComment },
                idx, register, control, errors, { idx, size: 12 })
              }</TableCell>
            : renderCustTableCell(siRowDetails, column, columnIdx)
          )}
          {mainContColumnConfig.map((field, idx) => renderCustTableCell(siRowDetails, field, idx))}
        </Fragment>
      }

      {(permission.contractor && fields.length > 0)
        &&
        <Fragment key={fields[idx].key}>
          {siDocumentRightConfig.map((field, idx) => renderCustTableCell(siRowDetails, field, idx))}
          {mainContColumnConfig.map((column, columnIdx) => column.isInput
            ? <TableCell key={columnIdx} style={column.isTextField && { minWidth: 240, }}>
              {fieldHelper.renderField({ ...column, name: `si_docs.[${idx}].${column.id}`, label: null, hideLabel: true, disabled: !siRowDetails.aecomComment },
                idx, register, control, errors, { idx, size: 12 })
              }</TableCell>
            : renderCustTableCell(siRowDetails, column, columnIdx)
          )}
        </Fragment>
      }
      <TableCell>
        <input {...register(`si_docs.[${idx}].id`)} hidden></input>
      </TableCell>
    </TableRow >;
  }
  function renderButtons(siRowDetails) {
    if (permission.contractor) return <>
      <div style={{ marginRight: 8 }}>
        <EllipseButton
          onClick={() => setModal({ open: true, data: { incidentNumber, siNumber: siRowDetails.siNumber, siId: siRowDetails.id, id: data.id } })}
          label="Add Attachments" />
      </div>
      <EllipseButton type="submit" label="Save comment" />
    </>;
    if (permission.aecom) return <>
      <div style={{ marginRight: 8 }}>
        <EllipseButton type="submit" label="Save comment" />
      </div>
    </>;
    return <></>;
  }

  async function handleDocumentDelete() {
    try {
      dispatch(setLoading(true));
      await incidentHelper.deleteSIDocument(popup.targetId);
      getSIDocuments();
      setPopup({ targetId: null, type: null });
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  }

  function decideAction() {
    switch (popup.type) {
      case "delete":
        return handleDocumentDelete;
      case "return":
        return handleSubmit((d) => onSubmit(d, "return"));
      case "approve":
        return handleSubmit((d) => onSubmit(d, "approve"));
      default:
        return () => console.log('submit');
    }
  }
  function checkKeyDown(e) {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={(e) => checkKeyDown(e)} >
      <Table>
        <TableHeader fields={incidentSIConfig} />
        <TableBody>
          {data.map((item, idx) => (
            <Fragment key={idx}>
              <TableRow hover>
                {incidentSIConfig.map((field, idx) => renderCustTableCell(item, field, idx))}
                <CustTableCell
                  icon={<ChevronRightIcon />}
                  onClick={() => handleGotoDetails(item)}
                  iconColor={theme.palette.primaryBlueLight}
                />
              </TableRow>
              {expandedSIRow.id === item.id && renderSIDocumentDetails(item, idx)}
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <Modal open={modal.open} handleClose={() => setModal({ type: modal.type, open: false, data: { files: [] } })}>
        <AddAttachmentsForm
          handleClose={() => setModal({ type: modal.type, open: false, data: { files: [] } })}
          reload={reloadWithSIDocuments} data={modal.data}
          createOnSave
        />
      </Modal>
      <ConfirmPopup
        anchorEl={anchorEl}
        open={popup.targetId || popup.data ? true : false}
        handleAction={decideAction()}
        handleClose={handleClosePopup}
        targetId={popup.targetId}
        message={popup.message}
      />
    </ form>
  );
}