import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    minHeight: "unset !important",
    padding: "0 12px 0 0",
    cursor: "unset !important"
  },
  summaryContent: {
    margin: "0 !important",
    // height: "48px"
  },
  ExpandButton: {
    borderRadius: "0",
    // height: "100%",
    "&:hover": {
      // background: "#313532",
      opacity: "0.5"
    }
  },
  ExpandIcon: {
    fill: "#FFFFFF",
  }
});
