import { fileStatusOptions, sourceOptions, getSafetyTemplateOptions } from "config/selectOptions";
import { location0603, location0704 } from "config/locationOptionsCombined";

export const safetyConfig = (contractNo) => {
  if (contractNo === "DC/2018/06" || contractNo === "DE/2018/03") {
    return [
      { id: "fileId", label: "File ID" },
      { id: "submissionDatetime", label: "Created Datetime", isDate: true },
      { id: "updatedAt", label: "Last Updated", isDate: true },
      { id: "fileStatus", label: "Status", type: "Select", options: fileStatusOptions },
      { id: "fileSource", label: "Source", type: "Select", options: sourceOptions },
      { id: "template", label: "Type", type: "Select", options: getSafetyTemplateOptions(contractNo) },
      { id: "description", label: "Description" },
      { id: "inspectionLocation", label: "Inspection Location", type: "Autocomplete", options: location0603, insertBlank: true, },
      { id: null, label: "" },
      { id: null, label: "" },
    ];
  } else {
    return [
      { id: "fileId", label: "File ID" },
      { id: "submissionDatetime", label: "Created Datetime", isDate: true },
      { id: "updatedAt", label: "Last Updated", isDate: true },
      { id: "fileStatus", label: "Status", type: "Select", options: fileStatusOptions },
      { id: "fileSource", label: "Source", type: "Select", options: sourceOptions },
      { id: "template", label: "Type", type: "Select", options: getSafetyTemplateOptions(contractNo) },
      { id: "description", label: "Description" },
      { id: "inspectionLocation", label: "Inspection Location", type: "Autocomplete", options: location0704, insertBlank: true, },
      { id: null, label: "" },
      { id: null, label: "" },
    ];
  }
}