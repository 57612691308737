import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  body: {
    marginTop: "12px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
  bottomRightWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: 'end',
  }
}));
