import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';

import { safetyPerformanceHelper } from 'helpers';
import ChartHeader from 'Common/ChartHeader';
import RISCInfoBox from "RISC/RISCInfoBox";
import SectionTitle from 'Common/SectionTitle';

import SafetyPerformanceTrend from './SafetyPerformanceTrend';
import { useStyles } from "../style";
export default function SafetyPerformanceDashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { type, contractId } = useParams();
  const [data, setData] = useState([{}, {}, {}, {}]);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    async function initData() {
      try {
        const result = await Promise.all([
          safetyPerformanceHelper.getSafetyPerformances({ contractId }),
          safetyPerformanceHelper.getSafetyPerformances({ status: "Closed - PM verified", contractId }),
        ]);
        return result;
      } catch (e) {
        console.log(e)
      }
    }
    let isMounted = true;
    initData().then((result) => {
      if (isMounted) setData(result);
    });
    return () => { isMounted = false };
  }, [])

  useEffect(() => {
    if (data.meta_data) getChartData(data.meta_data);
  }, [data, startDate, endDate]);

  function getChartData(records) {
    let ObjMap = {};

    records.forEach(element => {
      var targetKey = element.docType;
      var dateKey = element.createdAt;
      if (!ObjMap[targetKey]) {
        ObjMap[targetKey] = [];
      }
      if (moment(element[dateKey]) > startDate && moment(element[dateKey]) <= endDate) {
        ObjMap[targetKey].push({
          id: element.docType,
          label: element.docType,
          createdAt: element.createdAt
        });
      }
    });
    calculateChartData(ObjMap);
  }

  function calculateChartData(groupedData) {
    const keyList = Object.keys(groupedData);
    const resultArr = [];
    keyList.forEach(element => {
      resultArr.push({
        label: element,
        id: element,
        count: groupedData[element].length,
        value: groupedData[element].length,
      })
    })
    setChartData(resultArr);
  }

  return (
    <>
      <SectionTitle title="Safety Performance Dashboard" />
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"Safety Performance"} value={data[0].count ?? 0} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"PM Verified"} value={data[1].count ?? 0} withDate={false} />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
      <Grid item xs={12} md={12}>
        <div className={classes.bottomRightWrapper}>
          <ChartHeader
            title={''}
            filterType={"quarterRange"}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SafetyPerformanceTrend startDate={startDate} endDate={endDate} hackData={data[1].risk_register ?? []} />
        </Grid>
      </Grid>
    </>
  );
};