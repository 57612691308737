import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';
import CommonContainer from 'EDMS/CommonContainer';
import { actionReportTypeOptions, docInOutOptions } from 'Report/config';
import { useStyles } from "../style";

export default function ActionReportSearch() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const sp = new URLSearchParams(history.location.search);
  const { register, handleSubmit, control, formState: { errors }, } = useForm({
    defaultValues: {
      requestBy: sp.get("requestBy") ?? "",
      toBeDoneBy: sp.get("toBeDoneBy") ?? "",
      status: sp.get("status") ?? "all",
      type: sp.get("type") ?? "action",
    }
  });

  async function onSubmit(data) {
    try {
      const query = constrcutQuery(data);
      history.replace({ pathname: `${history.location.pathname}`, search: `?${query}` });
      history.push({ pathname: `${history.location.pathname}/search`, search: `?${query}` });
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function constrcutQuery(data) {
    let query = "";
    Object.keys(data).forEach((key, idx) => {
      if (data[key]) {
        if (query !== "") query += "&";
        query += `${key}=${data[key]}`;
      }
    });
    return query;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <CommonContainer>
      <SectionTitle title={`Action Report`} />
      <div className={classes.body}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Requested By", name: "requestedBy", isRequired: false, },
              1, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "To Be Done By", name: "toBeDoneBy", isRequired: false, },
              2, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item sm={12} md={4} />
            {fieldHelper.renderField(
              { label: "Status", name: "status", isRequired: false, type: "Select", options: docInOutOptions, },
              3, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Type", name: "type", isRequired: false, type: "Select", options: actionReportTypeOptions, },
              4, register, control, errors, { size: 12, mdSize: 4 })
            }

            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton children="Submit" backgroundColor="#70BAD3" type="submit" />
          </Grid>
        </form>
      </div>
    </CommonContainer >
  );
}