import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BoxItem from "Common/BoxItem";
import { useStyles } from "./style";

export default function OverviewBox({ type, data }) {
  const { contractId } = useParams();
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);

  function renderOverviewBox() {
    return <span className={classes.content}>{data[type.key] ?? 0}</span>;
  }

  return (
    <Link to={`/contract/${contractId}/${type.slug}`}>
      <BoxItem padding="15px 20px" margin="0 10px 12px 0">
        <div className={classes.column}>
          <span className={classes.title}>{type.name}</span>
          <span className={classes.content}>{renderOverviewBox()}</span>
        </div>
      </BoxItem>
    </Link>
  );
}

OverviewBox.propTypes = {
  type: PropTypes.object,
  data: PropTypes.object.isRequired,
};