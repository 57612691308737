import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import decryptHelper from 'utils/decryptHelper';
import InfoItem from 'Common/InfoItem';
import {
  documentHeaderConfig, siteDiaryHeaderConfig, inspectionHeaderConfig, photoHeaderConfig, edmsHeaderConfig,
  riskRegisterHeaderConfig, riskMeetingHeaderConfig, folderHeaderConfig, incidentHeaderConfig, safetyPerformanceConfig, earlyWarningHeaderConfig
} from './config';

import { useStyles } from "./style";
import { Grid } from '@material-ui/core';

export default function DocumentHeader({ data, padding = "unset", margin = "unset", type, contractData, justify }) {
  const combinedData = { ...data, ...contractData }
  const theme = useTheme();
  const props = { theme, padding, margin, justify };
  const classes = useStyles(props);
  function selectConfig() {
    switch (type) {
      case "site-diary":
        return siteDiaryHeaderConfig;
      case "risc":
        return documentHeaderConfig;
      case "safety":
        return inspectionHeaderConfig;
      case "cleansing":
        return inspectionHeaderConfig;
      case "labour":
        return documentHeaderConfig;
      case "photo":
        return photoHeaderConfig;
      case "edms":
        return edmsHeaderConfig;
      case "risk-register":
        return riskRegisterHeaderConfig;
      case "risk-meeting":
        return riskMeetingHeaderConfig;
      case "folder":
        return folderHeaderConfig;
      case "incident":
        return incidentHeaderConfig;
      case "safety-performance":
        return safetyPerformanceConfig;
      case "early-warning":
        return earlyWarningHeaderConfig;
      default:
        return edmsHeaderConfig;
    }
  }

  function renderParticipants() {
    return <>
      <Grid container style={{minHeight: 30}}></Grid>
   
      <Grid container direction="row">
        <Grid item xs={4} sm={2} >
          <div className={classes.headerText}>Present</div>
          {combinedData?.participants && renderParticipantColumn("present")}
        </Grid>
        <Grid item xs={4} sm={2}>
          <div className={classes.headerText}>Designation</div>
          {combinedData?.participants && renderParticipantColumn("designation")}
        </Grid>
        <Grid item xs={4} sm={2}>
          <div className={classes.headerText}>Organization</div>
          {combinedData?.participants && renderParticipantColumn("organization")}
        </Grid>
      </Grid>
    </>
  }

  function renderParticipantColumn(objectKey) {
    return combinedData?.participants.map((item, idx) => <div key={idx} className={classes.contentText}>{objectKey ? item[objectKey] : item}</div>)
  }

  return (
    <div className={classes.infoHeader}>
      {selectConfig(type).map((item, idx) =>
        <InfoItem
          key={idx}
          title={item.name}
          content={decryptHelper.renderLabel(item, combinedData)}
          flexDirection="column" />
      )}
      {type === "risk-meeting" && renderParticipants()}
    </div>
  );
}

DocumentHeader.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  padding: PropTypes.string,
  margin: PropTypes.string,
};