export const incidentSIConfig = [
  { id: "siNumber", label: "No." },
  { id: "contractCovered", label: "Contract Covered", isBoolean: true },
  { id: "issueDate", label: "Issue Date", type: "Date" },
  { id: "createdAt", label: "Create Date", type: "Datetime" },
  { id: "subject", label: "Subject", },
  { id: "location", label: "Location", },
  { id: "commerceDate", label: "Commerce Date", type: "Date" },
  { id: "completionDate", label: "Completion Date", type: "Date" },
  { id: "reasonForSi", label: "Reason for SI", width: 200 },
  { id: "createdBy", label: "Created By" },
  { id: "documentCheckedBy", label: "Checked By" },
  { id: null, label: "" },
  // { id: "", label: "" },
];

export const siDocumentConfig = [
  { label: "#" },
  { id: "uploadDate", label: "Upload Date", type: "Date" },
  { id: "description", label: "Description", },
  { id: "documentType", label: "Attachment Type", },
  { id: "createdBy", label: "Upload By", },
  { label: "", },
  { id: "aecomComment", label: "AECOM Comment", },
  { id: "noComment", label: "No Comment" },
  { id: "recordOnly", label: "Record Only" },
  { id: "documentCheckedDate", label: "Checked On", type: "Date" },
  { id: "documentCheckedBy", label: "Checked By" },
  { id: "mainContComment", label: "Main Cont Comment", isInput: true, default: "", },
  { id: "repliedDate", label: "Replied On", type: "Date" },
  { id: "repliedBy", label: "Replied By" },
  { label: "" },
]

export const siDocumentLeftConfig = [
  { label: "#" },
  { id: "createdAt", label: "Upload Date", type: "Datetime" },
  { id: "description", label: "Description", },
  { id: "documentType", label: "Document Type", },
  { id: "createdBy", label: "Upload By", },
  { label: "" },
];

export const siDocumentRightConfig = [
  { id: "aecomComment", label: "AECOM Comment", isInput: true, default: "", isTextField: true, rows: 3 },
  { id: "noComment", label: "No Comment", type: "Checkbox", isInput: true, default: false },
  { id: "recordOnly", label: "Record Only", type: "Checkbox", isInput: true, default: false },
  { id: "checkedDate", label: "Checked On", type: "Date" },
  { id: "checkedBy", label: "Checked By" },
];

export const mainContColumnConfig = [
  { id: "mainContComment", label: "Main Cont Comment", isInput: true, default: "", isTextField: true, rows: 3 },
  { id: "repliedOn", label: "Replied On", type: "Date" },
  { id: "repliedBy", label: "Replied By" },
];

export const siDocumentFormConfig = [
  {
    label: "Incident No",
    name: "incidentNumber",
    disabled: true
  },
  {
    label: "NCE No. / Communication Nr / SI No.",
    name: "siNumber",
    disabled: true,
  },
  {
    label: "Document Type",
    name: "documentType",
    type: "Select",
    options: [
      {
        label: "NCE",
        value: "NCE"
      },
      {
        label: "Quotation",
        value: "Quotation",
      },
      {
        label: "Supporting",
        value: "Supporting",
      },
      {
        label: "SI",
        value: "SI"
      },
    ]
  },
];