import PropTypes from "prop-types";
import AddIcon from '@material-ui/icons/Add';

import Button from "Common/EllipseButton";

import SelectionConfirmTable from "../SelectionConfirmTable";

function SelectionConfirm({
  numberOfDoc,
  backStage,
  data,
  photos,
  fileMetaData,
  riskData,
  handleSelect
}) {
  return(
    <div>
      {numberOfDoc > 0 
      ?<div>Please confirm that you have selected {numberOfDoc} documents.</div>
      :<div>You haven't selected any documents. Please click "Add more documents".</div>
      }
      <SelectionConfirmTable
        handleSelect={handleSelect}
        data={data}
        photos={photos}
        fileMetaData={fileMetaData ?? []}
        riskData={riskData ?? []}
      />
      <Button
        label="Add more documents"
        startIcon={<AddIcon />}
        onClick={backStage}
      />
    </div>
  );
}

SelectionConfirm.propTypes = {
  numberOfDoc: PropTypes.number,
  backStage: PropTypes.func,
  data: PropTypes.array
};

export default SelectionConfirm;