export const dummyData = [
  {
    id: 1,
    fileNumber: "C05",
    nextFolios: "1",
  }
]

export const tableConfig = [
  {
    id: "placeholder",
  },
  {
    id: "fileNumber",
    label: "File No.",
  },
  {
    id: "nextFolios",
    label: "Next Folios",
  },
  {
    id: "placeholder",
    label: "Set Next Folios"
  },
];