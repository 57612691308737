const chartHelper = {
	setWorkCatColorByKey(data, key) {
		// '#8c564b', '#9467bd', '#d62728', '#2ca02c', '#ff7f0e', '#1f77b4'
		let result = data;
		for (let i = 0; i < data.length; i++) {
			let target = data[i][key];
			if (target === "ABWF") result[i].color = "#1f77b4";
			else if (target === "BS") result[i].color = "#ff7f0e";
			else if (target === "Civil") result[i].color = "#2ca02c";
			else if (target === "E&M") result[i].color = "#d62728";
			else if (target === "Landscape") result[i].color = "#6467bd";
			else if (target === "Survey") result[i].color = "#8c564b";
			else if (target === "C") result[i].color = "#808000";
			else if (target === "E") result[i].color = "#bfef45";
			else if (target === "ITTest") result[i].color = "#a9a9a9";
			else if (target === "M") result[i].color = "#911eb4";
			else if (target === "Mechanical") result[i].color = "#800000";
			else result[i].color = "#e377c2";
		}
		return result;
	},
	setWeatherColorByKey(data, key) {
		let result = data;
		for (let i = 0; i < data.length; i++) {
			let target = data[i][key];
			if (target === "Cloudy") result[i].color = "#1f77b4";
			else if (target === "Rain") result[i].color = "#ff7f0e";
			else if (target === "Fine") result[i].color = "#2ca02c";
			else if (target === "Shower") result[i].color = "#d62728";
			else if (target === "Drizzle") result[i].color = "#6467bd";
			// else if (target === "Survey") result[i].color = "#8c564b";
			else result[i].color = "#8c564b";
		}
		return result;
	},
	checkIsNotEmpty(data) {
		try {
			const result = data.reduce(function (sum, current) {
				return sum + current.value;
			}, 0);

			if (result > 0) return true;
			else return false;
		} catch (e) {
			console.log(e);
			return false;
		}
	},
	setStatusColor(data, key) {
		// '#8c564b', '#9467bd', '#d62728', '#2ca02c', '#ff7f0e', '#1f77b4'
		let result = data;
		for (let i = 0; i < data.length; i++) {
			let target = data[i][key].toLowerCase();
			switch (target) {
				case "open":
				case "approved":
				case "quotation":
				case "managed":
					result[i].color = "#1f77b4";
					break;
				case "closed":
				case "closed - pm verified":
				case "si":
					result[i].color = "#ff7f0e";
					break;
				case "nce":
					result[i].color = "#9467bd";
					break;
				case "incident":
					result[i].color = "#d62728";
					break;
				default:
					result[i].color = "#e377c2";
			}
		}
		return result;
	},
}

export default chartHelper;