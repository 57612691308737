import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    padding: 36,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%"
  },
  gridContainer: {
    marginTop: 36
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "24px 18px",
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    color: theme.palette.primaryGrey,
    ...theme.typography.h6,
    marginBottom: "16px"
  },
}));