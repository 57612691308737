import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { setNotification } from "redux/slice/notificationSlice";
import { riskHelper } from "helpers";
import fieldHelper from "utils/fieldHelper";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import Loading from "Common/Loading";

import CloseIcon from "static/icons/close.svg";

import { useStyles } from "./style";
import ParticipantsFieldArray from "./ParticipantsFieldArray";

export default function RiskMeetingAddForm({ mode, handleClose, reload }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const fieldArrayName = "participants"
  const { watch, register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
    defaultValues: {
      participants: [{}]
    }
  })

  async function onSubmit(data) {
    try {
      setLoading(true);
      if (mode === "Add") {
        const requestVariables = { contractId, ...data };
        console.log(requestVariables)
        const result = await riskHelper.createRiskMeeting(requestVariables);
        if (result.error) throw new Error(result.error);
        history.push(`/contract/${contractId}/risk-register/meeting/${result.risk_meeting.id}`);
      }
      handleClose();
      setLoading(false);
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${mode} Risk Reduction Meeting`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"close"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>
          <Grid container spacing={3} className={classes.section}>
            {fieldHelper.renderField(
              { label: "Meeting Date", name: "date", type: "Date", isRequired: true, },
              0, register, control, errors)
            }

            {fieldHelper.renderField(
              { label: "Meeting Start Time", name: "startTime", type: "Time", isRequired: true },
              1, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "Meeting End Time", name: "endTime", type: "Time", isRequired: true },
              2, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "Venue", name: "venue", },
              3, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "Meeting No.", name: "referenceNumber", isRequired: true },
              4, register, control, errors)
            }
          </Grid>

          <ParticipantsFieldArray control={control} name={fieldArrayName} errors={errors} />

        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          <Button children="Add" backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}