export const tableConfig = [
  {
    id: "placeholder",
  },
  {
    id: "name",
    label: "Document Type",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "placeholder",
  },
  {
    id: "placeholder",
  }
];