import PropTypes from "prop-types";
import { TableCell, IconButton } from "@material-ui/core";

import { useStyles } from "./style";

function CustTableCell({
  // title,
  label,
  icon,
  iconColor,
  onClick,
  children,
  padding="checkbox",
  colSpan,
}) {
  const classes = useStyles();
  return(
    <TableCell padding={icon ? "checkbox": "default"}>
      {/* {title && <div className={classes.text}>{title}</div>} */}
      {label && <div className={classes.text}>{label}</div>}
      {icon && (
        <IconButton
          onClick={onClick}
          style={{
            color: iconColor
          }}
        >
          {icon}  
        </IconButton>
      )}
      {children}
    </TableCell>
  );
}

CustTableCell.propTypes = {
  // title: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  onClick: PropTypes.func,
  iconColor: PropTypes.string,
  colSpan: PropTypes.number,
};

export default CustTableCell;