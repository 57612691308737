import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';

import InfoHeader from 'Common/InfoHeader';

import { useStyles } from "./style";

export default function CommonContainer({ children, withInfoHeader = true }) {
  const classes = useStyles();
  const contractData = useSelector(selectContractData);

  return (
    <>
      {withInfoHeader && <InfoHeader data={contractData} />}
      <div className={classes.body}></div>
      {children}
    </>
  );
}