import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState, useEffect } from 'react';

import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import CustTableCell from "Common/TableCell";

import CommonContainer from 'EDMS/CommonContainer';
import { tableConfig, dummyData } from './config';
import { useStyles } from "../style";

export default function FoliosSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();

  function renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {tableConfig.map((column, idx) =>
            <TableCell className={classes.headerCell} key={idx}>{column.label}</TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
  function renderTableBody() {
    return (
      <TableBody>
        {dummyData.map((row, idx) => {
          return (
            <TableRow key={idx}>
              <CustTableCell>{idx + 1}</CustTableCell>
              {tableConfig.filter(item => item.id !== "placeholder").map((col, colIdx) =>
                <CustTableCell key={colIdx}>{row[col.id]}</CustTableCell>
              )}
              <CustTableCell>
                <EllipseButton label="Set Next Folios" onClick={()=>console.log('WIP')}></EllipseButton>
              </CustTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <CommonContainer>
      <SectionTitle
        title={`Folios Setup`}
        extraComponents={
          <div className={classes.buttonsContainer}>
            <EllipseButton onClick={() => history.push(`${history.location.pathname}/add`)} label="Add New" />
          </div>
        }
      />
      <div className={classes.body}>
        <Table style={{maxWidth: 500}}>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </div>
    </CommonContainer>
  );
}