import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  infoItem: props => ({
    display: "flex",
    flexDirection: props.flexDirection,
    marginBottom: "4px",
  }),
  headerText: {
    color: theme.palette.primaryGrey,
    maxWidth: "300px",
    minWidth: "150px"
  },
  rowHeaderText: {
    color: theme.palette.primaryGrey,
  }
}));
