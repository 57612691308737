import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import folderLogo from 'static/icons/folder.png';
import BoxItem from "Common/BoxItem";
import { useStyles } from "./style";

export default function ContractBox({ data }) {
  const theme = useTheme();
  const props = { theme }
  const classes = useStyles(props);

  return (
    <Link to={{pathname: `/contract/${data.id}`, state: "overview"}}>
      <BoxItem padding="15px 20px" margin="0 0px 12px 0">
        <div className={classes.row}>
          <img src={folderLogo} alt={"folder"} />
          <span className={classes.documentName}>{data.contractNo ?? ""}</span>
        </div>
        <div className={classes.column}>
          <span className={classes.detailName}>Title</span>
          <span className={classes.detailContent}>{data.projectTitle ?? ""}</span>
        </div>
        <div className={classes.column}>
          <span className={classes.detailName}>Location</span>
          <span className={classes.detailContent}>{data.projectLocation ?? ""}</span>
        </div>
        <div className={classes.column}>
          <span className={classes.detailName}>Contractor</span>
          <span className={classes.detailContent}>{data.projectContractor ?? ""}</span>
        </div>
      </BoxItem>
    </Link>
  );
}

ContractBox.propTypes = {
  data: PropTypes.object.isRequired,
};