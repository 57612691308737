import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    // padding: 24,
  },
  body: {
    marginTop: "32px",
  },
  label: {
    color: theme.palette.primaryGrey,
    ...theme.typography.h6,
    marginBottom: "8px"
  },
  divider: {
    background: "grey"
  },
  rowBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marginLeft: {
    marginLeft: 8,
  },
  checkboxSection: {
    display: "flex",
    flexDirection: "column",
  },
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
  },
  checkboxName: {
    color: "#fff",
  },
}));