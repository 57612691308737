import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: "16px",
  },
  box: {
    width: "unset",
    height: "100%",
    // background: theme.palette.secondaryDark,
    borderRadius: 5,
    padding: "10px 10px",
    border: `1px ${theme.palette.primaryLight} solid`,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    overflow: "auto",
  },
  groupTitle: {
    color: "#fff",
    fontWeight: 600,
    fontSize: 16,
  },
  parentFolder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderBottom: "2px #fff solid",
    fontWeight: 600,
    fontSize: 16,
  },
  subFolderWrapper: {
    marginLeft: 16,
  },
  folderRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  folderInfo: {
    display: "flex",
    flexDirection: "row",
    margin: "4px 0",
    cursor: "pointer",
  },
  folderRemarks: {
    color: "black",
    display: "flex",
    flexDirection: "row",
  },
  code: {
    minWidth: 30,
  },
  divider: {
    background: "grey",
    marginBottom: 4,
  },
  formDivider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  drawer: {
    backgroundColor: theme.palette.secondaryDark,
    overflowX: "hidden"
  },
  codeLabel: {
    marginLeft: 4,
    width: 20,
    height: 20,
    background: "white",
    borderRadius: 4,
    color: "black",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 2,
  },
}));