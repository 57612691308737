import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    backgoround: theme.palette.primaryDark,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  drawer: {
    backgroundColor: theme.palette.secondaryDark,
    overflowX: "hidden"
  },
}));
