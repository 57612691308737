import axios from './apiHelper';

const dashboardHelper = {
  countEdmsDashboard: async (variable) => {
    try {
      const result = await axios.get(`/dashboard/file-meta/total`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get edms dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  edmsPieChartDashboard: async (variable) => {
    try {
      const result = await axios.get(`/dashboard/file-meta/pie-chart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get edms dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  countFileStatus: async ({ type, variable }) => {
    try {
      const result = await axios.get(`/dashboard/${type}/count-file-status`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getCalculateUrgent: async ({ variable }) => {
    try {
      const result = await axios.get(`dashboard/risc/urgent-calulated`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLateSubmissionTrend: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/risc/late-submission-line-chart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLateSubmissionDistribution: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/risc/late-submission-pie-chart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getNonClosedLateSubmissionTrend: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/risc/non-closed-line-chart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getNonClosedLateSubmissionDistribution: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/risc/non-closed-pie-chart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getWeatherSummaryTable: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/site-diary/weather-summary-table`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getWeatherSummaryPie: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/site-diary/weather-summary-piechart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLabourAvgBar: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/site-diary/labour-avg-barchart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafetyDashboard: async ({ route, variable }) => {
    try {
      const result = await axios.get(`/dashboard/safety/${route}`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafetySummaryTable: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/safety/inspection-type-table`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafetySummaryPie: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/safety/inspection-type-piechart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getCleansingDashboard: async ({ route, variable }) => {
    try {
      const result = await axios.get(`/dashboard/cleansing/${route}`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getCleansingSummaryTable: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/cleansings/checklist-type-table?`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getCleansingSummaryPie: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/cleansings/checklist-type-piechart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLabourDashboard: async ({ route, variable }) => {
    try {
      const result = await axios.get(`/dashboard/labour/${route}`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLabourMonthlyAvg: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/labour/return-workers-monthly-average`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLabourGenderPie: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/labour/male-female-distribution`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLabourDeploymentPie: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/labour/return-workers-deployment`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLabourDeploymentBar: async ({ variable }) => {
    try {
      const result = await axios.get(`/dashboard/labour/return-workers-deployment-barchart`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPhotoDashboard: async ({ route, variable }) => {
    try {
      const result = await axios.get(`/dashboard/photo/${route}`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get dashboard data");
      }
      return result.data
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRiskStatusLine: async (variables) => {
    try {
      const result = await axios.get(`/dashboard/risk-register/status-line-chart`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get line chart data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRiskPieChart: async (variables) => {
    try {
      const result = await axios.get(`/dashboard/risk-register/status-pie-chart`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get pie chart data");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default dashboardHelper;