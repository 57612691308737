export const chartTheme = {
  fontFamily: "'Roboto', sans-serif",
  textColor: '#fff',
  tooltip: {
    color: "#181935"
  },
  legends: {
    text: {
      fill: '#fff',
    }
  },
  grid: {
    line: {
      stroke: '#D8D8D880'
    }
  },
};
