import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: "16px",
  },
  sectionTitle: {
    marginTop: "16px",
  },
  Icon: {
    width: "30px"
  },
  divider: {
    background: "grey"
  },
  docImg: {
    height: 25,
    width: 25,
  },
  fileInfo: {
    marginLeft: "16px",
    display: "flex",
    alignItems: "center",
  },
}));