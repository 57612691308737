import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@material-ui/core";

import { useStyles } from "./style";

function TableHeader({
  fields,
  orderBy,
  sortDirection,
  handleSort,
  withoutHead,
  borderLess,
}) {

  const createSortHandler = (property) => (event) => {
    handleSort(event, property);
  }

  const classes = useStyles();
  function renderContent() {
    return <TableRow>
      {fields.map((field, idx) => {
        if (field.hidden) return null;
        return (
          <TableCell key={idx} className={classes.cell} sortDirection={orderBy === field.id ? sortDirection : false} style={borderLess ? { border: "none" } : {}}>
            {sortDirection && !!field.id
              ?
              <TableSortLabel
                active={orderBy === field.id}
                direction={orderBy === field.id ? sortDirection : 'asc'}
                onClick={createSortHandler(field.id)}
              >
                {field.hideLabel ? "" : field.label}
                {orderBy === field.id ? (
                  <span className={classes.visuallyHidden}>
                    {orderBy === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
              :
              field.label
            }
          </TableCell>
        )
      })}
    </TableRow>;
  }
  if (withoutHead) return (
    renderContent()
  )
  return (
    <TableHead>
      {renderContent()}
    </TableHead>
  );
}

TableHeader.propTypes = {
  fields: PropTypes.array
};

export default TableHeader;