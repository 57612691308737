export const optionGroup = [
  {
    name: "Company",
    value: "company",
  },
  {
    name: "Attention",
    value: "attention",
  },
  {
    name: "Author",
    value: "author",
  },
  {
    name: "Keywords",
    value: "keywords",
  },
  {
    name: "Filed",
    value: "filed",
  },
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Query Consider",
    value: "query-consider"
  },
  {
    name: "Action Required",
    value: "action-required",
  },
  {
    name: "Response to CSF",
    value: "response-csf"
  },
  {
    name: "response to RFI",
    value: "response-rfi"
  },
];


export const tableConfig = [
  {
    label: "Name",
    id: "name"
  },
  {
    id: "placeholder"
  },
  {
    id: "placeholder"
  },
];

export const dummyData = [
  {
    name: "AECOM",

  }
];