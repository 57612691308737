export const dummyData = [
  {
    id: 1,
    barcodeId: "A605575",
    subject: "Test Report No.: SLP21060051, SLP21060052",
    refNumber: "Transimittal No.: SLT2160084",
    lockedBy: "Admin",
    lockedDate: "2022/01/07",
  }
]

export const tableConfig = [
  {
    id: "placeholder",
  },
  {
    id: "barcodeId",
    label: "Barcode ID",
  },
  {
    id: "subject",
    label: "Subject",
  },
  {
    id: "refNumber",
    label: "Ref No."
  },
  {
    id: "lockedBy",
    label: "LockedBy"
  },
  {
    id: "lockedDate",
    label: "Locked Date",
  },
];