import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import { useParams } from 'react-router';

import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Bar } from 'Common/Chart';
import { dailyAvgData } from "./config";
import { useStyles } from "./style";

export default function DailyAvgGraph() {
	const { contractId } = useParams();
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles(theme);
	const [startDate, setStartDate] = useState(moment().startOf('year'));
	const [endDate, setEndDate] = useState(moment().endOf('year'));
	const [data, setData] = useState([]);
	const [keys, setKeys] = useState([]);

	useEffect(() => {
		getDashboardData();
	}, [startDate, endDate]);

	async function getDashboardData() {
		try {
			const variable = {
				contractId,
				startDate: startDate.clone().format('YYYY-MM-DD'),
				endDate: endDate.clone().format('YYYY-MM-DD'),
			}
			const result = await dashboardHelper.getLabourAvgBar({ variable });

			// const result = mockChartApi(startDate, endDate)
			setData(result);
			if (result.length > 0) {
				const keys = Object.keys(result[0]).filter(item => item !== "quarter");
				setKeys(keys);
			}
			dispatch(setLoading(false));
		} catch (e) {
			console.log(e);
		}
	}

	function mockChartApi(start, end) {
		let obj = []
		const data = dailyAvgData.result;
		let newData = data.filter(each => {
			let targetDate = moment(each.date).clone();
			return targetDate.isBetween(start, end);
		});

		obj = [...newData];

		let result = {
			keys: dailyAvgData.keys,
			result: obj,
		}
		console.log(result, '123')
		return result;
	}

	function getKeys(data) {
		if (data.length > 0) {
			return Object.keys(data[0]).filter(item => item !== "quarter");
		}
		return []
	}

	return (
		<ChartContainer height={"400px"} elevation={0}>
			<ChartHeader
				padding={"16px 16px 0 16px"}
				title={'Site Labour Daily Average'}
				filterType={"quarterRange"}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>
			<div style={{ height: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
				{data.length === 0 || (!!data.result && data.result.length === 0)
					? <div>No/Insufficient data for the time period</div>
					: <Bar
						colors={['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf']}
						groupMode="grouped"
						data={data ?? []}
						keys={keys ?? []}
						indexBy="quarter"
						barPadding={0.5}
						enableLabel={false}
						enableTimeIndex={false}
						borderRadius={"8px"}
						axisLeftName={"Number of Labour"}
						margin={{ top: 50, right: 30, bottom: 70, left: 80 }}
						legends={[
							{
								dataFrom: "keys",
								data: keys.map((id, index) => ({
									color: '#1f77b4',
									id,
									label: id
								})),
								anchor: 'top-left',
								direction: 'row',
								justify: false,
								translateX: -25,
								translateY: -40,
								itemsSpacing: 45,
								itemDirection: 'left-to-right',
								itemWidth: 100,
								itemHeight: 20,
								itemOpacity: 0.9,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1
										}
									}
								]
							}
						]}
					/>
				}
			</div>
		</ChartContainer>
	);
}