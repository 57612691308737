import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: 16
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  placeholder: {
    padding: "0 !important",
  }
}));