import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { incidentHelper } from 'helpers';
import SectionTitle from 'Common/SectionTitle';
import SIListDetailsTable from './SIListDetailsTable';

import { useStyles } from "../style";

export default function SIDetails() {
  const { contractId, docId } = useParams();
  const location = useLocation();
  const sp = new URLSearchParams(location.search);
  const searchParam = sp.get("tab");
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      initData();
    } catch (e) {
      console.log(e)
    }
  }, [docId])

  async function initData() {
    try {
      const result = await incidentHelper.getSiteInstructions({ contractId, id: docId });
      if (!result.site_instruction) throw new Error("Data not found");
      console.log(result)
      setData(result.site_instruction);
    } catch (e) {
      console.log(e);
    }
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      default:
        return (
          <div>
            <SectionTitle title={`SI List`} />
            <SIListDetailsTable reload={initData} data={data} incidentNumber={data.incidentNumber} type={data?.[0]?.type} />
          </div>
        );

    }
  }

  return (
    <div className={classes.root}>
      {returnDocumentContent()}
    </div>
  );
}