export const summaryTableConfig = [
  { id: null, label: "" },
  { id: "checklistType", label: "Checklist Type" },
  { id: "count", label: "No. of Records" },
];

export const typeSummaryListData = [
  {
    type: "Daily Cleaning",
    noOfRecords: 5
  },
  {
    type: "Weekly Tidying",
    noOfRecords: 3
  },
  {
    type: "Cleanliness-testing",
    noOfRecords: 1
  },
]

export const typeSummaryData =  [
  {
    date: "2020-02-05",
    value: [
      {
        id: "Daily Cleaning",
        label: "Daily Cleaning",
        value: 0
      },
      {
        id: "Weekly Tidying",
        label: "Weekly Tidying",
        value: 0
      },
      {
        id: "Cleanliness-testing",
        label: "Cleanliness-testing",
        value: 0
      },
    ],
  },
  {
    date: "2020-06-07",
    value: [
      {
        id: "Daily Cleaning",
        label: "Daily Cleaning",
        value: 0
      },
      {
        id: "Weekly Tidying",
        label: "Weekly Tidying",
        value: 0
      },
      {
        id: "Cleanliness-testing",
        label: "Cleanliness-testing",
        value: 0
      },
    ],
  },
  {
    date: "2020-10-05",
    value: [
      {
        id: "Daily Cleaning",
        label: "Daily Cleaning",
        value: 0
      },
      {
        id: "Weekly Tidying",
        label: "Weekly Tidying",
        value: 0
      },
      {
        id: "Cleanliness-testing",
        label: "Cleanliness-testing",
        value: 0
      },
    ],
  },
  {
    date: "2020-12-01",
    value: [
      {
        id: "Daily Cleaning",
        label: "Daily Cleaning",
        value: 0
      },
      {
        id: "Weekly Tidying",
        label: "Weekly Tidying",
        value: 0
      },
      {
        id: "Cleanliness-testing",
        label: "Cleanliness-testing",
        value: 0
      },
    ],
  },
  {
    date: "2021-02-01",
    value: [
      {
        id: "Daily Cleaning",
        label: "Daily Cleaning",
        value: 3
      },
      {
        id: "Weekly Tidying",
        label: "Weekly Tidying",
        value: 1
      },
      {
        id: "Cleanliness-testing",
        label: "Cleanliness-testing",
        value: 0
      },
    ],
  },
  {
    date: "2021-05-01",
    value: [
      {
        id: "Daily Cleaning",
        label: "Daily Cleaning",
        value: 2
      },
      {
        id: "Weekly Tidying",
        label: "Weekly Tidying",
        value: 2
      },
      {
        id: "Cleanliness-testing",
        label: "Cleanliness-testing",
        value: 1
      },
    ],
  },
]

export const statusData = [
  {
    "quarter": "2020Q1",
    "Completed": 60,
    "Incomplete": 30,
    "Outstanding": 10
  },
  {
    "quarter": "2020Q2",
    "Completed": 65,
    "Incomplete": 15,
    "Outstanding": 20
  },
  {
    "quarter": "2020Q3",
    "Completed": 65,
    "Incomplete": 15,
    "Outstanding": 20
  },
  {
    "quarter": "2020Q4",
    "Completed": 75,
    "Incomplete": 15,
    "Outstanding": 20
  }
]


export const cleansingData = [
  {
    "quarter": "2020Q1",
    "DC/2018/06": 40,
    "DC/2018/07": 30,
    "DE/2018/03": 20,
    "DE/2018/04": 20
  },
  {
    "quarter": "2020Q2",
    "DC/2018/06": 50,
    "DC/2018/07": 30,
    "DE/2018/03": 30,
    "DE/2018/04": 20
  },
  {
    "quarter": "2020Q3",
    "DC/2018/06": 80,
    "DC/2018/07": 30,
    "DE/2018/03": 30,
    "DE/2018/04": 20
  },
  {
    "quarter": "2020Q4",
    "DC/2018/06": 70,
    "DC/2018/07": 30,
    "DE/2018/03": 20,
    "DE/2018/04": 10
  }
]

export const cleansingFormData = [
  {
    date: "2021-06-01",
    value: 3,
  },
  {
    date: "2021-06-02",
    value: 3,
  },
  {
    date: "2021-06-03",
    value: 6,
  },
  {
    date: "2021-06-04",
    value: 3,
  },
  {
    date: "2021-06-05",
    value: 4,
  },
  {
    date: "2021-06-06",
    value: 5,
  },
  {
    date: "2021-06-07",
    value: 2,
  },
];