import { useTheme, Popover } from "@material-ui/core";

import RoundedButton from "Common/RoundedButton";
import { useStyles } from "./style";


export default function ConfirmPopup({ anchorEl, open, handleAction, handleClose, targetId, confirmText, cancelText, message }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Popover
      id={targetId}
      open={open ?? false}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={classes.popoverContainer}>
        <div style={{ fontWeight: 600 }}>{message}</div>
        <div style={{ marginTop: 8 }}>
          <RoundedButton
            children={confirmText ?? "Confirm"}
            backgroundColor={theme.palette.primaryLight}
            onClick={() => handleAction(targetId)}
            style={{}}
          />
          <RoundedButton
            children={cancelText ?? "Cancel"}
            backgroundColor={theme.palette.primaryLight}
            onClick={() => handleClose()}
          />
        </div>
      </div>
    </Popover>
  );
}