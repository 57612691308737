import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { DialogTitle, DialogContent, DialogActions, Grid, Divider, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";

import Loading from "Common/Loading";
import { setNotification } from "redux/slice/notificationSlice";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import CloseIcon from "static/icons/close.svg";
import fieldHelper from "utils/fieldHelper";

import { photoFields } from './config';
import { useStyles } from "./style";
import BrowseDocument from "EDMSRegisterDocument/BrowseDocument";
import { photoHelper } from "helpers";

function PhotoAdd({ handleClose, reload, defaultData, type }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const { contractId } = useParams();
  // const username = (localStorage.getItem('username') || sessionStorage.getItem('username')) ?? "";
  const { watch, register, handleSubmit, control, formState: { errors }, setValue, clearErrors } = useForm({
    defaultValues: {
      location1: defaultData?.location1 ?? "",
      photoDesc: defaultData?.photoDesc ?? "",
      takenDatetime: defaultData?.takenDatetime ?? null,
      uploadDatetime: defaultData?.uploadDatetime ?? null,
      takenBy: defaultData?.takenBy ?? "",
      uploadBy: defaultData?.uploadBy ?? "",
    }
  })

  const watchFile = watch("file", false);
  async function onSubmit(data) {
    try {
      setLoading(true)
      let file = data.file[0];
      const variables = (({ file, ...o }) => o)(data);

      let result;
      if (type === "edit") result = await photoHelper.editPhotoRecord({ id: defaultData.id, variables: { contractId: contractId, ...variables } });
      else result = await photoHelper.postPhotoRecord({ contractId: contractId, ...variables });
      if (result.error) throw new Error(result.error);
      if (file) await photoHelper.addPhotoToRecord({ id: result?.photo?.id, variables: { file } });
      dispatch(setNotification({ type: "success", message: "New Photo submitted" }));
      reload();
      handleClose();

    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }))
      setLoading(false)
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={type === "edit" ? "Edit Photo" : "Add Photo"}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <BrowseDocument register={register} name="file" errors={errors} watchFile={watchFile} required={!type} />
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="Photo Info" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            {photoFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors))}
          </Grid>
          <Divider className={classes.divider} />


        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          <Button children={type === "edit" ? "Save" : "Add"} backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}

PhotoAdd.propTypes = {
  handleClose: PropTypes.func
};

export default PhotoAdd;