import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectContractData } from 'redux/slice/contractSlice';

import { documentHelper, contractHelper, edmsFileHelper } from 'helpers';

import DocumentHistoryLog from 'Document/DocumentHistoryLog';
import DocumentHeader from 'Document/DocumentHeader';
import DocumentDecrypted from 'Document/DocumentDecrypted';
// import { dummyData, riskDummyData } from '../CommonDocumentList/config';
import { useStyles } from "./style";
import DocumentAttachment from 'Document/DocumentAttachment';
import DocumentRelatedDocumentV2 from 'Document/DocumentRelatedDocumentV2';

export default function EDMSDetailsView() {
  const contractData = useSelector(selectContractData);
  const { contractId, type, docId } = useParams();
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState({});
  const [pdf, setPdf] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    try {
      initData();
    } catch (e) {
      console.log(e)
    }
  }, [type, docId])

  useEffect(() => {
    if (data.documents && data.documents.length > 0) {
      getPreview(data.documents[data.documents.length - 1].documentId)
    }
  }, [data])

  async function initData() {
    try {
      const result = await Promise.all([
        edmsFileHelper.getFileMeta(docId),
      ])
      if (result[0].error) throw new Error(result[0].error)
      // setDoc(result[0].error ? extraData : result[0]);
      // setDecrypted(result[1].error ? extraData : result[1]);
      const constructed = constructDataShape(result[0]);
      setData(constructed);
    } catch (e) {
      if (e.message) setError(e.message);
      console.log(e);
    }

  }

  function constructDataShape(raw) {
    let fileName = null;
    if (raw.documents && raw.documents.length > 0) fileName = raw.documents[raw.documents.length - 1].filename;
    return { ...raw, fileName };

  }

  async function getPreview(id) {
    try {
      const result = await documentHelper.decryptDocument(id);
      if (result.error) {
        throw new Error({ message: result.error });
      }
      const file = new Blob(
        [result],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);//Open the URL on new Window
      setPdf(fileURL);

    } catch (e) {
      setPdf(null);
    }
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "attachments":
        return <DocumentAttachment data={(data && data.documents) ?? {}} reload={initData} moduleType={"edms"} />;
      case "history-log":
        return <DocumentHistoryLog data={data ?? {}} docType="edms" />;
      case "related-documents":
        return <DocumentRelatedDocumentV2 data={data} componentType="fileMeta" />
      default:
        return (
          <DocumentDecrypted pdf={pdf} data={data ?? {}} padding="20px 0 40px 0" />
        );
    }
  }

  return (
    <div className={classes.root}>
      {error
        ? <div>{error}</div>
        : <>
          <DocumentHeader data={[]} type={type} contractData={contractData} padding="0px 0px 20px 0" />
          {returnDocumentContent()}
        </>
      }
    </div>
  );
}