export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    link: "dashboard"
  },
  {
    label: "Safety Performance",
    key: 1,
    link: "docs"
  },
];

export const incidentFieldConfig = [
  {
    label: "Reporting Month",
    name: "reportingMonth",
    type: "Date",
    isRequired: true,
  },
  {
    label: "Created On",
    name: "createdAt",
    type: "Date",
    isRequired: true,
  },
  {
    label: "Created By",
    name: "createdBy",
    type: "Date",
    isRequired: true,
  },
  {
    label: "Status",
    name: "status",
    isRequired: true,
  },
  {
    label: "Location",
    name: "location",
  },
];