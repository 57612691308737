import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import chartHelper from 'utils/chartHelper';
import { dashboardHelper, incidentHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Line } from 'Common/Chart';

export default function IncidentTrend({ startDate, endDate }) {
	const { contractId } = useParams();
	const dispatch = useDispatch();
	const [data, setData] = useState([]);

	function useDidUpdateEffect(fn, inputs) {
		const didMountRef = useRef(false);

		useEffect(() => {
			if (didMountRef.current)
				return fn();
			else
				didMountRef.current = true;
		}, inputs);
	}

	useDidUpdateEffect(() => {
		async function getDashboardData() {
			try {
				const result = await incidentHelper.getIncidentTrend({
					contractId,
					startDate: startDate.clone().format('YYYY-MM-DD'),
					endDate: endDate.clone().format('YYYY-MM-DD')
				})
				const targetKey = ["SI", "NCE", "Quotation", "Incident", "Supporting"];
				const reversed = result.filter(item => targetKey.includes(item.id));
				let coloredData = chartHelper.setStatusColor(reversed, "id");
				dispatch(setLoading(false));
				return coloredData;
			} catch (e) {
				console.log(e);
			}
		}
		let isMounted = true;
		getDashboardData().then((result) => {
			if (isMounted) setData(result);
		});
		return () => { isMounted = false };
	}, [startDate, endDate])

	return (
		<ChartContainer height={"600px"} elevation={0}>
			<ChartHeader
				title={''}
				filterType={"none"}
			/>
			<div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
				{data.length === 0
					? <div>No/Insufficient data for the time period</div>
					: <Line
						colors={d => d.color}
						data={data}
						xScale={{
							type: 'point',
						}}
						axisLeftName="Number of Submission"
						legends={[
							{
								anchor: 'top-left',
								direction: 'row',
								justify: false,
								translateX: -25,
								translateY: -40,
								itemsSpacing: 50,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.9,
								itemTextColor: "#ffffff",
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1
										}
									}
								]
							}
						]}
					/>
				}
			</div>
		</ChartContainer>
	);
}