import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { useState } from 'react';

import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';
import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";
import { assignOptions } from './config';


export default function ProfileFieldsAssignForm({ type }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const [fieldName, setFieldName] = useState();
  const sp = new URLSearchParams(history.location.search);
  const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
    }
  });

  async function onSubmit(data) {
    try {
      const query = constrcutQuery(data);

      history.replace({ pathname: `${history.location.pathname}`, search: `?${query}` });
      history.push({ pathname: `${history.location.pathname}/search`, search: `?${query}` });
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function constrcutQuery(data) {
    let query = "";
    Object.keys(data).forEach((key, idx) => {
      if (data[key]) {
        if (query !== "") query += "&";
        query += `${key}=${data[key]}`;
      }
    });
    return query;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <CommonContainer>
      <SectionTitle title={fieldName ?? `Response to CSF`} />
      <div className={classes.body}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {assignOptions.map((option, idx) =>
              fieldHelper.renderField(
                { label: option.fieldName, name: option.value, isRequired: false, type: "Checkbox" },
                idx, register, control, errors, { size: 12, mdSize: 4 })
            )}

            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton backgroundColor="#70BAD3" type="submit">{type === "add" ? "Submit" : "Save"}</RoundedButton>
          </Grid>
        </form>
      </div>
    </CommonContainer >
  );
}