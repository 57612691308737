import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgoround: theme.palette.primaryDark,
    width: "100%",
    display: "flex",
    position: 'relative',
    flexDirection: "column"
  },
  headerWrapper: {
    marginBottom: "16px"
  },
  sectionTitle: {
    ...theme.typography.h3,
  },
  mapResponsive: {
    paddingBottom: "56.25%",
    overflow: "hidden",
    "& iframe": {
      left: 0,
      top: 0,
      height: "300px",
      width: "100%",
      position: "absolute",
    }
  },
  mapOffset: {
    marginTop: "48px"
  },
  listGroup: {
    marginTop: "42px",
  },
  listItem: {
    marginBottom: "8px"
  }
}));
