import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  sectionTitle: props => ({
    ...props.fontStyle,
    color: theme.palette.primaryLight,
  }),
  anchorOffset: {
    paddingTop: "70px",
    marginTop: "-70px",
  }
}));
