import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    boxShadow: "none",
    borderRadius: "0 !important",
    marginBottom: "8px"
  },
  AccordionDetails: {
    padding: "0"
  }
});
