import { createMuiTheme } from '@material-ui/core';

export const defaultMaterialTheme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontSize: 48,
      fontWeight: 600,
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
    },
    h3: {
      fontSize: 30,
      fontWeight: 600
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 20,
    },
    h6: {
      fontSize: 16,
    },
    tableHeadCell: {
      fontSize: 16,
      fontWeight: 600
    },
  },
  palette: {
    type: 'dark',
     primary: {
      // light: will be calculated from palette.primary.main,
      main: '#70BAD3',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primaryHeader: "#70BAD3",
    primaryDark: "#181935",
    secondaryDark: "#2F3441",
    primaryLight: "#70BAD3",
    primaryLightHalf: "#70BAD3",
    SecondaryLight: "#B9C8F7",
    primaryBlueDark: "#3D66E9",
    primaryBlueLight: "#2FA2C8",
    primaryGrey: "#B9B9B9",
    primaryPurple: "#67AAC3",
    sidebarBg: "#22233B",
    secondaryYellow: "#FFED9E",
    errorRed: "#FF4F40",
    positiveGreen: "#00C294",
    alertOrange: "#EFAC57",
    bodyBG: "#F6F7F9",
    notFoundBrown: "#fff",
    addButton: "#9A9A9A",
    tickButton: "#ffeb3b",
    secondary: {
      light: '#ff7961',
      main: '#fff',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
  },
  MuiInputLabel: {
    color: "#fff",
  },
  overrides: {
    datePicker: {
      textColor: "#fff",
    },
    MuiDialogActions: {
      root: {
        padding: "24px"
      }
    }
  },

});
