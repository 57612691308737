import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme=>({
  root: {
    position: 'relative',
    width: '100%',
    height: '95%',
  },
  dot: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
  },
  toolTipBackground: {
    background: 'white',
    padding: '9px 12px',
    border: '1px solid #ccc',
  },
  tooltipItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  groupName: {
    color: '#151522',
    fontSize: '14px',
  },
  groupText: {
    color: '#000',
    marginLeft: '15px',
    fontSize: '14px',
  },
  overlay: props => ({
    position: "absolute",
    top: 0,
    right: props.margin.right,
    bottom: props.margin.bottom,
    left: props.margin.left,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2vw",
    color: "#fff",
    textAlign: "center",
    // This is important to preserve the chart interactivity
    pointerEvents: "none",
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    }
  }),
  legend: {
    text: {
      fill: "#fff"
    }
  },
  overlayTitle: {

  },
  overlayText: {

  }
}));
