import { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { useTheme } from '@material-ui/core/styles';
import React from "react";

import Select from "Common/Select";
import SectionTitle from "Common/SectionTitle";
import CustTextField from "Common/TextField";
import Button from "Common/RoundedButton";
import FilterDatePicker from "Common/FilterDatePicker";
import { useStyles } from "./style";

const filterAutocomplete = createFilterOptions();

function FilterPage({
  fieldsData,
  filter,
  setFilter,
  setUseFilter,
  setShowFilter
}) {
  const filterItems = fieldsData.filter(item => !!item.id ?? item.name);
  const classes = useStyles();
  const theme = useTheme();
  const [form, setForm] = useState(filter);

  const handleChange = (e, id) => {
    // console.log({ [id]: e.target.value })
    setForm({
      ...form,
      [id]: e.target.value,
    })
  };

  const handleAutocompleteChange = (value, id) => {
    // console.log({ [id]: value })
    setForm({
      ...form,
      [id]: value,
    })
  }

  const handleDateChange = (e, id) => {
    let newForm = { ...form };
    const splitId = id.split(";");
    const isStart = splitId[1] === "start";
    if (!newForm[splitId[0]] && !newForm[splitId[1]]) newForm[splitId[0]] = [null, null];
    const targetField = newForm[splitId[0]];
    if (isStart) targetField[0] = e;
    else targetField[1] = e;
    setForm(newForm);
  }

  const handleApplyFilter = () => {
    setFilter(form)
    setUseFilter(true);
    setShowFilter(false);
  };

  const handleResetFilter = () => {
    let obj = { ...form };
    filterItems.forEach(item => {
      if (item.isDate) obj[item.id ?? item.name] = null
      else obj[item.id ?? item.name] = null;
    });
    setForm(obj);
    setFilter(obj);
    setShowFilter(false);
  };

  function renderInputField(item) {
    if (item.type === "Select" || item.type === "Read") {
      return <Select
        label={item.label}
        options={item.options}
        value={form[item.id ?? item.name] ?? ""}
        insertBlank={item.insertBlank}
        handleChange={(e) => handleChange(e, item.id ?? item.name)} />
    } else if (item.type === "Autocomplete") {
      return <Autocomplete
        id="combo-box-demo"
        options={item.options}
        value={form[item.id ?? item.name] ?? ""}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.label) {
            return option.label;
          }
          // Regular option
          return option.label;
        }}
        disableClearable
        filterOptions={(options, params) => {
          const filtered = filterAutocomplete(options, params);
          // Suggest the creation of a new value
          // if (params.inputValue !== '') {
          //   filtered.push({
          //     value: params.inputValue,
          //     label: `No match`,
          //   });
          // }
          if (filtered.length > 20) return filtered.slice(0, 20);
          return filtered;
        }}
        selectOnFocus
        blurOnSelect
        freeSolo
        onChange={(e, newValue) => {
          console.log(e);
          if (!newValue) handleAutocompleteChange("", item.id ?? item.name);
          else if (typeof newValue === 'string') {
            handleAutocompleteChange(newValue, item.id ?? item.name);
          } else if (newValue.value && newValue.inputValue) {
            // Create a new value from the user input
            handleAutocompleteChange(newValue, item.id ?? item.name);
          } else {
            handleAutocompleteChange(newValue.value, item.id ?? item.name);
          }
        }}
        renderInput={(params) =>
          <div style={{ height: "100%" }}>
            <div className={classes.label}>{item.label}</div>
            <div style={{ display: "flex" }}>
              <TextField
                onBlur={(e) => handleChange(e, item.id ?? item.name)}
                {...params}
                className={classes.textField}
              />
            </div>
          </div>
        }
      />

    } else {
      return <div style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
        <CustTextField
          label={item.label}
          handleChange={(e) => handleChange(e, item.id ?? item.name)}
          value={form[item.id ?? item.name]}
        />
      </div >
    }

  }

  return (
    <div className={classes.root}>
      <div>
        <SectionTitle title="Filter" />
        <Grid container spacing={4} className={classes.gridContainer}>
          {fieldsData.map((item, idx) => {
            if (item.type === "NoFilter") return null
            else if (item.id ?? item.name) {
              if (item.isDate || item.type === "Datetime" || item.type === "Date") {
                return (
                  <React.Fragment key={idx}>
                    <Grid item xs={12} style={{ padding: 0 }} />
                    {idx % 2 ? <div style={{ width: "100%" }}></div> : null}
                    <Grid item xs={6}>
                      <div>From:</div>
                      <div className={classes.label}>{`${item.label}`}</div>
                      <FilterDatePicker
                        value={(form[item.id ?? item.name] && form[item.id ?? item.name][0]) ?? null}
                        id={`${item.id ?? item.name};start`}
                        handleChange={handleDateChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div>To:</div>
                      <div className={classes.label}>{`${item.label}`}</div>
                      <FilterDatePicker
                        value={(form[item.id ?? item.name] && form[item.id ?? item.name][1]) ?? null}
                        id={`${item.id ?? item.name};end`}
                        handleChange={handleDateChange}
                      />
                    </Grid>
                  </React.Fragment>
                );
              }
              return (
                <Grid key={idx} item xs={item.type === "Autocomplete" ? 12 : item.size ? item.size : 6}>
                  {renderInputField(item)}
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          children="Reset"
          backgroundColor={theme.palette.primaryLight}
          onClick={handleResetFilter}
          style={{}}
        />
        <Button
          children="Filter"
          backgroundColor={theme.palette.primaryLight}
          onClick={handleApplyFilter}
        />
      </div>
    </div >
  )
}

export default FilterPage;