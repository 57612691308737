import moment from "moment";

export const documentHeaderConfig = [
  {
    name: "Contract ID",
    id: "contractNo",
  },
  {
    name: "File ID",
    id: "fileId",
  },
  {
    name: "Status",
    id: "fileStatus",
  },
  {
    name: "Source",
    id: "fileSource",
  },
]

export const siteDiaryHeaderConfig = [
  {
    name: "Contract ID",
    id: "contractNo",
  },
  {
    name: "File ID",
    id: "fileId",
  },
  {
    name: "Site Diary Date",
    id: "submissionDatetime",
    type: "Date",
  },
  {
    name: "Status",
    id: "fileStatus",
  },
  {
    name: "Source",
    id: "fileSource",
  },
]

export const inspectionHeaderConfig = [
  {
    name: "Contract ID",
    id: "contractNo",
  },
  {
    name: "File ID",
    id: "fileId",
  },
  {
    name: "Created Datetime",
    id: "submissionDatetime",
    type: "Datetime"
  },
  {
    name: "Status",
    id: "fileStatus",
  },
  {
    name: "Source",
    id: "fileSource",
  },
]

export const labourHeaderConfig = [
  {
    name: "Contract ID",
    id: "contractNo",
  },
  {
    name: "File ID",
    id: "fileId",
  },
  {
    name: "Submission Datetime",
    id: "submissionDatetime",
    type: "Datetime"
  },
  {
    name: "Status",
    id: "fileStatus",
  },
  {
    name: "Source",
    id: "fileSource",
  },
]

export const photoHeaderConfig = [
  {
    name: "Contract ID",
    id: "contractNo",
  },
]

export const edmsHeaderConfig = [
  {
    name: "Contract ID",
    id: "contractNo",
  },
]

export const riskRegisterHeaderConfig = [
  {
    name: "Risk ID Ref.",
    id: "riskIdReference",
  },
  {
    name: "Date of Issue / Receipt of EW",
    id: "issueDate",
    type: "Date"
  },
  {
    name: "Issued by (DSD / AECOM / Contractor) (Ref No.)",
    id: "issuedBy",
  },
  {
    name: "Incident No.",
    id: "incidentNumber",
  },
  {
    name: "Impact",
    id: "impact",
  },
  {
    name: "Affected Work Section",
    id: "affectedWorkSection",
  },
  {
    name: "Affected Key Date",
    id: "affectedKeyDate",
  },
  {
    name: "Status",
    id: "status",
  },
]

export const riskMeetingHeaderConfig = [
  {
    name: "Meeting Date",
    id: "date",
    type: "Date",
  },
  {
    id: "startTime", name: "Time",
    render: (item) => {
      const start = moment(item.startTime, 'H:mm:ss').format('H:mm');
      const end = moment(item.endTime, 'H:mm:ss').format('H:mm');
      return `${start} - ${end}`;
    }
  },
  {
    name: "Venue",
    id: "venue",
  },
  {
    name: "Meeting No.",
    id: "referenceNumber",
  },
]

export const folderHeaderConfig = [
  {
    name: "File ID",
    id: "filePath",
    render(item) {
      // console.log(item)
      if (item.filePath && item.filePath.length > 0) {
        const arr = item.filePath.map(pathname => pathname.code);

        return arr.join('/')
      }
      else return "-";
    }
  },
  {
    name: "Description",
    id: "name",
  },
];

export const incidentHeaderConfig = [
  {
    name: "Incident No.",
    id: "incidentNumber",
  },
  {
    id: "issueDate",
    name: "Issue Date",
    type: "Date"
  },
  {
    name: "Location",
    id: "location",
  },
  {
    name: "Subject",
    id: "subject",
  },
  {
    id: "createdAt",
    name: "Created Date",
    type: "Date"
  },
  {
    id: "createdBy",
    name: "Created By",
  },
];

export const safetyPerformanceConfig = [
  {
    id: "reportPeriodFrom",
    name: "Reporting Month",
    type: "Month"
  },
  {
    id: "revisionNumber",
    name: "Revision No",
    render: (item) => item?.revisionNumber && item?.revisionNumber.toString(),
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "assignedTo",
    name: "Assigned To",
  },
  {
    id: "comment",
    name: "Action Required",
  },
  {
    id: "updatedAt",
    name: "Updated On",
    type: "Date"
  },
];

export const earlyWarningHeaderConfig = [
  {
    name: "Contractor's Ref No.",
    id: "contractorReferenceNumber",
  },
  {
    name: "Date of Issue / Receipt of EW",
    id: "dateOfIssue",
    type: "Date"
  },
  {
    name: "Issued by",
    id: "issuedBy",
  },
  {
    name: "Risk ID Ref.",
    id: "riskIdReference",
  },
  {
    name: "Assigned on",
    id: "assignedOn",
  },
  {
    name: "Assigned by",
    id: "assignedBy",
  },
]