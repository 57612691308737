import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectUserPermission } from 'redux/slice/permissionSlice';
import TabPanel from 'Common/TabPanel';
import LinkTabs from 'Common/LinkTabs';

import RiskRegisterList from './riskRegisterList';
import RiskReductionMeeting from './riskReductionMeeting';
import RiskDashboard from './RiskDashboard';
import { useStyles } from "./style";
import { tabData } from './config';
import CommonContainer from 'EDMS/CommonContainer';
import EarlyWarningList from './EarlyWarningList';

export default function RiskRegister() {
  const permission = useSelector(selectUserPermission);
  const location = useLocation();
  const sp = new URLSearchParams(location.search);
  const tabsName = sp.has('tab') ? sp.get("tab") : null;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [activeTab, setActiveTab] = useState(determineTabs());

  function determineTabs() {
    if (tabsName === "docs") return 1;
    if (tabsName === "meeting") return 2;
    if (tabsName === "early-warning") return 1;
    else return 0
  }

  useEffect(() => {
    async function initData() {
      setActiveTab(determineTabs());
    }
    initData();
  }, [tabsName])

  const returnContent = (idx) => {
    switch (idx) {
      case 2:
        return <RiskReductionMeeting permission={permission?.risk?.permissions} />
      case 1:
        return <RiskRegisterList permission={permission?.risk?.permissions} />
      // case 1: 
      //   return <EarlyWarningList />
      default:
        return <RiskDashboard />
    }
  }

  return (
    <CommonContainer>
      <div className={classes.body}>
        <LinkTabs data={tabData} activeTab={activeTab} />
        {tabData.map((item, index) =>
          <TabPanel key={index} value={activeTab} index={index}>
            {returnContent(index)}
          </TabPanel>
        )}
      </div>
    </CommonContainer>
  );
};