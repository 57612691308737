import React from "react";
import PropTypes from 'prop-types';

import { Accordion, AccordionDetails } from "@material-ui/core";

import AccordionHeader from "../AccordionHeader";

import { useStyles } from "./style";

function CustAccordion({
  header,
  detail,
  defaultExpanded = false
}){
  const classes = useStyles();
  return(
    <Accordion className={classes.root} defaultExpanded={defaultExpanded}>
      <AccordionHeader>
        {header}
      </AccordionHeader>
      <AccordionDetails className={classes.AccordionDetails}>
        {detail}
      </AccordionDetails>
    </Accordion>
  );
}

CustAccordion.propTypes = {
  header: PropTypes.node.isRequired,
  detail: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
};

export default CustAccordion;
