import { useState, useEffect } from 'react';
import { useStyles } from "./style";
import { useTheme, Grid, useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { setLoading } from 'redux/slice/loadingSlice';
import bim1 from 'static/videos/bim1.mp4';
import bim2 from 'static/videos/bim2.mp4';
import BoxItem from 'Common/BoxItem';
import TabPanel from 'Common/TabPanel';
import Tabs from 'Common/Tabs';
import contractHelper from 'helpers/contractHelper';
import ContractList from './ContractList';
import { tabData, dwssTabs, edmsTabs, riskTabs, pmiTabs, photoTabs, incidentTabs, safetyTabs, bimTabs } from './config';
import ContractsOverview from './ContractsOverview';

export default function Home() {
  const dispatch = useDispatch();
  const [contracts, setContracts] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [subActiveTab, setSubActiveTab] = useState(0);
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    try {
      getContracts();
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e);
    }
  }, [])

  async function getContracts() {
    try {
      const result = await contractHelper.getContractsByGroup();
      setContracts(result);
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  }

  function handleChangeParentTab(e) {
    setSubActiveTab(0);
    setActiveTab(e);
  }

  function handleChangeSubTab(e) {
    setSubActiveTab(e);
  }

  function selectSubTabConfig() {
    switch (tabData[activeTab].id) {
      case 'dwss':
        return dwssTabs;
      case 'edms':
        return edmsTabs;
      case 'risk':
        return riskTabs;
      case 'pmi':
        return pmiTabs;
      case 'photo':
        return photoTabs;
      case 'incident':
        return incidentTabs;
      case 'safety':
        return safetyTabs;
      case 'bim':
        return bimTabs;
      default:
        return [];
    }
  }

  const isMobileView = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <video src={bim1} autoPlay={true} width={isMobileView ? "100%" : "50%"} loop={true} muted />
        <video src={bim2} autoPlay={true} width={isMobileView ? "100%" : "50%"} loop={true} muted />
      </Grid>
      <div className={classes.mapOffset}>
        <ContractList data={contracts} />
      </div>

      <Grid item xs={12} style={{ marginTop: "16px" }}>
        <BoxItem padding="4px 20px" margin="0" onHover={false}>
          <div className={classes.body}>
            <Tabs data={tabData} activeTab={activeTab} handleChange={handleChangeParentTab} />
            <Tabs data={selectSubTabConfig()} activeTab={subActiveTab} handleChange={handleChangeSubTab} isSubTab />
            {selectSubTabConfig().map((item, index) =>
              <TabPanel key={item.id} value={subActiveTab} index={index}>
                <ContractsOverview type={item} contracts={contracts} parentTab={tabData[activeTab].id} />
              </TabPanel>
            )}
          </div>
        </BoxItem>
      </Grid>
    </div>
  );
}
