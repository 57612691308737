import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EllipseButton from "Common/EllipseButton";
import PropTypes from 'prop-types';

import { setLoading } from 'redux/slice/loadingSlice';
import SectionTitle from 'Common/SectionTitle';
import { riskDetailsTableConfig } from './config';

import RiskRegisterDetailsForm from './riskRegisterDetailsForm';
import { useStyles } from "./style";

export default function RiskRegisterDetails({ data, allowEdit, riskRegisterId, reload }) {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 500);

  }, [isEdit]);

  function renderTableCell(column, row) {
    if (column.render) {
      return column.render(row);
    }
    return row[column.id] ?? "-";
  }

  return (
    <div className={classes.container}>
      {isEdit && allowEdit
        ? <RiskRegisterDetailsForm data={data} setIsEdit={setIsEdit} riskRegisterId={riskRegisterId} reload={reload} />
        :
        <>
          <SectionTitle
            title={"Mitigation Measures / Decisions / Updates"}
            fontStyle={theme.typography.h4}
            extraComponents={
              allowEdit &&
              <EllipseButton
                label={isEdit ? "Save Risk Reduction" : <><AddIcon fontSize="small" /><span>Edit Risk Reduction</span></>}
                type="button"
                backgroundColor="transparent"
                onClick={() => setIsEdit(!isEdit)}
              />
            }
          />
          <div className={classes.body}>
            <Table>
              <TableHead>
                <TableRow>
                  {riskDetailsTableConfig.map((column, idx) =>
                    <TableCell className={classes.headerCell} key={idx}>{column.label}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, idx) =>
                  <TableRow key={idx}>
                    {riskDetailsTableConfig.map((column, colIdx) =>
                      <TableCell className={classes.tableCell} key={colIdx}>{renderTableCell(column, row)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </>
      }
    </div>
  );
}