import { createSlice } from "@reduxjs/toolkit";

const docDataSlice = createSlice({
  name: "docData",
  initialState: {
    data: {},
  },
  reducers: {
    setDocData: (state, action) => {
      state.data = action.payload 
    }
  }
});

export const { setDocData } = docDataSlice.actions;

export const selectDocData = state => state.docData.data;

export default docDataSlice.reducer;