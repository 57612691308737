import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import CloseIcon from "static/icons/close.svg";

import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';
import { useStyles } from "../style";

export default function MasterNumberEditModal({ type, handleClose, reload, data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
    }
  });

  async function onSubmit(data) {
    try {
      const query = constrcutQuery(data);

      history.replace({ pathname: `${history.location.pathname}`, search: `?${query}` });
      history.push({ pathname: `${history.location.pathname}/search`, search: `?${query}` });
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function constrcutQuery(data) {
    let query = "";
    Object.keys(data).forEach((key, idx) => {
      if (data[key]) {
        if (query !== "") query += "&";
        query += `${key}=${data[key]}`;
      }
    });
    return query;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`Edit Master Number`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Name", name: "name", isRequired: false, disabled: true },
              1, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Master Number", name: "masterNumber", isRequired: false, },
              2, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton backgroundColor="#70BAD3" type="submit">{type === "add" ? "Submit" : "Save"}</RoundedButton>
          </Grid>
        </form>
      </DialogContent>
    </ >
  );
}