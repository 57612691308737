import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "@material-ui/core";
import ConfirmPopup from "Common/ConfirmPopup";
import { useStyles } from "./style";

function ConfirmButton({
  children,
  startIcon,
  onClick,
  backgroundColor,
  message,
  ...props
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClickConfirm(e, data) {
    setAnchorEl(e.currentTarget);
  }
  function handleClosePopup() {
    setAnchorEl(null);
  }
  function handleAction() {
    onClick();
    handleClosePopup();
  }
  return (
    <>
      <Button
        startIcon={startIcon}
        onClick={handleClickConfirm}
        style={{
          backgroundColor: backgroundColor
        }}
        className={classes.button}
        {...props}
      >
        {children}
      </Button>
      <ConfirmPopup
        anchorEl={anchorEl}
        open={anchorEl ? true : false}
        handleAction={handleAction}
        handleClose={handleClosePopup}
        message={message}
      />
    </>
  );
}

ConfirmButton.propTypes = {
  label: PropTypes.any,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  message: PropTypes.string,
};

export default ConfirmButton;