import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primaryPurple,
    borderColor: theme.palette.primaryPurple,
    borderRadius: "100px",
    textTransform: "none"
  }
}));
