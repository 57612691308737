import { createSlice } from "@reduxjs/toolkit";

const contractSlice = createSlice({
  name: "contract",
  initialState: {
    data: {},
  },
  reducers: {
    setContract: (state, action) => {
      state.data = action.payload
    }
  }
});

export const { setContract } = contractSlice.actions;

export const selectContractData = (state => state.contract.data);

export default contractSlice.reducer;