import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import chartHelper from 'utils/chartHelper';
import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Pie } from 'Common/Chart';
// import { useStyles } from "./style";

export default function RiskRatio({ startDate, endDate }) {
	const dispatch = useDispatch();
	const { contractId } = useParams();
	const [data, setData] = useState([]);

	function useDidUpdateEffect(fn, inputs) {
		const didMountRef = useRef(false);

		useEffect(() => {
			if (didMountRef.current)
				return fn();
			else
				didMountRef.current = true;
		}, inputs);
	}

	useDidUpdateEffect(() => {
		async function getDashboardData() {
			try {
				const result = await dashboardHelper.getRiskPieChart({
					contractId,
					startDate: startDate.clone().format('YYYY-MM-DD'),
					endDate: endDate.clone().format('YYYY-MM-DD'),
				})
				let coloredData = chartHelper.setStatusColor(result, "id");
				dispatch(setLoading(false));
				if (chartHelper.checkIsNotEmpty(coloredData)) return coloredData;
				else return [];
			} catch (e) {
				console.log(e);
			}
		}
		let isMounted = true;
		getDashboardData().then((result) => {
			if (isMounted) setData(result);
		});

		return () => { isMounted = false };
	}, [startDate || endDate]);


	return (
		<ChartContainer height={"600px"} elevation={0}>
			<ChartHeader
				title={'Open vs Closed Risk Ratio'}
				filterType={"none"}
			/>
			<div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
				{data.length === 0
					? <div>No/Insufficient data for the time period</div>
					: <Pie
						colors={({ id, data }) => data.color}
						data={data}
						innerRadius={0.6}
						enableArcLabels={true}
						enableArcLinkLabels={false}
						enablePercentageLabel={true}
						margin={{ top: 10, right: 50, bottom: 150, left: 50 }}
						legends={[
							{
								anchor: 'bottom',
								direction: 'column',
								justify: false,
								translateX: 30,
								translateY: 120,
								itemsSpacing: 0,
								itemWidth: 200,
								itemHeight: 18,
								itemTextColor: '#fff',
								itemDirection: 'left-to-right',
								itemOpacity: 0.9,
								symbolSize: 18,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1
										}
									}
								]
							}
						]}
					/>
				}
			</div>
		</ChartContainer>
	);
}