import PropTypes from "prop-types";
import { RadioGroup, Radio } from "@material-ui/core";

import { useStyles } from "./style";

function CustRadio({
  options,
  label,
  dense,
  field,
  disabled,
  hideLabel
}) {
  const classes = useStyles();

  return (
    <div>
      {!hideLabel && <div className={dense ? classes.denseLabel : classes.label}>{label}</div>}
      <RadioGroup
        row
        onChange={(e) => field.onChange(e.target.value)}
        value={field.value}
      >
        {options.map((option, idx) =>
          <div key={idx}><Radio value={option.value} disabled={disabled} /><span>{option.label}</span></div>
        )}
      </RadioGroup>
    </div>
  );
}

CustRadio.propTypes = {
  label: PropTypes.any,
  options: PropTypes.array,
};

export default CustRadio;