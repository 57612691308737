import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  body: {
    minHeight: "150px"
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
  contentText: {
    ...theme.typography.h6
  },
  column: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  title: props => ({
    ...props.theme.typography.h6,
    color: props.theme.palette.secondary.main,
    textAlign: "center",
  }),
  content: props => ({
    ...props.theme.typography.h2,
    color: props.theme.palette.secondary.main,
    textAlign: "center",
  }),
}));
