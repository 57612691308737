import { Switch, Route, useHistory } from "react-router-dom";
import PrivateRoute from "router/PrivateRoute";
import { useSelector, useDispatch } from "react-redux";

import { selectIsLoading } from 'redux/slice/loadingSlice';
import Loading from 'Common/Loading';
import { selectNotification } from "redux/slice/notificationSlice";
import NotificationPopup from "Common/NotificationPopup";
import MainLayout from "./MainLayout";
import Login from "Login";
import Home from "Home";
import Overview from "Overview";
import Risc from "RISC";
import SiteDiary from "SiteDiary";
import SafetyInspection from "SafetyInspection";
import CleansingInspection from "CleansingInspection";
import Labour from "Labour";
import Photo from "Photos";
import PhotoDocument from "Photos/PhotoDocument";
import RISCDocument from "RISC/RISCDocument";
import SiteDiaryDocument from "SiteDiary/SiteDiaryDocument";
import SafetyDocument from "SafetyInspection/SafetyDocument";
import CleansingDocument from "CleansingInspection/CleansingDocument";
import LabourDocument from "Labour/LabourDocument";
import AddRelatedDoc from "Document/DocumentAddRelatedDocument";
import ResetPassword from "Login/ResetPassword";
import Verification from "Login/Verification";
import EDMS from "EDMS";
import EDMSDetailsView from "EDMS/EDMSDetailsView";
import EDMSFolder from "EDMS/EDMSFolder";
import EDMSFolderList from "EDMS/EDMSFolderList";
import EDMSFolderCreate from "EDMS/EDMSFolderCreate";
import EDMSHome from "EDMS/EDMSHome";
import EDMSFolderProperties from "EDMS/EDMSFolderProperties";
import EDMSFolderSummary from "EDMS/EDMSFolderSummary";
import RiskRegister from "RiskRegister";
import RiskRegisterDetails from "RiskRegister/RiskRegisterDetailsView";
import RiskMeetingDetailsView from "RiskRegister/RiskMeetingDetailsView";
import DailyCheckReport from "Report/DailyCheckReport";
import UnscannedReport from "Report/UnscannedReport";
import AcknowledgeReceiveSearch from "Report/AcknowledgeReceiveSearch";
import DailyCheckReportSearch from "Report/DailyCheckReportSearch";
import UnscannedReportSearch from "Report/UnscannedReportSearch";
import ActionReport from "Report/ActionReport";
import ActionReportSearch from "Report/ActionReportSearch";
import CorrespondenceReport from "Report/CorrespondenceReport";
import CorrespondenceReportSearch from "Report/CorrespondenceReportSearch";
import DocumentTypeSetup from "Setup/DocumentTypeSetup";
import DropdownOptionSetup from "Setup/DropdownOptionSetup";
import FoliosSetup from "Setup/FoliosSetup";
import MasterNumberSetup from "Setup/MasterNumberSetup";
import ProfileFieldsSetup from "Setup/ProfileFieldsSetup";
import UnlockDocument from "Setup/UnlockDocument";
import DocumentTypeSetupForm from "Setup/DocumentTypeSetup/DocumentTypeSetupForm";
import ProfileFieldsSetupForm from "Setup/ProfileFieldsSetup/ProfileFieldsSetupForm";
import ProfileFieldsAssignForm from "Setup/ProfileFieldsSetup/ProfileFieldsAssignForm";
import DropdownOptionsList from "Setup/DropdownOptionSetup/DropdownOptionsList";
import Incident from "Incident";
import IncidentDetails from "Incident/IncidentDetails";
import SIDetails from "Incident/SIDetails";
import SafetyPerformance from "SafetyPerformance";
import BIM from "BIMNew";
import SafetyPerformanceDetails from "SafetyPerformance/SafetyPerformanceDetails";
import BIMProjectStatus from "BIMNew/BIMProjectStatus";
import BIMFolder from "BIMNew/BIMFolder";
import EDMSSearchResult from "EDMS/Search/EDMSSearchResult";
import EarlyWarningDetailsView from "RiskRegister/EarlyWarningDetailsView";
import { fetchConstVar, selectConstVar } from "redux/slice/permissionSlice";
import { useEffect } from "react";

function Layout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(selectIsLoading);
  const notification = useSelector(selectNotification);
  const constVar = useSelector(selectConstVar);

  useEffect(() => {
    async function getConstVar() {
      dispatch(fetchConstVar());
    }
    if (!constVar?.id) getConstVar();
  }, []);

  return (
    <>
      {notification
        && <NotificationPopup type={notification.type} message={notification.message} />
      }
      <Loading isLoading={isLoading} />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/user-activation">
          <Verification />
        </Route>
        <Route path="/contract/:contractId/:type/document/:docId/related-documents">
          <div style={{ color: "white", overflow: "auto" }}>
            <AddRelatedDoc isExternal={true} handleClose={() => console.log('confirm')} />
          </div>
        </Route>
        <PrivateRoute exact path="/contract/:contractId">
          <MainLayout>
            <Overview />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(risc)">
          <MainLayout>
            <Risc />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(photo)/document/:docId">
          <MainLayout pageType={"document"}>
            <PhotoDocument />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(risc)/document/:docId">
          <MainLayout pageType={"document"}>
            <RISCDocument />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(site-diary)/document/:docId">
          <MainLayout pageType={"document"}>
            <SiteDiaryDocument />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(safety-inspection)/document/:docId">
          <MainLayout pageType={"document"}>
            <SafetyDocument pageType={"document"} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(cleansing-inspection)/document/:docId">
          <MainLayout pageType={"document"}>
            <CleansingDocument />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(edms)/document/:docId">
          <MainLayout pageType={"document"}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/:type/document/:docId">
          <MainLayout pageType={"document"}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(labour)/document/:docId">
          <MainLayout pageType={"document"}>
            <LabourDocument />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(risk-register)/document/:docId">
          <MainLayout pageType={"document"}>
            <RiskRegisterDetails />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(risk-register)/meeting/:docId">
          <MainLayout pageType={"document"}>
            <RiskMeetingDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(risk-register)/early-warning/:docId">
          <MainLayout pageType={"document"} overrideGoBack={(contractId) => `/contract/${contractId}/risk-register?tab=early-warning`} >
            <EarlyWarningDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(site-diary)">
          <MainLayout>
            <SiteDiary />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(safety-inspection)">
          <MainLayout>
            <SafetyInspection />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(cleansing-inspection)">
          <MainLayout>
            <CleansingInspection />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(labour)">
          <MainLayout>
            <Labour />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(photo)">
          <MainLayout>
            <Photo />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/:type(audit|ce|cp|csf|ewn|msf|pmi|quotation|rfi|rr|letter)">
          <MainLayout>
            <EDMS />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/pmi/:type(pmi|ce|quotation)">
          <MainLayout>
            <EDMS />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(risk-register)">
          <MainLayout>
            <RiskRegister />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms">
          <MainLayout>
            <EDMSHome />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/search-result">
          <MainLayout overrideGoBack={(contractId) => `/contract/${contractId}/edms?tab=search`}>
            <EDMSSearchResult />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder-list">
          <MainLayout>
            <EDMSFolderList />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder/:folderId">
          <MainLayout>
            <EDMSFolder />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder/:folderId/properties">
          <MainLayout>
            <EDMSFolderProperties />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder-security-report">
          <EDMSFolderSummary />
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/register-document/:direction(in|out)/view/document/:id">
          <MainLayout currentPage={"EDMS"}>
            View id
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/unscanned-report">
          <MainLayout>
            <UnscannedReportSearch />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/unscanned-report/search">
          <MainLayout>
            <UnscannedReport />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/daily-check-report">
          <MainLayout>
            <DailyCheckReportSearch />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/daily-check-report/search">
          <MainLayout>
            <DailyCheckReport />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/action-report">
          <MainLayout>
            <ActionReportSearch />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/action-report/search">
          <MainLayout>
            <ActionReport />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/correspondence-report">
          <MainLayout>
            <CorrespondenceReportSearch />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/correspondence-report/search">
          <MainLayout>
            <CorrespondenceReport />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(report)/acknowledge-receive">
          <MainLayout>
            <AcknowledgeReceiveSearch />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(edms)/folder/:folderId/document/:docId">
          <MainLayout pageType={"document"}>
            <EDMSDetailsView />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/edms/folder-create">
          <MainLayout>
            <EDMSFolderCreate mode="Add" />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/document-type">
          <MainLayout>
            <DocumentTypeSetup />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/document-type/add">
          <MainLayout>
            <DocumentTypeSetupForm type="add" />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(setup)/document-type/edit/:id">
          <MainLayout>
            <DocumentTypeSetupForm type="edit" />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/dropdown-option">
          <MainLayout>
            <DropdownOptionSetup />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/dropdown-option/:optionType">
          <MainLayout>
            <DropdownOptionsList />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(setup)/folios">
          <MainLayout>
            <FoliosSetup />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(setup)/master-number">
          <MainLayout>
            <MasterNumberSetup />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(setup)/profile-field">
          <MainLayout>
            <ProfileFieldsSetup />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(setup)/profile-field/add">
          <MainLayout>
            <ProfileFieldsSetupForm type="add" />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(setup)/profile-field/edit/:id">
          <MainLayout>
            <ProfileFieldsSetupForm type="edit" />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(setup)/profile-field/assign/:id">
          <MainLayout>
            <ProfileFieldsAssignForm />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/admin/unlock-document">
          <MainLayout>
            <UnlockDocument />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/:type(incident)">
          <MainLayout>
            <Incident />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(incident)/document/:docId">
          <MainLayout pageType={"document"}>
            <IncidentDetails />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(main-contractor)/document/:docId">
          <MainLayout pageType={"document"}>
            <SIDetails />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(safety-performance)">
          <MainLayout>
            <SafetyPerformance />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/:type(safety-performance)/document/:docId">
          <MainLayout pageType={"document"}>
            <SafetyPerformanceDetails />
          </MainLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/contract/:contractId/bim">
          <MainLayout>
            <BIM />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/bim/project-status">
          <MainLayout>
            <BIMProjectStatus />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contract/:contractId/bim/document-manage/:folderId">
          <MainLayout>
            <BIMFolder />
          </MainLayout>
        </PrivateRoute>


        <PrivateRoute exact path="/">
          <MainLayout>
            <Home />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute>
          <h1>Page Not Found</h1>
          <div style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => history.goBack()}>Go Back</div>
        </PrivateRoute>
      </Switch>
    </>
  );
}

export default Layout;