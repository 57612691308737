import PropTypes from "prop-types";
import { Select, MenuItem, Checkbox, ListItemText } from "@material-ui/core";

import { useStyles } from "./style";

function CustSelect({
  value,
  options,
  handleChange,
  label,
  dense,
  field,
  labelKey,
  valueKey,
  renderMultipleValue,
  checkboxKey,
  multiple = false,
  insertBlank = false,
  defaultValue,
  hideLabel,
}) {
  const classes = useStyles();
  function formOnChange(e) {
    field.onChange(e);
  }

  if (multiple) {
    return (
      <div>
        {hideLabel
          ? <></>
          : <div className={dense ? classes.denseLabel : classes.label}>{label}</div>
        }
        <Select
          {...field}
          onChange={(e) => formOnChange(e.target.value)}
          className={classes.select}
          multiple
          renderValue={(selected) => renderMultipleValue ? renderMultipleValue(selected) : selected.join(', ')}
          value={field.value}
          defaultValue={defaultValue}
        >
          {options.map((option, idx) => (
            <MenuItem key={idx} value={valueKey ? option[valueKey] : option}>
              <Checkbox
                checked={field.value.indexOf(valueKey ? option[valueKey] : option) > -1}
              />
              <ListItemText primary={labelKey ? option[labelKey] : option.label} />
            </MenuItem>
          ))}
        </Select>
      </div >
    );
  } else if (handleChange) {
    return (
      <div>
        {hideLabel
          ? <></>
          : <div className={dense ? classes.denseLabel : classes.label}>{label}</div>
        }
        <Select
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          className={classes.select}
          multiple={multiple}
        >
          {insertBlank &&
            <MenuItem value={""}></MenuItem>
          }
          {options.map((option, idx) => (
            <MenuItem value={valueKey ? option[valueKey] : option.value} key={idx}>{labelKey ? option[labelKey] : option.label}</MenuItem>
          ))}
        </Select>
      </div>
    );
  } else {
    return (
      <div>
        {hideLabel
          ? <></>
          : <div className={dense ? classes.denseLabel : classes.label}>{label}</div>
        }
        <Select
          {...field}
          onChange={(e) => formOnChange(e.target.value)}
          className={classes.select}
          defaultValue={defaultValue}
          multiple={multiple}
        >
          {insertBlank &&
            <MenuItem value={""}></MenuItem>
          }
          {options.map((option, idx) => (
            <MenuItem value={valueKey ? option[valueKey] : option.value} key={idx}>{labelKey ? option[labelKey] : option.label}</MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}

CustSelect.propTypes = {
  label: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  options: PropTypes.array,
  handleChange: PropTypes.func
};

export default CustSelect;