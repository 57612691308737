import { permissionHelper } from "helpers";
export const folderGroupMenuOptions = async (handleClose, data, action) => {
	// const permission = await permissionHelper.checkPermission({ fileId: id, checkFor: "edit" });
	if (true) {
		return [
			{
				name: "Edit Group",
				onClick: function () {
					action("Group", "Edit", data);
					handleClose();
				}
			},
		]
	} else {
		return [
			{
				name: "No Action",
			}
		]
	}
};

export const folderMenuOptions = async (handleClose, history, contractId, id) => {
	const permission = await permissionHelper.checkPermission({ fileId: id, checkFor: "edit" });
	if (permission.pass) {
		return [
			{
				name: "Create Folder",
				onClick: function () {
					handleClose();
					history.push({ pathname: `/contract/${contractId}/edms/folder-create`, search: `?folderId=${id}`, params: { folderId: id } })
				}
			},
			{
				name: "Properties",
				onClick: function () {
					handleClose();
					history.push(`/contract/${contractId}/edms/folder/${id}/properties`);
				}
			}
		]
	} else {
		return [
			{
				name: "No Action",
			}
		]
	}
}

export const moduleMenuOptions = async (handleClose, history, contractId, moduleType, props) => {
	if (moduleType === "hide") {
		return [
		]
	}
	else {
		return [
			{
				name: "No Action",
			}
		]
	}
}
