import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import folderLogo from 'static/icons/folder.png';
import { useStyles } from "./style";

export default function InfoHeader({ data }) {
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);
  return (
    <div className={classes.infoHeader}>
      <div className={classes.rowItem}>
        <div className={classes.logoWrapper}>
          <img className={classes.logo} src={folderLogo} alt={"folder"} />
        </div>
        <span className={classes.special}>{data?.contractNo ?? ""}</span>
      </div>
    </div>
  );
}

InfoHeader.propTypes = {
  data: PropTypes.object.isRequired,
};