import axios from './apiHelper';

const photosHelper = {
  getPhotos: async () => {
    try {
      const result = await axios.get(`/photos`);

      if (!result.data) {
        throw new Error("Fail to get photos");
      }
      return result.data["photo"];
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getOriginPhoto: async (id) => {
    try {
      const result = await axios.get(`/photo-document/${id}`, { responseType: "blob" });

      if (!result.data) {
        throw new Error("Fail to decrypt photo");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPhotoThumbnail: async (id, size) => {
    try {
      const result = await axios.get(`/photo-document/${id}?size=${size}`, { responseType: 'arraybuffer' });

      if (!result.data) {
        throw new Error("Fail to decrypt photo");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          // console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  decryptPhoto: async (id) => {
    try {
      const result = await axios.get(`/photo/${id}/decrypt`);

      if (!result.data) {
        throw new Error("Fail to decrypt photo");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 404)
          console.log(e.response)
        return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPhotoRecord: async (id) => {
    try {
      const result = await axios.get(`/photo/${id}`);

      if (!result.data) {
        throw new Error("Fail to get photo record");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postPhotoRecord: async (variables) => {
    try {
      const result = await axios.post(`/photo`, variables);

      if (!result.data) {
        throw new Error(`Fail to create photo`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  addPhotoToRecord: async ({ id, variables }) => {
    try {
      let formData = new FormData();
      formData.append('file', variables.file);
      const result = await axios({
        method: 'post',
        url: `/photo/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (!result.data) {
        throw new Error(`Fail to post the photo to the record`);
      }
      return result.data;
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editPhotoRecord: async ({ id, variables }) => {
    try {
      const result = await axios.patch(`/photo/${id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to edit photo record`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deletePhotoRecord: async ({ id, variables }) => {
    try {
      const result = await axios.delete(`/photo/${id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to edit photo record`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deletePhoto: async (id) => {
    try {
      const result = await axios.delete(`/photo/${id}`);

      if (!result.data) {
        throw new Error(`Fail to delete photo record`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPhotoTrend: async (variables) => {
    try {
      const result = await axios.get(`/dashboard/photo/line-chart`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get photo trend`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },

}

export default photosHelper;