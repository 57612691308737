import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import SectionTitle from "Common/SectionTitle";
import SafetyCompliance from 'SafetyInspection/SafetyCompliance';
import { useStyles } from "./style";

export default function SafetyDashboard({ data }) {
  const contractData = useSelector(selectContractData);
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <SectionTitle title={"Safety Inspection Dashboard"} />
      <SafetyCompliance data={contractData} />
    </div>
  );
};

SafetyDashboard.propTypes = {
  data: PropTypes.any,
}