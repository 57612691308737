import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Table, TableRow, TableBody, TableCell } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

import { setLoading } from "redux/slice/loadingSlice";
import { documentHelper } from "helpers";

// import TableCell from "Common/TableCell";
import DashboardListHeader from "Common/DashboardListHeader";
import ViewFile from 'static/icons/viewFile.svg';

import { useStyles } from "./style";

function DashboardList({
  data,
  fieldsData,
  handleSort,
  orderBy,
  sortDirection,
  total,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();

  function renderLabel(field, item) {
    const value = item[field.id];
    if (field.type === "Datetime") {
      let fieldDate = moment(value);
      return fieldDate.isValid() ? fieldDate.format("YYYY-MM-DD HH:mm") : "-";
    }
    if (field.isDate) {
      let fieldDate = moment(value);
      return fieldDate.isValid() ? fieldDate.format("YYYY-MM-DD HH:mm") : "-";
    }
    if (field.render) return field.render(item);
    else if (!value) return "-"
    else return value;
  }

  return (
    <Table className={classes.root}>
      <DashboardListHeader fields={fieldsData} handleSort={handleSort} orderBy={orderBy} sortDirection={sortDirection} />
      {data.length > 0
        ?
        <TableBody>
          {data.map((item, idx) => (
            <TableRow key={idx}>
              <TableCell style={{ padding: "4px" }}>{idx + 1}</TableCell>
              {fieldsData.map((field, idx2) => (
                field.id && <TableCell style={{ padding: "4px" }} key={idx2} >{renderLabel(field, item)}</TableCell>
              ))}
            </TableRow>
          ))}
          {total && <TableRow>
            <TableCell className={classes.lastRow}></TableCell>
            <TableCell className={classes.lastRow}>Grand total</TableCell>
            <TableCell className={classes.lastRow}>{total}</TableCell>
          </TableRow>
          }
        </TableBody>
        : <TableBody>
          <TableRow>
            <TableCell colSpan={3} style={{ padding: "4px", textAlign: "center" }}>No Data</TableCell>
          </TableRow>
        </TableBody>
      }
    </Table>
  );
}

DashboardList.propTypes = {
  data: PropTypes.array,
  fields: PropTypes.array,
  fieldsHeader: PropTypes.array,
  fieldsData: PropTypes.array
};

export default DashboardList;