import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Pie } from 'Common/Chart';
import { deploymentPieData } from "./config";
import { useStyles } from "./style";
import chartHelper from 'utils/chartHelper';

export default function DeploymentPieChart({ startDate, endDate }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { contractId } = useParams();
  const classes = useStyles(theme);
  const [data, setData] = useState([])

  const isMiddleView = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    getDashboardData();
  }, [startDate, endDate]);

  async function getDashboardData() {
    try {
      const variable = {
        contractId,
        startDate: startDate.clone().format('YYYY-MM-DD'),
        endDate: endDate.clone().format('YYYY-MM-DD'),
      }
      const result = await dashboardHelper.getLabourDeploymentPie({ variable });
      if (chartHelper.checkIsNotEmpty(result)) setData(result);
      else setData([]);
      // const sorted = arrayHelper.sortByKey(result, "label", 'asc', false);
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  }

  function mockPieChartApi(start, end) {
    let obj = []
    const data = [...deploymentPieData];
    const clone = data.filter(item => {
      let targetDate = moment(item.date).clone();
      return targetDate.isBetween(start, end);
    });
    const inner = clone.map(item => item.value);
    inner.map(item => {
      item.map(innerItem => obj.push(innerItem))
    })

    const newObj = [...obj]

    var output = [];

    newObj.forEach(function (item) {
      var existing = output.filter((v, i) => {
        return v.id === item.id;
      });
      if (existing.length) {
        var existingIndex = output.indexOf(existing[0]);
        output[existingIndex] = { ...output[existingIndex], value: output[existingIndex].value + item.value }
      } else {
        output.push(item);
      }
    });
    return output
  }

  return (
    <ChartContainer elevation={0} height={"500px"}>
      <ChartHeader
        title={'Top 5 Site Labour By Type'}
        filterType={"none"}
      />
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {data.length === 0
          ? <div>No/Insufficient data for the time period</div>
          : <Pie
            data={data}
            colors={{ "scheme": "category10" }}
            innerRadius={0.6}
            enableArcLabels={true}
            enableArcLinkLabels={false}
            enablePercentageLabel={true}
            margin={{ top: 10, right: isMiddleView ? 50 : 200, bottom: isMiddleView ? 150 : 10, left: isMiddleView ? 50 : 0 }}
            legends={[
              {
                anchor: isMiddleView ? 'bottom' : 'right',
                direction: 'column',
                justify: false,
                translateX: isMiddleView ? 25 : 150,
                translateY: isMiddleView ? 150 : 0,
                itemsSpacing: 0,
                itemWidth: 200,
                itemHeight: 18,
                itemTextColor: '#fff',
                itemDirection: 'left-to-right',
                itemOpacity: 0.9,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        }
      </div>
    </ChartContainer >
  );
}