
import { useState } from "react";
import { TextField, Button, Grid, Checkbox } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import jwt from 'jwt-decode';
import { useLocation } from "react-router";

import authHelper from "helpers/authHelper";
import { useStyles } from "../style";

export default function SigninForm({ toForgotPassword }) {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const [message, setMessage] = useState('');
  const [member, setMember] = useState({
    email: "",
    password: "",
  });
  const [isPersist, setIsPersist] = useState(false)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setMember({ ...member, [name]: value });
  };

  const login = async (e) => {
    e.preventDefault();
    try {
      const result = await authHelper.login(
        {
          username: member.email,
          password: member.password,
        },
      );
      if (result.error) throw new Error(result.error);
      const user = jwt(result.access_token);
      if (isPersist) {
        localStorage.username = user.username;
        localStorage.accessToken = result.access_token;
        localStorage.code = (result.permission && result.permission.code) ? result.permission.code : null;
        localStorage.userType = result.userType;
      } else {
        sessionStorage.username = user.username;
        sessionStorage.accessToken = result.access_token;
        sessionStorage.code = (result.permission && result.permission.code) ? result.permission.code : null;
        sessionStorage.userType = result.userType;
      }
      if (location.state && location.state.referrer) history.push(location.state.referrer.pathname);
      else history.push("/");
    } catch (e) {
      if (!!e.message) setMessage(e.message)
      else setMessage('Unknown Error')
      return;
    }
  };

  const handleCheckBoxChange = (event) => {
    setIsPersist(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>Sign In</div>
      <form className={classes.form} onSubmit={login}>
        <Grid container className={classes.row}>
          <TextField
            InputProps={{
              classes: {
                root: classes.loginFieldLabel,
                focused: classes.loginFieldLabel,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.loginFieldLabel,
                focused: classes.loginFieldLabel,
                filled: classes.loginFieldLabel,
              },
            }}
            className={classes.loginField}
            variant="filled"
            required
            fullWidth
            id="email"
            onChange={handleChange}
            label="Email Address"
            name="email"
          />
        </Grid>
        <Grid container className={classes.row}>
          <TextField
            InputProps={{
              classes: {
                root: classes.loginFieldLabel,
                focused: classes.loginFieldLabel,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.loginFieldLabel,
                focused: classes.loginFieldLabel,
                filled: classes.loginFieldLabel,
              },
            }}
            className={classes.loginField}
            variant="filled"
            onChange={handleChange}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
          />
        </Grid>

        {message &&
          <Grid container className={`${classes.row} ${classes.error}`}>
            {message}
          </Grid>
        }

        <Grid container className={classes.row}>
          <div><Checkbox color="secondary" onChange={handleCheckBoxChange} /><span>Keep me signed in on this device</span></div>
          <div>Need to find <a onClick={toForgotPassword} className={classes.link}>your password</a>?</div>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          type="submit"
          className={classes.submit}
        >
          Sign In
        </Button>
      </form>
    </div>
  );
}
