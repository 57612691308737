import {
  decryptedRows, siteDiaryDecryptedRows, photoDecryptedRows,
  safetyDecryptedRows, cleansingDecryptedRows, labourDecryptedRows,
  edmsInfoRows, incomingEdmsRows, outgoingEdmsRows
} from "Document/DocumentDecrypted/config";
import moment from 'moment';
const decryptHelper = {
  selectdecryptedRows(type) {
    switch (type) {
      case "risc":
        return decryptedRows;
      case "safety-inspection":
        return safetyDecryptedRows;
      case "cleansing-inspection":
        return cleansingDecryptedRows;
      case "labour":
        return labourDecryptedRows;
      case "photos":
        return photoDecryptedRows;
      case "site-diary":
        return siteDiaryDecryptedRows;
      case "incomingEdms":
        return incomingEdmsRows;
      case "outgoingEdms":
        return outgoingEdmsRows;
      default:
        return edmsInfoRows;
    }
  },
  renderLabel(field, item) {
    if (!item) return "-"
    if (field.render) return field.render(item);
    if (!item.fileType && field.id === "fileType") return "Photo";
    if (field.isDate) return moment(item[field.id]).clone().isValid() && item[field.id] ? moment(item[field.id]).clone().format("YYYY-MM-DD HH:mm") : "-";
    if (field.type === "Month") return moment(item[field.id]).clone().isValid() && item[field.id] ? moment(item[field.id]).clone().format("YYYY-MM") : "-";
    if (field.type === "Date") return moment(item[field.id]).clone().isValid() && item[field.id] ? moment(item[field.id]).clone().format("YYYY-MM-DD") : "-";
    if (field.type === "Datetime") return moment(item[field.id]).clone().isValid() && item[field.id] ? moment(item[field.id]).clone().format("YYYY-MM-DD HH:mm") : "-";
    if (field.isBool) return item[field.id] ? "Yes" : "No"
    if (field.id === "filename" && !!item.photoDocument) {
      return item.photoDocument.length > 0 ? item.photoDocument[item.photoDocument.length - 1].filename : null;
    }
    if (!item[field.id]) return !!item.fileMeta ? item.fileMeta[field.id] : "-";
    return item[field.id];
  }
}

export default decryptHelper;