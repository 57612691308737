import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: 16,
  },
  tableCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
  },
  headerCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
    fontWeight: 600,
  },
  divider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
}));