import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useHistory, useParams, useLocation } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { safetyPerformanceHelper } from "helpers";
import { setNotification } from "redux/slice/notificationSlice";
import fieldHelper from "utils/fieldHelper";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import Loading from "Common/Loading";

import CloseIcon from "static/icons/close.svg";

import { useStyles } from "./style";

export default function SafetyPerformanceAddForm({ mode, handleClose, reload, enableRedirection, data }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { contractId } = useParams();
  const [canChange, setCanChange] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      ...data
    }
  })

  const sp = new URLSearchParams(location.search);

  useEffect(() => {
  }, []);

  async function onSubmit(data) {
    try {
      setLoading(true);
      console.log(moment(data.reportPeriodFrom, 'YYYY/MM'));
      const requestVariables = { contractId, reportPeriodFrom: moment(data.reportPeriodFrom, 'YYYY/MM'), revisionNumber: data.revisionNumber };
      if (mode === "Add") {
        
        var result = await safetyPerformanceHelper.createSafetyPerformance(requestVariables);
        if (result.error) throw new Error(result.error);
        dispatch(setNotification({ type: "success", message: "New Safety Performance Created" }))
      }

      if (enableRedirection) history.push(`/contract/${contractId}/safety-performance/document/${result.safety_performance.id}`);
      handleClose();
      setLoading(false);
      if (reload) reload();
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${mode} Report`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"close"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3} className={classes.section}>
            {fieldHelper.renderField({ label: "Reporting Month", name: "reportPeriodFrom", type: "Date", isRequired: true, format: "yyyy/MM", views: ['year', 'month'], hasInputValue: true },
              0, register, control, errors, { size: 6 })}
          </Grid>
          <Grid container spacing={3} className={classes.section}>
            {fieldHelper.renderField({ label: "Revision No.", name: "revisionNumber", type: "Number", isRequired: true, format: "yyyy/MM", views: ['year', 'month'], hasInputValue: true },
              1, register, control, errors, { size: 6 })}
          </Grid>
        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          <Button children={mode === "Add" ? "Add" : "Save"} backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}