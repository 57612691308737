import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectUserPermission } from 'redux/slice/permissionSlice';
import { riskHelper } from 'helpers';
import DocumentAttachment from 'Document/DocumentAttachment';
import DocumentHistoryLog from 'Document/DocumentHistoryLog';
import DocumentHeader from 'Document/DocumentHeader';
import DocumentRelatedDocumentV2 from 'Document/DocumentRelatedDocumentV2';
import RiskRegisterDetails from './riskRegisterDetails';
import RiskRegisterHeader from './riskRegisterHeader';
import { useStyles } from "./style";
import EarlyWarningDisplay from 'RiskRegister/EarlyWarningDetailsView/EarlyWarningDisplay';
import SectionTitle from 'Common/SectionTitle';

export default function RiskRegisterDetailsView() {
  const { contractId, type, docId } = useParams();
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState({});
  const [decisions, setDecisions] = useState([]);
  const [contract, setContract] = useState({});

  const permission = useSelector(selectUserPermission);
  const riskPermission = permission?.risk?.permissions ?? [];
  const haveEditRight = riskPermission.find(item => item.code === "edit");

  async function init() {
    try {
      const result = await Promise.all([
        riskHelper.getRiskRegister(docId),
        riskHelper.getRiskDecisions({ riskRegisterId: docId }),
      ]);
      if (!result[0].risk_register) throw new Error("Risk Data not found");
      setData(result[0].risk_register);
      if (!result[1].risk_decision) throw new Error("Risk Decision not found");
      setDecisions(result[1].risk_decision);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    init();
  }, [])

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "history-log":
        return <DocumentHistoryLog data={data ?? {}} docType="edms" />;
      case "attachments":
        return <DocumentAttachment hideAdd={!haveEditRight} reload={init} data={(data && data.documents) ?? []} moduleType={"risk-register"} />
      case "related-documents":
        return <DocumentRelatedDocumentV2 data={data} componentType={type} />
      default:
        return (
          <>
            <RiskRegisterHeader allowEdit={haveEditRight} contract={contract} data={data} reload={init} />
            <div className={classes.infoHeader}>
              <RiskRegisterDetails data={decisions ?? []} allowEdit={haveEditRight} reload={init} riskRegisterId={docId} />
            </div>
            {data?.riskEarlyWarning &&
              <div className={classes.infoHeader}>
                <SectionTitle title="Early Warning Notification" fontStyle={theme.typography.h4} />
                <EarlyWarningDisplay data={data.riskEarlyWarning} />
              </div>
            }
          </>
        );
    }
  }

  return (
    <div className={classes.root}>
      {
        (!!searchParam && searchParam !== "basic-info")
        && <DocumentHeader data={data} type={type} contractData={contract} padding="0px 0px 20px 0" />
      }
      {returnDocumentContent()}
    </div>
  );
}