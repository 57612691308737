import { Grid } from '@material-ui/core';

import InfoItem from 'Common/InfoItem';

import CustCheckbox from 'Common/Checkbox';

export default function EarlyWarningDisplay({ data }) {
  return (
    <Grid container style={{ marginTop: 16 }} spacing={3}>
      <Grid xs={12} item>
        <InfoItem title="Subject / Description of Work" content={data.subject} />
      </Grid>
      <Grid xs={4} item>
        <InfoItem title="Condition of Contract Clause(s)" content={<>
          {data?.conditionsOfContractClause &&
            <>
              <div>
                <CustCheckbox mode="manual" disabled={true} defaultChecked={data.conditionsOfContractClause?.increaseTheTotalOfThePrices} />
                <span>Increase the total of the Prices</span>
              </div>
              <div>
                <CustCheckbox mode="manual" disabled={true} defaultChecked={data.conditionsOfContractClause?.delayCompletion} />
                <span>Delay Completion</span>
              </div>
              <div>
                <CustCheckbox mode="manual" disabled={true} defaultChecked={data.conditionsOfContractClause?.delayMeetingAKeyDate} />
                <span>Delay meeting a Key Date</span>
              </div>
              <div>
                <CustCheckbox mode="manual" disabled={true} defaultChecked={data.conditionsOfContractClause?.impairThePerformance} />
                <span>Impair the performance</span>
              </div>
            </>
          }
        </>}
        />
      </Grid>
      <Grid xs={4} item>
        <InfoItem title="Request for Change" content={data.requestForChange ? "Yes" : "No"} />
      </Grid>
      <Grid xs={4} item>
        <InfoItem title="Early Warning Meeting Required" content={data.meetingRequired ? "Yes" : "No"} />
      </Grid>

      <Grid xs={4} item>
        <InfoItem title="Urgency" content={data.urgency ?? "-"} />
      </Grid>
      <Grid xs={4} item>
        <InfoItem title="Nature of Change" content={data.natureOfChange ?? "-"} />
      </Grid>
      <Grid xs={4} item>
        <InfoItem title="Type of Change" content={data.typeOfChange ?? "-"} />
      </Grid>
      <Grid xs={12} item>
        <InfoItem title="Matter Giving Rise To Notification" content={data.matterGivingRiseToNotification ?? "-"} />
      </Grid>
    </Grid>
  );
}