import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider, Drawer } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { authHelper, riskHelper } from 'helpers';
import EllipseButton from 'Common/EllipseButton';
import IconButton from "Common/IconButton";
import CommonContainer from 'EDMS/CommonContainer';
import SectionTitle from 'Common/SectionTitle';
import CommonDocumentList from 'EDMS/CommonDocumentList';
import FilterPage from 'Common/FilterPage';
import Modal from "Common/Modal";
import { riskReductionMeetingTableConfig } from 'EDMS/CommonDocumentList/config';

import RiskMeetingAddForm from './riskMeetingAddForm';
import { useStyles } from "./style";

export default function RiskReductionMeeting({ permission = [] }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [modalOpen, setModalOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
  });
  const haveEditRight = permission.find(item => item.code === "edit");

  useEffect(() => {
    initDate()
  }, []);

  function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  async function initDate() {
    try {
    } catch (e) {
      console.log(e);
    }
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <SectionTitle
        title={"Risk Reduction Meeting"}
        extraComponents={
          <div className={classes.buttonsContainer}>
            {haveEditRight && <EllipseButton onClick={() => setModalOpen(true)} label="+ New Meeting" />}
            <div style={{ marginLeft: "16px" }}>
              <IconButton
                icon={<FilterListIcon />}
                onClick={handleOpenFilter}
              />
            </div>
          </div>
        }
      />
      <CommonDocumentList
        tableType={"risk-meeting"}
        useFilter={useFilter}
        filter={filter}
        page={page}
        getDataApi={riskHelper.getRiskMeetings}
        defaultOrderBy="date"
        responseKey={"risk_meeting"}
      />
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={riskReductionMeetingTableConfig} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter, setPage }} />
      </Drawer>
      <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
        <RiskMeetingAddForm mode={"Add"} handleClose={() => setModalOpen(false)} />
      </Modal>
    </>
  );
};