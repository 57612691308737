import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Pie } from 'Common/Chart';

export default function PMWeatherPieChart({ pieData }) {
  const theme = useTheme();

  const isMiddleView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ChartContainer elevation={0} height={'450px'}>
      <ChartHeader
        title={'PM Weather Distribution'}
        filterType={"none"}
      />
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {pieData.length === 0
          ? <div>No/Insufficient data for the time period</div>
          : <Pie
            data={pieData ?? []}
            colors={({ id, data }) => data.color}
            innerRadius={0.6}
            enableArcLabels={true}
            enableArcLinkLabels={false}
            enablePercentageLabel={true}
            margin={{ top: 10, right: isMiddleView ? 50 : 75, bottom: isMiddleView ? 150 : 10, left: isMiddleView ? 50 : 0 }}
            legends={[
              {
                anchor: isMiddleView ? 'bottom' : 'right',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: isMiddleView ? 125 : 0,
                itemsSpacing: 0,
                itemWidth: 50,
                itemHeight: 18,
                itemTextColor: '#fff',
                itemDirection: 'left-to-right',
                itemOpacity: 0.9,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        }
      </div>
    </ChartContainer>
  );
}