import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

export const DSDTabs = withStyles(theme => ({
  root: {
    zIndex: 10,
    position: "relative",
    // borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.primaryLight,
    height: '4px',
  },
}))(Tabs);

export const DSDTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    minWidth: 72,
    fontWeight: '600',
    marginRight: theme.spacing(4),

    '&:hover': {
      color: theme.palette.primaryLight,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primaryLight,

    },
    '&:focus': {
      color: theme.palette.primaryLight,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const useStyles = makeStyles((theme) => ({
  Appbar: {
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
  },
}));