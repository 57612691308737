import PropTypes from "prop-types";
import { ResponsiveLine } from '@nivo/line';

import { chartTheme } from '../config';
import { useStyles } from '../style';

function LineChart({
  data,
  margin = { top: 40, right: 30, bottom: 60, left: 60 },
  colors = { scheme: "nivo" },
  xScale = {
    type: 'time',
    format: '%Y-%m-%d',
    useUTC: false,
    precision: 'month',
  },
  xFormat = 'time:%b %d',
  axisBottom = {
    // format: '%b %d',
    // tickValues: 'every month',
    // // legend: 'time scale',
    // legendOffset: -12,
  },
  axisLeftName = null,
  legends = [
    {
      anchor: 'top-left',
      direction: 'row',
      justify: false,
      translateX: -25,
      translateY: -40,
      itemsSpacing: 50,
      itemDirection: 'left-to-right',
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.9,
      itemTextColor: "#ffffff",
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
          }
        }
      ]
    }
  ]
}) {
  const props = { margin };
  const classes = useStyles(props);
  function formatYTooltipValue(value) {
    return value;
  }
  function formatYAxisValue(value) {
    if (value === 0) return 0;
    return value;
  }

  const curveOptions = ['linear', 'monotoneX', 'step', 'stepBefore', 'stepAfter'];

  return (
    <div className={classes.root}>
      <ResponsiveLine
        className={classes.root}
        colors={colors}
        data={data}
        margin={margin}
        xScale={xScale}
        yScale={{
          type: 'linear',
          stacked: false,
        }}
        xFormat={xFormat}
        yFormat={formatYTooltipValue}
        axisLeft={{
          format: e => Math.floor(e) === e && e,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisLeftName,
          legendPosition: 'middle',
          legendOffset: -40
        }}
        axisBottom={axisBottom}
        curve={('curve', curveOptions, 'linear')}
        // curve={select('curve', curveOptions, 'monotoneX')}
        enablePoints={false}
        enablePointLabel={false}
        enableGridX={false}
        pointSize={16}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        // useMesh={true}
        enableSlices='x'
        legends={legends}
        sliceTooltip={({ slice }) => {
          return (
            <div className={classes.toolTipBackground}>
              {slice.points.map(point => (
                <div key={point.id} className={classes.tooltipItem}>
                  <div>
                    <span className={classes.dot} style={{ background: point.serieColor }}></span>
                    <span className={classes.groupName}>{point.serieId}</span >
                  </div>
                  <div className={classes.groupText}>{point.data.yFormatted}</div>
                </div>
              ))}
            </div>
          );
        }}
        theme={chartTheme}
      />
    </div>
  );
}

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  margin: PropTypes.object,
  colors: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  xScale: PropTypes.object,
  xFormat: PropTypes.string,
  axisBottom: PropTypes.object,
  legends: PropTypes.arrayOf(PropTypes.object),
};

export default LineChart;
