import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';

import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";
import CommonDocumentList from 'EDMS/CommonDocumentList';

export default function ActionReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId, direction } = useParams();

  useEffect(() => {
    console.log(direction);
  }, [direction]);


  return (
    <CommonContainer>
      <SectionTitle
        title={`Action Report`}
        extraComponents={
          <div className={classes.buttonsContainer}>
            <div style={{ marginRight: 8 }}>
              <EllipseButton onClick={() => alert('WIP')} label="Export" />
            </div>
            <EllipseButton onClick={() => window.print()} label="Print" />
          </div>
        }
      />
      <CommonDocumentList
        tableType={"actionReport"}
        hideGoToButton
      />
    </CommonContainer>
  );
}