import { useState } from 'react';
import MomentUtils from "@date-io/moment";
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTheme } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from 'prop-types';

import BoxItem from "Common/BoxItem";
import { useStyles } from "./style";

export default function RISCInfoBox({ title, withDate = true, value }) {
  const theme = useTheme();
  const props = { theme }
  const classes = useStyles(props);

  const [selectedDate, handleDateChange] = useState(new Date());
  // const [value, setValue] = useState();

  return (
    <BoxItem padding="15px 20px" margin="0 0px 12px 0" onHover={false}>
      <div className={classes.column}>
        <div className={classes.column}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <span>
              {withDate && <DatePicker
                maxDate={new Date()}
                format="MMM DD, yyyy"
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                value={selectedDate}
                onChange={handleDateChange}
              />
              }
            </span>
          </MuiPickersUtilsProvider>
          <span className={classes.title} >{title}</span>
        </div>
        <span className={classes.content}>{value ?? "0"}</span>
      </div>
    </BoxItem>
  );
};

RISCInfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  getDate: PropTypes.func,
  withDate: PropTypes.bool,
}