import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    current: "",
    contractId: "",
    isMenuOpen: true,
  },
  reducers: {
    setNavigation: (state, action) => {
      const { contractId, page } = action.payload;
      state = { ...state, contractId, current: page };
      return state;
    },
    toggleMenuOpen: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    setMenuOpen: (state) => {
      state.isMenuOpen = true;
    }
  }
});

export const { toggleMenuOpen, setMenuOpen } = navigationSlice.actions;

export const selectMenuOpen = (state => state.navigation.isMenuOpen);

export default navigationSlice.reducer;