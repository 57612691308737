import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';
import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";

export default function AcknowledgeReceiveSearch() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const { register, handleSubmit, control, formState: { errors }, setValue, } = useForm({
    defaultValues: {
      dispatchDate: null,
      toCompany: "default",
    }
  });

  async function onSubmit(data) {
    try {
      alert('WIP');
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  const defaultOptions = [
    {
      label: "default",
      value: "default"
    }
  ];

  return (
    <CommonContainer>
      <SectionTitle title={`Acknowledge Receive`} />
      <div className={classes.body}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              {fieldHelper.renderField(
                { label: "Dispatch Date", name: "dispatchDate", isRequired: false, type: "Date" },
                1, register, control, errors, { size: 12, mdSize: 4 })
              }
            </Grid>
            {fieldHelper.renderField(
              { label: "To Company / CC To Company", name: "toCompany", isRequired: false, type: "Select", options: defaultOptions, },
              2, register, control, errors, { size: 12, mdSize: 6 })
            }

            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton children="Export" backgroundColor="#70BAD3" type="submit" />
          </Grid>
        </form>
      </div>
    </CommonContainer >
  );
}