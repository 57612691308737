import PropTypes from "prop-types";
import { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message'
import { useParams } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDispatch } from "react-redux";
import { useState } from "react";

import Loading from "Common/Loading";
import { setNotification } from "redux/slice/notificationSlice";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";

import CloseIcon from "static/icons/close.svg";
import FileIcon from "static/icons/viewFile.svg";

import ActivityFieldArray from './fieldArray';
import { fileInfoFields, fileMetaFields, fileLinkFields, activityFields, staffFields } from './config';
import { useStyles } from "./style";
import fieldHelper from "utils/fieldHelper";
import { documentHelper } from "helpers";

function SiteDiaryAdd({ contract, handleClose, reload }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { watch, register, handleSubmit, control, formState: { errors }, clearErrors, setValue } = useForm({
    defaultValues: {
      file: null,
      contractId: contractId,
      site_diary: {
        project: contract,
        file: {
          file_id: "",
          previous_file_id: "",
          file_source: "Manual",
          file_type: "Site Diary",
          file_status: "Created",
          file_tags: [],
          file_remark: "",
          version_no: null,
          no_of_revisions: null,
        },
        file_meta: {
          creation_datetime: "",
          creation_by: "",
          submission_datetime: "",
          submission_by: "",
          am_weather: "",
          pm_weather: "",
          rainfall: "",
          thunderstorm_warning: "",
          rainstorm_warning: "",
          typhoon_warning: "",
          day: "",
          contract_day: null,
          instructions: "",
          utility: "",
          progress: "",
          visitor: null,
          accidents: null,
          people_signed: [],
          pmd_signed_by: "",
          pmd_signed_comment: "",
          pmd_signed_datetime: "",
          cr_signed_by: "",
          cr_signed_comment: "",
          cr_signed_datetime: "",
          sup_signed_by: "",
          sup_signed_comment: "",
          sup_signed_datetime: "",
          activities: [
            {
              activity: "",
              location: "",
              period: "",
              remarks: "",
              labour: [{
                labour_type: "",
                labour_code: "",
                labour_count: null,
                labour_hours: null,
              }],
              plant: [{
                plant_type: "",
                plant_working_count:null,
                plant_working_id:"",
                plant_working_hours:null,
                plant_idle_count:null,
                plant_idle_id:"",
                plant_idle_hours:null,
              }]
            }
          ],
          site_staff: [],
          assistance: [],
          // number_of_labour_people: 50,
          // number_of_plant_equipment: 10,
          // count_site_staff_type: 10,
          overdue_reason: "",
          complete_sd: true,
        }
      }
    }
  })

  // const activitiesFields = useFieldArray({ control, name: "site_diary.file_meta.activities" });
  const siteStaffFields = useFieldArray({ control, name: "site_diary.file_meta.site_staff" });
  const assistanceFields = useFieldArray({ control, name: "site_diary.file_meta.assistance" });

  const watchFile = watch("file", false);
  const watchFileStatus = watch("site_diary.file.file_status");
  const watchAllFields = watch();

  useEffect(() => {
    clearErrors();
  }, [watchFileStatus])

  async function onSubmit(data) {
    try {
      setLoading(true);
      let file = data.file[0];
      let site_diaryVar = data.site_diary;
      // site_diaryVar.file_meta.number_of_labour_people = data.site_diary.file_meta.activities.reduce((total, obj) => Number(obj.labour) + total, 0);
      // site_diaryVar.file_meta.number_of_plant_equipment = data.site_diary.file_meta.activities.reduce((total, obj) => Number(obj.plant) + total, 0);
      // site_diaryVar.file_meta.number_of_labour_people = 0;
      // site_diaryVar.file_meta.number_of_plant_equipment = 0;
      site_diaryVar.file_meta.count_site_staff_type = data.site_diary.file_meta.site_staff.length;

      let postData = { contract_id: contractId, site_diary: site_diaryVar };
      console.log(postData, "site");
      const result = await documentHelper.createDocument({ type: 'site-diary', variable: postData });
      if (result.error) throw new Error(result.error);
      await documentHelper.uploadDocument({ type: "site-diary", id: result.site_diary.id, variable: { file: file } });
      dispatch(setNotification({ type: "success", message: "New Site Diary submitted" }));
      reload();
      handleClose();
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({type:"error", message:"Some field(s) are missing."}))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title="Add Site Diary"
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Button
                varient="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                backgroundColor="#70BAD3"
              >
                Upload a file
                <input
                  {...register('file', { required: "File is required." })}
                  type="file"
                  hidden
                  accept="application/pdf"
                />
              </Button>
              {watchFile &&
                <div className={classes.fileInfo}>
                  <img className={classes.docImg} src={FileIcon}></img>
                  {`Uploaded:  ${watchFile[0].name}`}
                </div>
              }
              <ErrorMessage
                errors={errors}
                name="file"
                render={({ message }) => <p style={{ color: 'red', marginLeft: "16px" }}>{message}</p>}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="File Info" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            {fileInfoFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors))}
          </Grid>
          <Divider className={classes.divider} />

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="File Meta" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            {fileMetaFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors, { status: watchFileStatus }))}
          </Grid>
          <Divider className={classes.divider} />

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="Activities" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            <ActivityFieldArray control={control} register={register} errors={errors} name={"site_diary.file_meta.activities"} setValue={setValue}/>
            {/* {activityFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors, { activities: activitiesFields }))} */}
          </Grid>
          <Divider className={classes.divider} />

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="Staff and Assistance" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            {staffFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors, { site_staff: siteStaffFields, assistance: assistanceFields }))}
          </Grid>
          <Divider className={classes.divider} />

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="Links" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            {fileLinkFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors))}
          </Grid>
          <Divider className={classes.divider} />
          <Loading isLoading={isLoading} />

        </DialogContent>

        <DialogActions>
          <Button children="Submit" backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}

SiteDiaryAdd.propTypes = {
  handleClose: PropTypes.func
};

export default SiteDiaryAdd;