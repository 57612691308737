import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, TableRow, TableBody, TableCell } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from '@material-ui/icons/Delete';
import { setLoading } from "redux/slice/loadingSlice";
import { documentHelper } from "helpers";
import tableHelper from "utils/tableHelper";

import CustTableCell from "Common/TableCell";
import TableHeader from "Common/TableHeader";
import ViewFile from 'static/icons/viewFile.svg';

import { useStyles } from "./style";
import CustCheckbox from "Common/Checkbox";

function DocumentsTable({
  data,
  fieldsData,
  handleSort,
  orderBy,
  sortDirection,
  selectedData,
  handleSelect,
  tableType,
  hidePdfBtn,
  handleDelete,
  handleAction,
  actionIcon,
  handleGoTo,
  hideGoToButton,
  reload,
  handleCheckbox,
  isChecked
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const handleGotoDetails = (id, row) => {
    if (handleGoTo) handleGoTo(row);
    else if (tableType === "risk-meeting") history.push(`${history.location.pathname}/meeting/${id}`);
    else if (tableType === "updateDocumentList") history.push(`${history.location.pathname}/${id}`);
    else history.push(`${history.location.pathname}/document/${id}`);
  };

  function handleOnClick(rowData) {
    try {
      dispatch(setLoading(true));
      let documentId;
      documentId = getTypeDocument(rowData, rowData.fileType.toLowerCase());
      if (!!documentId) openDocInBlankPage(documentId);
      else throw new Error('not found');
    } catch (e) {
      alert('File not found')
      dispatch(setLoading(false));
      console.log(e);
    }
  }

  function getTypeDocument(data, type) {
    let targetArr = [];
    if (type === "risc") targetArr = data.riscDocument;
    else if (type === "site diary" || type === "site_diary") targetArr = data.siteDiaryDocument;
    else if (type === "cic") targetArr = data.cleansingDocument;
    else if (type === "ssr") targetArr = data.safetyDocument;
    else if (type === "lrr") targetArr = data.labourDocument;
    return targetArr.length > 0 ? targetArr[targetArr.length - 1].documentId : null;
  }

  async function openDocInBlankPage(docId) {
    try {
      dispatch(setLoading(true));
      const blob = await documentHelper.decryptDocument(docId)

      var url = URL.createObjectURL(blob);
      window.open(url, '_blank', 'noopener noreferrer');
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      alert('File not found');
      dispatch(setLoading(false));
    }
  }

  const isSelected = (id) => {
    return !!selectedData ? selectedData.find((item) => item && item.id === id) : false;
  };

  function renderCustTableCell(item, field, idx) {
    if (field.id === "subject") return <TableCell className={classes.cell} key={idx} style={{ minWidth: 200, maxWidth: 200 }}>{tableHelper.renderLabel(field, item, reload)}</TableCell>
    return field.id && <TableCell className={classes.cell} key={idx} style={{ maxWidth: field.width ?? 200, minWidth: field.width ?? null }} >{tableHelper.renderLabel(field, item, reload)}</TableCell>
  }

  function handleCheckboxChange(value, id) {
    handleCheckbox(value, id)
  }

  return (
    <Table className={classes.root}>
      <TableHeader fields={fieldsData} handleSort={handleSort} orderBy={orderBy} sortDirection={sortDirection} />
      <TableBody>
        {data.map((item, idx) => (
          <TableRow key={idx} hover style={{ background: (isSelected(item.id) ? theme.palette.primaryBlueLight : "unset") }}>
            {(!handleSelect && handleCheckbox) && <CustTableCell>
              <CustCheckbox mode="manual" onChange={(e) => handleCheckboxChange(e, item.id)} defaultChecked={isChecked(item.id)} />
            </CustTableCell>}
            {fieldsData.filter(field => !field.hidden).map((field, idx) => renderCustTableCell(item, field, idx))}
            {(!hidePdfBtn) &&
              <CustTableCell
                onClick={() => handleOnClick(item)}
                icon={<div target="_blank"
                  rel="noreferrer noopener"
                  className={classes.a}>
                  <img
                    className={classes.img}
                    src={ViewFile}
                    alt={"document"}
                  />
                </div>
                }
              />
            }

            {handleSelect
              && <CustTableCell
                icon={<DoneIcon fontSize={"large"} />}
                onClick={handleSelect.bind(this, item)}
                iconColor={isSelected(item.id)
                  ? theme.palette.secondary.main
                  : "#ffffff22"
                }
              />
            }

            {handleAction &&
              <CustTableCell
                icon={actionIcon}
                onClick={() => handleAction(item)}
              />
            }

            {handleDelete &&
              <CustTableCell
                icon={<DeleteIcon />}
                onClick={handleDelete}
              />
            }
            {(!hideGoToButton && !handleSelect) &&
              <CustTableCell
                icon={<ChevronRightIcon />}
                onClick={handleGotoDetails.bind(this, item["id"], item)}
                iconColor={theme.palette.primaryBlueLight}
              />
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

DocumentsTable.propTypes = {
  data: PropTypes.array,
  fields: PropTypes.array,
  fieldsHeader: PropTypes.array,
  fieldsData: PropTypes.array
};

export default DocumentsTable;