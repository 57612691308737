import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from "@material-ui/core";
import { useParams } from "react-router-dom";
import moment from "moment";
import { riskHelper } from "helpers";
import EllipseButton from "Common/EllipseButton";
import SectionTitle from "Common/SectionTitle";
import fieldHelper from "utils/fieldHelper";
import { setLoading } from "redux/slice/loadingSlice";
import { setNotification } from "redux/slice/notificationSlice";

import { headerConfig, headerConfigSection2 } from './config';
import { useStyles } from "./style";

export default function RiskRegisterHeaderForm({ data, setIsEdit }) {
  const classes = useStyles();
  const theme = useTheme();
  const { docId } = useParams();
  const dispatch = useDispatch();
  const { watch, register, handleSubmit, control, formState: { errors }, clearErrors } = useForm({
    defaultValues: {
      ...data
    },
  });

  async function onSubmit(data) {
    try {
      dispatch(setLoading(true));
      const result = await riskHelper.editRiskRegister(docId, data);
      setIsEdit(false);
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      dispatch(setLoading(false));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <SectionTitle
        title={""}
        fontStyle={theme.typography.h4}
        extraComponents={
          <div className={classes.editHeader}>
            <div style={{ marginRight: 8 }}>
              <EllipseButton
                label={"Save"}
                type="submit"
                backgroundColor="transparent"
              />
            </div>
            <EllipseButton
              label={"Cancel"}
              type="button"
              backgroundColor="transparent"
              onClick={() => setIsEdit(false)}
            />
          </div>
        }
      />
      <Grid container spacing={2}>
        {headerConfig.map((inputField, idx) =>
          fieldHelper.renderField(inputField.type === "Date" ? { ...inputField, default: moment(data[inputField.name]) } : inputField,
            idx, register, control, errors))}
        {headerConfigSection2.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors, { size: 12 }))}
      </Grid>

    </form>
  );
}
