import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import clsx from "clsx";
import { Link, useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import bimHelper from 'helpers/bimHelper';

import { folderMenuOptions } from 'config/rightClickMenuOptions';
import { useStyles } from "./style";


export default function DocumentManagement({ option, isRoot = false, subItems, setAnchorEl, setMenuOptions }) {
  const [expand, setExpand] = useState(false);
  const [pathData, setPathData] = useState([]);
  const { contractId } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);

  useEffect(() => {
    setExpand(false);
  }, [contractId]);

  async function handleExpand(option) {
    if (!expand) {
      await getDirectory();
    }
    setExpand(!expand);
  }

  async function getDirectory() {
    try {
      const variables = { parentAutodeskId: option.parentAutodeskId }
      const result = await bimHelper.getDocumentManagementDirectory(variables);
      setPathData(result?.bim_file);
    } catch (e) {
      console.log(e);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderSubItems(option, item, idx) {
    const newOption = { ...option, name: item.name, key: item.name, parentAutodeskId: item.autodeskId }
    return <DocumentManagement key={item.id} option={newOption} subItems={item.children} setAnchorEl={setAnchorEl} setMenuOptions={setMenuOptions} />
  }

  async function onContextMenu(e, id) {
    e.preventDefault();
    const options = await folderMenuOptions(handleClose, history, contractId, id)
    await setMenuOptions(options);
    setAnchorEl(e.target);
  }

  return (
    <div className={clsx(classes.groupItem)}>
      <div className={classes.row}>
        <div className={classes.btnControl} onClick={() => handleExpand(option)}>{expand ? "-" : "+"}</div>
        <Link
          onContextMenu={(e) => onContextMenu(e, option.id)}
          className={clsx(classes.navigationLink, isRoot && classes.groupTitle)}
          to={option.linkTo(contractId, option.parentAutodeskId, isRoot)}
        >
          {isRoot ? option.name : option.name}
        </Link>
      </div>
      <div className={clsx(classes.group, expand ? classes.expanded : classes.collapsed, classes.subGroup)}>
        {pathData.map((item, idx) => {
          if (item.type !== "folders") return null;
          return renderSubItems(option, item, idx);
        })}
      </div>

    </div>
  );
}