import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "40px"
  },
  img: {
    height: 25,
    width: 25
  },
  a: {
    height: 25,
  },
}));
