import { useTheme } from '@material-ui/core/styles';
import { Table } from '@material-ui/core';

import SectionTitle from 'Common/SectionTitle';

import SafetyReportHeader from './SafetyReportHeader';
import { useStyles } from "../style";
import SafetyReportContent from './SafetyReportContent';
import SafetyFootnote from './SafetyFootnote';

export default function SafetyReport({ data, register, control, errors, fields, reportStatus, safetyMeta, permission }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const maxSection = (data && data.length) ? Number(data?.[data?.length - 1].section) : 0;
  return (
    <>
      <SectionTitle title={`B. Safety Performance of the contract in the Reporting Period`} fontStyle={theme.typography.h5} />
      <div className={classes.section} style={{ overflowX: "auto" }}>
        <Table className={classes.table}>
          <SafetyReportHeader safetyMeta={safetyMeta} />
          <SafetyReportContent
            data={data}
            maxSection={maxSection}
            register={register}
            control={control}
            errors={errors}
            fields={fields}
            reportStatus={reportStatus}
            permission={permission}
          />
        </Table>
      </div>
      <SafetyFootnote />
    </>
  );
}