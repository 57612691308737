import PropTypes from "prop-types";
import { ResponsiveBar } from '@nivo/bar';
import moment from "moment";
import { BoxLegendSvg } from '@nivo/legends';

import { chartTheme } from '../config';
import { useStyles } from '../style';

function BarChart({
  data,
  keys,
  indexBy,
  margin = { top: 40, right: 30, bottom: 70, left: 50 },
  colors = ['#3D66E9', '#70BAD3', "#B9C8F7", '#08636e', '#83BD95', '#5FB59A'],
  groupMode = 'stacked', //stacked, grouped
  layout = 'vertical',
  barPadding = 0.3,
  borderRadius = 0,
  enableLabel = true,
  enableTimeIndex = false,
  showDecimal = false,
  axisLeftName = null,
  legends = [
    {
      dataFrom: "keys",
      data: keys.map((id, index) => ({
        color: colors[index],
        id,
        label: id.length > 20
          ? id.substring(0, 19) + '...'
          : id
      })),
      truncate: true,
      colors: ['#fff'],
      anchor: 'top-left',
      direction: 'row',
      justify: false,
      translateX: -25,
      translateY: -40,
      itemsSpacing: 45,
      itemDirection: 'left-to-right',
      itemWidth: 100,
      itemHeight: 20,
      itemOpacity: 0.9,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1
          }
        }
      ]
    }
  ]
}) {
  const props = { margin };
  const classes = useStyles(props);

  const BarLegend = ({ height, legends, width }) => (
    <>
      {legends.map(legend => (
        <BoxLegendSvg
          key={JSON.stringify(legend.data.map(({ id }) => id))}
          {...legend}
          containerHeight={height}
          containerWidth={width}
        />
      ))}
    </>
  );
  const customTick = tick => {
    let day, month;
    if (enableTimeIndex) {
      month = moment(tick.value).format('MMM');
      day = moment(tick.value).format('DD');
    }
    return (
      <g transform={`translate(${tick.x},${tick.y + 22})`}>
        <line stroke="white" strokeWidth={1} y1={-22} y2={-12} />
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: '#fff',
            fontSize: 12,
          }}
        >
          {enableTimeIndex
            ? <>
              <tspan>{month} {day}</tspan>
            </>
            : tick.value}
        </text>
      </g>
    );
  };

  function formatYAxisLabel(e) {
    if (showDecimal) {
      return e && e.toLocaleString()
    } else {
      return Math.floor(e) === e && e.toLocaleString()
    }
  }

  return (
    <div className={classes.root}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={margin}
        padding={barPadding}
        colors={colors}
        groupMode={groupMode}
        layout={layout}
        borderRadius={borderRadius}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'country',
          legendPosition: 'middle',
          legendOffset: 32,
          renderTick: customTick,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: axisLeftName,
          legendPosition: 'middle',
          legendOffset: -60,
          format: (e) => formatYAxisLabel(e)
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="white"
        enableLabel={enableLabel}
        legends={legends}
        layers={["grid", "axes", "bars", "markers", BarLegend]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={chartTheme}
      />
    </div>
  );
}

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keys: PropTypes.array.isRequired,
  indexBy: PropTypes.string.isRequired,
  margin: PropTypes.object,
  colors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  groupMode: PropTypes.string,
  layout: PropTypes.string,
  barPadding: PropTypes.number,
  enableLabel: PropTypes.bool,
  enableTimeIndex: PropTypes.bool,
  legends: PropTypes.arrayOf(PropTypes.object),
};

export default BarChart;