import { DC06CleansingOptions, DC07CleansingOptions, DE03CleansingOptions, DE04CleansingOptions, } from "./cleansingOptions"
import { DC06SafetyOptions, DC07SafetyOptions, DE03SafetyOptions, DE04SafetyOptions } from "./safetyOptions"
export const fileStatusOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Created",
    value: "Created",
  },
  {
    label: "Closed",
    value: "Closed"
  }
]

export const riscStatusOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Submitted",
    value: "Submitted",
  },
  {
    label: "Closed",
    value: "Closed"
  }
]

export const fileStatusSiteDirayOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: 'Created',
    value: "Created"
  },
  {
    label: 'Closed',
    value: "Closed"
  },
]


export const sourceOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Innodoc",
    value: "INNODOC",
  },
  {
    label: "Manual",
    value: "Manual"
  }
]

export const weatherOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Cloudy",
    value: "Cloudy",
  },
  {
    label: "Drizzle",
    value: "Drizzle",
  },
  {
    label: "Fine",
    value: "Fine",
  },
  {
    label: "Rain",
    value: "Rain",
  },
  {
    label: "Shower",
    value: "Shower",
  },
]

export const photoPurposeOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "For Record",
    value: "For Record,記錄用途",
  },
  {
    label: "For Attention",
    value: "For Attention,須跟進",
  },
]

export function getCleansingTemplateOptions(contractNo) {
  switch (contractNo) {
    case "DE/2018/03":
      return DE03CleansingOptions;
    case "DE/2018/04":
      return DE04CleansingOptions;
    case "DC/2018/06":
      return DC06CleansingOptions;
    case "DC/2018/07":
      return DC07CleansingOptions;
    default:
      return [];
  }
}

export function getSafetyTemplateOptions(contractNo) {
  switch (contractNo) {
    case "DE/2018/03":
      return DE03SafetyOptions;
    case "DE/2018/04":
      return DE04SafetyOptions;
    case "DC/2018/06":
      return DC06SafetyOptions;
    case "DC/2018/07":
      return DC07SafetyOptions;
    default:
      return [];
  }
}


