export const dummyData = [
  {
    id: 1,
    fieldName: "Response to CSF",
    docType: "MSF",
  }
]

export const tableConfig = [
  {
    id: "placeholder",
  },
  {
    id: "fieldName",
    label: "Field Name",
  },
  {
    id: "placeholder",
  },
  {
    id: "placeholder",
  },
  {
    id: "placeholder",
  },
];

export const assignOptions = [
  {
    fieldName: "CCOM",
    value: "ccom"
  },
  {
    fieldName: "CE",
    value: "ce"
  },
  {
    fieldName: "CSF",
    value: "csf"
  },
  {
    fieldName: "PMI",
    value: "pmi"
  },
]