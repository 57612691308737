import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';
import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";

export default function CorrespondenceReportSearch() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const sp = new URLSearchParams(history.location.search);
  const { register, handleSubmit, control, formState: { errors }, setValue,  watch} = useForm({
    defaultValues: {
      logNoFrom: sp.get("logNoFrom") ?? "",
      logNoTo: sp.get("logNoTo") ?? "",
      letterDateFrom: sp.get("letterDateFrom") ?? null,
      letterDateTo: sp.get("letterDateTo") ?? null,
      receivedDateFrom: sp.get("receivedDateFrom") ?? null,
      receivedDateTo: sp.get("receivedDateTo") ?? null,
      subject: sp.get("subject") ?? "",
      incomingOutstanding: sp.get('incomingOutstanding') == "true" ? true : false,
      outgoingOutstanding: sp.get('outgoingOutstanding') == "true" ? true : false,
      showCompleted: sp.get('showCompleted') == "true" ? true : false,
    }
  });



  console.log(sp.get('incomingOutstanding'),)
  async function onSubmit(data) {
    try {
      const query = constrcutQuery(data);

      history.replace({ pathname: `${history.location.pathname}`, search: `?${query}` });
      history.push({ pathname: `${history.location.pathname}/search`, search: `?${query}` });
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function constrcutQuery(data) {
    let query = "";
    Object.keys(data).forEach((key, idx) => {
      if (data[key]) {
        if (query !== "") query += "&";
        query += `${key}=${data[key]}`;
      }
    });
    return query;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <CommonContainer>
      <SectionTitle title={`Correspondence Report`} />
      <div className={classes.body}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Log No. From", name: "logNoFrom", isRequired: false, },
              1, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Log No. To", name: "logNoTo", isRequired: false, },
              2, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item sm={12} md={4} className={classes.placeholder} />
            {fieldHelper.renderField(
              { label: "Letter Date From", name: "letterDateFrom", isRequired: false, type: "Date" },
              3, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Letter Date To", name: "letterDateTo", isRequired: false, type: "Date" },
              4, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item sm={12} md={4} className={classes.placeholder} />
            {fieldHelper.renderField(
              { label: "Received Date From", name: "receivedDateFrom", isRequired: false, type: "Date" },
              5, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Received Date To", name: "receivedDateTo", isRequired: false, type: "Date" },
              6, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Subject", name: "subject", isRequired: false, },
              7, register, control, errors, { size: 12, mdSize: 12 })
            }

            {fieldHelper.renderField(
              { label: "Incoming Outstanding", name: "incomingOutstanding", isRequired: false, type: "Checkbox" },
              8, register, control, errors, { size: 6, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Outgoing Outstanding", name: "outgoingOutstanding", isRequired: false, type: "Checkbox" },
              9, register, control, errors, { size: 6, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Show Completed", name: "showCompleted", isRequired: false, type: "Checkbox" },
              10, register, control, errors, { size: 6, mdSize: 4 })
            }

            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton children="Submit" backgroundColor="#70BAD3" type="submit" />
          </Grid>
        </form>
      </div>
    </CommonContainer >
  );
}