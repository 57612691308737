import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useTheme } from '@material-ui/core/styles';
import { Drawer } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch, useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import { authHelper, documentsHelper } from "helpers";
import tableHelper from "utils/tableHelper";

import SafetyAdd from "SafetyInspection/SafetyAdd";
import Loading from "Common/Loading";
import SafetyFormTable from "Common/Documents/DocumentsTable";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/EllipseButton";
import IconButton from "Common/IconButton";
import Pagination from "Common/Pagination";
import FilterPage from "Common/FilterPage";
import Modal from "Common/Modal";

import { useStyles } from "./style";
import { safetyConfig } from "./config";

export default function SafetyForm({ selectedData, handleSelect, isSelect, externalContractId, contractNo }) {
  const contractData = useSelector(selectContractData);
  const theme = useTheme();
  const props = { theme, isSelect: !!handleSelect }
  const { contractId } = useParams();
  const classes = useStyles(props);
  const noFilter = {
  }
  const [data, setData] = useState([])
  const [page, setPage] = useState(1);
  const [size, _] = useState(10);
  const [count, setCount] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [filter, setFilter] = useState({
  });
  const [sort, setSort] = useState({
    orderBy: "updatedAt",
    sortDirection: "desc"
  })
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRestricted, setIsRestricted] = useState();
  const maxPage = () => {
    return Math.ceil(count / 10);
  }

  useEffect(() => {
    try {
      setIsLoading(true)
      if (useFilter) getPageData(page, size, filter, sort);
      else getPageData(page, size, noFilter, sort);
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }, [useFilter, filter, sort, page, externalContractId])

  async function getPageData(cPage, cSize, cFilter, cSort) {
    try {
      let filterVariable = { ...cFilter };
      filterVariable.page = cPage;
      filterVariable.items = cSize;
      filterVariable.orderBy = `${cSort.orderBy}.${cSort.sortDirection}`
      filterVariable.contractId = externalContractId ?? contractId;

      const filterKeys = Object.keys(cFilter);
      filterKeys.forEach((key) => {
        if (Array.isArray(cFilter[key])) {
          filterVariable[key] = tableHelper.handleDateTimeFilter(filterVariable[key])
        }
      })

      const result = await documentsHelper.getDocuments({
        type: "safeties",
        variable: filterVariable
      });
      if (result.error) throw new Error(result.error);
      if (!result.safety) throw new Error({ error: "safety data not found" });
      setData(result.safety);
      setCount(result.count);
      setIsRestricted(null);
      setIsLoading(false);
      return result;
    } catch (e) {
      if (e.message) setIsRestricted(e.message);
      setIsLoading(false);
      console.log(e);
    }
  }

  function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  function handleAddForm() {
    setModalOpen(true);
  };

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  async function handlePageChange(_, target) {
    setIsLoading(true);
    setPage(target);
    setIsLoading(false);
  };

  function handleRequestSort(event, property) {
    const isAsc = sort.orderBy === property && sort.sortDirection === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSort({
      orderBy: property,
      sortDirection: direction,
    });
  };

  function reload() {
    if (useFilter) getPageData(page, size, filter, sort);
    else getPageData(page, size, noFilter, sort);
  }

  function resetFilter() {
    setFilter({})
  }


  return (
    <div className={classes.root}>
      <Loading isLoading={isLoading} />
      <SectionTitle
        title={handleSelect ? "" : "Safety Inspection List"}
        extraComponents={
          <div style={{ marginLeft: "16px" }}>
            <IconButton
              icon={<FilterListIcon />}
              onClick={handleOpenFilter}
            />
          </div>
        }
      />
      <div style={{ marginTop: "16px" }}>
        {tableHelper.renderFilterBy(filter, safetyConfig(contractData.contractNo ?? contractNo), resetFilter)}
      </div>
      {isRestricted ??
        <SafetyFormTable
          data={data}
          handleSort={handleRequestSort}
          orderBy={sort.orderBy}
          sortDirection={sort.sortDirection}
          fieldsData={safetyConfig(contractData.contractNo ?? contractNo)}
          selectedData={selectedData}
          handleSelect={handleSelect}
        />
      }
      <Pagination count={maxPage()} page={page} onChange={handlePageChange} />
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={safetyConfig(contractData.contractNo ?? contractNo)} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter, setPage }} />
      </Drawer>
      {/* <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
        <SafetyAdd handleClose={() => setModalOpen(false)} contract={contract} reload={reload} />
      </Modal> */}
    </div>
  );
};

SafetyForm.propTypes = {
  contract: PropTypes.object
};