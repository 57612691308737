import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';

import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";

import { optionGroup } from './config';

export default function DropdownOptionSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();

  function navigateTo(value) {
    history.push(`${history.location.pathname}/${value}`);
  }
  return (
    <CommonContainer>
      <SectionTitle title={`Dropdown Option Setup`} />
      <div className={classes.body}>
        <Grid container spacing={2}>
          {optionGroup.map((item, idx) =>
            <Grid item xs={6} md={3}>
              <EllipseButton label={item.name} onClick={() => navigateTo(item.value)} />
            </Grid>
          )}
        </Grid>


      </div>
    </CommonContainer>
  );
}