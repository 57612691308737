import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

import { useStyles } from './style';

function ChartContainer({ children, height, elevation=0, background }) {
  const theme = useTheme();
  const props = { theme, height, background };
  const classes = useStyles(props);
  return(
    <Paper className={classes.root} elevation={elevation}>
      {children}
    </Paper>
  );
}

ChartContainer.propTypes = {
  header: PropTypes.node,
  height: PropTypes.string,
  elevation: PropTypes.number,
};

export default ChartContainer;