export const amWeatherSummaryConfig = [
  { id: null, label: "" },
  { id: "weather", label: "AM Weather" },
  { id: "days", label: "No. of Half Days" },
];

export const pmWeatherSummaryConfig = [
  { id: null, label: "" },
  { id: "weather", label: "PM Weather" },
  { id: "days", label: "No. of Half Days" },
];