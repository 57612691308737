import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect, Fragment } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';

import { permissionHelper } from 'helpers';
import SectionTitle from 'Common/SectionTitle';
import Pagination from "Common/Pagination";
import Loading from 'Common/Loading';
import EllipseButton from 'Common/EllipseButton';

import EDMSContainer from '../CommonContainer';
import { reportConfig } from './config';
import { useStyles } from "./style";

export default function EDMSFolderSummary({ }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isRestricted, setIsRestricted] = useState();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isShowAll, setIsShowAll] = useState(false);

  const maxPage = () => {
    return isShowAll ? 1 : Math.ceil(count / 50);
  }

  useEffect(() => {
    console.log(isShowAll)
    init();
  }, [page, isShowAll]);

  async function init() {
    try {
      setIsLoading(true);
      const result = isShowAll
        ? await permissionHelper.getFolderPermissionList({ contractId, orderBy: "name,parentId.asc" })
        : await permissionHelper.getFolderPermissionList({ contractId, items: 50, page: page, orderBy: "name,parentId.asc" });
      setData(result.file);
      setCount(result.count);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsRestricted(e.message);
      setIsLoading(false);
    }
  }

  function renderTableCell(column, row, folder) {
    if (column.render) {
      return column.render(folder, row);
    }
    return row[column.id] ?? "-";
  }

  async function handlePageChange(_, target) {
    setIsLoading(true);
    setPage(target);
    setIsLoading(false);
  };

  function toggleShowAll() {
    setPage(1);
    setIsShowAll(!isShowAll);
  }

  return (
    <div style={{ padding: "16px" }}>
      <Loading isLoading={isLoading} />
      <EDMSContainer>
        <SectionTitle
          title="Folder Security Report"
          extraComponents={<div className={classes.buttonsContainer}>
            <EllipseButton onClick={() => toggleShowAll()} label={isShowAll ? `Resume` : "Show All"} />
          </div>}
        />
        <div className={classes.body}>
          {isRestricted && <div style={{ color: "white" }}>{isRestricted}</div>}
          <Table>
            <TableHead>
              <TableRow>
                {reportConfig.map((column, idx) =>
                  <TableCell style={{ width: column.size }} className={classes.headerCell} key={idx}>{column.label}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.map((folder, folderIdx) =>
                <Fragment key={folderIdx}>
                  {folder.groups.map((row, idx) =>
                    <TableRow key={idx}>
                      {reportConfig.map((column, idx) =>
                        <TableCell className={classes.tableCell} key={idx}>{renderTableCell(column, row, folder)}</TableCell>
                      )}
                    </TableRow>
                  )}
                  {folder.users.map((row, idx) =>
                    <TableRow key={idx}>
                      {reportConfig.map((column, idx) =>
                        <TableCell className={classes.tableCell} key={idx}>{renderTableCell(column, row, folder)}</TableCell>
                      )}
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </Table>

          <Pagination count={maxPage()} page={page} onChange={handlePageChange} />
        </div>
      </EDMSContainer>
    </div>
  );
}