import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme=>({
  root: props => ({
    background: props.background ?? theme.palette.secondaryDark,
    height: props.height ?? 400,
    display: 'flex',
    flexDirection: 'column',
  })
}));
