import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  latestMessage: {
    marginBottom: "4px",
  },
  buttonGroup: {
    textAlign: "end"
  },
  oldMessage: {
    marginTop: "8px",
  }
}));