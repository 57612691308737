export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    id: "dashboard",
    link: "dashboard"
  },
  {
    label: "Site Diary",
    key: 1,
    id: "site-diary",
    link: "docs"
  },
]