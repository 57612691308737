
import { useState } from "react";
import { TextField, Button, Grid, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch } from "react-redux";

import { setLoading } from "redux/slice/loadingSlice";
import authHelper from "helpers/authHelper";
import { useStyles } from "../style";

export default function ForgotPasswordForm({ toLogin }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');

  const [form, setForm] = useState({
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };


  const findPassword = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      const result = await authHelper.forgotPassword(form.email);
      if (result.error) throw new Error(result.error);
      setMessage('');
      setSuccess('Please check your email to find the reset link')
      setTimeout(() => {
        dispatch(setLoading(false));
      }, [1500])

    } catch (e) {
      console.log(e);
      if (!!e.message) setMessage(e.message);
      else setMessage('Unknown Error');
      dispatch(setLoading(false));
    }
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.btnBack}>
        <IconButton onClick={toLogin}><ArrowBackIosIcon /></IconButton>
      </div>
      <div className={classes.title}>Forgot Password</div>
      <form className={classes.form} onSubmit={findPassword}>
        <Grid container className={classes.row}>
          <TextField
            InputProps={{
              classes: {
                root: classes.loginFieldLabel,
                focused: classes.loginFieldLabel,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.loginFieldLabel,
                focused: classes.loginFieldLabel,
                filled: classes.loginFieldLabel,
              },
            }}
            className={classes.loginField}
            variant="filled"
            required
            fullWidth
            type="email"
            id="email"
            onChange={handleChange}
            label="Email Address"
            name="email"
          />
        </Grid>
        {success &&
          <Grid container className={`${classes.row}`}>
            {success}
          </Grid>
        }
        {message &&
          <Grid container className={`${classes.row} ${classes.error}`}>
            {message}
          </Grid>
        }

        <Button
          fullWidth
          variant="contained"
          type="submit"
          className={classes.submit}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
