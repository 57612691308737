import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgoround: theme.palette.primaryDark,
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  body: {
    marginTop: "32px",
  },
  divider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  bottomRightWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: 'end',
  }
}));
