import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://edms.swh-aecom.hk:4433/"
});

axiosInstance.interceptors.request.use(
  function (config) {
    if ((!!localStorage && localStorage.getItem("accessToken"))
      || (!!sessionStorage && sessionStorage.getItem("accessToken"))) {
      const token = localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");
      if (!!token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (response) => response,
  (error) => Promise.reject(error.response)
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("error", error.toString());
    if (error.toString() === "Error: Network Error") {
      console.log("unauthorized");
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
