import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from "clsx";
import { Link, useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { useStyles } from "./style";


export default function DocumentType({ option, subItems }) {
  const [expand, setExpand] = useState(false);
  const { contractId } = useParams();
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);

  return (
    <div className={classes.groupItem}>
      <div onClick={() => setExpand(!expand)}>
        <div className={classes.btnControl}>{expand ? "-" : "+"}</div>
        <div className={classes.navigationLink} key={option.key}>{option.name}</div>
      </div>
      <div className={clsx(classes.group, expand ? classes.expanded : classes.collapsed)}>
        {subItems.map((item, idx) =>
          <div key={item.name} className={classes.subGroup}>
            <Link
              className={classes.navigationLink}
              to={{ pathname: item.linkTo(contractId), state: { type: item.key }, search: `?type=${item.key}` }}
              key={option.key}>
              {item.name}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}