import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import SectionTitle from "Common/SectionTitle";
import RISCCompliance from 'RISC/RISCCompliance';
import { useStyles } from "./style";

export default function RISCDashboard() {
  const contractData = useSelector(selectContractData);
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <SectionTitle title={"RISC Form Dashboard"} />
      <RISCCompliance data={contractData} />
    </div>
  );
};

RISCDashboard.propTypes = {
  data: PropTypes.any,
}