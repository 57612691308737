import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { Divider } from '@material-ui/core';

import FolderRow from './folderRow';
import { folderMenuOptions } from 'config/rightClickMenuOptions';
import { useStyles } from "./style";

export default function ParentFolder({ folder, setAnchorEl, setMenuOptions, withMargin }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();

  useEffect(() => {
    async function init() {
      try {
        // const result = await edmsFileHelper.getFileGroups(contractId);
      } catch (e) {
        console.log(e);
      }
    }
    init();
  }, []);

  async function onContextMenu(e, id) {
    e.preventDefault();
    setMenuOptions(await folderMenuOptions(handleClose, history, contractId, id));
    setAnchorEl(e.target);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function redirectToFolder(id) {
    history.push(`/contract/${contractId}/edms/folder/${id}`);
  }

  return (
    <>
      <div
        onClick={() => redirectToFolder(folder.id)}
        onContextMenu={(e) => onContextMenu(e, folder.id)}
        className={clsx(classes.parentFolder, withMargin && classes.subFolderWrapper)}
      >
        <div className={classes.folderInfo}>
          <div className={classes.code}>{folder.code}</div>
          <div style={{ margin: "0 4px" }}>-</div>
          <div>{folder.name}</div>
        </div>
        <div className={classes.folderRemarks}>
          {folder.labels.map((label, idx) => <div key={idx} className={classes.codeLabel}>{label.code}</div>)}
        </div>
      </div>
      <div className={withMargin && classes.subFolderWrapper}>
        {folder.children && folder.children.map((child, idx) =>
          <Fragment key={idx}>
            {(child.children && child.children.length > 0)
              ? <><ParentFolder
                folder={child}
                setAnchorEl={setAnchorEl}
                setMenuOptions={setMenuOptions}
                withMargin
              />
                {(!child.children || child.children.length === 0) && <Divider className={classes.divider} />}
              </>
              : <div className={classes.subFolderWrapper}>
                <FolderRow
                  withMargin
                  folder={child}
                  setAnchorEl={setAnchorEl}
                  setMenuOptions={setMenuOptions}
                />
                <Divider className={classes.divider} />
              </div>
            }

          </Fragment>
        )}
      </div>
    </>
  );
}