import axios from './apiHelper';

const safetyPerformanceHelper = {
  getSafetyPerformances: async (variables) => {
    try {
      const result = await axios.get(`/safety-performances`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get safety performances");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafetyPerformanceResults: async (variables) => {
    try {
      const result = await axios.get(`/safety-performance-question-results`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get safety performance results");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createSafetyPerformance: async (variables) => {
    try {
      const result = await axios.post(`/safety-performance`, variables);

      if (!result.data) {
        throw new Error("Fail to create safety performance");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafetyPerformanceMetas: async (variables) => {
    try {
      const result = await axios.get(`/safety-performance-metas`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get safety performance metas");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getActionPanel: async (id) => {
    try {
      const result = await axios.get(`/safety-performance/${id}/has-right-panel`);

      if (!result.data) {
        throw new Error("Fail to get action panel");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafetyPerformanceLineChart: async (variables) => {
    try {
      const result = await axios.get(`/dashboard/safety-performance/line-chart`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get safety performance trend");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSafetyReportPdf: async (id) => {
    try {
      const result = await axios.get(`/safety-performance/${id}/get-pdf`, { responseType: "blob" });

      if (!result.data) {
        throw new Error("Fail to get safety report pdf");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  updateQuestions: async ({ id, action, variables }) => {
    try {
      const result = await axios.patch(`/safety-performance/${id}/question-result/${action}`, variables);

      if (!result.data) {
        throw new Error("Fail to update questions");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deleteSafetyPerformance: async (id) => {
    try {
      const result = await axios.delete(`/safety-performance/${id}`);

      if (!result.data) {
        throw new Error("Fail to delete safety-performance");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default safetyPerformanceHelper;