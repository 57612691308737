import { fileStatusSiteDirayOptions, sourceOptions, weatherOptions } from "config/selectOptions";

export const siteDiaryConfig = [
  { id: "fileId", label: "File ID" },
  { id: "creationDatetime", label: "Created Date", type: "Date" },
  { id: "submissionDatetime", label: "Site Diary Date", type: "Date" },
  { id: "updatedAt", label: "Last Updated", type: "Datetime" },
  { id: "fileStatus", label: "Status", type: "Select", options: fileStatusSiteDirayOptions },
  { id: "fileSource", label: "Source", type: "Select", options: sourceOptions },
  { id: "amWeather", label: "AM Weather", type: "Select", options: weatherOptions },
  { id: "pmWeather", label: "PM Weather", type: "Select", options: weatherOptions },
  { id: "rainfall", label: "Rain Fall" },
  { id: null, label: <></> },
  { id: null, label: <></> }
];
