import { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';

import { safetyPerformanceHelper } from 'helpers';
import EllipseButton from 'Common/EllipseButton';
import IconButton from "Common/IconButton";
import SectionTitle from 'Common/SectionTitle';
import CommonDocumentList from 'EDMS/CommonDocumentList';
import FilterPage from 'Common/FilterPage';
import Modal from "Common/Modal";
import { safetyPerformanceConfig } from 'EDMS/CommonDocumentList/config';
import { setLoading } from 'redux/slice/loadingSlice';

import ViewFile from 'static/icons/viewFile.svg';
import IncidentAddForm from './SafetyPerformanceAddForm';
import { useStyles } from "./style";
import permissionUtils from 'utils/permissionUtils';

export default function SafetyPerformanceList({ selectedData, handleSelect, hideGoToButton, permission }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [modal, setModal] = useState({ type: "", open: false, data: null });
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
  });
  const haveEditRight = permissionUtils.checkPermission(permission, "safetyPerformance", "edit");

  async function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  async function downloadPdf(item) {
    try {
      dispatch(setLoading(true));
      const blob = await safetyPerformanceHelper.getSafetyReportPdf(item.id);
      var url = URL.createObjectURL(blob);
      window.open(url, '_blank', 'noopener noreferrer');
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
    }
  }

  return (
    <>
      <SectionTitle
        title={handleSelect ? "" : "Safety Performance Reports"}
        extraComponents={
          <div className={classes.buttonsContainer}>
            {haveEditRight && <EllipseButton onClick={() => setModal({ type: "Add", open: true })} label="+ New Report" />}
            <div style={{ marginLeft: "16px" }}>
              <IconButton
                icon={<FilterListIcon />}
                onClick={handleOpenFilter}
              />
            </div>
          </div>
        }
      />
      <CommonDocumentList
        handleSelect={handleSelect}
        selectedData={selectedData}
        tableType={"safety-performance"}
        useFilter={useFilter}
        filter={filter}
        page={page}
        getDataApi={safetyPerformanceHelper.getSafetyPerformances}
        defaultOrderBy="reportPeriodFrom"
        responseKey={"safety_performance"}
        handleAction={downloadPdf}
        actionIcon={<img
          className={classes.img}
          src={ViewFile}
          alt={"document"}
        />}
        hideGoToButton={hideGoToButton}
      />
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={safetyPerformanceConfig} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter, setPage }} />
      </Drawer>
      <Modal open={modal.open} handleClose={() => setModal({ type: modal.type, open: false, data: null })}>
        <IncidentAddForm mode={modal.type} handleClose={() => setModal({ type: modal.type, open: false, data: null })} data={modal.data} enableRedirection={modal.type === "Add"} />
      </Modal>
    </>
  );
};