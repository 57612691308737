import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';

import { dashboardHelper } from 'helpers';
import ChartHeader from 'Common/ChartHeader';
import TrendGraph from './trendGraph';
import DistributionGraph from './distributionGraph';
import RISCInfoBox from "RISC/RISCInfoBox";
import { useStyles } from "./style";
import NonClosedDistributionGraph from './nonClosedDistributionGraph';
import NonClosedTrendGraph from './nonClosedTrendGraphy';

export default function RISCCompliance({ data }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { type, contractId } = useParams();
  const [countUrgent, setCountUrgent] = useState({
    onTime: 0,
    notOnTime: 0,
  });
  const [countFileStatus, setCountFileStatus] = useState({
    submitted: 0,
    closed: 0,
  });
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));

  useEffect(() => {
    initDate()
  }, [])

  async function initDate() {
    try {
      const result = await Promise.all([
        dashboardHelper.getCalculateUrgent({ variable: { contractId, onTime: true } }),
        dashboardHelper.getCalculateUrgent({ variable: { contractId, onTime: false } }),
        dashboardHelper.countFileStatus({ type: type, variable: { by: "Submitted", contractId } }),
        dashboardHelper.countFileStatus({ type: type, variable: { by: "Closed", contractId } })
      ])
      if (result[0].error || result[1].error) throw new Error(result.error);
      setCountUrgent({
        onTime: result[0].count,
        notOnTime: result[1].count
      })
      if (result[2].error || result[3].error) throw new Error(result.error);
      setCountFileStatus({
        submitted: result[2].count,
        closed: result[3].count
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"Total RISC Form"} value={data.noOfRisc ?? 0} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"Submitted RISC Form"} value={countFileStatus.submitted ?? "-"} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"Closed RISC Form"} value={countFileStatus.closed ?? "-"} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox
            title={"On-Time RISC Form"}
            withDate={false}
            value={countUrgent.onTime ?? '-'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <RISCInfoBox
            title={"Not On-Time RISC Form"}
            withDate={false}
            value={countUrgent.notOnTime ?? '-'}
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.bottomRightWrapper}>
            <ChartHeader
              title={''}
              filterType={"quarterRange"}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </Grid>
        <Grid container className={classes.body} spacing={2}>
          <Grid item xs={12} md={4}>
            <DistributionGraph startDate={startDate} endDate={endDate} />
          </Grid>
          <Grid item xs={12} md={8}>
            <TrendGraph startDate={startDate} endDate={endDate} />
          </Grid>


          <Grid item xs={12} md={4}>
            <NonClosedDistributionGraph startDate={startDate} endDate={endDate} />
          </Grid>
          <Grid item xs={12} md={8}>
            <NonClosedTrendGraph startDate={startDate} endDate={endDate} />
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
    </div>
  );
};