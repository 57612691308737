export const quarterList = [
  {
    label: "Q1",
    value: "1",
  },
  {
    label: "Q2",
    value: "2",
  },
  {
    label: "Q3",
    value: "3",
  },
  {
    label: "Q4",
    value: "4",
  }
]