import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  img: {
    height: 25,
    width: 25
  },
  a: {
    height: 25
  },
}));
