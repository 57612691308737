import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState, useEffect } from 'react';

import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import CustTableCell from "Common/TableCell";
import Modal from 'Common/Modal';

import CommonContainer from 'EDMS/CommonContainer';
import { tableConfig, dummyData } from './config';
import { useStyles } from "../style";
import MasterNumberEditModal from './MasterNumberEditModal';

export default function MasterNumberSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const [modalOpen, setModalOpen] = useState({ open: false, type: null, data: {} });

  function renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {tableConfig.map((column, idx) =>
            <TableCell className={classes.headerCell} key={idx}>{column.label}</TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
  function renderTableBody() {
    return (
      <TableBody>
        {dummyData.map((row, idx) => {
          return (
            <TableRow key={idx}>
              <CustTableCell>{idx + 1}</CustTableCell>
              {tableConfig.filter(item => item.id !== "placeholder").map((col, colIdx) =>
                <CustTableCell key={colIdx}>{row[col.id]}</CustTableCell>
              )}
              <CustTableCell
                icon={<EditIcon />}
                onClick={() => setModalOpen({ open: true, data: {} })}
              />
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  function reload() {
    console.log('reload');
  }

  return (
    <CommonContainer>
      <SectionTitle
        title={`Master Number Setup`}
      />
      <div className={classes.body}>
        <Table>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </div>
      <Modal open={modalOpen.open} handleClose={() => setModalOpen(false)}>
        <MasterNumberEditModal handleClose={() => setModalOpen({ ...modalOpen, open: false })} reload={reload} data={modalOpen.data} type={modalOpen.type} />
      </Modal>
    </CommonContainer>
  );
}