import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectContractData } from 'redux/slice/contractSlice';

import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { setMenuOpen } from 'redux/slice/navigationSlice';
import bim1 from 'static/videos/bim1.mp4';
import bim2 from 'static/videos/bim2.mp4';
import { bimHelper, contractHelper, dashboardHelper, incidentHelper, riskHelper, safetyPerformanceHelper } from 'helpers';
import InfoHeader from 'Common/InfoHeader';
import SectionTitle from 'Common/SectionTitle';
import OverviewBox from './OverviewBox';
import { dwssItems, phaseTwoItems } from './config';
import { useStyles } from "./style";

export default function Overview() {
  const contractData = useSelector(selectContractData);
  const dispatch = useDispatch();
  const { contractId } = useParams()
  const theme = useTheme();
  const classes = useStyles(theme);
  const [ph2Count, setPh2Count] = useState({
    edmsCount: 0,
    riskCount: 0,
    incidentCount: 0
  });

  useEffect(() => {
    async function initData() {
      const result = await Promise.all([
        contractHelper.getContractsTypeCount({ contractId, type: "edms" }),
        riskHelper.getRiskMeetings({ contractId }),
        riskHelper.getRiskRegisters({ contractId }),
        incidentHelper.getIncidents({ contractId }),
        safetyPerformanceHelper.getSafetyPerformances({ contractId }),
        bimHelper.getBimFileCount({ contractId }),
        dashboardHelper.getPhotoDashboard({ route: "total-photos", variable: { contractId } }),
      ]);
      const risk = `${result[1].count ?? "-"}/${result[2].count ?? "-"} `;
      const incident = result[3].count;
      const bim = `${result[5]?.managedCount ?? "-"}/${result[5]?.publishedCount ?? "-"}`
      getCount(result[0].contract, risk, incident, result[4].count ?? "-", bim, result[6]);
    }
    try {
      initData();
      dispatch(setMenuOpen());
    } catch (e) {
      console.log(e)
    }
  }, [contractId])

  function getCount(edms, risk, incident, safety, bim, photos) {
    let edmsCount = 0, riskCount = 0;
    if (edms.length > 0) {
      edms.forEach(type => {
        edmsCount += Number(type.count);
      })
    }
    setPh2Count({
      edmsCount,
      riskCount: risk,
      incidentCount: incident,
      safetyCount: safety,
      bimCount: bim,
      publishedCount: bim?.publishedCount,
      photos: photos?.total_photos
    });
  }

  return (
    <>
      <div className={classes.headerWrapper}>
        <InfoHeader data={contractData} />
      </div>
      <div className={classes.root}>
        <Grid container >
          {contractData && (contractData.contractNo === "DC/2018/06" || contractData.contractNo === "DE/2018/03")
            ? <video src={bim1} autoPlay={true} width={"100%"} loop={true} muted style={{ maxHeight: "400px" }} />
            : <video src={bim2} autoPlay={true} width={"100%"} loop={true} muted style={{ maxHeight: "400px" }} />
          }
        </Grid>
        <div className={classes.mapOffset}>
          <SectionTitle title="Overview" />
          <Grid container spacing={1} className={classes.listGroup}>
            {dwssItems.map((item, i) =>
              <Grid item key={i} className={classes.listItem} xs={12} sm={6} md={4}>
                <OverviewBox type={item} data={contractData ?? {}} />
              </Grid>
            )}
            {phaseTwoItems.map((item, i) =>
              <Grid item key={i} className={classes.listItem} xs={12} sm={6} md={4}>
                <OverviewBox type={item} data={ph2Count ?? {}} />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};