import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectUserPermission } from 'redux/slice/permissionSlice';
import TabPanel from 'Common/TabPanel';
import LinkTabs from 'Common/LinkTabs';

import SafetyPerformanceDashboard from './SafetyPerformanceDashboard';
import SafetyPerformanceList from './SafetyPerformanceList';
import { useStyles } from "./style";
import { tabData } from './config';
import CommonContainer from 'EDMS/CommonContainer';


export default function SafetyPerformance() {
  const location = useLocation();
  const sp = new URLSearchParams(location.search);
  const tabsName = sp.has('tab') ? sp.get("tab") : null;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [activeTab, setActiveTab] = useState(determineTabs());
  const permission = useSelector(selectUserPermission);

  function determineTabs() {
    if (tabsName === "docs") return 1
    else return 0
  }

  useEffect(() => {
    async function initData() {
      setActiveTab(tabsName === "docs" ? 1 : 0);
    }
    initData();
  }, [tabsName])

  const returnContent = () => {
    switch (tabsName) {
      case "docs":
        return <SafetyPerformanceList permission={permission} />
      default:
        return <SafetyPerformanceDashboard />
    }
  }

  return (
    <CommonContainer>
      <div className={classes.body}>
        <LinkTabs data={tabData} activeTab={activeTab} />
        {tabData.map((item, index) =>
          <TabPanel key={index} value={activeTab} index={index}>
            {returnContent()}
          </TabPanel>
        )}
      </div>
    </CommonContainer>
  );
};