import axios from './apiHelper';

const relatedDocHelper = {
  getRelatedDocuments: async ({ docType, docId }) => {
    try {
      const result = await axios.get(`/related-document/${docType}Id/${docId}`);

      if (!result.data) {
        throw new Error(`Fail to get related Documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
        if (e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postRelatedDocuments: async (variables) => {
    try {
      const result = await axios.post(`/doc-type-related-document`, variables);

      if (!result.data) {
        throw new Error(`Fail to post related Documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
        if (e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  }
}

export default relatedDocHelper;