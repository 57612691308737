import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';

import { dashboardHelper } from 'helpers';
import ChartHeader from 'Common/ChartHeader';
import InfoBox from 'RISC/RISCInfoBox';
import DeploymentPieChart from './deploymentPieChart';
import DeploymentGraph from './deploymentGraph';
import GenderPieChart from './genderPieChart';
import { useStyles } from "./style";

export default function LabourCompliance({ data }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { type, contractId } = useParams();
  const [avgMonthly, setAvgMonthly] = useState(0);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));

  useEffect(() => {
    initDate()
  }, [startDate, endDate])

  async function initDate() {
    try {
      const result = await dashboardHelper.getLabourMonthlyAvg({
        variable: {
          contractId,
          startDate: startDate.clone().format('YYYY-MM-DD'),
          endDate: endDate.clone().format('YYYY-MM-DD'),
        }
      });

      if (result.error) throw new Error(result.error);
      setAvgMonthly(Number(result.total))

    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div className={classes.root}>
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={4}>
          <InfoBox title={"Total Labour Return"} value={data.noOfLabour} withDate={false} />
        </Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12} sm={8}>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.bottomRightWrapper}>
            <ChartHeader
              title={''}
              filterType={"quarterRange"}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InfoBox title={"Labour Return Workers Monthly Average"} value={avgMonthly.toLocaleString({ maximummaximumFractionDigits: 0 })} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={8}>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <GenderPieChart startDate={startDate} endDate={endDate} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DeploymentPieChart startDate={startDate} endDate={endDate} />
        </Grid>
      </Grid>
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={12}>
          <DeploymentGraph startDate={startDate} endDate={endDate} />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};