import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: "16px",
  },
  editHeader: {
    display: "flex",
    marginBottom: 8,
  },
  infoHeader: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    color: "#fff",
    padding: "20px 0px 20px 0",
    justifyContent: "start",
    borderBottom: "1px solid #CACACA",
  },
  header: {
    padding: "8px 16px",
    ...theme.typography.h5,
    whiteSpace: "pre-line",
  },
  column: {
    width: "100%",
    display: 'flex',
    flexDirection: "column",
    padding: "8px 16px 16px 16px",
    '& span': {
      marginTop: "8px",
    }
  },
  row: {
    margin: "16px 0",
  },
  tableCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
  },
  headerCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
    fontWeight: 600,
  },
  divider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
}));
