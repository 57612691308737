import { Fragment, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";

import RoundedButton from "Common/RoundedButton";
import { setNotification } from "redux/slice/notificationSlice";
import fieldHelper from "utils/fieldHelper";
import SectionTitle from "Common/SectionTitle";
import Loading from "Common/Loading";

import CloseIcon from "static/icons/close.svg";
import { useStyles } from "./style";
import { incidentHelper } from "helpers";

const attachmentConfig = [
  {
    id: "fileName",
    key: "fileName",
    label: "File Name",
    disabled: true,
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
  },
  // {
  //   id: "documentType",
  //   key: "documentType",
  //   label: "Attachment Type",
  //   isRequired: true,
  //   type: "Select",
  //   name: (props) => {
  //     return `${props.prename}.${props.name}`
  //   },
  //   options: [
  //     {
  //       label: "NCE",
  //       value: "NCE"
  //     },
  //     {
  //       label: "Quotation",
  //       value: "Quotation",
  //     },
  //     {
  //       label: "Supporting",
  //       value: "Supporting",
  //     },
  //     {
  //       label: "SI",
  //       value: "SI"
  //     },
  //   ]
  // },
  // {
  //   id: "description",
  //   key: "description",
  //   label: "Description",
  //   name: (props) => {
  //     return `${props.prename}.${props.name}`
  //   },
  // },
]

export default function AddAttachmentsForm({ mode, handleClose, data, createOnSave = false, setAttachments, reload }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      files: data?.files,
      attachments: data.attachments ?? []
    }
  })

  const { fields } = useFieldArray({
    control,
    name: "attachments",
    keyName: "_id"
  });

  async function onSubmit(formData) {
    try {
      setLoading(true);
      if (createOnSave) {
        if (formData.files && formData?.files?.length > 0) {
          // await Promise.all([formData.attachments.map(
          //   (item, idx) => incidentHelper.uploadSIDocument(data.siId, { file: formData.files[idx], description: item.description, documentType: item.documentType }))
          // ]);
        }
        reload();
      } else {
        setAttachments({
          files: formData.files,
          attachments: formData.attachments,
        })
      }
      handleClose();
      setLoading(false);

    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function handleFileChange(e, field) {
    try {
      const fileArr = Array.from(e.target.files);
      field.onChange(e.target.files);
      setValue("attachments", fileArr.map(file => {
        return {
          fileName: file.name,
          documentType: "",
          description: "",
        }
      }))
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`Add Reference Document(s)`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"close"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form>
        <DialogContent>
          <RoundedButton
            varient="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            backgroundColor="#70BAD3"
          >
            Upload a file
            <Controller
              name="files"
              control={control}
              render={({ field }) => <input
                onChange={(e) => handleFileChange(e, field)}
                type="file"
                multiple
                hidden
              />}>

            </Controller>
          </RoundedButton>
          <ErrorMessage
            errors={errors}
            name={"files"}
            render={({ message }) => <p className={classes.error} style={{ marginLeft: "16px" }}>{message}</p>}
          />

          <Grid container spacing={3} className={classes.section}>
            {fields.map((field, index) => {
              return (
                <Fragment key={field._id}>
                  {attachmentConfig.map((inputField, idx) =>
                    fieldHelper.renderField(inputField, idx, register, control, errors, { prename: `attachments.[${index}]`, index, name: inputField.key, })
                  )}
                </Fragment>
              );
            })}
          </Grid>
          <Divider className={classes.divider} />

        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          <RoundedButton children={"Save"} backgroundColor="#70BAD3" onClick={() => handleSubmit(onSubmit)()} type="button" />
        </DialogActions>
      </form>
    </>
  );
}