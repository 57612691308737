export const dummyData = [
  {
    ref: "KLCWJV/990/MSF/0006-2019_A",
    subject: "Underground Services Detection Equipment",
    letterDate: "2019/10/09",
    receivedDate: "2019/10/10",
    outstandingDays: 837,
    logNumber: "A600104",
    docType: "MSF",
  }
]

export const reportIncomingConfig = [
  {
    id: "ref",
    label: "Ref",
    // size: "40%",
  },
  {
    id: "subject",
    label: "Subject",
    // size: "15%",
  },
  {
    label: "LetterDate",
    id: "letterDate",
    // render: (folder, row) => {
    //   return row.group || row.username;
    // },
  },
  {
    label: "Received Date",
    id: "receivedDate",
    // size: "10%",
  },
  {
    label: "No. of Days Outstanding",
    id: "outstandingDays",
  },
  {
    label: "Log No.",
    id: "logNumber",
  },
  {
    label: "Document Type",
    id: "docType",
  },
];

export const reportOutgoingConfig = [
  {
    label: "Log No.",
    id: "logNumber",
  },
  {
    id: "ref",
    label: "Ref",
  },
  {
    id: "subject",
    label: "Subject",
  },
  {
    label: "Document Type",
    id: "docType",
  },
  {
    label: "LetterDate",
    id: "letterDate",
  },
  {
    label: "Issue Date",
    id: "issueDate",
  },
  {
    label: "No. of Days Outstanding",
    id: "outstandingDays",
  },
  {
    label: "Remark",
    id: "remark",
  },
];