import { riscConfig } from "RISC/RISCForm/config";
import { siteDiaryConfig } from "SiteDiary/SiteDiaryForm/config";
import { photoConfig } from "Photos/PhotoGallery/config";

const typeHelper = {
  getCurrentType(type) {
    const typeLowerCase = type.toLowerCase();
    let currentType = "";
    if (typeLowerCase === "risc") currentType = "risc";
    else if (typeLowerCase === "site diary" || typeLowerCase === "site-diary") currentType = "site-diary";
    else if (typeLowerCase === "photo" || !type) currentType = "photo";
    else if (typeLowerCase === "safety-inspection" || typeLowerCase === "srr" || typeLowerCase === "safety") currentType = "safety";
    else if (typeLowerCase === "cleansing-inspection" || typeLowerCase === "cic" || typeLowerCase === "cleansing") currentType = "cleansing";
    else if (typeLowerCase === "labour" || typeLowerCase === "lrr") currentType = "labour";
    return currentType
  },
  getDocumentTypeSlug(type) {
    if (!type) return "photo"
    const typeLowerCase = type.toLowerCase();
    let currentType = "";
    if (typeLowerCase === "risc") currentType = "risc";
    else if (typeLowerCase === "site diary" || typeLowerCase === "site-diary" || typeLowerCase === "site_diary") currentType = "site-diary";
    else if (typeLowerCase === "photo") currentType = "photo";
    else if (typeLowerCase === "safety-inspection" || typeLowerCase === "ssr") currentType = "safety-inspection";
    else if (typeLowerCase === "cleansing-inspection" || typeLowerCase === "cic") currentType = "cleansing-inspection";
    else if (typeLowerCase === "labour" || typeLowerCase === "lrr") currentType = "labour";
    return currentType;
  },
  getCurrentDocumentsType(type) {
    let currentType = "";
    if (type === "RISC" || type === "risc") currentType = "riscs";
    else if (type === "Site Diary" || type === "site-diary") currentType = "site-diarys";
    else if (type === "photo") currentType = "photos";
    else if (type === "safety" || type === "safety-inspection") currentType = "safeties";
    else if (type === "cleansing" || type === "cleansing-inspection") currentType = "cleansings";
    else if (type === "labour") currentType = "labours";
    return currentType;
  },
  getTableConfig(type) {
    let config = []
    if (type === "RISC" || type === "risc") config = riscConfig;
    else if (type === "Site Diary" || type === "site-diary") config = siteDiaryConfig;
    else if (type === "photo") config = photoConfig;
    return config
  },
  getTypeDefaultSort(type) {
    let config = {}
    if (type === "RISC" || type === "risc") config = { orderBy: "actualSurveyInspectionDatetime", sortDirection: "desc" };
    else config = { orderBy: "submissionDatetime", sortDirection: "desc" };
    return config;
  },
  getTypeTitle(type) {
    switch (type) {
      case "audit":
        return "Audit";
      case "ce":
        return "Compensation";
      case "csf":
        return "Contractor's Submission";
      case "ewn":
        return "Early Warning Notice";
      case "msf":
        return "Contractor's Material Submission";
      case "pmi":
        return "Project Manager's Instruction";
      case "rfi":
        return "Request for Information";
      case "cp":
        return "Construction Programme";
      case "rr":
        return "Risk Register";
      case "letter":
        return "Letter";
      case "quotation":
        return "Quotation";
      case "risk-register":
        return "Risk Register";
      default:
        return type.toUpperCase();
    }
  },
  extractDocumentObject(object, type) {
    let result = object;
    if (type === "RISC" || type === "risc") result = object.risc;
    else if (type === "Site Diary" || type === "site-diary") result = object.site_diary
    else if (type === "photo") result = object.photo;
    else if (type === "safety-inspection" || type === "safety") result = object.safety;
    else if (type === "cleansing-inspection" || type === "cleansing") result = object.cleansing;
    else if (type === "labour") result = object.labour;
    else if (type === "edms") result = object;
    else if (type === "risk-register") result = object.risk_register;

    return result;
  },
  convertHyphenToCamelType(type) {
    switch(type) {
      case "risk-register":
        return "riskRegister";
      case "site-diary":
        return "siteDiary";
      default:
        return type;
    }
  }
}

export default typeHelper;