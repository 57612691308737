export const DE03CleansingOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Daily Cleaning Checklist",
    value: "Daily Cleaning Checklist",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const DE04CleansingOptions = [
  {
    label: "",
    value: "",
  },

  {
    label: "Daily Cleaning Checklist",
    value: "Daily Cleaning Checklist",
  },  
  {
    label: "Weekly Tidying Checklist",
    value: "Weekly Tidying Checklist",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const DC06CleansingOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Daily Cleaning",
    value: "Daily Cleaning",
  },
  {
    label: "Weekly Tidying",
    value: "Weekly Tidying",
  },
  {
    label: "Others",
    value: "Others",
  },
]

export const DC07CleansingOptions = [
  {
    label: "",
    value: "",
  },
  {
    label: "Daily Cleaning Inspection Checklist",
    value: "Daily Cleaning Inspection Checklist",
  },
  {
    label: "Weekly Tidying Checklist",
    value: "Weekly Tidying Checklist",
  },
  {
    label: "Others",
    value: "Others",
  },
]