import moment from "moment";

export const riskDetailsTableConfig = [
  {
    id: "reductionMeetingDate",
    key: "reductionMeetingDate",
    label: "Date of Risk Reduction Meeting (^)",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
    render: (data) => {
      return data.reductionMeetingDate ? moment(data.reductionMeetingDate).format('YYYY-MM-DD') : "-";
    },
    type: "Date",
    isRequired: true,
  },
  {
    id: "action",
    key: "action",
    label: "Action to be taken to avoid / reduce the risk(s)",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
    isRequired: true,
    rows: 3
  },
  {
    id: "referenceNumber",
    key: "referenceNumber",
    label: "Letter Reference",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
  },
  {
    id: "followUpParty",
    key: "followUpParty",
    label: "Party to follow up",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
    isRequired: true,
  },
  {
    id: "targetDueDate",
    key: "targetDueDate",
    label: "Target Due Date / Deadline",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
    render: (data) => {
      return data.targetDueDate ? moment(data.targetDueDate).format('YYYY-MM-DD') : '-';
    },
    type: "Date",
  },
  {
    id: "status",
    key: "status",
    label: "Status (Open / Closed)",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
    type: "Select",
    options: [
      {
        label: 'Open',
        value: "Open"
      },
      {
        label: 'Closed',
        value: "Closed"
      },
    ],
    isRequired: true,
  },
  {
    id: "closureDate",
    key: "closureDate",
    label: "Date of Closure of EW",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
    render: (data) => {
      return data.closureDate ? moment(data.closureDate).format('YYYY-MM-DD') : "-";
    },
    type: "Date",
  },
  {
    id: "remark",
    key: "remark",
    label: "Remark",
    name: (props) => {
      return `${props.prename}.${props.name}`
    },
  },
  {
    id: "updatedAt",
    key: "updatedAt",
    label: "Last Updated Date",
    render: (data) => {
      return data.updatedAt ? moment(data.updatedAt).format('YYYY-MM-DD') : "-";
    },
    type: "placeholder",
  },
  {
    id: "lastUpdateUser",
    key: "lastUpdateUser",
    label: "Last Updated User",
    type: "placeholder",
  },
];

export const headerConfig = [
  {
    label: "Risk ID Ref.",
    name: "riskIdReference",
    isRequired: true,
  },
  {
    label: "Date of Issue / Receipt of EW",
    name: "issueDate",
    type: "Date",
    isRequired: true,
  },
  {
    label: "Issued by (DSD / AECOM / Contractor) (Ref No.)",
    name: "issuedBy",
  },
  {
    label: "Incident No.",
    name: "incidentNumber",
  },
  {
    label: "Impact",
    name: "impact",
  },
  {
    label: "Affected Work Section",
    name: "affectedWorkSection",
  },
  {
    label: "Affected Key Date",
    name: "affectedKeyDate",
  },
  {
    label: "Status",
    name: "status",
    type: 'Select',
    options: [
      {
        label: 'Open',
        value: "Open"
      },
      {
        label: 'Closed',
        value: "Closed"
      },
    ],
    isRequired: true,
  },
]

export const headerConfigSection2 = [
  {
    label: "Description of Risk",
    name: "description",
    isRequired: true,
  },
  {
    label: "Potential Problem(s) and the Risk(s)",
    name: "potentialRisks",
    isRequired: true,
  },
];