import { useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { setLoading } from 'redux/slice/loadingSlice';
import { setNotification } from 'redux/slice/notificationSlice';
import { safetyPerformanceHelper } from 'helpers';
import SectionTitle from 'Common/SectionTitle';

import { useStyles } from "../style";
import ContractDetails from './ContractDetails';
import SafetyReport from './SafetyReport';
import SafetyReportAction from './SafetyReportAction';

export default function SafetyReportFormContainer({ data, safetyData, safetyMeta, reload, contractData, hasActionPanel }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { contractId } = useParams();;
  const classes = useStyles(theme);
  const history = useHistory();

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      report: data.questions,
      verifiedAgree: data.verifiedAgree,
      counterVerifiedAgree: data.counterVerifiedAgree,
    }
  });

  const { fields } = useFieldArray({
    control,
    name: "report",
  });

  async function onSubmit(formData, action, meta) {
    try {
      dispatch(setLoading(true));
      const requestVariables = constructRequestBody(data.questions, formData, meta);
      if (!action || action === "endorse") {
        const result = await safetyPerformanceHelper.updateQuestions({ id: safetyData.id, action: "save", variables: requestVariables });
        if (result.error) throw new Error(result.error);
      }
      // console.log(formData, requestVariables)
      if (action) {
        const result = await safetyPerformanceHelper.updateQuestions({ id: safetyData.id, action: action, variables: requestVariables });
        if (result.error) throw new Error(result.error);
      }
      dispatch(setNotification({ type: "success", message: "Success" }))

      dispatch(setLoading(false));
      reload();
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      dispatch(setLoading(false));
      console.log(e)
    }
  }

  function constructRequestBody(defaultData, formData, meta) {
    let answerArr = [];
    for (let i = 0; i < defaultData.length; i++) {
      const cAgreeRow = Number(defaultData[i].agreeRow) - 1;
      answerArr.push(formData.report[i]
        ? {
          ...defaultData[i], result: defaultData[i].answerType === "boolean" ? !!Number(formData.report[i].result) : formData.report[i].result,
          verifiedAgree: safetyData?.status === "Endorsed" ? !!Number(formData.verifiedAgree[cAgreeRow]) : defaultData[i].verifiedAgree,
          counterVerifiedAgree: safetyData?.status === "Supervisor verified" ? !!Number(formData.counterVerifiedAgree[cAgreeRow]) : defaultData[i].counterVerifiedAgree,
        }
        : { ...defaultData[i] }
      );
    }

    const requestVariables = { answers: answerArr, meta };

    return requestVariables;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  async function onDelete() {
    try {
      const result = await safetyPerformanceHelper.deleteSafetyPerformance(safetyData.id);
      if (result.error) throw new Error(result.error);
      dispatch(setNotification({ type: "success", message: "Success" }));
      history.replace(`/contract/${contractId}/safety-performance`);
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <SectionTitle title={`Contractor's Monthly Report on Safety Performance`} />
        <form>
          <ContractDetails data={contractData} reportPeriodFrom={safetyData?.reportPeriodFrom ?? "-"} />
          <SafetyReport
            data={data.questions}
            register={register} control={control} errors={errors} fields={fields}
            reportStatus={safetyData?.status ?? null}
            safetyMeta={safetyMeta} 
            permission={hasActionPanel?.rightPanel}
            />
        </form>
      </Grid>
      <Grid item xs={3} className={classes.assignMenuContainer}>
        {hasActionPanel?.rightPanel &&
          <SafetyReportAction
            data={data}
            reportStatus={safetyData?.status ?? null}
            onSave={(status, meta) => handleSubmit((d) => onSubmit(d, status, meta), onError)}
            onDelete={onDelete}
            hasActionPanel={hasActionPanel}
          />
        }
      </Grid>
    </Grid>

  );
}