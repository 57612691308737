import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import chartHelper from 'utils/chartHelper';
import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Pie } from 'Common/Chart';
// import { useStyles } from "./style";

export default function DistributionGraph({ startDate, endDate }) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { contractId } = useParams();
	const [data, setData] = useState([])

	const isMiddleView = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		getDashboardData();
	}, [startDate, endDate]);

	async function getDashboardData() {
		try {
			const result = await dashboardHelper.getLateSubmissionDistribution({
				variable: {
					contractId,
					startDate: startDate.clone().format('YYYY-MM-DD'),
					endDate: endDate.clone().format('YYYY-MM-DD'),
				}
			})
			let coloredData = chartHelper.setWorkCatColorByKey(result, "id");
			if (chartHelper.checkIsNotEmpty(coloredData)) setData(coloredData);
      else setData([]);
			dispatch(setLoading(false));
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<ChartContainer height={"600px"} elevation={0}>
			<ChartHeader
				title={'Distribution of Not On-Time Submission'}
				filterType={"none"}
			/>
			<div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
				{data.length === 0
					? <div>No/Insufficient data for the time period</div>
					: <Pie
						colors={({ id, data }) => data.color}
						data={data}
						innerRadius={0.6}
						enableArcLabels={true}
						enableArcLinkLabels={false}
						enablePercentageLabel={true}
						margin={{ top: 10, right: 50, bottom: 150, left: 50 }}
						legends={[
							{
								anchor: 'bottom',
								direction: 'column',
								justify: false,
								translateX: 30,
								translateY: 120,
								itemsSpacing: 0,
								itemWidth: 200,
								itemHeight: 18,
								itemTextColor: '#fff',
								itemDirection: 'left-to-right',
								itemOpacity: 0.9,
								symbolSize: 18,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1
										}
									}
								]
							}
						]}
					/>
				}
			</div>
		</ChartContainer>
	);
}