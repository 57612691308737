import PropTypes from "prop-types";

import RISCTable from "RISC/RISCForm";
import SiteDiaryTable from "SiteDiary/SiteDiaryForm";
import PhotoGallery from "Photos/PhotoGallery";
import SafetyTable from "SafetyInspection/SafetyForm";
import CleansingTable from "CleansingInspection/CleansingForm";
import LabourTable from "Labour/LabourForm";
import EDMSDocumentList from "EDMS/EDMSDocumentList";
import RiskRegisterList from "RiskRegister/riskRegisterList";

function SelectionArea({ selectedData, handleSelect, count, selectedType, externalContractId, contractNo }) {
  function showCount(type) {
    switch (type) {
      case "risc":
      case "site-diary":
      case "photo":
      case "safety":
      case "cleansing":
      case "labour":
        return true;
      default:
        return false;
    }
  }
  function getTypeTable(type) {
    switch (type) {
      case "risc":
        return <RISCTable
          selectedData={selectedData}
          handleSelect={handleSelect}
          externalContractId={externalContractId}
        />
      case "site-diary":
        return <SiteDiaryTable
          selectedData={selectedData}
          handleSelect={handleSelect}
          externalContractId={externalContractId}
        />
      case "photo":
        return <PhotoGallery
          selectedData={selectedData}
          handleSelect={handleSelect}
          externalContractId={externalContractId}
          contractNo={contractNo}
        />
      case "safety":
        return <SafetyTable
          selectedData={selectedData}
          handleSelect={handleSelect}
          externalContractId={externalContractId}
          contractNo={contractNo}
        />
      case "cleansing":
        return <CleansingTable
          selectedData={selectedData}
          handleSelect={handleSelect}
          externalContractId={externalContractId}
          contractNo={contractNo}
        />
      case "labour":
        return <LabourTable
          selectedData={selectedData}
          handleSelect={handleSelect}
          externalContractId={externalContractId}
        />
      case "risk-register":
        return <RiskRegisterList
          selectedData={selectedData}
          handleSelect={handleSelect}
          externalContractId={externalContractId}
          contractNo={contractNo}
        />
      default:
        return <EDMSDocumentList
          type={type}
          selectedData={selectedData}
          handleSelect={handleSelect}
        />
    }
  }
  return (
    <div>
      {showCount(selectedType) && <div>{count ?? 0} results found</div>}
      {getTypeTable(selectedType)}
    </div>
  );
}

SelectionArea.propTypes = {
  notSelectedType: PropTypes.bool,
  data: PropTypes.array,
  selectedData: PropTypes.array,
  handleSelect: PropTypes.func,
};

export default SelectionArea;
