import moment from 'moment';

const dateHelper = {
  calculateBarGraphData(data) {
    let newData = data;
    let oneWeekBefore = moment().subtract(6, 'day');

    for (let i = 0; i < data.length; i++) {
      newData[i].date = oneWeekBefore.clone().add(i, 'day').format("YYYY-MM-DD")
    }
    return newData;
  },
   getQuarterRange(quarter) {

    const start = moment().quarter(quarter).startOf('quarter');
  
    const end = moment().quarter(quarter).endOf('quarter');
  
    return {start, end};
  }

}

export default dateHelper;