import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from "static/icons/close.svg";

import docTypeHelper from 'helpers/admin/docTypeHelper';
import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';

import { useStyles } from "../style";


export default function DocumentTypeSetupForm({ type, handleClose, reload, data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: data ?? {}
  });

  async function onSubmit(formData) {
    try {
      if (type === "add") await docTypeHelper.createdocType({ contractId, ...formData });
      else await docTypeHelper.editdocType({ ...formData, id: data.id, contractId });
      handleClose();
      reload();
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${type === "add" ? "Add" : `Edit`} Document Type`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Document Type", name: "name", isRequired: true, default: "" },
              1, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Code", name: "code", isRequired: true, default: "" },
              2, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Description", name: "description", isRequired: false, default: "" },
              3, register, control, errors, { size: 12, mdSize: 12 })
            }

            {/* {fieldHelper.renderField(
              { label: "Show Revision on Detail Page", name: "showRevision", isRequired: false, type: "Checkbox" },
              4, register, control, errors, { size: 6, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Show Latest Revision Version", name: "showLatestRevision", isRequired: false, type: "Checkbox" },
              5, register, control, errors, { size: 6, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Is Drawing Type", name: "isDrawing", isRequired: false, type: "Checkbox" },
              6, register, control, errors, { size: 6, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Update existing documents' DocType", name: "isUpdateExisting", isRequired: false, type: "Checkbox" },
              7, register, control, errors, { size: 6, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Default Upload to Blockchain", name: "uploadToBlockchain", isRequired: false, type: "Checkbox" },
              8, register, control, errors, { size: 6, mdSize: 4 })
            } */}

            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton backgroundColor="#70BAD3" type="submit">{type === "add" ? "Submit" : "Save"}</RoundedButton>
          </Grid>
        </form>
      </DialogContent>
    </>
  );
}