import { useLocation } from 'react-router';
import { useEffect } from 'react';

import CommonContainer from 'EDMS/CommonContainer';
import CommonDocumentList from 'EDMS/CommonDocumentList';
import { edmsFileHelper } from 'helpers';
import SectionTitle from 'Common/SectionTitle';

export default function EDMSSearchResult() {
  const location = useLocation();

  useEffect(() => {
    async function init() {
      try {
      } catch (e) {
        console.log(e);
      }
    }
    init();
  }, [])

  return (
    <CommonContainer>
      <SectionTitle title="EDMS Search" />
      <CommonDocumentList
        getDataApi={edmsFileHelper.getFileMetas}
        responseKey={"meta_data"}
        extraFilter={location.state}
        useFilter
      />
    </CommonContainer>
  );
}