
import { useState, useEffect } from "react";
import queryString from 'query-string';
import { useLocation, useHistory } from "react-router";
import { TextField, Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { setNotification } from "redux/slice/notificationSlice";
import LoginContainer from "Login/LoginContainer";
import authHelper from "helpers/authHelper";
import { useStyles } from "../style";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation()
  const [isSuccess, setIsSuccess] = useState(false);
  const [token, setToken] = useState('');
  useEffect(() => {
    const values = queryString.parse(search);
    if (!values.token) {
      history.push('/login')
    }
    setToken(values.token);
  }, [])

  const [message, setMessage] = useState('');
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };


  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      if (form.password !== form.confirmPassword) {
        setMessage('Passwords not match');
        return
      }
      let lengthRegex = new RegExp("^.{8,}$");
      if (!lengthRegex.test(form.password)) {
        setMessage('Please choose a password with at least 8 characters.');
        return
      }
      let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(.{8,})$");
      if (!strongRegex.test(form.password)) {
        setMessage('Password must contain at least one uppercase character, one lowercase character and one digit');
        return
      }
      const result = await authHelper.resetPassword({ password: form.password, token });
      if (result.error) throw new Error(result.error);
      setMessage("");
      dispatch(setNotification({ type: 'success', message: 'Password reset success! Please login again' }))
      history.push("/login")
      setIsSuccess(true);
    } catch (e) {
      if (!!e.message) setMessage(e.message);
      else setMessage('Something went wrong');
      console.log(e)
    }
  };

  return (
    <LoginContainer>
      <div className={classes.root}>
        <div className={classes.title}>Reset Password</div>
        <form className={classes.form} onSubmit={resetPassword}>
          <Grid container className={classes.row}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.loginFieldLabel,
                  focused: classes.loginFieldLabel,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.loginFieldLabel,
                  focused: classes.loginFieldLabel,
                  filled: classes.loginFieldLabel,
                },
              }}
              className={classes.loginField}
              variant="filled"
              required
              fullWidth
              id="password"
              onChange={handleChange}
              label="Password"
              type="password"
              name="password"
            />
          </Grid>
          <Grid container className={classes.row}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.loginFieldLabel,
                  focused: classes.loginFieldLabel,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.loginFieldLabel,
                  focused: classes.loginFieldLabel,
                  filled: classes.loginFieldLabel,
                },
              }}
              className={classes.loginField}
              variant="filled"
              required
              fullWidth
              id="confirm-password"
              onChange={handleChange}
              label="Confirm Password"
              name="confirmPassword"
              type="password"
            />
          </Grid>

          {isSuccess &&
            <Grid className={`${classes.row}`}>
              Success! Please go back to <a href="/login" style={{ fontWeight: 600, textDecoration: "underline" }}>Login Page</a> to login.
            </Grid>
          }

          {message &&
            <Grid container className={`${classes.row} ${classes.error}`}>
              {message}
            </Grid>
          }

          <Button
            fullWidth
            variant="contained"
            type="submit"
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </div>
    </LoginContainer>
  );
}
