import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message'
import { useHistory, useParams, useLocation } from "react-router";
import { Radio, RadioGroup } from "@material-ui/core";

import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";

import Modal from "Common/Modal";
import ConfirmButton from "Common/ConfirmButton";
import { setNotification } from "redux/slice/notificationSlice";
import { incidentHelper } from "helpers";
import fieldHelper from "utils/fieldHelper";
import Select from "Common/Select";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import Loading from "Common/Loading";

import CloseIcon from "static/icons/close.svg";
import { siFieldConfig } from "./config";
import { useStyles } from "./style";
import AddAttachmentsForm from "./AddAttachmentsForm";


export default function SIAddForm({ mode, handleClose, reload, enableRedirection, data }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [pmiOptions, setPMIOptions] = useState([]);
  const [pendingAttachments, setPendingAttachments] = useState({});
  const [modal, setModal] = useState({ type: "", open: false, data: null });
  const { watch, register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: { ...data, incidentId: data?.incidentId ?? "", assign: data?.incidentId ? "1" : "0", contractCovered: data?.contractCovered ? "1" : "0" } ?? {}
  })
  const sp = new URLSearchParams(location.search);
  const isAssign = watch('assign');

  useEffect(() => {
    async function getPMIOptions() {
      try {
        const result = await incidentHelper.getIncidents({ contractId });
        const options = result.incident.map((item, idx) => {
          return ({
            label: item.incidentNumber,
            value: item.id,
          })
        })
        setPMIOptions(options);
      } catch (error) {
        console.log(error)
      }
    }
    getPMIOptions();
  }, [contractId]);

  async function onSubmit(data) {
    try {
      setLoading(true);
      let docId = data.id ?? null;
      let result;
      const requestVariables = { contractId, ...data, incidentId: data.assign === "1" ? data.incidentId : null, contractCovered: Boolean(Number(data.contractCovered)), };
      if (mode === "Add") {
        result = await incidentHelper.createSiteInstruction(requestVariables);
        if (result.error) throw new Error(result.error);
        docId = result.site_instruction.id;
        dispatch(setNotification({ type: "success", message: "New Site InStruction Created" }))
      } else {
        result = await incidentHelper.editSiteInstruction(data.id, requestVariables);
        dispatch(setNotification({ type: "success", message: "Site Instruction Updated" }))
      }

      if (pendingAttachments.files && pendingAttachments?.files?.length > 0) {
        console.log("Upload attachments");
        const result = await Promise.all([pendingAttachments.attachments.map(
          (item, idx) => incidentHelper.uploadSIDocument(docId, { file: pendingAttachments.files[idx], description: item.description, documentType: item.documentType }))
        ]);
      }

      if (reload) {
        reload();
      } else {
        if (enableRedirection) history.push(`/contract/${contractId}/main-contractor/document/${result.site_instruction.id}`);
        else {
          const updated = sp.has('updated') ? sp.get('updated') : 1;
          history.push({ pathname: location.pathname, search: `tab=main-contractor&updated=${Number(updated) + 1}` });
        }
      }

      handleClose();
      setLoading(false);
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  async function handleDelete() {
    try {
      const result = await incidentHelper.deleteSI(data.id);
      if (result.error) throw new Error(result.error);
      const updated = sp.has('updated') ? sp.get('updated') : 1;
      history.push({ pathname: location.pathname, search: `tab=main-contractor&updated=${Number(updated) + 1}` });
      dispatch(setNotification({ type: "success", message: "Successfully delete SI" }));
      handleClose();
    } catch (error) {
      console.log(error);
      if (error.message) dispatch(setNotification({ type: "error", message: error.message }))
    }
  }

  function handleModalClose() {
    setModal({ type: modal.type, open: false, data: null });
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${mode} Main Contractor Document`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"close"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>
          <Grid container spacing={3} className={classes.section}>
            {siFieldConfig.map((inputField, idx) => {
              if (inputField.name === "incidentId") return (
                <Grid item sm={12} key={idx}>
                  <div className={classes.label}>{"Incident No"}</div>
                  <Controller
                    name={"assign"}
                    control={control}
                    defaultValue={"0"}
                    render={({ field }) => <RadioGroup
                      row
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                    >
                      <div><Radio value={"0"} /><span>Not assigned</span></div>
                      <div><Radio value={"1"} /><span>Assigned as:</span></div>
                      {isAssign === "1" && <div style={{ marginLeft: 8 }}>
                        <Controller
                          key={12}
                          rules={{ required: `Value is Required` }}
                          name={"incidentId"}
                          control={control}
                          defaultValue={""}
                          render={({ field }) => <Select
                            label={""}
                            options={pmiOptions}
                            field={field}
                          />}
                        >
                        </Controller>
                        <ErrorMessage
                          errors={errors}
                          name={"incidentId"}
                          render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
                        />
                      </div>
                      }
                    </RadioGroup>
                    }>
                  </Controller>
                  <ErrorMessage
                    errors={errors}
                    name={"assign"}
                    render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
                  />
                </Grid>
              );
              else return (
                fieldHelper.renderField(inputField, idx, register, control, errors, { size: inputField.size })
              );
            }

            )}
          </Grid>
          <Divider className={classes.divider} />

          <div style={{ textAlign: "end" }}>
            <Button children={"Add Attachments"} backgroundColor="#70BAD3" type="button" onClick={() => setModal({ type: "Add", open: true, data: pendingAttachments })} />
          </div>

        </DialogContent>
        <Loading isLoading={isLoading} />


        <DialogActions>
          {(!data?.status && mode === "Edit") && <ConfirmButton children={"Delete"} type="button" message="Confirm to Delete?" onClick={handleDelete} />}
          {((!data?.status && mode === "Edit") || mode === "Add") && <Button children={mode === "Add" ? "Add" : "Save"} backgroundColor="#70BAD3" type="submit" />}
        </DialogActions>
      </form>
      <Modal open={modal.open} handleClose={handleModalClose}>
        <AddAttachmentsForm data={pendingAttachments} setAttachments={setPendingAttachments} handleClose={handleModalClose} />
      </Modal>
    </>
  );
}