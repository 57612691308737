import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  noIcon: {
    display: "none"
  },
  headerText: {
    color: theme.palette.primaryGrey,
    maxWidth: "200px",
    minWidth: "150px"
  },
  rowHeaderText: {
    color: theme.palette.primaryGrey,
  },
  label: {
    color: theme.palette.primaryGrey,
    marginBottom: "8px",
    ...theme.typography.h6,
  }
}));