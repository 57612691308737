import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AddIcon from '@material-ui/icons/Add';

import { setLoading } from "redux/slice/loadingSlice";
import { authHelper, documentHelper, relatedDocHelper, edmsFileHelper, riskHelper, permissionHelper } from "helpers";
import permissionUtils from "utils/permissionUtils";
import DocumentTable from "../DocumentTable";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/EllipseButton";
import Modal from "Common/Modal";
import DocumentAddRelatedDocumentV2 from "Document/DocumentAddRelatedDocumentV2";

import { keyArray } from "./config";
import { useStyles } from "./style";
import typeHelper from "utils/typeHelper";

function DocumentRelatedDocumentV2({
  componentType, searchFilter, afterAction, incidentRelatedDoc
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { docId, contractId, type } = useParams();
  const [rawData, setRawData] = useState({});
  const [relatedData, setRelatedData] = useState([]);
  const [relatedPhoto, setRelatedPhoto] = useState([]);
  const [relatedFile, setRelatedFile] = useState([]);
  const [relatedRisk, setRelatedRisk] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [accessPermission, setAccessPermission] = useState({});

  useEffect(() => {
    async function getUserPermission() {
      if (contractId) {
        if (type === "incident") {
          const groupPermission = await authHelper.checkGroupPermission();
          if (!groupPermission.aecom) return;
          const result = await permissionHelper.checkUserAccessPermission(contractId);
          setAccessPermission(result.user_permissions);
        } else {
          const result = await permissionHelper.checkUserAccessPermission(contractId);
          setAccessPermission(result.user_permissions);
        }
      }
    }
    init();
    getUserPermission();
  }, [])

  const handleOpenAddDoc = () => {
    setModalOpen(true);
  }

  const handleCloseAddDoc = () => {
    setModalOpen(false);
  }

  useEffect(() => {
    dispatch(setLoading(true));
    returnRelatedDocumentData();
  }, [rawData])

  async function init() {
    const typeName = typeHelper.convertHyphenToCamelType(componentType);
    const result = await relatedDocHelper.getRelatedDocuments({ docType: typeName, docId });
    setRawData(result.related_document.relatedDocuments);
  }

  function reload() {
    init();
    afterAction();
  }

  const returnRelatedDocumentData = async () => {
    try {
      let tempData = [];
      let finalData = [];

      
      console.log(rawData)
      keyArray.map((keyItem, idx) => {
        const targetKey = rawData?.[keyItem.key];
        if (!targetKey) return;
        targetKey.forEach(item =>
          tempData.push(documentHelper.getDocument({ type: keyItem.type, id: item }))
        );
      })
      if (rawData.fileMetaId)
        rawData.fileMetaId.forEach(item =>
          tempData.push(incidentRelatedDoc ? edmsFileHelper.getRelatedDocFileMeta(item) : edmsFileHelper.getFileMeta(item))
        );
      if (rawData.riskRegisterId)
        rawData.riskRegisterId.forEach(item =>
          tempData.push(riskHelper.getRiskRegister(item))
        );
      if (tempData.length > 0) {
        const result = await Promise.all(tempData);
        const riscs = result.filter(item => item.risc).map(item => item.risc);
        const siteDiarys = result.filter(item => item.site_diary).map(item => item.site_diary);
        const safeties = result.filter(item => item.safety).map(item => item.safety);
        const cleansings = result.filter(item => item.cleansing).map(item => item.cleansing);
        const labours = result.filter(item => item.labour).map(item => item.labour);
        const photos = result.filter(item => item.photo).map(item => item.photo);
        const fileMetas = result.filter(item => item.fileId);
        const risks = result.filter(item => item.risk_register).map(item => item.risk_register);
        finalData = finalData.concat(riscs, siteDiarys, safeties, cleansings, labours);

        setRelatedData(finalData);
        setRelatedPhoto(photos);
        setRelatedFile(fileMetas);
        setRelatedRisk(risks);
      }

      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  };

  return (
    <div className={classes.root}>
      <SectionTitle
        title={"Related Documents"}
        extraComponents={
          permissionUtils.checkPermission(accessPermission, type, "edit") && <Button
            label="Add Related Document"
            startIcon={<AddIcon />}
            onClick={handleOpenAddDoc}
          />
        }
      />

      {(relatedData.length === 0 && relatedPhoto.length === 0 && relatedRisk.length === 0 && relatedFile.length === 0)
        && <div className={classes.noResult}>No result found</div>
      }
      {relatedData.length > 0 &&
        <div style={{ marginTop: "40px" }}>
          <SectionTitle title={"Documents"} />
          <DocumentTable data={relatedData} tableType={"relatedDoc"} noMargin />
        </div>}
      {relatedPhoto.length > 0 &&
        <div style={{ marginTop: "40px" }}>
          <SectionTitle title={"Photos"} />
          <DocumentTable data={relatedPhoto} tableType={"photo"}
          />
        </div>
      }
      {relatedFile.length > 0 &&
        <div style={{ marginTop: "40px" }}>
          <SectionTitle title={"EDMS Files"} />
          <DocumentTable
            data={relatedFile}
            tableType={"edms"}
          />
        </div>
      }
      {relatedRisk.length > 0 &&
        <div style={{ marginTop: "40px" }}>
          <SectionTitle title={"Risk Registers"} />
          <DocumentTable
            hidePdfBtn
            data={relatedRisk}
            tableType={"risk-register"}
          />
        </div>
      }
      <Modal open={modalOpen} handleClose={handleCloseAddDoc} maxWidth="xl">
        <DocumentAddRelatedDocumentV2
          handleClose={handleCloseAddDoc}
          reload={afterAction ? reload : init}
          currentDocType={componentType}
          searchFilter={searchFilter}
        />
      </Modal>
    </div>
  );
}

DocumentRelatedDocumentV2.propTypes = {
  data: PropTypes.object,
  reload: PropTypes.func,
};

export default DocumentRelatedDocumentV2;