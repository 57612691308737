import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import AddIcon from '@material-ui/icons/Add';

import { permissionHelper } from "helpers";
import permissionUtils from "utils/permissionUtils";
import DocumentTable from "../DocumentTable";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/EllipseButton";
import Modal from "Common/Modal";

import DocumentAddAttachment from "../DocumentAddAttachment";
import { useStyles } from "./style";

function DocumentAttachment({
  data,
  reload,
  moduleType,
  hideAdd,
}) {
  const { type, contractId } = useParams();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [accessPermission, setAccessPermission] = useState({});
  const handleOpenAddDoc = () => {
    setModalOpen(true);
  }

  const handleCloseAddDoc = () => {
    setModalOpen(false);
  }

  useEffect(() => {
    async function getUserPermission() {
      if (contractId) {
        const result = await permissionHelper.checkUserAccessPermission(contractId);
        setAccessPermission(result.user_permissions);
      }
    }
    getUserPermission();
  }, [])

  return (
    <div className={classes.root}>
      <SectionTitle
        title={"Attachments"}
        extraComponents={(permissionUtils.checkPermission(accessPermission, type, "edit") && !hideAdd) &&
          <Button
            label="Upload new attachment"
            startIcon={<AddIcon />}
            onClick={handleOpenAddDoc}
          />
        }
      />

      {data.length === 0 && <div className={classes.noResult}>No result found</div>}
      {data.length > 0 && <DocumentTable data={data} tableType={type === "photo" ? "photoAttachment" : "attachment"} />}
      <Modal open={modalOpen} handleClose={handleCloseAddDoc}>
        <DocumentAddAttachment handleClose={handleCloseAddDoc} reload={reload} moduleType={moduleType} />
      </Modal>
    </div>
  );
}

DocumentAttachment.propTypes = {
  data: PropTypes.array,
  reload: PropTypes.func,
};

export default DocumentAttachment;