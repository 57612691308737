import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useRef } from 'react';

import { selectUserPermission } from 'redux/slice/permissionSlice';
import { selectContractData } from 'redux/slice/contractSlice';
import { riskHelper } from 'helpers';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import DocumentHeader from 'Document/DocumentHeader';

import RiskMeetingHeader from './riskMeetingHeader';
import RiskRegisterHeader from 'RiskRegister/RiskRegisterDetailsView/riskRegisterHeader';
import RiskRegisterDetails from 'RiskRegister/RiskRegisterDetailsView/riskRegisterDetails';
import AddRiskDiscussedTab from './addRiskDiscussedTab';
import { useStyles } from "./style";
import permissionUtils from 'utils/permissionUtils';


export default function RiskMeetingDetailsView() {
  const contractData = useSelector(selectContractData);
  const { docId } = useParams();
  const location = useLocation();
  const { pathname } = useLocation();
  const history = useHistory();
  const sp = new URLSearchParams(location.search);
  const searchParam = sp.get("tab");
  const isMounted = useRef(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState({});
  const permission = useSelector(selectUserPermission);
  const haveEditRight = permissionUtils.checkPermission(permission, "risk", "edit");

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    try {
      if (isMounted) initData();
    } catch (e) {
      console.log(e)
    }
  }, [docId])

  async function initData() {
    try {
      const result = await Promise.all([
        riskHelper.getRiskMeeting(docId)
      ]);
      if (!result[0].risk_meeting) throw new Error("Data not found");
      setData(result[0].risk_meeting);
    } catch (e) {
      console.log(e);
    }
  }

  function redirectToAddRisk() {
    history.push({ pathname: pathname, search: "tab=add-risk" });
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "history-log":
        return <></>;
      case "attachments":
        return <></>;
      case "related-documents":
        return <></>;
      case "add-risk":
        return <AddRiskDiscussedTab data={data} haveEditRight={haveEditRight} />;
      default:
        return (
          <>
            <RiskMeetingHeader allowEdit={haveEditRight} contract={contractData} data={data} reload={initData} />
            <div className={classes.body}>
              <SectionTitle
                title={`Risk Reduction Meeting #${data.referenceNumber ?? 1}`}
                extraComponents={
                  <div className={classes.buttonsContainer}>
                    {haveEditRight && <EllipseButton onClick={redirectToAddRisk} label="+ Risk Discussed" />}
                  </div>
                }
              />
              {(data && data.riskRegisters) && data.riskRegisters.map((risk, idx) =>
                <div className={classes.riskContainer} key={idx}>
                  <RiskRegisterHeader contract={contractData} data={risk} reload={() => { }} />
                  <div className={classes.infoHeader} style={{ overflow: "auto" }}>
                    <RiskRegisterDetails allowEdit={haveEditRight} data={risk.riskDecisions} riskRegisterId={risk.id} reload={initData} />
                  </div>
                </div>
              )}
            </div>
          </>
        );

    }
  }

  return (
    <div className={classes.root}>
      {
        (!!searchParam && searchParam !== "basic-info")
        && <DocumentHeader data={data} type={"risk-meeting"} contractData={contractData} padding="0px 0px 20px 0" />
      }
      {returnDocumentContent()}
    </div>
  );
}
