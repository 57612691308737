import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { setNotification } from 'redux/slice/notificationSlice';
import RoundedButton from 'Common/RoundedButton';
import SectionTitle from 'Common/SectionTitle';
import fieldHelper from 'utils/fieldHelper';
import CommonContainer from 'EDMS/CommonContainer';
import { useStyles } from "../style";

export default function ProfileFieldsSetupForm({ type }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();
  const sp = new URLSearchParams(history.location.search);
  const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
    }
  });



  console.log(sp.get('incomingOutstanding'),)
  async function onSubmit(data) {
    try {
      const query = constrcutQuery(data);

      history.replace({ pathname: `${history.location.pathname}`, search: `?${query}` });
      history.push({ pathname: `${history.location.pathname}/search`, search: `?${query}` });
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      console.log(e)
    }
  }

  function constrcutQuery(data) {
    let query = "";
    Object.keys(data).forEach((key, idx) => {
      if (data[key]) {
        if (query !== "") query += "&";
        query += `${key}=${data[key]}`;
      }
    });
    return query;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <CommonContainer>
      <SectionTitle title={`Document Type Setup`} />
      <div className={classes.body}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Field Name", name: "fieldName", isRequired: false, },
              1, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item sm={8} />
            {fieldHelper.renderField(
              { label: "Field Type", name: "type", isRequired: false, type: "Select", options: [{ label: "Dropdown", value: "dropdown" }] },
              2, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Sub Type", name: "subtype", isRequired: false, type: "Select", options: [{ label: "Placeholder", value: "type1" }] },
              3, register, control, errors, { size: 12, mdSize: 4 })
            }
            <Grid item sm={12} className={classes.placeholder} />

            {fieldHelper.renderField(
              { label: "Incoming", name: "incoming", isRequired: false, type: "Checkbox" },
              4, register, control, errors, { size: 6, mdSize: 3 })
            }
            {fieldHelper.renderField(
              { label: "Outgoing", name: "outgoing", isRequired: false, type: "Checkbox" },
              5, register, control, errors, { size: 6, mdSize: 3 })
            }
            {fieldHelper.renderField(
              { label: "Internal", name: "internal", isRequired: false, type: "Checkbox" },
              6, register, control, errors, { size: 6, mdSize: 3 })
            }
            <Grid item sm={12} className={classes.placeholder} />
            {fieldHelper.renderField(
              { label: "Required", name: "required", isRequired: false, type: "Checkbox" },
              7, register, control, errors, { size: 12, mdSize: 12 })
            }
            {fieldHelper.renderField(
              { label: "Sequence", name: "sequence", isRequired: false, },
              8, register, control, errors, { size: 12, mdSize: 4 })
            }
            {fieldHelper.renderField(
              { label: "Need Revision", name: "needRevision", isRequired: false, type: "Checkbox" },
              9, register, control, errors, { size: 12, mdSize: 12 })
            }
            <Grid item sm={12} />
          </Grid>

          <Grid container item justify="flex-end">
            <RoundedButton backgroundColor="#70BAD3" type="submit">{type === "add" ? "Submit" : "Save"}</RoundedButton>
          </Grid>
        </form>
      </div>
    </CommonContainer >
  );
}