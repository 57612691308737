import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: "16px",
  },
  tableCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
  },
  headerCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
    fontWeight: 600,
  },
}));
