import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import { safetyPerformanceHelper } from 'helpers';
import DocumentHeader from 'Document/DocumentHeader';

import { useStyles } from "../style";
import SafetyReportFormContainer from './SafetyReportFormContainer';

export default function SafetyPerformanceDetails() {
  const contractData = useSelector(selectContractData);
  const { docId, contractId } = useParams();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState({});
  const [safetyData, setSafetyData] = useState({});
  const [safetyMeta, setSafetyMeta] = useState([]);
  const [hasActionPanel, setHasActionPanel] = useState({});

  useEffect(() => {
    try {
      initData();
    } catch (e) {
      console.log(e)
    }
  }, [docId])

  async function initData() {
    try {
      const result = await Promise.all([
        safetyPerformanceHelper.getSafetyPerformanceResults({
          safetyPerformanceId: docId,
          orderBy: "section.asc",
        }),
        safetyPerformanceHelper.getSafetyPerformances({
          contractId,
          id: docId,
        }),
        safetyPerformanceHelper.getSafetyPerformanceMetas({
          safetyPerformanceId: docId,
        }),
        safetyPerformanceHelper.getActionPanel(docId),
      ]);
      if (!result[0].safety_performance_question_result) throw new Error("Data not found");
      setData({
        questions: result[0]?.safety_performance_question_result,
        verifiedAgree: result[0]?.verifiedAgree,
        counterVerifiedAgree: result[0]?.counterVerifiedAgree
      });
      setSafetyData(result[1]?.safety_performance[0]);
      setSafetyMeta(result[2]?.safety_performance_meta ?? []);
      setHasActionPanel(result[3].result);
    } catch (e) {
      console.log(e);
    }
  }
  
  return (
    <div className={classes.root}>
      <DocumentHeader contract={contractData} data={safetyData} type="safety-performance" />
      <div className={classes.body}>
        {data.questions && <SafetyReportFormContainer data={data} safetyData={safetyData} safetyMeta={safetyMeta} reload={initData} contractData={contractData} hasActionPanel={hasActionPanel} />}
      </div>
    </div>
  );
}