export const typeOptions = [
  { value: "risc", label: "RISC Form", orderBy: "actualSurveyInspectionDatetime" },
  { value: "site-diary", label: "Site Diary", orderBy: "submissionDatetime" },
  { value: "safety", label: "Safety Inspection" },
  { value: "cleansing", label: "Cleansing Inspection" },
  { value: "labour", label: "Labour Return" },
  { value: "photo", label: "Photo", orderBy: "takenDatetime" },
  {
    label: "Audit",
    value: "audit",
  },
  {
    label: "CE",
    value: "ce",
  },
  {
    label: "CP",
    value: "cp",
  },
  {
    label: "CSF",
    value: "csf",
  },
  {
    label: "EWN",
    value: "ewn",
  },
  {
    label: "MSF",
    value: "msf",
  },
  {
    label: "PMI",
    value: "pmi",
  },
  {
    label: "Quotation",
    value: "quotation",
  },
  {
    label: "RFI",
    value: "rfi",
  },
  {
    label: "RR",
    value: "rr",
  },
  { value: "risk-register", label: "Risk Register", },
  {
    label: "Letter",
    value: "letter"
  },
];