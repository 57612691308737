import PropTypes from "prop-types";
import { useTheme } from '@material-ui/core/styles';
import Button from "Common/RoundedButton";
import AddIcon from '@material-ui/icons/Add';

function SelectionAction({
  stage,
  goToStage,
  handleConfirm
}) {
  const theme = useTheme();
  const returnLabel = () => {
    switch (stage) {
      case "select":
        return "Add";
      case "confirm":
        return "Confirm";
      default:
        return "";
    }
  };

  const returnBackgroundColor = () => {
    switch (stage) {
      case "select":
        return theme.palette.primaryBlueLight;
      case "confirm":
        return theme.palette.primaryBlueLight;
      default:
        return "";
    }
  };

  const returnOnClick = () => {
    switch (stage) {
      case "select":
        return goToStage.bind(this,"confirm");
      case "confirm":
        return handleConfirm;
      default:
        return () => {};
    }
  }

  return(
    <Button
      children={returnLabel()}
      startIcon={<AddIcon/>}
      backgroundColor={returnBackgroundColor()}
      onClick={returnOnClick()}
    />
  );
}

SelectionAction.propTypes = {
  stage: PropTypes.string,
  goToStage: PropTypes.func,
  handleConfirm: PropTypes.func
};

export default SelectionAction;