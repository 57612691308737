import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { useParams } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDispatch } from "react-redux";
import { useState } from "react";

import Loading from "Common/Loading";
import { setNotification } from "redux/slice/notificationSlice";
import FileIcon from 'static/icons/viewFile.svg';
import { documentHelper, edmsFileHelper, riskHelper } from "helpers";
import typeHelper from "utils/typeHelper";
import SectionTitle from "Common/SectionTitle";
import TextField from "Common/TextField";
import Button from "Common/RoundedButton";

import CloseIcon from "static/icons/close.svg";

import { useStyles } from "./style";

function DocumentAddAttachment({
  handleClose, reload, moduleType,
}) {
  const dispatch = useDispatch();
  const { type, docId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const { watch, register, handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      file: null,
      description: "",
      isAttachment: false,
    }
  });

  async function onSubmit(data) {
    try {
      setLoading(true);
      const docType = typeHelper.getCurrentType(type);
      let newVariable = data;
      newVariable.file = data.file[0];
      if (moduleType === "edms") {
        const result = await edmsFileHelper.uploadEDMSAttachment({ id: docId, variables: data });
        if (result.error) throw new Error("Fail to upload attachment");
      } else if (moduleType === "risk-register") {
        const result = await riskHelper.uploadRiskAttachment({ id: docId, variables: data });
        if (result.error) throw new Error("Fail to upload attachment");
      } else {
        const result = await documentHelper.uploadDocument({ type: docType, id: docId, variable: data });
        if (result.error) throw new Error("Fail to upload attachment");
      }

      if (!!reload) reload();
      handleClose();
      dispatch(setNotification({ type: "success", message: "New attachment added" }))
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const watchFile = watch('file')
  return (
    <>
      <DialogTitle>
        <SectionTitle
          title="Add Attachment"
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Button
                varient="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                backgroundColor="#70BAD3"
              >
                Upload a file
                <input
                  {...register('file', { required: "File is required." })}
                  type="file"
                  hidden
                />
              </Button>
              <ErrorMessage
                errors={errors}
                name="file"
                render={({ message }) => <p className={classes.error} style={{ marginLeft: "16px" }}>{message}</p>}
              />
              {(watchFile && watchFile[0]) &&
                <div className={classes.fileInfo}>
                  <img className={classes.docImg} src={FileIcon}></img>
                  {`Uploaded: ${watchFile[0].name}`}
                </div>
              }
            </Grid>

            <Grid item xs={6}>
              <Controller
                name={'description'}
                control={control}
                render={({ field }) =>
                  <TextField
                    label="Description"
                    field={field}
                  />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Loading isLoading={isLoading} />
        <DialogActions>
          <Button children="Add" backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}

DocumentAddAttachment.propTypes = {
  handleClose: PropTypes.func
};

export default DocumentAddAttachment;