import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    margin: "240px 158px 0 75px",
    flex: 1,
    [theme.breakpoints.down('md')]: {
      margin: "240px 75px 0 75px",
    },
  },
  title: {
    marginBottom: "70px",
    color: theme.palette.primaryHeader,
    ...theme.typography.h1,
  },
  submit: {
    background: "linear-gradient(90deg, #70BAD3 0%, #2FA2C8 100%)",
    height: "77px",
    ...theme.typography.h3,
    color: "white",
    boxShadow: "0px 0px 30px 0px #34A4C9",

  },
  loginField: {
    fontSize: "18px",
    background: "#F2FCFF",
    borderRadius: "5px",
    label: {
      fontSize: "18px",
      color: "#535353"
    }
  },
  loginFieldLabel: {
    fontSize: "18px",
    color: "#535353"
  },
  row: {
    marginBottom: "22px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  error: {
    color: "red"
  },
  link: {
    color: theme.palette.primaryBlueLight,
    cursor: "pointer",
  },
  btnBack: {
    position: "absolute",
    top: "-60px",
  }
}));
