import axios from './apiHelper';

const documentsHelper = {
  getDocuments: async ({ type, variable }) => {
    try {
      const result = await axios.get(`/${type}`, { params: variable });

      if (!result.data) {
        throw new Error(`Fail to get ${type}`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getContractDocuments: async ({ type, contractId, variable }) => {
    try {
      const result = await axios.get(`/contract/${contractId}/${type}`, { params: variable });

      if (!result.data) {
        throw new Error(`Fail to get ${type}`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getDocumentsCount: async ({ type }) => {
    try {
      const result = await axios.get(`/${type}/count`);

      if (!result.data) {
        throw new Error(`Fail to get ${type} count`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default documentsHelper;