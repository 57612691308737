import { createSlice } from "@reduxjs/toolkit";

const modalViewSlice = createSlice({
  name: "modalView",
  initialState: {},
  reducers: {
    setModalData: (state, action) => {
      const { view } = action.payload;
      return view;
    }
  }
});

export const { setView } = modalViewSlice.actions;

export default modalViewSlice.reducer;