import { useTheme } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import fallback from 'static/fallback/notAvailable.png'
import InfoItem from "Common/InfoItem";
import BoxItem from "Common/BoxItem";
import { useStyles } from "./style";

export default function PhotoInfoBox({ photo, details, onClick, isSelected }) {
  const [thumbnail, setThumbnail] = useState(null);
  const theme = useTheme();
  const props = { theme }
  const classes = useStyles(props);

  useEffect(() => {
    const base64 = btoa(
      new Uint8Array(photo).reduce(
        (dataArray, byte) => {
          return dataArray + String.fromCharCode(byte);
        },
        ''
      )
    );
    if (!base64) setThumbnail(fallback);
    else setThumbnail(`data:image/*;base64,${base64}`);
  }, [photo])

  return (
    <BoxItem padding="15px 20px" onHover={true} onClick={onClick} isSelected={isSelected}>
      <div className={classes.column}>
        <div className={classes.imgWrapper}>
          <img alt="thumbnail" src={!!thumbnail ? thumbnail : fallback} className={classes.img} />
        </div>
        <div style={{ marginTop: "16px", wordWrap: "break-word" }}>
          <InfoItem title={"File Name"} content={details?.photoDocument?.[0]?.filename ?? "-"} flexDirection={"row"} />
          <InfoItem title={"Description"} content={details?.photoDesc ?? "-"} flexDirection={"row"} />
          <InfoItem title={"Location"} content={details.location1 ?? "-"} flexDirection={"row"} />
          <InfoItem title={"Taken By"} content={details.takenBy ?? "-"} flexDirection={"row"} />
          <InfoItem title={"Taken at"} content={details.takenDatetime ? moment(details.takenDatetime).format('yyyy-MM-DD HH:mm') : "-"} flexDirection={"row"} />
          <InfoItem title={"Uploaded by"} content={details.uploadBy ?? "-"} flexDirection={"row"} />
          <InfoItem title={"Uploaded on"} content={details.uploadDatetime ? moment(details.uploadDatetime).format('yyyy-MM-DD') : "-"} flexDirection={"row"} />
        </div>
      </div>
    </BoxItem>
  );
};

PhotoInfoBox.propTypes = {
  photo: PropTypes.any,
  details: PropTypes.object,
}