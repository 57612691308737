export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    link: "dashboard"
  },
  {
    label: "Incident",
    key: 1,
    link: "incident"
  },
  {
    label: "Main Contractor Document",
    key: 2,
    link: "main-contractor"
  },
];

export const incidentFieldConfig = [
  {
    label: "Incident Number",
    name: "incidentNumber",
    isRequired: true,
  },
  {
    label: "Issue Date",
    name: "issueDate",
    type: "Date",
    isRequired: true,
  },
  {
    label: "Subject",
    name: "subject",
    size: 12,
    isRequired: true,
  },
  {
    label: "Location",
    name: "location",
  },
];

export const siFieldConfig = [
  {
    label: "NCE No. / Communication Nr / SI No.",
    name: "siNumber",
    isRequired: true,
  },
  {
    type: "placeholder",
  },
  {
    type: "placeholder",
  },
  {
    label: "Document Type",
    name: "type",
    isRequired: true,
    type: "Select",
    options: [
      {
        label: "NCE",
        value: "NCE"
      },
      {
        label: "Communication",
        value: "Communication",
      },
      {
        label: "SI",
        value: "SI"
      },
    ]
  },
  {
    type: "placeholder",
  },
  {
    type: "placeholder",
  },
  {
    label: "Contract Covered",
    name: "contractCovered",
    isRequired: true,
    isBoolean: true,
    type: "Radio",
    options: [
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      }
    ]
  },
  {
    label: "Incident No.",
    name: "incidentId",
  },
  {
    label: "Issue Date",
    name: "issueDate",
    type: "Date",
    isRequired: true,
  },
  {
    label: "Subject",
    name: "subject",
    size: 12,
    isRequired: true,
  },
  {
    label: "Location",
    name: "location",
    isRequired: true,
  },
  {
    label: "Commerce Date",
    name: "commerceDate",
    type: "Date",
  },
  {
    label: "Completion Date",
    name: "completionDate",
    type: "Date",
  },
  {
    label: "Reason for SI",
    name: "reasonForSi",
    rows: 3
  },
];