import { forwardRef } from "react";
import PropTypes from "prop-types";

import { Dialog, Slide, Container } from "@material-ui/core";

import { useStyles } from "./style";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Modal({
  open,
  handleClose,
  fullScreen = true,
  padding,
  children,
  maxWidth,
}) {
  const props = { padding }
  const classes = useStyles(props);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{
        paper: classes.paper
      }}
    >
      <Container maxWidth={maxWidth ?? "lg"} className={classes.container}>
        {children}
      </Container>
    </Dialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.string,
}

export default Modal;