import moment from "moment";

export const contractDetailsConfig = [
  {
    id: "projectClient",
    label: "Department",
  },
  {
    id: "projectTitle",
    label: "Contract No. and Title",
  },
  {
    id: "projectContractor",
    label: "Name of Contractor",
  },
  {
    id: "reportPeriodFrom",
    label: "Reporting Month (dd/mm/yyyy)",
    render: (reportPeriodFrom) => {
      if (!reportPeriodFrom) return "-";
      const start = moment(reportPeriodFrom).clone().utcOffset('+0800').format('DD/MM/YYYY');
      const end = moment(reportPeriodFrom).endOf('month').utcOffset('+0800').format('DD/MM/YYYY');
      return `${start} to ${end}`;
    }
  },
];

export const safetyReportConfig = [
  {
    label: "",
  },
  {
    label: "Performance Indicator",
  },
  {
    id: "1",
    label: "Performance achievements in this Reporting Month (to be reported by the Contractor)",
  },
  {
    id: "2",
    label: "Verified by the Supervisor",
  },
  {
    id: "3",
    label: "Counter-verified by the Supervisor",
  },
];

export const yesNoOptions = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0"
  }
];

export const agreeOptions = [
  {
    label: "Agree",
    value: "1",
  },
  {
    label: "Disagree",
    value: "0"
  }
];

export const awardOptions = [
  {
    label: "Gold",
    value: "Gold",
  },
  {
    label: "Silver",
    value: "Silver"
  },
  {
    label: "Bronze",
    value: "Bronze"
  },
  {
    label: "Merit",
    value: "Merit"
  },
  {
    label: "Not applicable",
    value: "Not applicable "
  },
];