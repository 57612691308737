import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.main,
    width: "150px",
    borderRadius: "10px",
    textTransform: "none",
    zIndex: 5,
    // boxShadow: "0px 0px 30px #34A4C9"
  }
}));
