import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';

import { dashboardHelper } from 'helpers';
import typeHelper from 'utils/typeHelper';
import ChartHeader from 'Common/ChartHeader';
import InfoBox from 'RISC/RISCInfoBox';
import TypeSummaryList from './typeSummaryList';
import TypeSummaryPieChart from './typeSummaryPieChart';
import { useStyles } from "./style";

export default function CleansingCompliance({ data }) {
  const { type, contractId } = useParams();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [countFileStatus, setCountFileStatus] = useState({
    created: 0,
    closed: 0,
  });
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));

  useEffect(() => {
    initDate()
  }, [])

  async function initDate() {
    try {
      const result = await Promise.all([
        dashboardHelper.countFileStatus({ type: typeHelper.getCurrentType(type), variable: { by: "Created", contractId } }),
        dashboardHelper.countFileStatus({ type: typeHelper.getCurrentType(type), variable: { by: "Closed", contractId } })
      ])
      if (result[0].error || result[1].error) throw new Error(result.error);
      setCountFileStatus({
        created: result[0].count,
        closed: result[1].count
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={4}>
          <InfoBox title={"Total Cleansing Inspection"} value={data.noOfCleansing} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InfoBox title={"Created Cleansing Inspection"} value={countFileStatus.created ?? "-"} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InfoBox title={"Closed Cleansing Inspection"} value={countFileStatus.closed ?? "-"} withDate={false} />
        </Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}>
          <ChartHeader
            title={''}
            filterType={"quarterRange"}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TypeSummaryPieChart startDate={startDate} endDate={endDate} />
        </Grid>
        <Grid item xs={12} md={8}>
          <TypeSummaryList startDate={startDate} endDate={endDate} />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
          <NCGraph />
        </Grid> */}
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};