import { useTheme } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Divider, Grid } from '@material-ui/core';

import RISCInfoBox from 'RISC/RISCInfoBox';
import SectionTitle from "Common/SectionTitle";
import { dashboardHelper } from 'helpers';

import { useStyles } from "./style";
import ChartHeader from 'Common/ChartHeader';
import PhotoTrend from './PhotoTrend';

export default function PhotoDashboard() {
  const theme = useTheme();
  const { contractId } = useParams();
  const classes = useStyles(theme);
  const [data, setData] = useState({
    total: 0,
  });
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));

  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];

  useEffect(() => {
    let isMounted = true;
    if (isMounted && searchParam !== "docs") initData();
    return () => { isMounted = false };
  }, [searchParam])

  async function initData() {
    try {
      const result = await Promise.all([
        dashboardHelper.getPhotoDashboard({ route: "total-photos", variable: { contractId } }),
      ])
      if (!result[0].total_photos) throw new Error("fail to fetch dashboard data")
      setData({
        total: result[0].total_photos,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={classes.root}>
      <SectionTitle title={"Dashboard"} />
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={4}>
          <RISCInfoBox title={"Total Photos"} withDate={false} value={data?.total} />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />

      <ChartHeader
        title={''}
        filterType={"quarterRange"}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <PhotoTrend startDate={startDate} endDate={endDate} />
        </Grid>
      </Grid>
    </div>
  );
};

PhotoDashboard.propTypes = {
  data: PropTypes.any,
}