import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';

import arrayHelper from "utils/arrayHelper";
import { pmWeatherSummaryConfig } from "./dashboardTableConfig";
import DashboardList from "Common/DashboardList/index";

import { useStyles } from "./style";

export default function PMWeatherList({ tableData }) {
  const theme = useTheme();
  const props = { theme }
  const classes = useStyles(props);
  const [data, setData] = useState(tableData)
  const [sort, setSort] = useState({
    orderBy: "days",
    sortDirection: "desc"
  })
  const [count, setCount] = useState(0);

  useEffect(() => {
    function initData() {
      let isNumber = false;
      if (sort.orderBy === "days") isNumber = true;
      const sorted = arrayHelper.sortByKey(tableData, sort.orderBy, sort.sortDirection, isNumber);
      setData(sorted);
    }

    function countData(input) {
      const count = input.reduce((total, obj) => Number(obj.days) + total, 0);
      setCount(count);
    }
    initData(tableData);
    countData(tableData);
  }, [tableData])



  useEffect(() => {
    try {
      getPageData(sort);
    } catch (e) {
      console.log(e);
    }
  }, [sort])

  async function getPageData(cSort) {
    try {
      const result = sortData(cSort)
      setData(result);
      return result;
    } catch (e) {
      console.log(e);
    }
  }

  function sortData(sort) {
    let result = [...data];
    let isNumber = false;
    if (sort.orderBy === "days") isNumber = true;
    result = arrayHelper.sortByKey(result, sort.orderBy, sort.sortDirection, isNumber)

    return result;
  }

  function handleRequestSort(event, property) {
    const isAsc = sort.orderBy === property && sort.sortDirection === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSort({
      orderBy: property,
      sortDirection: direction,
    });
  };

  return (
    <div className={classes.dashboardList}>
      <div className={classes.title} style={{ height: "33.6px" }}></div>
      <DashboardList
        data={data ?? []}
        handleSort={handleRequestSort}
        orderBy={sort.orderBy}
        sortDirection={sort.sortDirection}
        fieldsData={pmWeatherSummaryConfig}
        total={count}
      />
    </div>
  );
};

PMWeatherList.propTypes = {
  contract: PropTypes.object
};