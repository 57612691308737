import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectContractData } from 'redux/slice/contractSlice';

import SectionTitle from "Common/SectionTitle";
import CleansingCompliance from 'CleansingInspection/CleansingCompliance';
import { useStyles } from "./style";

export default function CleansingDashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const contractData = useSelector(selectContractData);
  return (
    <div className={classes.root}>
      <SectionTitle title={"Cleansing Inspection Dashboard"} />
      <CleansingCompliance data={contractData} />
    </div>
  );
};

CleansingDashboard.propTypes = {
  data: PropTypes.any,
}