import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from "react-router-dom";

import authHelper from 'helpers/authHelper';
import Menu from 'Common/Menu';
import { useStyles } from "./style";

export default function ProfileMenu({ name }) {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  const options = [
    {
      name: "Logout",
      onClick: function () {
        handleClose();
        authHelper.logout();
        history.push('/login');
      }
    }
  ]

  return (
    <div className={classes.root}>
      <div className={classes.message}>
        <span>Welcome back,<br />{name}</span>
      </div>
      <Avatar className={classes.avatar} onClick={handleClick}>
        {name[0]}
      </Avatar>
      <Menu anchorEl={anchorEl} options={options} handleClose={handleClose} />
    </div>
  );
}

ProfileMenu.propTypes = {
  name: PropTypes.string.isRequired,
};