export const searchFields = [
  {
    label: "Subject",
    name: "subject",
  },
];

export const inOutList = [
  {
    label: "All",
    value: "All"
  },
  {
    label: "Incoming",
    value: "IN"
  },
  {
    label: "Outgoing",
    value: "OUT"
  },
]