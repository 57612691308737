import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useState, useEffect } from 'react';

import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import CustTableCell from "Common/TableCell";

import CommonContainer from 'EDMS/CommonContainer';
import { tableConfig, dummyData } from './config';
import { useStyles } from "../style";

export default function ProfileFieldsSetup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId } = useParams();

  function renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {tableConfig.map((column, idx) =>
            <TableCell className={classes.headerCell} key={idx}>{column.label}</TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
  function renderTableBody() {
    return (
      <TableBody>
        {dummyData.map((row, idx) => {
          return (
            <TableRow key={idx}>
              <CustTableCell>{idx + 1}</CustTableCell>
              {tableConfig.filter(item => item.id !== "placeholder").map((col, colIdx) =>
                <CustTableCell key={colIdx}>{row[col.id]}</CustTableCell>
              )}
              <CustTableCell
                icon={<EditIcon />}
                onClick={() => history.push(`${history.location.pathname}/edit/${row.id}`)}
              />
              <CustTableCell
                icon={<DeleteIcon />}
                onClick={() => alert("WIP")}
              />
              <CustTableCell
                icon={<AssignmentIcon />}
                onClick={() => history.push(`${history.location.pathname}/assign/${row.id}`)}
              />
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <CommonContainer>
      <SectionTitle
        title={`Profile Fields Setup`}
        extraComponents={
          <div className={classes.buttonsContainer}>
            <EllipseButton onClick={() => history.push(`${history.location.pathname}/add`)} label="Add New" />
          </div>
        }
      />
      <div className={classes.body}>
        <Table>
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </div>
    </CommonContainer>
  );
}