import moment from "moment";
import { Checkbox } from "@material-ui/core";

const tableHelper = {
  getHistoryDocumentId(data, type) {
    let targetArr = [];
    if (type === "RISC") targetArr = data.riscDocument;
    else if (type === "Site Diary") targetArr = data.siteDiaryDocument;
    return targetArr.length > 0 ? targetArr[targetArr.length - 1].documentId : null;
  },
  renderFilterBy(filter, config, reset) {
    const filteredKeys = Object.keys(filter);
    let filterArr = []
    let filterNameArr = []
    for (let i = 0; i <= filteredKeys.length - 1; i++) {
      if (!!filter[filteredKeys[i]] && filter[filteredKeys[i]] !== "") {
        filterArr.push(filteredKeys[i])
      }
    }
    for (let i = 0; i <= filterArr.length - 1; i++) {
      let target = config.find(element => element.id === filterArr[i]);
      if (!!target.label) filterNameArr.push(target.label);
    }
    return filterArr.length > 0
      ? <>
        <span>Currently is filtered by {filterNameArr.join(', ')} column(s)</span>
        <span onClick={reset} style={{ textDecoration: "underline", marginLeft: "8px", cursor: "pointer" }}>Reset Filter</span>
      </>
      : null;
  },
  handleDateTimeFilter(arrValue) {
    const formattedStart = !!arrValue[0] ? arrValue[0].clone().format('yyyy-MM-DD') : "";
    const formattedEnd = !!arrValue[1] ? arrValue[1].clone().format('yyyy-MM-DD') : "";
    return `${formattedStart}.${formattedEnd}`;
  },
  renderLabel(field, item, reload) {
    const value = item[field.id];
    if (field.isBoolean) {
      return value ? "Yes" : "No";
    }
    if (field.type === "Date") {
      let fieldDate = moment(value);
      fieldDate = (fieldDate.isValid() && value) ? fieldDate.format("YYYY-MM-DD") : "-";
      return fieldDate;
    }
    if (field.type === "Month") {
      let fieldDate = moment(value);
      fieldDate = (fieldDate.isValid() && value) ? fieldDate.format("YYYY-MM") : "-";
      return fieldDate;
    }
    if (field.type === "Datetime") {
      let fieldDate = moment(value);
      fieldDate = (fieldDate.isValid() && value) ? fieldDate.format("YYYY-MM-DD HH:mm") : "-";
      return fieldDate.replace(" ", "\n");
    }
    if (field.isDate) {
      let fieldDate = moment(value);
      fieldDate = (fieldDate.isValid() && value) ? fieldDate.format("YYYY-MM-DD HH:mm") : "-";
      return fieldDate.replace(" ", "\n");
    }
    if ((field.type === "Number" || field.type === "Float") && value === 0) {
      return 0;
    }
    if (field.type === "Checkbox") return <Checkbox defaultChecked={value} checked={value} disabled />;
    if (field.render) return field.render(item, reload);
    if (!value) return "-"
    else return value;
  },
  renderContent(record, row) {
   
    if (!record && typeof record !== "boolean") return "-"
    if (row.render) return row.render(record);
    if (row.type === "Boolean") if (record) return "Yes"; else return "No";
    if (row.type === "BooleanReverse") if (record) return "No"; else return "Yes";
    if (row.type === "Datetime") return moment(record).format('yyyy-MM-DD HH:mm');
    if (row.type === "Date") return moment(record).format('yyyy-MM-DD');
    if (typeof (record) === typeof ([]) ) {
      console.log(record);
      if(typeof record?.[0] !== "string") return;
      return record.join(",");
    }
    return record;
  }
}

export default tableHelper;