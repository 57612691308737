import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { setNotification } from 'redux/slice/notificationSlice';
import { riskHelper } from 'helpers';
import Button from "Common/RoundedButton";
import Modal from "Common/Modal";
import EllipseButton from 'Common/EllipseButton';
import SectionTitle from 'Common/SectionTitle';
import RiskRegisterList from 'RiskRegister/riskRegisterList';

import RiskRegisterAddForm from '../riskRegisterAddForm';
import { useStyles } from "./style";

export default function AddRiskDiscussedTab({ data, haveEditRight }) {
  const dispatch = useDispatch();
  const { type, docId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [selectedRisk, setSelectedRisk] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setSelectedRisk(data.riskRegisters);
  }, [])

  useEffect(() => {
    try {
    } catch (e) {
      console.log(e)
    }
  }, [type, docId])

  const handleRiskSelect = (selectedData) => {
    if (selectedRisk.find((item) => !!item && item.id === selectedData.id)) {
      setSelectedRisk(selectedRisk.filter((item) => item.id !== selectedData.id));
    } else {
      setSelectedRisk([...selectedRisk, selectedData]);
    }
  };

  async function handleAddRisk() {
    try {
      const requestVariables = selectedRisk.map(risk => ({ riskMeetingId: Number(docId), riskRegisterId: risk.id }))
      const result = await riskHelper.addRiskDiscussed({ riskMeetingId: Number(docId), data: requestVariables });
      if (result.error) throw new Error(result.error);
      dispatch(setNotification({ type: "success", message: "Added Risk Discussed" }));
      history.push({ pathname: pathname, search: "tab=basic-info" });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        {haveEditRight ?
          <>
            <SectionTitle
              title={`Add Risk Discussed`}
              extraComponents={
                <div className={classes.buttonsContainer}>
                  {haveEditRight && <EllipseButton onClick={() => setModalOpen(true)} label="+ New Risk" />}
                </div>
              }
            />

            <div className={classes.body}>
              <RiskRegisterList
                selectedData={selectedRisk}
                handleSelect={handleRiskSelect}
                hideGoToButton
              />
            </div>
            <div style={{ textAlign: "end" }}>
              <Button children="Add" backgroundColor="#70BAD3" type="button" onClick={handleAddRisk} />
            </div>

            <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
              <RiskRegisterAddForm mode={"Add"} handleClose={() => setModalOpen(false)} />
            </Modal>
          </>
          : "No Permission"
        }
      </div>
    </div>
  );
}