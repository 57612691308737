import PropTypes from "prop-types";
import { useTheme } from '@material-ui/core/styles';
import { Container, Grid } from "@material-ui/core";
import Button from "Common/RoundedButton";

import SectionTitle from "Common/SectionTitle";

import { useStyles } from "./style";

function Reminder({
  cancelDrawer,
  confirmLeave
}) {
  const classes = useStyles();
  const theme = useTheme();
  return(
    <Container maxWidth="lg" className={classes.root}>
      <SectionTitle
        title={"Confirm to leave the current page?"}
      />
      <Grid container justify="flex-end" spacing={2} className={classes.buttonArea}>
        <Grid item xs={2}>
          <Button
            children="Cancel"
            onClick={cancelDrawer}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            children="Confirm"
            backgroundColor={theme.palette.addButton}
            onClick={confirmLeave}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

Reminder.propTypes = {
  cancelDrawer: PropTypes.func,
  confirmLeave: PropTypes.func
};

export default Reminder;