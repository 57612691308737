import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DSDTabs, DSDTab, useStyles } from "./style";

function LinkTabs({ data, activeTab, query }) {
  const classes = useStyles();
  const [value, setValue] = useState(activeTab ?? 0);
  const { pathname } = useLocation();
  const handleOnChange = (_, newValue) => {
    setValue(newValue);
  };
  if (!query) {
    return (
      <div className={classes.root}>
        <DSDTabs value={activeTab} onChange={handleOnChange} aria-label="link tabs">
          {data.map((item, i) =>
            <LinkTab label={item.render ?? item.label} to={`${pathname}?tab=${item.link}`} key={i} {...a11yProps(item.id)} />
          )}
        </DSDTabs>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <DSDTabs value={activeTab} onChange={handleOnChange} aria-label="link tabs">
        {data.map((item, i) =>
          <LinkTab label={item.render ?? item.label} to={`${pathname}?${query}=${item.link}`} key={i} {...a11yProps(item.id)} />
        )}
      </DSDTabs>
    </div>
  );

}

function a11yProps(index) {
  return {
    id: `link-tab-${index}`,
    'aria-controls': `link-tabpanel-${index}`,
  };
}

LinkTabs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.number.isRequired,
    id: PropTypes.string,
  })).isRequired,
  activeTab: PropTypes.any.isRequired,
  key: PropTypes.string,
};

export default LinkTabs;

function LinkTab(props) {
  return (
    <DSDTab
      component={Link}
      {...props}
    />
  );
}
