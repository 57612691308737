import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchTitleWrapper: {
    cursor: "pointer",
  },
  formBody: {
    overflow: "hidden",
    transition: "max-height 0.25s ease-in",
    marginTop: "16px",
  },
  collapsed: {
    maxHeight: 0,
  },
  expanded: {
    maxHeight: 2000,
  },
  btnWrapper: {
    marginLeft: 8,
    marginTop: 8,
  }
}));
