import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "16px",
  },
  header: {
    padding: "8px 16px",
    ...theme.typography.h5,
    whiteSpace: "pre-line",
  },
  column: {
    width: "100%",
    display: 'flex',
    flexDirection: "column",
    padding: "8px 16px 16px 16px",
    '& span': {
      marginTop: "8px",
    }
  },
  row: {
    margin: "16px 0",
  },
  tableCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
  },
  headerCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
  }
}));
