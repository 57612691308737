import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgoround: theme.palette.primaryDark,
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  body: {
    marginTop: "32px",
  },
  infoTitle: {
    color: "#B9B9B9"
  },
  section: {
    marginTop: 16,
  },
  divider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
  buttonsContainer: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  drawer: {
    backgroundColor: theme.palette.secondaryDark,
    overflowX: "hidden"
  },
  img: {
    height: 25,
    width: 25
  },
  a: {
    height: 25
  },
  middleCell: {
    minWidth: 130,
    borderRight: "1px solid rgba(81, 81, 81, 1)",
  },
  assignMenuContainer: {
    borderLeft: "1px solid rgba(81, 81, 81, 1)",
    padding: 8,
    overflow: "auto"
  },
  tableCell: {
    border: "none",
    borderRight: "1px solid #fff",
    whiteSpace: "pre-line",
    padding: "6px 10px",
  },
  firstSection: {
    borderTop: "1px solid #fff",
  },
  lastRow: {
    borderBottom: "1px solid #fff"
  },
  firstColumn: {
    border: "none",
    borderLeft: "1px solid #fff",
    borderRight: "1px solid #fff",
    whiteSpace: "pre-line",
    padding: "6px 10px",
  },
  headerCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
    verticalAlign: "top",
  },
  label: {
    minHeight: "25.6px",
    color: theme.palette.primaryGrey,
    ...theme.typography.h6,
  },
}));
