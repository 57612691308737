import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from "./style";

export default function SectionTitle({ title, extraComponents, fontStyle, anchorId }) {
  const theme = useTheme();

  const props = { theme, fontStyle: fontStyle ?? theme.typography.h3 }
  const classes = useStyles(props);

  return (
    <>
      <div id={anchorId} className={clsx(classes.row)}>
        <div
          className={classes.sectionTitle}>
          {title}
        </div>
        {extraComponents ?? null}
      </div>
    </>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  fontStyle: PropTypes.any,
  anchorId: PropTypes.string,
};