export const fileInfoFields = [
	{
		label: "File ID",
		name: "photo.public_meta.file_id",
		isRequired: true,
	},
	{
		label: "File Source",
		name: "photo.public_meta.file_source",
		isHidden: true,
		default: "Manual"
	},
];

export const fileMetaFields = [
	{
		label: "Taken Date Time",
		name: "photo.public_meta.taken_datetime",
		isRequired: true,
		type: "Datetime"
	},
	{
		label: "Taken By",
		name: "photo.public_meta.taken_by",
		isRequired: true,
	},
	{
		label: "Upload By",
		name: "photo.public_meta.upload_by",
		isHidden: true,
	},
	{
		label: "Purpose",
		name: "photo.private_meta.type",
		isRequired: true,
		type: "Select",
		options: [
			{
				label: 'For Record',
				value: "For Record"
			},
			{
				label: 'For Attention',
				value: "For Attention"
			},
		]
	},
	{
		label: "Photo Description",
		name: "photo.private_meta.photo_desc",
		isRequired: true,
	},
	{
		label: "Remarks",
		name: "photo.public_meta.remarks",
	},
]

export const photoFields = [
	{
		label: "Location",
		name: "location1",
	},
	{
		label: "Description",
		name: "photoDesc",
	},
	{
		type: "placeholder"
	},
	{
		label: "Taken by",
		name: "takenBy",
	},
	{
		label: "Taken at",
		name: "takenDatetime",
		type: "Datetime"
	},
	{
		type: "placeholder"
	},
]