import { Checkbox } from "@material-ui/core";

import { useStyles } from "./style";

function CustCheckbox({ clearErrors, name, field, disabled, defaultChecked, onChange, mode }) {
  const classes = useStyles();
  function onChangeInternal(e, value) {
    if (clearErrors && name) clearErrors(name);
    if (field?.onChange) field.onChange(value);
    else onChange(value)
  }
  if (mode === "manual") {
    return (
      <Checkbox
        disabled={disabled}
        className={classes.Checkbox}
        onChange={onChangeInternal}
        checked={defaultChecked}
        classes={{
          checked: classes.Checked,
          indeterminate: classes.indeterminate
        }}
      />
    )
  }
  return (
    <Checkbox
      disabled={disabled}
      className={classes.Checkbox}
      onChange={onChangeInternal}
      defaultChecked={((field && field.value) ?? false)}
      classes={{
        checked: classes.Checked,
        indeterminate: classes.indeterminate
      }}
    />
  );
}

export default CustCheckbox;