export const decryptedRows = [
  {
    name: "Works Category",
    key: "worksCategory",
  },
  {
    name: "Location of Work",
    key: "workLocation",
  },
  {
    name: "Work to be Inspected",
    key: "workInspected",
  },
  {
    name: "Work to be Inspected Datetime",
    key: "plannedSurveyInspectionDatetime",
    type: "Datetime"
  },
  {
    name: "Work Proposed",
    key: "workProposed",
  },
  {
    name: "Work Proposed Datetime",
    key: "workProposedDatetime",
    type: "Datetime",
  },
  {
    name: "Remark",
    key: "remark",
  },
  {
    name: "Submitted by",
    key: "submissionBy",
  },
  {
    name: "Submitted Datetime",
    key: "submissionDatetime",
    type: "Datetime"
  },
  {
    name: "Received by",
    key: "receivedBy",
  },
  {
    name: "Received Datetime",
    key: "receivedDatetime",
    type: "Datetime"
  },
  {
    name: "Inspection Assigned by",
    key: "inspectorAssignedBy",
  },
  {
    name: "Inspection Assigned Datetime",
    key: "inspectorAssignedByDatetime",
    type: "Datetime"
  },
  {
    name: "Actual Inspection by",
    key: "actualSurveyInspectionBy",
  },
  {
    name: "Actual Inspection Datetime",
    key: "actualSurveyInspectionDatetime",
    type: "Datetime"
  },
  {
    name: "Inspection Result",
    key: "inspectionResult",
  },
  {
    name: "Result Reason",
    key: "resultReason",
  },
  {
    name: "Returned by",
    key: "returnedBy",
  },
  {
    name: "Returned Datetime",
    key: "returnedToContractorDatetime",
    type: "Datetime"
  },
  {
    name: "Countersigned by",
    key: "countersignedBy",
  },
  {
    name: "Countersigned Datetime",
    key: "countersignedDatetime",
    type: "Datetime"
  },
  {
    name: "Closed by",
    key: "closedBy",
  },
  {
    name: "Closed Datetime",
    key: "closedDatetime",
    type: "Datetime"
  },
  {
    name: "Uploaded to the Blockchain Date",
    key: "createdAt",
    type: "Datetime",
  },
]

export const siteDiaryDecryptedRows = [
  {
    name: "Created Date",
    key: "creationDatetime",
    type: "Date",
  },
  {
    name: "AM Weather",
    key: "amWeather",
  },
  {
    name: "PM Weather",
    key: "pmWeather",
  },
  {
    name: "Rainfall",
    key: "rainfall",
  },
  {
    name: "Thunderstorm Warning",
    key: "thunderstormWarning",
  },
  {
    name: "Typhoon Warning",
    key: "typhoonWarning",
  },
  {
    name: "Day",
    key: "day",
  },
  {
    name: "Contract Day",
    key: "contractDay",
  },
  {
    name: "Instruction",
    key: "instructions",
  },
  {
    name: "Utility",
    key: "utility",
  },
  {
    name: "Progress",
    key: "progress",
  },
  {
    name: "Visitor",
    key: "visitor",
  },
  {
    name: "Site Staff",
    key: "siteStaff",
    render(item) {
      if (!item) return 0;
      return item.reduce(function (accumulator, currentValue) {
        return accumulator + Number(currentValue.count_site_staff)
      }, 0)
    }
  },
  {
    name: "Assistance",
    key: "assistance",
    render(item) {
      if (!item) return 0;
      return item.reduce(function (accumulator, currentValue) {
        return accumulator + Number(currentValue.count_assistance)
      }, 0)
    }
  },
  {
    name: "No. of Labour",
    key: "numberOfLabourPeople",
  },
  {
    name: "No. of Plant",
    key: "numberOfPlantEquipment",
  },
  {
    name: "Accidents",
    key: "accidents",
  },
  {
    name: "Remark",
    key: "remark",
  },
  // {
  //   name: "Photo Link (if any)",
  //   key: "photoLink",
  // },
  // {
  //   name: "Drawing Link (if any)",
  //   key: "drawingLink",
  // },
  // {
  //   name: "Method Statement (if any)",
  //   key: "methodStatement",
  // },
  {
    name: "People Signed",
    key: "peopleSigned",
  },
  {
    name: "Senior Inspector of Works Signed by",
    key: "pmdSignedBy"
  },
  {
    name: "Senior Inspector of Works Signed Comment",
    key: "pmdSignedComment",
  },
  {
    name: "Senior Inspector of Works Signed Datetime",
    key: "pmdSignedDatetime",
    type: "Date"
  },
  {
    name: "Contractor's Representative Signed by",
    key: "crSignedBy"
  },
  {
    name: "Contractor's Representative Signed Comment",
    key: "crSignedComment"
  },
  {
    name: "Contractor's Representative Signed Datetime",
    key: "crSignedDatetime",
    type: "Date",
  },
  {
    name: "Supervisor's Delegate Signed by",
    key: "supSignedBy"
  },
  {
    name: "Supervisor's Delegate Signed Comment",
    key: "supSignedComment"
  },
  {
    name: "Supervisor's Delegate Signed Datetime",
    key: "supSignedDatetime",
    type: "Date"
  },
  {
    name: "Uploaded to the Blockchain Date",
    key: "createdAt",
    type: "Datetime",
  },
]

export const photoDecryptedRows = [
  {
    name: "File Name",
    key: "photoDocument",
    render(item) {
      if (item && item.length > 0) {
        return item[0].filename;
      }
    }
  },
  {
    name: "Description",
    key: "photoDesc",
  },
  {
    name: "Location",
    key: "location1",
  },
  {
    name: "Taken by",
    key: "takenBy",
  },
  {
    name: "Taken at",
    key: "takenDatetime",
    type: "Datetime",
  },
  {
    name: "Upload by",
    key: "uploadBy",
  },
  {
    name: "Upload on",
    key: "uploadDatetime",
    type: "Datetime",
  },
]

export const safetyDecryptedRows = [
  {
    name: "Inspection Type",
    key: "inspectionType",
  },
  {
    name: "Inspection Location",
    key: "inspectionLocation",
  },
  {
    name: "Description",
    key: "description",
  },
  {
    name: "Type",
    key: "template",
  },
  {
    name: "Compliance",
    key: "compliance",
    type: "Boolean",
  },
  {
    name: "Created By",
    key: "submissionBy",
  },
  {
    name: "Created Datetime",
    key: "submissionDatetime",
    type: "Datetime",
  },
  // {
  //   name: "Inspection By",
  //   key: "inspectionBy",
  // },
  // {
  //   name: "Inspection Datetime",
  //   key: "inspectionDatetime",
  //   type: "Datetime",
  // },
  {
    name: "Signed By",
    key: "signedBy",
  },
  {
    name: "Signed Datetime",
    key: "signedDatetime",
    type: "Date",
  },
  {
    name: "Uploaded to the Blockchain Date",
    key: "createdAt",
    type: "Datetime",
  },
  {
    name: "Updated",
    key: "updatedAt",
    type: "Datetime",
  },
]

export const cleansingDecryptedRows = [
  {
    name: "Inspection Type",
    key: "inspectionType",
  },
  {
    name: "Inspection Location",
    key: "inspectionLocation",
  },
  {
    name: "Description",
    key: "description",
  },
  {
    name: "Type",
    key: "template",
  },
  {
    name: "Compliance",
    key: "ncCic",
    type: "BooleanReverse",
  },
  {
    name: "Created By",
    key: "submissionBy",
  },
  {
    name: "Created Datetime",
    key: "submissionDatetime",
    type: "Datetime",
  },
  // {
  //   name: "Inspection By",
  //   key: "inspectionBy",
  // },
  // {
  //   name: "Inspection Datetime",
  //   key: "inspectionDatetime",
  //   type: "Datetime",
  // },
  {
    name: "Signed By",
    key: "signedBy",
  },
  {
    name: "Signed Datetime",
    key: "signedDatetime",
    type: "Date",
  },

  {
    name: "Uploaded to the Blockchain Date",
    key: "createdAt",
    type: "Datetime",
  },
  {
    name: "Updated",
    key: "updatedAt",
    type: "Datetime",
  },
]

export const labourDecryptedRows = [
  {
    name: "LRR Year Month",
    key: "lrrYearMonth",
  },
  {
    name: "Submission By",
    key: "submissionBy",
  },
  {
    name: "Submission Datetime",
    key: "submissionDatetime",
    type: "Datetime",
  },
  {
    name: "Signed By",
    key: "signedBy",
  },
  {
    name: "Signed Datetime",
    key: "signedDatetime",
    type: "Datetime",
  },
  {
    name: "Uploaded to the Blockchain Date",
    key: "createdAt",
    type: "Datetime",
  },
  {
    name: "Updated",
    key: "updatedAt",
    type: "Datetime",
  },
]

export const edmsInfoRows = [
  {
    name: "Barcode/Log No.",
    key: "logNumber",
  },
  {
    name: "Doc Type",
    key: "docType",
  },
  {
    name: "Letter Date",
    key: "letterDate",
    type: "Date",
  },
  {
    name: "Receive Date",
    key: "receiveDate",
    type: "Date",
  },
  {
    name: "Filing Date",
    key: "filingDate",
    type: "Date",
  },
  {
    name: "From Company",
    key: "fromCompany",
  },
  {
    name: "From Person",
    key: "fromPerson",
  },
  {
    name: "To Company",
    key: "toCompany",
  },
  {
    name: "To Person",
    key: "toPerson",
  },
  {
    name: "CC To Company",
    key: "ccToCompany",
  },
  {
    name: "Subject",
    key: "subject",
  },
  {
    name: "Action Required",
    key: "actionRequired",
    type: "Boolean"
  },
  {
    name: "Exclude Outstanding List",
    key: "excludeOutstandingList",
    type: "Boolean",
  },
  {
    name: "Letter Ref.",
    key: "letterRef",
  },
  {
    name: "Reply To",
    key: "replyTo",
  },
  {
    name: "File No.",
    key: "fileNumber",
  },
  {
    name: "Folio",
    key: "folio",
  },
  {
    name: "CC To File",
    key: "ccToFile",
  },
  {
    name: "Enclosure",
    key: "enclosure",
    type: "Boolean"
  },
  {
    name: "CD/DVD",
    key: "cdOrDvd",
  },
  {
    name: "Sketch",
    key: "sketch",
  },
  {
    name: "A1 Drawing",
    key: "a1Drawing",
  },
  {
    name: "Others",
    key: "others",
  },
  {
    name: "Confidential",
    key: "confidential",
    type: "Boolean"
  },
  {
    name: "Prepared By",
    key: "preparedBy",
  },
  {
    name: "remarks",
    key: "remarks",
  },
  // {
  //   name: "Related Doc",
  //   key: "relatedDoc",
  // },
  {
    name: "Replied By",
    key: "repliedBy",
  },
  {
    name: "Circulation",
    key: "circulation",
  },
  {
    name: "",
    key: "",
  },
  {
    name: "File Name",
    key: "fileName",
  },
  {
    name: "Size",
    key: "size",
  },
  {
    name: "Version",
    key: "version",
  },
  {
    name: "Last Update",
    key: "lastUpdate",
    type: "Date",
  },
  {
    name: "Edit History",
    key: "editHistory",
  },
  {
    name: "Uploaded to the Blockchain Date",
    key: "createdAt",
    type: "Datetime",
  },
];

export const incomingEdmsRows = [
  {
    name: "Barcode/Log No.",
    key: "logNumber",
  },
  {
    name: "Doc Type",
    key: "docType",
  },
  {
    name: "Letter Date",
    key: "letterDate",
    type: "Date",
  },
  {
    name: "Receive Date",
    key: "receiveDate",
    type: "Date",
  },
  {
    name: "Filing Date",
    key: "filingDate",
    type: "Date",
  },
  {
    name: "From Company",
    key: "fromCompany",
  },
  {
    name: "From Person",
    key: "fromPerson",
  },
  {
    name: "To Company",
    key: "toCompany",
  },
  {
    name: "To Person",
    key: "toPerson",
  },
  {
    name: "CC To Company",
    key: "ccToCompany",
  },
  {
    name: "Subject",
    key: "subject",
  },
  {
    name: "Action Required",
    key: "actionRequired",
    type: "Boolean"
  },
  {
    name: "Exclude Outstanding List",
    key: "excludeOutstandingList",
    type: "Boolean",
  },
  {
    name: "Letter Ref.",
    key: "letterRef",
  },
  {
    name: "Reply To",
    key: "replyTo",
  },
  {
    name: "File No.",
    key: "fileNumber",
  },
  {
    name: "Folio",
    key: "folio",
  },
  {
    name: "CC To File",
    key: "ccToFile",
  },
  {
    name: "Enclosure",
    key: "enclosure",
    type: "Boolean"
  },
  {
    name: "CD/DVD",
    key: "cdOrDvd",
  },
  {
    name: "Sketch",
    key: "sketch",
  },
  {
    name: "A1 Drawing",
    key: "a1Drawing",
  },
  {
    name: "Others",
    key: "others",
  },
  {
    name: "Confidential",
    key: "confidential",
    type: "Boolean"
  },
  {
    name: "Prepared By",
    key: "preparedBy",
  },
  {
    name: "remarks",
    key: "remarks",
  },
  {
    name: "Replied By",
    key: "repliedBy",
  },
  {
    name: "Circulation",
    key: "circulation",
  },
  {
    name: "",
    key: "",
  },
  {
    name: "File Name",
    key: "fileName",
  },
  {
    name: "Size",
    key: "size",
  },
  {
    name: "Version",
    key: "version",
  },
  {
    name: "Last Update",
    key: "lastUpdate",
    type: "Date",
  },
  {
    name: "Edit History",
    key: "editHistory",
  },
];


export const outgoingEdmsRows = [
  {
    name: "Barcode/Log No.",
    key: "logNumber",
  },
  {
    name: "Doc Type",
    key: "docType",
  },
  {
    name: "Letter Date",
    key: "letterDate",
    type: "Date",
  },
  {
    name: "Dispatch Date",
    key: "dispatchDate",
    type: "Date",
  },
  {
    name: "Filing Date",
    key: "filingDate",
    type: "Date",
  },
  {
    name: "From Company",
    key: "fromCompany",
  },
  {
    name: "From Person",
    key: "fromPerson",
  },
  {
    name: "To Company",
    key: "toCompany",
  },
  {
    name: "To Person",
    key: "toPerson",
  },
  {
    name: "CC To Company",
    key: "ccToCompany",
  },
  {
    name: "Subject",
    key: "subject",
  },
  {
    name: "Action Required",
    key: "actionRequired",
    type: "Boolean"
  },
  {
    name: "Exclude Outstanding List",
    key: "excludeOutstandingList",
    type: "Boolean",
  },
  {
    name: "Letter Ref.",
    key: "letterRef",
  },
  {
    name: "Reply To",
    key: "replyTo",
  },
  {
    name: "File No.",
    key: "fileNumber",
  },
  {
    name: "Folio",
    key: "folio",
  },
  {
    name: "CC To File",
    key: "ccToFile",
  },
  {
    name: "Enclosure",
    key: "enclosure",
    type: "Boolean"
  },
  {
    name: "CD/DVD",
    key: "cdOrDvd",
  },
  {
    name: "Sketch",
    key: "sketch",
  },
  {
    name: "A1 Drawing",
    key: "a1Drawing",
  },
  {
    name: "Others",
    key: "others",
  },
  {
    name: "Confidential",
    key: "confidential",
    type: "Boolean"
  },
  {
    name: "Prepared By",
    key: "preparedBy",
  },
  {
    name: "remarks",
    key: "remarks",
  },
  {
    name: "Replied By",
    key: "repliedBy",
  },
  {
    name: "Circulation",
    key: "circulation",
  },
  {
    name: "",
    key: "",
  },
  {
    name: "File Name",
    key: "fileName",
  },
  {
    name: "Size",
    key: "size",
  },
  {
    name: "Version",
    key: "version",
  },
  {
    name: "Last Update",
    key: "lastUpdate",
    type: "Date",
  },
  {
    name: "Edit History",
    key: "editHistory",
  },
];

export const labourDetailsFields = [
  { id: "worker_desc", label: "Trade List" },
  { id: "worker_code", label: "Trade" },
  { id: "worker_count", label: "Total Man-days" },
];