export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    id: "dashboard",
    link: "dashboard"
  },
  {
    label: "Cleansing Inspection",
    key: 1,
    id: "cleansingInspection",
    link: "docs"
  },
]