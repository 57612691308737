import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import moment from 'moment';

import arrayHelper from 'utils/arrayHelper';
import { deploymentData } from './config';
import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Bar } from 'Common/Chart';
import { useStyles } from "./style";

export default function DeploymentGraph({ startDate, endDate }) {
	const dispatch = useDispatch();
	const { contractId } = useParams();
	const theme = useTheme();
	const classes = useStyles(theme);
	const [data, setData] = useState([]);
	const [keys, setKeys] = useState([]);

	useEffect(() => {
		getDashboardData();
	}, [startDate, endDate]);

	async function getDashboardData() {
		try {
			const variable = {
				contractId,
				startDate: startDate.clone().format('YYYY-MM-DD'),
				endDate: endDate.clone().format('YYYY-MM-DD'),
			}
			const result = await dashboardHelper.getLabourDeploymentBar({ variable });
			if(result.error) throw result.error;
			if (result.length > 0) {
				let keys = Object.keys(result[0]).filter(item => item !== "quarter");
				keys.sort();
				setKeys(keys);
			}
			setData(result);
			dispatch(setLoading(false));
		} catch (e) {
			setData([]);
			setKeys([])
			console.log(e);
		}
	}

	function mockChartApi(start, end) {
		let obj = []
		const data = deploymentData.result;
		let newData = data.filter(each => {
			let targetDate = moment(each.date).clone();
			return targetDate.isBetween(start, end);
		});

		obj = [...newData];

		let result = {
			keys: deploymentData.keys,
			result: obj,
		}
		return result;
	}

	return (
		<ChartContainer height={"500px"} elevation={0}>
			<ChartHeader
				title={'Top 5 Site Labour by Type'}
				filterType={"none"}
			/>
			<div style={{ height: "450px", display: "flex", justifyContent: "center", alignItems: "center" }}>
				{data.length === 0
					? <div>No/Insufficient data for the time period</div>
					: <Bar
						axisLeftName={"Number of Labour"}
						colors={['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf']}
						groupMode="grouped"
						data={data ?? []}
						keys={keys ?? []}
						indexBy="quarter"
						barPadding={0.5}
						enableLabel={false}
						enableTimeIndex={false}
						borderRadius={"8px"}
						margin={{ top: 50, right: 30, bottom: 70, left: 80 }}
					/>
				}
			</div>
		</ChartContainer>
	);
}