import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router';

import chartHelper from 'utils/chartHelper';
import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Line } from 'Common/Chart';

export default function NonClosedTrendGraph({ startDate, endDate }) {
	const { contractId } = useParams();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [data, setData] = useState([])

	useEffect(() => {
		getDashboardData();
	}, [startDate, endDate]);

	async function getDashboardData() {
		try {
			const result = await dashboardHelper.getNonClosedLateSubmissionTrend({
				variable: {
					contractId,
					startDate: startDate.clone().format('YYYY-MM-DD'),
					endDate: endDate.clone().format('YYYY-MM-DD')
				}
			})
			// const result = mockChartApi(startDate, endDate)
			const reversed = result.reverse();
			let coloredData = chartHelper.setWorkCatColorByKey(reversed, "id");
			setData(coloredData);

			dispatch(setLoading(false));
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<ChartContainer height={"600px"} elevation={0}>
			<ChartHeader
				title={'Trend of Non-closed Submission'}
				filterType={"none"}
			/>
			<div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
				{data.length === 0
					? <div>No/Insufficient data for the time period</div>
					: <Line
						colors={d => d.color}
						data={data}
						xScale={{
							type: 'point',
						}}
						axisLeftName="Number of Submission"
						legends={[
							{
								anchor: 'top-left',
								direction: 'row',
								justify: false,
								translateX: -25,
								translateY: -40,
								itemsSpacing: 50,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.9,
								itemTextColor: "#ffffff",
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1
										}
									}
								]
							}
						]}
					/>
				}
			</div>
		</ChartContainer>
	);
}