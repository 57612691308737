import { ErrorMessage } from '@hookform/error-message';
import { Grid, } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import FileIcon from 'static/icons/viewFile.svg';
import Button from "Common/RoundedButton";

import { useStyles } from "./style";

function BrowseDocument({ control, register, setValue, getValues, name, errors, defaultValue, watchFile, required=true }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} style={{ display: "flex" }}>
      <Button
        varient="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        backgroundColor="#70BAD3"
      >
        Upload a file
        <input
          {...register(name, required && { required: "File is required." })}
          type="file"
          hidden
        />
      </Button>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className={classes.error} style={{ marginLeft: "16px" }}>{message}</p>}
      />
      {(watchFile && watchFile[0]) &&
        <div className={classes.fileInfo}>
          <img className={classes.docImg} src={FileIcon}></img>
          {`Uploaded: ${watchFile[0].name}`}
        </div>
      }
    </Grid>
  );
}

BrowseDocument.propTypes = {
};

export default BrowseDocument;