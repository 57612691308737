import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    "& img": {
      width: "36px",
      marginRight: "12px"
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  documentName: {
    fontSize: 14,
    color: props=>props.theme.palette.primaryBlueLight,
  },
  detailName: {
    fontSize: 14,
    color: props=>props.theme.palette.primaryGrey,
  },
  detailContent: {
    fontSize: 14,
    color: "white",
  }
});
