import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';

import DocumentsTable from 'Common/Documents/DocumentsTable';
import { selectContractData } from 'redux/slice/contractSlice';
import { incidentHelper, relatedDocHelper, edmsFileHelper, authHelper } from 'helpers';
import SectionTitle from 'Common/SectionTitle';
import DocumentHeader from 'Document/DocumentHeader';
import { tableConfig } from 'EDMS/CommonDocumentList/config';
import DocumentRelatedDocumentV2 from 'Document/DocumentRelatedDocumentV2';
import SIListDetailsTable from 'Incident/SIDetails/SIListDetailsTable';
import EllipseButton from 'Common/EllipseButton';
import Modal from "Common/Modal";

import SIAddForm from "../SIAddForm";
import { useStyles } from "../style";

export default function IncidentDetails() {
  const contractData = useSelector(selectContractData);
  const { docId, contractId } = useParams();
  const location = useLocation();
  const sp = new URLSearchParams(location.search);
  const searchParam = sp.get("tab");
  const theme = useTheme();
  const classes = useStyles(theme);
  const [data, setData] = useState([]);
  const [relatedDocs, setRelatedDocs] = useState();
  const [docData, setDocData] = useState([]);
  const [siData, setSIData] = useState([]);
  const [groupPermission, setPermission] = useState({});
  const [modal, setModal] = useState({ type: "", open: false, data: null });

  useEffect(() => {
    try {
      initData();
    } catch (e) {
      console.log(e)
    }
  }, [docId])

  async function initData() {
    try {
      const result = await Promise.all([
        incidentHelper.getIncident(docId),
        relatedDocHelper.getRelatedDocuments({ docType: "incident", docId }),
        incidentHelper.getIncidentMainDocuments(docId, { contractId: contractId }),
        authHelper.checkGroupPermission(),
      ]);
      if (!result[0].incident) throw new Error("Data not found");
      setData(result[0]?.incident);
      setRelatedDocs(result[1]?.related_document);
      setSIData(result[2]?.site_instruction);
      setPermission(result[3]);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (relatedDocs) extractDocData();
  }, [relatedDocs])

  async function extractDocData() {
    if (!relatedDocs?.relatedDocuments) return [];
    if (!relatedDocs?.relatedDocuments.fileMetaId.length === 0) return [];
    let tempData = [];

    let targetKey = relatedDocs.relatedDocuments.fileMetaId;
    targetKey.forEach(item =>
      tempData.push(edmsFileHelper.getRelatedDocFileMeta(item))
    );

    const result = await Promise.all(tempData);
    setDocData(result);
  }

  const searchFilter = [
    {
      label: "PMI",
      value: "pmi",
    },
    {
      label: "CE",
      value: "ce",
    },
  ];

  function handleGoToDetails(rowDetails) {
    const newWindow = window.open(`/contract/${contractId}/edms/document/${rowDetails.id}`, '_blank');
    if (newWindow) newWindow.opener = null;
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "related-documents":
        return <DocumentRelatedDocumentV2 componentType={"incident"} data={relatedDocs} searchFilter={searchFilter} afterAction={initData} incidentRelatedDoc />;
      default:
        return (
          <>
            <DocumentHeader contract={contractData} data={data} type="incident" />
            <div className={classes.body}>
              <SectionTitle title={`PMI/CE`} />
              <DocumentsTable data={docData} tableType={"edms"} fieldsData={tableConfig} hidePdfBtn handleGoTo={(item) => handleGoToDetails(item)} />

              <Divider className={classes.divider} />
              {groupPermission.contractor &&
                <div style={{ textAlign: "end" }}>
                  <EllipseButton onClick={() => setModal({ type: "Add", open: true, data: { assign: "1", incidentId: docId } })} label="+ New Document" />
                </div>
              }
              {siData?.nce?.length > 0 &&
                <>
                  <SectionTitle title={`NCE`} />
                  <SIListDetailsTable reload={initData} data={siData?.nce ?? []} incidentNumber={data.incidentNumber} type="NCE" />
                </>
              }

              {siData?.communication?.length > 0 &&
                <div style={{ marginTop: 16 }}>
                  <SectionTitle title={`Contractor's Communication`} />
                  <SIListDetailsTable reload={initData} data={siData?.communication ?? []} incidentNumber={data.incidentNumber} type="Communication" />
                </div>
              }
              {siData?.si?.length > 0 &&
                <div style={{ marginTop: 16 }}>
                  <SectionTitle title={`SI List`} />
                  <SIListDetailsTable reload={initData} data={siData?.si ?? []} incidentNumber={data.incidentNumber} type="SI" />
                </div>
              }
            </div>
          </>
        );

    }
  }

  return (
    <div className={classes.root}>
      {
        (!!searchParam && searchParam !== "basic-info")
        && <DocumentHeader contract={contractData} data={data} type="incident" />
      }

      {returnDocumentContent()}
      <Modal open={modal.open} handleClose={() => setModal({ type: modal.type, open: false, data: null })}>
        <SIAddForm mode={modal.type} handleClose={() => setModal({ type: modal.type, open: false, data: null })} data={modal.data} enableRedirection={false} reload={initData} />
      </Modal>
    </div>
  );
}