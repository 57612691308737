import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Grid, Drawer } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import SimpleMenu from 'Common/Menu';
import { edmsFileHelper, authHelper } from 'helpers';
import SectionTitle from 'Common/SectionTitle';
import EllipseButton from 'Common/EllipseButton';
import IconButton from "Common/IconButton";
import Modal from "Common/Modal";
import { setLoading } from 'redux/slice/loadingSlice';
import RemarksDrawer from 'Common/RemarksDrawer';

import EditGroup from './editGroup';
import EditRemarks from './editRemarks';
import FolderGroup from './folderGroup';
import EDMSContainer from '../CommonContainer';
import { useStyles } from "./style";

export default function EDMSFolderList({ }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const [groups, setGroups] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOptions, setMenuOptions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    modalType: null,
  });
  const [remarks, setRemarks] = useState([]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      dispatch(setLoading(true))
      const result = await Promise.all([
        edmsFileHelper.getFileGroups(contractId),
        edmsFileHelper.getRemarksLabels(contractId),
      ]);
      if (!result[0].file_category) throw { message: "no data" };
      setGroups(result[0].file_category);
      setRemarks(result[1].label);
      dispatch(setLoading(false))
    } catch (e) {
      setGroups([]);
      console.log(e);
      dispatch(setLoading(false))
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function changeModalContent(modalType, mode, data) {
    setModalContent({
      modalType,
      mode,
      data
    });
    setModalOpen(true);
  }

  function renderModalContent() {
    if (modalContent.modalType === "Group") {
      return <EditGroup mode={modalContent.mode} handleClose={() => setModalOpen(false)} reload={init} defaultData={modalContent.data} />
    } else if (modalContent.modalType === "Remarks") {
      return <EditRemarks mode={modalContent.mode} handleClose={() => setModalOpen(false)} reload={init} defaultData={modalContent.data} />
    }
  }

  return (
    <EDMSContainer>
      <SectionTitle
        title="Filing List"
        extraComponents={
          <div className={classes.buttonsContainer}>
            <div>
              <EllipseButton label="+ Add Group" onClick={() => changeModalContent('Group', "Add")} />
            </div>
            <div style={{ marginLeft: "8px" }}>
              <EllipseButton label="Remarks" onClick={() => setShowFilter(true)} />
            </div>

            {/* <div style={{ marginLeft: "16px" }}>
              <IconButton
                icon={<FilterListIcon />}
                onClick={handleOpenFilter}
              />
            </div> */}
          </div>
        }
      />
      <Grid container spacing={2} className={classes.body}>
        {groups.map((group, idx) =>
          <Grid item xs={12} sm={6} md={4} key={idx}>
            <FolderGroup
              changeModalContent={changeModalContent}
              group={group}
              setAnchorEl={setAnchorEl}
              setMenuOptions={setMenuOptions}
            />
          </Grid>
        )}
      </Grid>
      <SimpleMenu
        options={menuOptions}
        handleClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{}}
      />
      <Modal open={modalOpen} handleClose={() => setModalOpen(false)}>
        <>{renderModalContent()}</>
      </Modal>
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <RemarksDrawer reload={init} {...{ setShowFilter, changeModalContent, remarks }} />
      </Drawer>
    </EDMSContainer>
  );
}