import axios from './apiHelper';
import jwt_decode from "jwt-decode";

const authHelper = {
  login: async ({ username, password }) => {
    try {
      const result = await axios.post(`/user/login`, {
        username,
        password
      });
      if (!result.data) {
        throw new Error(result.error);
      }
      return result.data;
    } catch (e) {
      if (e.response && e.response.data) {
        return { error: e.response.data.message }
      }
      return { error: 'error' }
    }
  },
  logout: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
  forgotPassword: async (email) => {
    try {
      const result = await axios.post(`/user/forgot-password`, {
        email
      });

      if (!result.data) {
        throw new Error('Fail to forgot password');
      }
      return result.data;
    } catch (e) {
      if (e.response && e.response.data) {
        return { error: e.response.data.message }
      }
      return { error: 'error' }
    }
  },
  resetPassword: async ({ password, token }) => {
    try {
      const result = await axios.post(`/user/reset-password`, {
        password,
        token
      });

      if (!result.data) {
        throw new Error('Fail to reset password');
      }
      return result.data;
    } catch (e) {
      if (e.response && e.response.data) {
        return { error: e.response.data.message }
      }
      return { error: 'error' }
    }
  },
  userActivate: async ({ token }) => {
    try {
      const result = await axios.post(`/user/activate`, {
        token
      });

      if (!result.data) {
        throw new Error('Fail to activate user');
      }
      return result.data;
    } catch (e) {
      if (e.response && e.response.data) {
        return { error: e.response.data.message }
      }
      return { error: 'error' }
    }
  },
  checkPermission: () => {
    const permission = localStorage.getItem('code') || sessionStorage.getItem('code');
    if (permission === "read_write") return true
    return false;
  },
  checkAdminPermission: () => {
    const permission = localStorage.getItem('userType') || sessionStorage.getItem('userType');
    if (permission.includes("admin")) return true
    return false;
  },
  getMyInfo: async () => {
    try {
      const result = await axios.get(`/users`, { params: { id: authHelper.getMyUserId() } });
      return result.data;
    } catch (e) {
      console.log(e)
    }
  },
  getMyUserId: () => {
    const token = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    const decoded = jwt_decode(token);
    return decoded.id;
  },
  isAdmin: () => {
    const type = localStorage.getItem('userType');

    return type === "admin" ? true : false;
  },
  checkGroupPermission: async () => {
    try {
      let groupPermission = {};
      const contractorConditions = ["chunwo", "bestwise", "jec"];
      const userId = authHelper.getMyUserId();
      const result = await axios.get(`/user/${userId}/groups`);
      if (!result.data) {
        throw new Error("Fail to get Permission");
      }
      const groupArr = result.data?.result?.groups ?? [];
      
      // check aecom
      const aecomArr = groupArr.filter(item => item.name.toLowerCase().includes("aecom"));
      if (aecomArr.length > 0) groupPermission.aecom = true;
      // check main contractor
      const contractorArr = groupArr.filter(item => contractorConditions.some(condition => item.name.toLowerCase().includes(condition)));
      if (contractorArr.length > 0) groupPermission.contractor = true;

      return groupPermission;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getConstantVar: async () => {
    try {
      const result = await axios.get(`/constant-variable/${process.env.REACT_APP_ENV}/values`);

      if (!result.data) {
        throw new Error(`Fail to get constant variables`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  }
}

export default authHelper