import axios from './apiHelper';

const contractHelper = {
  getContracts: async ({ variable }) => {
    try {
      const result = await axios.get(`/contracts`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get contracts");
      }
      return result.data["contract"];
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getContractByNo: async ({ variable }) => {
    try {
      const result = await axios.get(`/contracts/get-by-no`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get contracts");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getContractsByGroup: async () => {
    try {
      const result = await axios.get(`/contracts/by-group`);

      if (!result.data) {
        throw new Error("Fail to get contracts");
      }
      return result.data['result']['contracts'];
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getContract: async (id) => {
    try {
      const result = await axios.get(`/contract/${id}`);

      if (!result.data) {
        throw new Error("Fail to get contract");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getContractRisc: async (id) => {
    try {
      const result = await axios.get(`/contract/${id}/risc`);

      if (!result.data) {
        throw new Error("Fail to get contract");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getContractType: async ({ id, type }) => {
    try {
      const result = await axios.get(`/contract/${id}/${type}`);

      if (!result.data) {
        throw new Error(`Fail to get contract - ${type}`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getContractsTypeCount: async (variable) => {
    try {
      const result = await axios.get(`/contracts/count-by-type`, { params: variable });

      if (!result.data) {
        throw new Error(`Fail to get contract - ${variable.type}`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  }
}

export default contractHelper;