import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTheme } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import { Divider, Grid } from "@material-ui/core";
import moment from "moment";

import { riskHelper } from "helpers";
import EllipseButton from "Common/EllipseButton";
import SectionTitle from "Common/SectionTitle";
import fieldHelper from "utils/fieldHelper";
import { setLoading } from "redux/slice/loadingSlice";
import { setNotification } from "redux/slice/notificationSlice";

import { useStyles } from "./style";
import ParticipantsFieldArray from "RiskRegister/ParticipantsFieldArray";

export default function RiskMeetingHeaderForm({ data, setIsEdit }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { docId } = useParams();

  const { watch, register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      ...data
    },
  });

  console.log(data, watch())

  async function onSubmit(data) {
    try {
      dispatch(setLoading(true));
      const result = await riskHelper.editRiskMeeting(docId, data);
      dispatch(setLoading(false));
      setIsEdit(false);
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      dispatch(setLoading(false));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <SectionTitle
        title={""}
        fontStyle={theme.typography.h4}
        extraComponents={
          <div className={classes.editHeader}>
            <div style={{ marginRight: 8 }}>
              <EllipseButton
                label={"Save"}
                type="submit"
                backgroundColor="transparent"
              />
            </div>
            <EllipseButton
              label={"Cancel"}
              type="button"
              backgroundColor="transparent"
              onClick={() => setIsEdit(false)}
            />
          </div>
        }
      />
      <Grid container spacing={2}>
        <Grid container spacing={3} className={classes.section}>
          {fieldHelper.renderField(
            { label: "Meeting Date", name: "date", type: "Date", isRequired: true, default: moment(data.date) },
            0, register, control, errors)
          }

          {fieldHelper.renderField(
            { label: "Meeting Start Time", name: "startTime", type: "Time", isRequired: true, default: moment(data.startTime, 'H:mm:ss') },
            1, register, control, errors)
          }
          {fieldHelper.renderField(
            { label: "Meeting End Time", name: "endTime", type: "Time", isRequired: true, default: moment(data.endTime, 'H:mm:ss') },
            2, register, control, errors)
          }
          {fieldHelper.renderField(
            { label: "Venue", name: "venue", },
            3, register, control, errors)
          }
          {fieldHelper.renderField(
            { label: "Meeting No.", name: "referenceNumber", isRequired: true },
            4, register, control, errors)
          }
        </Grid>
        <Divider className={classes.divider} />
        
        <ParticipantsFieldArray control={control} name={"participants"} errors={errors} defaultValue={data?.participants}/>
        
      </Grid>

    </form>
  );
}
