import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%"
  },
  label: {
    color: theme.palette.primaryGrey,
    ...theme.typography.h6,
    marginBottom: "8px"
  }
}));
