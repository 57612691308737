import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    background: `url(${process.env.PUBLIC_URL + '/login.png'}), #181935 center center`,
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    color: 'white',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'cover'
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain'
    },
  },
  logo:{
    position: "absolute",
    top: "48px",
    left: "48px",
  },
  title: {
    display: "flex",
    marginLeft: "75px",
    marginTop: "240px",
    maxWidth: "500px",
    ...theme.typography.h1,
    color: theme.palette.primaryHeader,
  },
  titleContainer: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
}));
