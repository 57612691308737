import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "16px",
    "& .MuiPaginationItem-root": {
      color: theme.palette.secondary.main
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.primaryPurple
    }
  },
  ul: props => ({
    justifyContent: props.justifyContent,
  }),
}));
