import { Grid, IconButton } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import MapIcon from '@material-ui/icons/Map';

import arrayHelper from "./arrayHelper";
import RoundedButton from "Common/RoundedButton";
import Select from "Common/Select";
import TextField from "Common/TextField";
import ChipInput from 'Common/ChipInput';
import CustCheckbox from "Common/Checkbox";
import DatePickerUncontrol from "Common/DatePickerUncontrol";
import CustRadio from "Common/Radio";
import CustAutoComplete from "Common/CustAutoComplete";

const fieldHelper = {
  renderField(inputField, idx, register, control, errors, props, clearErrors) {
    let isRequired = typeof (inputField.isRequired) === 'function' ? inputField.isRequired(props) : inputField.isRequired;
    let fieldName = typeof (inputField.name) === "function" ? inputField.name(props) : inputField.name;
    let size = (!!props && props.size) ? props.size : 4;
    let mdSize = (!!props && props.mdSize) ? props.mdSize : null;
    if (inputField.isHidden) {
      return (
        <div key={idx}>
          <input {...register(fieldName)} hidden={true} defaultValue={inputField.default}></input>
        </div>
      );
    } else if (!inputField.type || inputField.type === "string") {
      return (
        <Grid item xs={size} md={mdSize} key={idx}>
          <Controller
            name={fieldName}
            control={control}
            rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
            defaultValue={inputField.default ?? ""}
            render={({ field }) => <TextField
              hideLabel={inputField.hideLabel}
              disabled={inputField.disabled}
              inputProps={inputField.inputProps}
              defaultValue={inputField.default ?? ""}
              label={isRequired ? inputField.label + " *" : inputField.label}
              field={field}
              rows={inputField.rows}

            />}
          >
          </Controller>
          <ErrorMessage
            errors={errors}
            name={fieldName}
            render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
          />

        </Grid>
      )
    }
    switch (inputField.type) {
      case "ObjectArray":
        // const fieldArray = props[inputField.key];
        const { fields, append, remove } = props[inputField.key];
        const newRowValue = arrayHelper.convertArrayToObject(inputField.values.filter(item => item.key !== ""), 'key');
        const oneBtnStyle = { display: 'flex', width: "100%", justifyContent: "end", marginBottom: "16px" };
        const twoBtnStyle = { ...oneBtnStyle, justifyContent: "space-between" };
        return (
          <>
            {fields.map((item, index) => {
              return (
                inputField.values.map((innerItem, innerIndex) =>
                  this.renderField(innerItem, innerIndex, register, control, errors, { name: `${fieldName}[${index}].${innerItem.key}` })
                )
              );
            })
            }
            <div style={fields.length > 1 || (!inputField.isRequired && fields.length > 0) ? twoBtnStyle : oneBtnStyle}>
              {fields.length > 1 || (!inputField.isRequired && fields.length > 0)
                ? <RoundedButton
                  type="button"
                  backgroundColor="#9A9A9A"
                  onClick={() => {
                    remove(fields.length - 1);
                  }}
                >
                  Remove
                </RoundedButton>
                : null
              }
              <RoundedButton
                type="button"
                backgroundColor="#70BAD3"
                onClick={() => {
                  append(newRowValue);
                }}
              >
                Add {inputField.label}
              </RoundedButton>
            </div>
          </>
        )
      case "Select":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              defaultValue={inputField?.options[0]?.value ?? ""}
              render={({ field }) => <Select
                labelKey={inputField.labelKey}
                valueKey={inputField.valueKey}
                defaultValue={""}
                label={isRequired ? inputField.label + " *" : inputField.label}
                options={inputField.options}
                field={field}
              />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "award":
      case "boolean":
      case "Radio":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              // defaultValue={inputField.defaultEmpty ? '' : inputField.options[0].value}
              render={({ field }) => <CustRadio
                disabled={inputField.disabled}
                label={isRequired ? inputField.label + " *" : inputField.label}
                options={inputField.options}
                field={field}
                hideLabel={inputField.hideLabel}
              />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "nr":
      case "number":
      case "Number":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              defaultValue=""
              render={({ field }) => <TextField
                inputProps={inputField.inputProps}
                label={isRequired ? inputField.label + " *" : inputField.label}
                field={field}
                type="number"
                hideLabel={inputField.hideLabel}
                disabled={inputField.disabled}
              />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "percentage":
        const validation = { validate: value => Number(value) <= 100 || 'Percentage can not exceed 100', valueAsNumber: true }
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required`, ...validation } : validation}
              name={fieldName}
              control={control}
              defaultValue=""
              render={({ field }) => <TextField
                disabled={inputField.disabled}
                hideLabel={inputField.hideLabel}
                inputProps={{ step: "0.01", ...inputField.inputProps, max: 100 }}
                label={isRequired ? inputField.label + " *" : inputField.label}
                field={field}
                type="number"
                trailingText={"%"}
              />
              }
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "Float":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              defaultValue=""
              render={({ field }) => <TextField
                hideLabel={inputField.hideLabel}
                inputProps={{ step: "0.01", ...inputField.inputProps }}
                label={isRequired ? inputField.label + " *" : inputField.label}
                field={field}
                type="number" />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "Array":
        const instruction = " (Press enter to Add) "
        const label = isRequired ? inputField.label + instruction + " *" : inputField.label + instruction;
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              render={({ field }) => <ChipInput label={label} field={field} />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "datetime":
      case "Datetime":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              hasInputValue={inputField.hasInputValue}
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              render={({ field }) => <DatePickerUncontrol
                disabled={inputField.disabled}
                hideLabel={inputField.hideLabel}
                format={inputField.format}
                field={field}
                label={isRequired ? inputField.label + " *" : inputField.label}
                type="Datetime"
              />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "date":
      case "Date":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              render={({ field }) => <DatePickerUncontrol
                disabled={inputField.disabled}
                hideLabel={inputField.hideLabel}
                hasInputValue={inputField.hasInputValue}
                field={field}
                label={isRequired ? inputField.label + " *" : inputField.label}
                format={inputField.format ?? 'yyyy/MM/DD'}
                type="Date"
                views={inputField.views}
              />}
            />
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "Time":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              render={({ field }) => <DatePickerUncontrol
                disabled={inputField.disabled}
                hideLabel={inputField.hideLabel}
                field={field}
                label={isRequired ? inputField.label + " *" : inputField.label}
                format={inputField.format ?? "HH:mm"}
                type="Time"
                views={inputField.views}
              />}
            />
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "year":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              render={({ field }) => <DatePickerUncontrol
                hasInputValue={inputField.hasInputValue}
                disabled={inputField.disabled}
                hideLabel={inputField.hideLabel}
                field={field}
                label={isRequired ? inputField.label + " *" : inputField.label}
                format={"yyyy"}
                type="Year"
                views={"year"}
              />}
            />
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "Map":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              render={({ field }) => <TextField
                inputProps={inputField.inputProps}
                label={isRequired ? inputField.label + " *" : inputField.label}
                icon={<IconButton><MapIcon /></IconButton>}
                field={field}
                type={"number"}
              />}
            />
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "Checkbox":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <div style={{
              color: "#B9B9B9",
              marginBottom: "8px",
            }}>
              {inputField.label}
            </div>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              render={({ field }) => <CustCheckbox field={field} disabled={inputField.disabled} />}
            />
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "CheckboxGroup":
        return (
          <Grid item xs={size} md={mdSize} key={idx}>
            <div style={{
              color: "#B9B9B9",
              marginBottom: "8px",
            }}>
              {inputField.label}
            </div>
            {inputField.options.map((option, idx) =>
              <Controller
                key={idx}
                // rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
                name={option.name}
                control={control}
                render={({ field }) =>
                  <div>
                    <CustCheckbox field={field} clearErrors={clearErrors} name={inputField.name} disabled={inputField.disabled} />
                    <span>{option.label}</span>
                  </div>}
              />
            )
            }
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      case "AutoComplete":
        return (
          <Grid item xs={size}>
            <Controller
              rules={isRequired ? { required: `${inputField.label} is Required` } : {}}
              name={fieldName}
              control={control}
              defaultValue={inputField.default ?? (inputField.multiple ? [] : undefined)}
              render={({ field }) =>
                <CustAutoComplete
                  multiple={inputField.multiple}
                  label={isRequired ? inputField.label + " *" : inputField.label}
                  options={inputField.options}
                  field={field}
                  defaultValue={inputField.default ?? field.value}
                  isUppercase={inputField.isUppercase}
                />
              }
            />
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
        );
      default:
        return (
          <Grid item xs={size} md={mdSize} key={idx}></Grid>
        );
    }
  }
}

export default fieldHelper;