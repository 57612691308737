import errorHelper from 'utils/errorHelper';
import axios from './apiHelper';

// temp route
const riskHelper = {
  getRiskMeetings: async (variables) => {
    try {
      const result = await axios.get(`/risk-meetings`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get risk meetings");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRiskMeeting: async (id) => {
    try {
      const result = await axios.get(`/risk-meeting/${id}`);

      if (!result.data) {
        throw new Error("Fail to get risk meeting");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getEarlyWarning: async (id) => {
    try {
      const result = await axios.get(`/risk-early-warning/${id}`);

      if (!result.data) {
        throw new Error("Fail to get risk early warning");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getEarlyWarnings: async (variables) => {
    try {
      const result = await axios.get(`/risk-early-warnings`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get risk early warnings");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createEarlyWarning: async (variables) => {
    try {
      const result = await axios.post(`/risk-early-warning`, variables);

      if (!result.data) {
        throw new Error("Fail to create risk early warning");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editEarlyWarning: async ({ variables, id }) => {
    try {
      const result = await axios.patch(`/risk-early-warning/${id}`, variables);

      if (!result.data) {
        throw new Error("Fail to edit risk early warning");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },

  createRiskMeeting: async (variables) => {
    try {
      const result = await axios.post(`/risk-meeting`, variables);

      if (!result.data) {
        throw new Error("Fail to create risk meeting");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editRiskMeeting: async (id, variables) => {
    try {
      const result = await axios.patch(`/risk-meeting/${id}`, variables);

      if (!result.data) {
        throw new Error("Fail to edit risk meeting");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRiskRegister: async (id) => {
    try {
      const result = await axios.get(`/risk-register/${id}`);

      if (!result.data) {
        throw new Error("Fail to get risk register");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRiskRegisters: async (variables) => {
    try {
      const result = await axios.get(`/risk-registers`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get risk registers");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getStatusTotal: async(variables ) => {
    try {
      const result = await axios.get(`/dashboard/risk-register/status-total`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get status total");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createRiskRegister: async (variables) => {
    try {
      const result = await axios.post(`/risk-register`, variables);

      if (!result.data) {
        throw new Error("Fail to create risk register");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editRiskRegister: async (id, variables) => {
    try {
      const result = await axios.patch(`/risk-register/${id}`, variables);

      if (!result.data) {
        throw new Error("Fail to edit risk register");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRiskDecisions: async (variables) => {
    try {
      const result = await axios.get(`/risk-decisions`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get risk decisions");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  addRiskDiscussed: async (variables) => {
    try {
      const result = await axios.post(`/risk-meeting-risk-register`, variables);

      if (!result.data) {
        throw new Error("Fail to add risk discussed");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  addRiskDecision: async (variables) => {
    try {
      const result = await axios.post(`/risk-decision`, variables);

      if (!result.data) {
        throw new Error("Fail to add risk decision");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  uploadRiskAttachment: async ({ id, variables }) => {
    try {
      let formData = new FormData();

      formData.append('description', variables.description);
      formData.append('isAttachment', '1');
      formData.append('file', variables.file);
      const result = await axios({
        method: 'post',
        url: `/risk-register/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (!result.data) {
        throw new Error(`Fail to post the attachment`);
      }
      return result.data;
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  uploadEarlyWarningAttachment: async ({ id, variables }) => {
    try {
      let formData = new FormData();
      formData.append('file', variables.file);
      const result = await axios({
        method: 'post',
        url: `/risk-early-warning/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (!result.data) {
        throw new Error(`Fail to post the attachment`);
      }
      return result.data;
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRiskExcel: async (variables) => {
    try {
      const result = await axios.post(`/risk-register-excel`, variables, { responseType: "blob" });
      if (result?.error) {
        throw new Error(result.error);
      }
      if (!result.data) {
        throw new Error("Fail to get risk excel");
      }
      return { data: result.data, headers: result.headers };
    } catch (e) {
      const returnResult = await errorHelper.catchApiError(e);
      if (returnResult) return returnResult;
      return { error: "error" };
    }
  },
}

export default riskHelper;