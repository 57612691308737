import axios from './apiHelper';

// temp route
const incidentHelper = {
  getIncidents: async (variables) => {
    try {
      const result = await axios.get(`/incidents`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get incidents");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getIncident: async (id) => {
    try {
      const result = await axios.get(`/incident/${id}`);

      if (!result.data) {
        throw new Error("Fail to get incident");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createIncident: async (variables) => {
    try {
      const result = await axios.post(`/incident`, variables);

      if (!result.data) {
        throw new Error("Fail to create incident");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editIncident: async (id, variables) => {
    try {
      const result = await axios.patch(`/incident/${id}`, variables);

      if (!result.data) {
        throw new Error("Fail to edit incident");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSiteInstruction: async (id) => {
    try {
      const result = await axios.get(`/site-instruction/${id}`);

      if (!result.data) {
        throw new Error("Fail to get site instruction");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSiteInstructionDocuments: async (variables) => {
    try {
      const result = await axios.get(`/site-instruction-documents`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get site instruction documents");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSiteInstructions: async (variables) => {
    try {
      const result = await axios.get(`/site-instructions`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get site instructions");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getIncidentCount: async (variables) => {
    try {
      const result = await axios.get(`/dashboard/incident/count`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get site instructions");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getIncidentTrend: async (variables) => {
    try {
      const result = await axios.get(`/dashboard/incident/line-chart`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get incident trend");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getIncidentMainDocuments: async (id, variables) => {
    try {
      const result = await axios.get(`/incident/${id}/site-instructions`, { params: variables });

      if (!result.data) {
        throw new Error("Fail to get incident main documents");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createSiteInstruction: async (variables) => {
    try {
      const result = await axios.post(`/site-instruction`, variables);

      if (!result.data) {
        throw new Error("Fail to create site instruction");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editSiteInstruction: async (id, variables) => {
    try {
      const result = await axios.patch(`/site-instruction/${id}`, variables);

      if (!result.data) {
        throw new Error("Fail to edit site instruction");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  uploadSIDocument: async (id, variables) => {
    try {
      let formData = new FormData();

      formData.append('description', variables.description);
      formData.append('documentType', variables.documentType);
      formData.append('file', variables.file);
      const result = await axios({
        method: 'post',
        url: `/site-instruction/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (!result.data) {
        throw new Error(`Fail to post the attachment`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  updateSIStatus: async (id, status) => {
    try {
      const result = await axios.patch(`/site-instruction/${id}/${status}`);

      if (!result.data) {
        throw new Error("Fail to update SI Status");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  updateSIDocsComment: async (variables) => {
    try {
      const result = await axios.patch(`/site-instruction-documents`, variables);

      if (!result.data) {
        throw new Error("Fail to update SI docs");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deleteIncident: async (id) => {
    try {
      const result = await axios.delete(`/incident/${id}`);

      if (!result.data) {
        throw new Error("Fail to delete incident");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deleteSI: async (id) => {
    try {
      const result = await axios.delete(`/site-instruction/${id}`);

      if (!result.data) {
        throw new Error("Fail to delete SI");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deleteSIDocument: async (id) => {
    try {
      const result = await axios.delete(`/site-instruction-document/${id}`);

      if (!result.data) {
        throw new Error("Fail to delete si document");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default incidentHelper;