import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    color: theme.palette.secondary.main,
    padding: "48px 24px",
  },
  control: {
    ...theme.typography.h5,
    width: '100%',
  },
  divider: {
    background: '#FFF',
    margin: '5px 0',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '5px',
    cursor: "pointer",
  },
  navigationLink: {
    display: "inline-block",
    "&:hover": {
      color: theme.palette.primaryLight
    }
  },
  inline:{
    display: "inline"
  },
  navigationContainer: {
    transition: "max-height .25s ease-out",
    overflow: "hidden",
  },
  navigationMenu: {
    ...theme.typography.h6,
    overflow: 'hidden',
    marginTop: "16px",
    padding: "16px 16px",
    border: "1px solid #67AAC3",
    borderRadius: "5px",
  },
  anchorList: {
    display: "flex",
    flexDirection: "column",
    margin: "32px 5px 0px 5px",
  },
  anchorItem: {
    ...theme.typography.h6,
    lineHeight: 3,
    "&:hover": {
      color: theme.palette.primaryLight
    }
  },
  selected: {
    ...theme.typography.tableHeadCell,
    color: theme.palette.primaryHeader
  },
  underline: {
    textDecoration: "underline",
    display: "inline",
  },
  groupTitle: {
    fontWeight: 600,
    fontSize: 16,
    cursor: "pointer",
    display: "block",
  },
  group: {
    marginLeft: 12,
    transition: "max-height .15s ease-out",
    overflow: "hidden",
  },
  collapsed: {
    maxHeight: 0,
  },
  expanded: {
    maxHeight: "unset",
  },
  btnControl: {
    textAlign: "left",
    display: "inline-block",
    width: "12px",
    "&:hover": {
      color: theme.palette.primaryLight,
    }
  },
  hidden: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  }
}));
