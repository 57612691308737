import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  iconButtons: {
    color: theme.palette.primaryLight,
    border: `1px solid ${theme.palette.primaryLight}`,
    borderRadius: "5px",
    padding: "2px"
  }
}));
