
import { useState, useEffect } from "react";
import queryString from 'query-string';
import { useLocation, useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { authHelper } from "helpers";
import Loading from "Common/Loading";
import { setNotification } from "redux/slice/notificationSlice";
import LoginContainer from "Login/LoginContainer";
import { useStyles } from "../style";

export default function Verification() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation()
  const [isLoading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const values = queryString.parse(search);
    if (!values.token) {
      history.push('/login')
    }
    verifyUser(values.token);
  }, [])

  const [message, setMessage] = useState('');

  const verifyUser = async (token) => {
    try {
      const result = await authHelper.userActivate({ token });
      if (result.error) {
        if (result.error) throw new Error(result.error);
      }
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (e) {
      if (!!e.message) setMessage(e.message);
      setLoading(false);
      console.log(e);
    }
  };
  if (isLoading) return <LoginContainer><Loading isLoading={isLoading} /></LoginContainer>

  return (
    <LoginContainer>
      <div className={classes.root}>
        <div className={classes.title}>Verification</div>
        {success
          ?
          <Grid className={`${classes.row}`}>
            Success! Redirecting to <a href="/login" style={{ fontWeight: 600, textDecoration: "underline" }}>Login Page</a> to login.
          </Grid>
          : <Grid className={`${classes.row} ${classes.error}`}>
            {message ?? "Fail to activate the account."}
          </Grid>
        }
      </div>
    </LoginContainer>
  );
}
