import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: "32px",
  },
  tableContainer: {
    overflowX: "auto",
  },
}));
