import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectContractData } from 'redux/slice/contractSlice';

import SectionTitle from "Common/SectionTitle";
import LabourCompliance from 'Labour/LabourCompliance';
import { useStyles } from "./style";

export default function LabourDashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const contractData = useSelector(selectContractData);
  return (
    <div className={classes.root}>
      <SectionTitle title={"Labour Return Dashboard"} />
      <LabourCompliance data={contractData}/>
    </div>
  );
};

LabourDashboard.propTypes = {
  data: PropTypes.any,
}