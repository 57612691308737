import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import CommonContainer from 'EDMS/CommonContainer';
import TabPanel from 'Common/TabPanel';
import LinkTabs from 'Common/LinkTabs';
import LabourDashboard from './LabourDashboard';
import LabourForm from './LabourForm';
import { useStyles } from "./style";
import { tabData } from './config';

export default function Labour() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const [activeTab, setActiveTab] = useState(searchParam === "docs" ? 1 : 0);

  useEffect(() => {
    try {
      setActiveTab(searchParam === "docs" ? 1 : 0);
    } catch (e) {
      console.log(e);
    }
  }, [searchParam]);

  const returnContent = () => {
    switch (searchParam) {
      case "docs":
        return <LabourForm />;
      default:
        return <LabourDashboard />;
    }
  }

  return (
    <CommonContainer className={classes.root}>
      <div className={classes.body}>
        <LinkTabs data={tabData} activeTab={activeTab} />
        {tabData.map((item, index) =>
          <TabPanel key={item.id} value={activeTab} index={index}>
            {returnContent()}
          </TabPanel>
        )}
    </div>
    </CommonContainer>
  );
}