import { IconButton, useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { setLoading } from "redux/slice/loadingSlice";
import { documentHelper } from "helpers";
import ViewFile from 'static/icons/viewFile.svg';
import { useStyles } from "./style";


export default function PreviewComponent({ id }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);

  async function openDocInBlankPage(docId) {
    try {
      dispatch(setLoading(true));
      const blob = await documentHelper.decryptDocument(docId)

      var url = URL.createObjectURL(blob);
      window.open(url, '_blank', 'noopener noreferrer');
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      alert('File not found');
      dispatch(setLoading(false));
    }
  }

  return (
    <IconButton onClick={() => openDocInBlankPage(id)}>
      <div target="_blank"
        rel="noreferrer noopener"
        className={classes.a}>
        <img
          className={classes.img}
          src={ViewFile}
          alt={"document"}
        />
      </div>
    </IconButton>
  );
}