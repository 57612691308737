import React from "react";
import PropTypes from 'prop-types';

import { AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStyles } from "./style";

function AccordionHeader({
  children
}) {
  const classes = useStyles();
  return(
    <AccordionSummary 
      className={classes.root}
      classes={{
        content: classes.summaryContent
      }}
      IconButtonProps={{
        classes: {
          root: classes.ExpandButton
        }
      }}
      expandIcon={<ExpandMoreIcon className={classes.ExpandIcon}/>}
    >
      {children}
    </AccordionSummary>
  );
}

AccordionHeader.propTypes = {
  children: PropTypes.element.isRequired
};


export default AccordionHeader;
