import { TableRow, TableCell, TableBody } from '@material-ui/core';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';

import fieldHelper from 'utils/fieldHelper';

import { yesNoOptions, agreeOptions, awardOptions } from './config';
import { useStyles } from "../style";

export default function SafetyReportContent({ data, maxSection, register, control, errors, reportStatus, permission }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  function renderRow() {
    let rows = [];
    let rowPointer = 1;
    let dataRowPointer = 0;
    let agreePointer = 0;
    let isRenderAgree = false;
    for (let i = 0; i < maxSection; i++) {
      let isFirstRow = true;
      for (let j = dataRowPointer; j < data.length; j++) {
        if (Number(data[j].agreeRow) > agreePointer) {
          isRenderAgree = true;
          agreePointer += 1;
        }
        let cTarget = data[j];
        if (Number(cTarget.section) > rowPointer) break;
        rows.push(
          <TableRow key={j} className={clsx(isFirstRow && classes.firstSection, j === data.length - 1 && classes.lastRow)}>
            <TableCell className={classes.firstColumn}>
              <div key={j} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <div style={{ width: "5%", alignSelf: i === 0 ? "center" : "flex-start", }}>{isFirstRow && i + 1.}</div>
                <div style={{ width: "47.5%", }} dangerouslySetInnerHTML={{ __html: cTarget.question }}></div>
                <div style={{ width: "47.5%", display: "flex", alignItems: "flex-end" }} id={j}>
                  {fieldHelper.renderField({
                    hasInputValue: true,
                    label: "", name: `report.[${j}].result`, type: cTarget.answerType ?? "placeholder",
                    hideLabel: true, align: "flex-end", options: cTarget.answerType === "award" ? awardOptions : yesNoOptions, defaultEmpty: true,
                    disabled: !!reportStatus && reportStatus !== "Rejected", format: "DD/MM/yyyy"
                  },
                    j + 20, register, control, errors, { size: 12, })
                  }
                  {cTarget.answerType === "nr" && "(nr)"}
                </div>
              </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {isRenderAgree &&
                fieldHelper.renderField({
                  label: "This Field", name: `verifiedAgree.[${agreePointer - 1}]`, type: "boolean",
                  hideLabel: true, options: agreeOptions, defaultEmpty: true,
                  disabled: !permission || reportStatus !== "Endorsed", isRequired: reportStatus === "Endorsed" && true
                },
                  i, register, control, errors, { size: 12, }
                )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {isRenderAgree &&
                fieldHelper.renderField({
                  label: "This Field", name: `counterVerifiedAgree.[${agreePointer - 1}]`, type: "boolean",
                  hideLabel: true, options: agreeOptions, defaultEmpty: true,
                  disabled: !permission || reportStatus !== "Supervisor verified", isRequired: reportStatus === "Supervisor verified" && true
                },
                  i + 10, register, control, errors, { size: 12, }
                )}
            </TableCell>
          </TableRow>
        );
        isFirstRow = false;
        isRenderAgree = false;
        dataRowPointer += 1;
      }
      rowPointer += 1;
    }

    return <>{rows}</>;
  }

  return (
    <TableBody>
      {renderRow()}
    </TableBody >
  );
}