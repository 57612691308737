export const dailyAvgData = {
  keys: [
    'Site Labour Daily Average', 
  ],
  result: [
    {
      "quarter": "2020Q1",
      "Site Labour Daily Average": 222.4,
    },
    {
      "quarter": "2020Q2",
      "Site Labour Daily Average": 200.65,
    },
    {
      "quarter": "2020Q3",
      "Site Labour Daily Average": 240.65,
    },
    {
      "quarter": "2020Q4",
      "Site Labour Daily Average": 230.07,
    },
    {
      "quarter": "2021Q1",
      "Site Labour Daily Average": 300.25,
    },
    {
      "quarter": "2021Q2",
      "Site Labour Daily Average": 250,
    },
    {
      "quarter": "2021Q3",
      "Site Labour Daily Average": 270.5,
    },
    {
      "quarter": "2021Q4",
      "Site Labour Daily Average": 0,
    },
  ]
}

export const amListData = [
  {
    weather: "Fine",
    days: 7
  },
  {
    weather: "Rain",
    days: 4
  },
  {
    weather: "Cloudy",
    days: 3
  }
]

export const pmListData = [
  {
    weather: "Fine",
    days: 5
  },
  {
    weather: "Rain",
    days: 4
  },
  {
    weather: "Drizzle",
    days: 2
  },
  {
    weather: "Cloudy",
    days: 2
  },
  {
    weather: "Shower",
    days: 1
  },
]


export const amPieData = [
  {
    date: "2020-02-05",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2020-06-07",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2020-08-07",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2020-10-05",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2021-02-01",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 5
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 2
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2021-05-01",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 2
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 1
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 4
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
]

export const pmPieData = [
  {
    date: "2020-02-05",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2020-06-07",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2020-08-07",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2020-10-05",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 0
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 0
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2021-02-01",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 3
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 2
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 0
      },
      {
        id: "rain",
        label: "Rain",
        value: 2
      },
      {
        id: "shower",
        label: "Shower",
        value: 0
      },
    ],
  },
  {
    date: "2021-05-01",
    value: [
      {
        id: "fine",
        label: "Fine",
        value: 2
      },
      {
        id: "cloudy",
        label: "Cloudy",
        value: 0
      },
      {
        id: "drizzle",
        label: "Drizzle",
        value: 2
      },
      {
        id: "rain",
        label: "Rain",
        value: 2
      },
      {
        id: "shower",
        label: "Shower",
        value: 1
      },
    ],
  },
]

export const distributionData = [
  {
    date: "2020-02-01",
    value: [{
      id: "projectManagerDelegate_pmd",
      label: "PMD",
      value: 6
    },
    {
      id: "contractorRep",
      label: "Contractor Rep",
      value: 8
    },
    {
      id: "supervisor",
      label: "Supervisor",
      value: 10
    }]
  },
  {
    date: "2020-05-01",
    value: [{
      id: "projectManagerDelegate_pmd",
      label: "PMD",
      value: 11
    },
    {
      id: "contractorRep",
      label: "Contractor Rep",
      value: 5
    },
    {
      id: "supervisor",
      label: "Supervisor",
      value: 10
    }]
  },
  {
    date: "2020-08-01",
    value: [{
      id: "projectManagerDelegate_pmd",
      label: "PMD",
      value: 13
    },
    {
      id: "contractorRep",
      label: "Contractor Rep",
      value: 5
    },
    {
      id: "supervisor",
      label: "Supervisor",
      value: 15
    }]
  },
  {
    date: "2020-11-01",
    value: [{
      id: "projectManagerDelegate_pmd",
      label: "PMD",
      value: 14
    },
    {
      id: "contractorRep",
      label: "Contractor Rep",
      value: 15
    },
    {
      id: "supervisor",
      label: "Supervisor",
      value: 10
    }]
  },
  {
    date: "2021-02-01",
    value: [{
      id: "projectManagerDelegate_pmd",
      label: "PMD",
      value: 12
    },
    {
      id: "contractorRep",
      label: "Contractor Rep",
      value: 1.
    },
    {
      id: "supervisor",
      label: "Supervisor",
      value: 4
    }]
  },
  {
    date: "2021-05-01",
    value: [{
      id: "projectManagerDelegate_pmd",
      label: "PMD",
      value: 15
    },
    {
      id: "contractorRep",
      label: "Contractor Rep",
      value: 1
    },
    {
      id: "supervisor",
      label: "Supervisor",
      value: 5
    }]
  },
]

export const statusData = [
  {
    date: "2020-02-01",
    "quarter": "2020Q1",
    "Completed": 14,
    "Incompleted": 12,
    "Outstanding": 12,
  },
  {
    date: "2020-05-01",
    "quarter": "2020Q2",
    "Completed": 12,
    "Incompleted": 22,
    "Outstanding": 15,
  },
  {
    date: "2020-08-01",
    "quarter": "2020Q3",
    "Completed": 12,
    "Incompleted": 3,
    "Outstanding": 7,
  },
  {
    date: "2020-11-01",
    "quarter": "2020Q4",
    "Completed": 7,
    "Incompleted": 8,
    "Outstanding": 11,
  },
  {
    date: "2021-02-01",
    "quarter": "2021Q1",
    "Completed": 30,
    "Incompleted": 10,
    "Outstanding": 5,
  },
  {
    date: "2021-05-01",
    "quarter": "2021Q2",
    "Completed": 18,
    "Incompleted": 20,
    "Outstanding": 10,
  },
  {
    date: "2021-08-01",
    "quarter": "2021Q3",
    "Completed": 0,
    "Incompleted": 2,
    "Outstanding": 2,
  },
  {
    date: "2021-11-01",
    "quarter": "2021Q4",
    "Completed": 0,
    "Incompleted": 0,
    "Outstanding": 0,
  }
]

export const overdueData = [
  {
    date: "2020-02-01",
    "quarter": "2020Q1",
    "DC/2019/06": 6,
    "DC/2019/07": 3,
    "DE/2019/03": 2,
    "DE/2019/04": 3,
  },
  {
    date: "2020-05-01",
    "quarter": "2020Q2",
    "DC/2019/06": 7,
    "DC/2019/07": 6,
    "DE/2019/03": 5,
    "DE/2019/04": 3,
  },
  {
    date: "2020-08-01",
    "quarter": "2020Q3",
    "DC/2019/06": 5,
    "DC/2019/07": 6,
    "DE/2019/03": 2,
    "DE/2019/04": 2,
  },
  {
    date: "2020-11-01",
    "quarter": "2020Q4",
    "DC/2019/06": 5,
    "DC/2019/07": 3,
    "DE/2019/03": 2,
    "DE/2019/04": 3,
  },
  {
    date: "2021-02-01",
    "quarter": "2021Q1",
    "DC/2019/06": 6,
    "DC/2019/07": 3,
    "DE/2019/03": 2,
    "DE/2019/04": 3,
  },
  {
    date: "2021-05-01",
    "quarter": "2021Q2",
    "DC/2019/06": 7,
    "DC/2019/07": 6,
    "DE/2019/03": 3,
    "DE/2019/04": 3,
  },
  {
    date: "2021-08-01",
    "quarter": "2021Q3",
    "DC/2019/06": 0,
    "DC/2019/07": 0,
    "DE/2019/03": 0,
    "DE/2019/04": 0,
  },
  {
    date: "2021-11-01",
    "quarter": "2021Q4",
    "DC/2019/06": 0,
    "DC/2019/07": 0,
    "DE/2019/03": 0,
    "DE/2019/04": 0,
  }
]

export const labourData = [
  {
    date: "2021-06-01",
    value: 97,
  },
  {
    date: "2021-06-02",
    value: 50,
  },
  {
    date: "2021-06-03",
    value: 58,
  },
  {
    date: "2021-06-04",
    value: 88,
  },
  {
    date: "2021-06-05",
    value: 95,
  },
  {
    date: "2021-06-06",
    value: 107,
  },
  {
    date: "2021-06-07",
    value: 102,
  },
];

export const equipmentData = [
  {
    date: "2021-06-01",
    value: 21,
  },
  {
    date: "2021-06-02",
    value: 92,
  },
  {
    date: "2021-06-03",
    value: 23,
  },
  {
    date: "2021-06-04",
    value: 24,
  },
  {
    date: "2021-06-05",
    value: 24,
  },
  {
    date: "2021-06-06",
    value: 28,
  },
  {
    date: "2021-06-07",
    value: 22,
  },
];

export const pieData = [
  {
    id: 'Completed',
    label: "Completed",
    value: 0
  },
  {
    id: 'inprogress',
    label: "In-progress",
    value: 0
  },
];