export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    link: "dashboard"
  },
  // {
  //   label: "Early Warning List",
  //   key: 1,
  //   link: "early-warning"
  // },
  {
    label: "Risk Register List",
    key: 1,
    link: "docs"
  },
  {
    label: "Risk Reduction Meeting",
    key: 2,
    link: "meeting"
  },
];

export const earlyWarningFieldConfig = [
  {
    label: "Contractor Reference Number",
    name: "contractorReferenceNumber",
    isRequired: true,
  },
  {
    label: "Date of Issue / Receipt of EW",
    name: "dateOfIssue",
    type: "Date",
    isRequired: true,
  },
  {
    label: "Risk ID Ref.",
    name: "riskIdReference",
    isRequired: true,
    mode: "Assign"
  },
  {
    label: "Subject / Description of Risk",
    name: "subject",
    size: 12,
    isRequired: true,
  },
  {
    label: "Condition of Contract Clause(s)",
    name: "conditionsOfContractClause",
    type: "CheckboxGroup",
    // isRequired: true,
    options: [
      {
        label: "Increase the total of the Prices",
        name: "conditionsOfContractClause.increaseTheTotalOfThePrices",
      },
      {
        label: "Delay Completion",
        name: "conditionsOfContractClause.delayCompletion",
      },
      {
        label: "Delay meeting a Key Date",
        name: "conditionsOfContractClause.delayMeetingAKeyDate",
      },
      {
        label: "Impair the performance",
        name: "conditionsOfContractClause.impairThePerformance",
      },
    ]
  },
  {
    label: "Require for Change",
    name: "requestForChange",
    type: "Radio",
    options: [
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      }
    ],
    defaultEmpty: true,
    isRequired: true,
  },
  {
    label: "Early Warning Meeting Required",
    name: "meetingRequired",
    type: "Radio",
    options: [
      {
        label: "Yes",
        value: "1",
      },
      {
        label: "No",
        value: "0",
      }
    ],
    defaultEmpty: true,
    isRequired: true,
  },
  {
    label: "Urgency",
    name: "urgency",
  },
  {
    label: "Nature of Change",
    name: "natureOfChange",
  },
  {
    label: "Type of Change",
    name: "typeOfChange",
  },
  {
    label: "Matter Giving Rise To Notification",
    name: "matterGivingRiseToNotification",
    rows: 4,
    size: 12,
  },
];
