import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import ContractBox from '../ContractBox';
import { useStyles } from "./style";
import React from 'react';

export default function ContractList({ data = [] }) {
  const theme = useTheme();
  const props = { theme }
  const classes = useStyles(props);
  const [sorted, setSorted] = useState([])

  useEffect(() => {
    let result = sortBy(data.filter(item => item.contractNo !== null), 'asc');
    setSorted(result);
  }, [data]);

  function sortBy(array, ordering) {
    try {
      let a = array.sort(function (a, b) {

        var x = a.contractNo.split('/');
        var y = b.contractNo.split('/');

        if (ordering === 'asc') return ((x[2] < y[2]) ? -1 : ((x[2] > y[2]) ? 1 : 0));
        else return ((x[2] < y[2]) ? -1 : ((x[2] < y[2]) ? 1 : 0));
      });
      return a;
    } catch (e) {
      console.log(e);
    }
  }
  
  return (
    <div className={classes.contractList}>
      {sorted.length > 0
        ? <Grid container spacing={2} className={classes.listGroup}>
          {sorted.map((item, i) =>
            <Grid item xs={12} sm={6} md={3} lg={3} key={i}>
              <ContractBox data={item} />
            </Grid>
          )}
        </Grid>
        : <div>No Contracts Available</div>
      }
    </div>
  );
}

ContractList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};