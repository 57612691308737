import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: 16
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  placeholder: {
    padding: "0 !important",
  },
  tableCell: {
    border: "1px solid #fff",
    whiteSpace: "pre-line",
  },
  // headerCell: {
  //   border: "1px solid #fff",
  //   whiteSpace: "pre-line",
  //   fontWeight: 600,
  // },
  headerCell: {
    color: theme.palette.primaryPurple,
    ...theme.typography.tableHeadCell,
  },
  confirmContainer: {
    padding: 8,
    textAlign: "center"
  }
}));