import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    type: null,
    message: "",
  },
  reducers: {
    setNotification: (state, action) => {
      const { type, message } = action.payload
			state.type = type;
			state.message = message;
    },
  }
});

export const { setNotification } = notificationSlice.actions;

export const selectNotification = (state => state.notification);

export default notificationSlice.reducer;