import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    padding: 36,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%"
  },
  gridContainer: {
    marginTop: 36
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "24px 18px",
    justifyContent: "center",
    alignItems: "center"
  },
  codeLabel: {
    width: 20,
    height: 20,
    background: "white",
    borderRadius: 4,
    color: "black",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 2,
  },
  popoverContainer: {
    padding: 12,
  }
}));