import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from 'prop-types';

import { useStyles } from "./style";

export default function DateRangeSelect({ handleChange, value, id }) {
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <span>
            {<DatePicker
              autoOk
              value={value}
              format="yyyy-MM-DD"
              className={classes.datePicker}
              disableToolbar
              variant="inline"
              onChange={date => handleChange(date, id)}
            />
            }
          </span>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}

DateRangeSelect.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  handleOnChange: PropTypes.func,
};