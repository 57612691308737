export const tabData = [
  {
    label: "DWSS",
    id: "dwss",
  },
  {
    label: "EDMS",
    id: "edms",
  },
  {
    label: "EW & Risk",
    id: "risk",
  },
  {
    label: "NCE",
    id: "incident",
  },
  {
    label: "Safety Performance",
    id: "safety",
  },
  {
    label: "Site Progress Photo",
    id: "photo",
  },
  {
    label: "BIM",
    id: "bim"
  }
]

export const dwssTabs = [
  {
    label: "RISC",
    id: "risc",
    key: "noOfRisc"
  },
  {
    label: "Site Diary",
    id: "site diary",
    key: "noOfSiteDiary",
  },
  {
    label: "Safety Inspection",
    id: "ssr",
    key: "noOfSafety",
  },
  {
    label: "Cleansing Inspection",
    id: "cic",
    key: "noOfCleansing",
  },
  {
    label: "Labour Return",
    id: "lrr",
    key: "noOfLabour",
  },
];

export const edmsTabs = [
  {
    label: "Audit",
    id: "Audit",
    parent: 'EDMS',
  },
  {
    label: "CE",
    id: "CE",
    parent: 'EDMS',
  },
  {
    label: "CP",
    id: "CP",
    parent: 'EDMS',
  },
  {
    label: "CSF",
    id: "CSF",
    parent: 'EDMS',
  },
  {
    label: "EWN",
    id: "EWN",
    parent: 'EDMS',
  },
  {
    label: "MSF",
    id: "MSF",
    parent: 'EDMS',
  },
  {
    label: "PMI",
    id: "PMI",
    parent: 'EDMS',
  },
  {
    label: "Quotation",
    id: "Quotation",
    parent: 'EDMS',
  },
  {
    label: "RFI",
    id: "RFI",
    parent: 'EDMS',
  },
  {
    label: "RR",
    id: "RR",
    parent: 'EDMS',
  },
  {
    label: "Letter",
    id: "Letter",
    parent: 'EDMS',
  }
];

export const riskTabs = [
  {
    label: "Risk Reduction Meeting",
    id: "risk-meeting",
  },
  {
    label: "Risk Register",
    id: "risk-register",
  },
];


export const incidentTabs = [
  {
    label: "Incident",
    id: "incident",
  },
  {
    label: "NCE",
    id: "nce",
  },
  {
    label: "Quotation",
    id: "Quotation",
  },
  {
    label: "SI",
    id: "si",
  },
  {
    label: "Supporting",
    id: "supporting",
  },
];

export const pmiTabs = [
  {
    label: "PMI",
    id: "PMI",
  },
  {
    label: "CE",
    id: "CE",
  },
  {
    label: "Quotation",
    id: "Quotation",
  },
];

export const photoTabs = [
  {
    label: "Site Progress Photo",
    id: "photo",
    id: "total_photos",
  },
];

export const bimTabs = [
  {
    label: "Managed",
    id: "managedCount",
  },
  {
    label: "Published",
    id: "publishedCount",
  },
];


export const safetyTabs = [
  {
    label: "Safety Performance",
    id: "safety",
  },
]
