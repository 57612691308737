import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';

import ChartHeader from 'Common/ChartHeader';
import { dashboardHelper } from 'helpers';
import RISCInfoBox from "RISC/RISCInfoBox";

import DocTypeSummaryPieChart from './docTypeSummaryPieChart';
import DocTypeSummaryList from './docTypeSummaryList';
import { useStyles } from "./style";

export default function EDMSDashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { contractId } = useParams();
  const [data, setData] = useState({});
  const [pieData, setPieData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));

  useEffect(() => {
    initDate();
  }, [])

  useEffect(() => {
    if (data.oldestYear) getChartData();
  }, [data, startDate, endDate]);

  async function initDate() {
    try {
      getCountDocuments();
    } catch (e) {
      console.log(e)
    }
  }

  async function getCountDocuments() {
    const result = await dashboardHelper.countEdmsDashboard({ contractId });
    setData(result);
  }

  async function getChartData() {
    const start = startDate.clone().format("YYYY-MM-DD");
    const end = endDate.clone().format("YYYY-MM-DD");
    const chartData = await dashboardHelper.edmsPieChartDashboard({ contractId, startDate: start, endDate: end });
    setPieData(chartData);
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"Total Document"} value={data.total ?? 0} withDate={false} />
        </Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>

        {data?.oldestYear &&
          <>
            <Grid item xs={12} md={12}>
              <div className={classes.bottomRightWrapper}>
                <ChartHeader
                  // min={data?.oldestYear && Number(data?.oldestYear)}
                  title={''}
                  filterType={"quarterRange"}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <DocTypeSummaryPieChart startDate={startDate} endDate={endDate} hack hackData={pieData} />
            </Grid>
            <Grid item xs={12} md={8}>
              <DocTypeSummaryList startDate={startDate} endDate={endDate} hack hackData={pieData} />
            </Grid>
          </>
        }
      </Grid>
    </div>
  );
};
