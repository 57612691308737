import PropTypes from "prop-types";
import DocumentTable from "Document/DocumentTable";

function SelectionConfirmTable({ data, photos, fileMetaData, riskData, handleSelect }) {
  return (
    <div style={{ marginBottom: "16px" }}>
      {data.length > 0 && <DocumentTable data={data} tableType={"relatedDoc"} enableDelete handleSelect={handleSelect} />}
      {photos.length > 0 && <DocumentTable data={photos} tableType={"photo"} enableDelete handleSelect={handleSelect} />}
      {fileMetaData.length > 0 && <DocumentTable data={fileMetaData} tableType={"edms"} enableDelete handleSelect={handleSelect} />}
      {riskData.length > 0 && <DocumentTable data={riskData} tableType={"risk-register"} enableDelete handleSelect={handleSelect} hidePdfBtn />}
    </div>
  );
}

SelectionConfirmTable.propTypes = {
  data: PropTypes.array,
};

export default SelectionConfirmTable;
