import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import EllipseButton from "Common/EllipseButton";
import DocumentHeader from 'Document/DocumentHeader';
import { setLoading } from 'redux/slice/loadingSlice';

import RiskMeetingHeaderForm from './riskMeetingHeaderForm';
import { useStyles } from "./style";

export default function RiskMeetingHeader({ data, allowEdit, contract, reload }) {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 500);
    if (allowEdit) reload();
  }, [isEdit]);

  return (
    <div>
      {isEdit
        ? <RiskMeetingHeaderForm setIsEdit={setIsEdit} data={data} />
        :
        <>
          {
            allowEdit && <div style={{ textAlign: "end", marginBottom: 8 }}>
              <EllipseButton
                label={"Edit"}
                type="button"
                backgroundColor="transparent"
                onClick={() => setIsEdit(!isEdit)}
              />
            </div>
          }
          <DocumentHeader data={data} type={"risk-meeting"} contractData={contract} padding="0px 0px 20px 0" />
        </>
      }
    </div>
  );
}