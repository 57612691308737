import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useForm, useFieldArray } from "react-hook-form";
import EllipseButton from "Common/EllipseButton";

import InfoItem from 'Common/InfoItem';
import DocumentHeader from 'Document/DocumentHeader';
import { setLoading } from 'redux/slice/loadingSlice';
import SectionTitle from 'Common/SectionTitle';
import { dummyData, riskDummyData } from 'EDMS/CommonDocumentList/config';

import RiskRegisterHeaderForm from './riskRegisterHeaderForm';
import { useStyles } from "./style";

export default function RiskRegisterHeader({ data, allowEdit, contract, reload }) {
  const dispatch = useDispatch();
  const { type } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 500);
  }, [isEdit]);

  function handleEdit() {
    if(isEdit) {
      setIsEdit(false);
      reload();
    } else {
      setIsEdit(true);
    }
  }

  return (
    <div>
      {isEdit
        ? <RiskRegisterHeaderForm setIsEdit={handleEdit} data={data} />
        :
        <>
          {
            allowEdit && <div style={{ textAlign: "end", marginBottom: 8 }}>
              <EllipseButton
                label={"Edit"}
                type="button"
                backgroundColor="transparent"
                onClick={handleEdit}
              />
            </div>
          }
          <DocumentHeader data={data} type={type} contractData={contract} padding="0px 0px 20px 0" />
          <div className={classes.infoHeader}>
            <InfoItem title={"Description"} content={data.description ?? "-"} flexDirection="column" />
            <InfoItem title={"Potential Problem(s) and the Risk(s)"} content={data.potentialRisks ?? "-"} flexDirection="column" />
          </div>
        </>
      }
    </div>
  );
}