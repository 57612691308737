import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "80px"
  },
  buttonArea: {
    marginTop: "50px"
  }
}));
