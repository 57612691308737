import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';

import dateHelper from "utils/dateHelper";
import Select from 'Common/Select';
import { useStyles } from './style';
import { quarterList } from './config';


function ChartHeader({ title, padding = "16px", filterType, setStartDate, setEndDate }) {
  const theme = useTheme();
  const props = { theme, padding };
  const classes = useStyles(props);
  const minYear = 2019;
  const maxYear = new Date().getFullYear();
  const yearList = createYearList();
  const [year, setYear] = useState([yearList[yearList.length - 1].value, yearList[yearList.length - 1].value]);
  const [quarter, setquarter] = useState([quarterList[0].value, quarterList[quarterList.length - 1].value]);

  function createYearList() {
    var list = [];
    for (var i = minYear; i <= maxYear; i++) {
      list.push({ label: i, value: i });
    }
    return list;
  }

  useEffect(() => {
    if (setStartDate) {
      let startQuarterRange = dateHelper.getQuarterRange(quarter[0]);
      let startDate = startQuarterRange.start.set("year", year[0]);
      setStartDate(startDate);
      if (filterType === "quarterRange") {
        let endQuarterRange = dateHelper.getQuarterRange(quarter[1]);
        let endDate = endQuarterRange.end.set("year", year[1]);
        setEndDate(endDate);
      }
    }

  }, [year, quarter])

  function yearOnChange(e, idx) {
    let newYr = [...year];
    newYr[idx] = e.target.value;
    setYear(newYr);
  }

  function quarterOnChange(e, idx) {
    let newQ = [...quarter];
    newQ[idx] = e.target.value;
    setquarter(newQ);
  }


  function renderFilter(type) {
    switch (type) {
      case "quarterRange":
        return (
          <div className={classes.chartFilter}>
            <div style={{ display: "flex", flexDirection: "row", marginRight: "8px" }}>
              <Select dense options={yearList} value={year[0]} label={"From"} handleChange={(e) => yearOnChange(e, 0)}></Select>
              <Select dense options={quarterList} value={quarter[0]} label={<span style={{ visibility: "hidden" }}>quarter</span>} handleChange={(e) => quarterOnChange(e, 0)}></Select>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginRight: "8px" }}>
              <Select dense options={yearList} value={year[1]} label={"To"} handleChange={(e) => yearOnChange(e, 1)}></Select>
              <Select dense options={quarterList} value={quarter[1]} label={<span style={{ visibility: "hidden" }}>quarter</span>} handleChange={(e) => quarterOnChange(e, 1)}></Select>
            </div>
          </div>
        );
      case "none":
        return <></>;
      default:
        return (
          <div className={classes.chartFilter}>
            <Select dense options={yearList} value={year[0]} label={"Year"} handleChange={(e) => yearOnChange(e, 0)}></Select>
            <Select dense options={quarterList} value={quarter[0]} label={"Quarter"} handleChange={(e) => quarterOnChange(e, 0)}></Select>
          </div>
        );
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div ><div className={classes.title}>{title}</div></div>
        <div>{renderFilter(filterType)}</div>
      </div>
    </div>
  );
}

ChartHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  extraComponents: PropTypes.node,
  padding: PropTypes.string,
};

export default ChartHeader;
