import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { useStyles } from "./style";

export default function BoxItem({ onHover = true, padding = "30px 20px", margin = "0", children, width, onClick, isSelected, background }) {
  const theme = useTheme();
  const themeProps = { theme, padding, margin, hover: !!onHover, width, isSelected: isSelected, background };
  const classes = useStyles(themeProps);

  return (
    <div className={classes.box} onClick={onClick}>
      {children}
    </div>
  );
}

BoxItem.propTypes = {
  onHover: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  padding: PropTypes.string,
  margin: PropTypes.string,
  children: PropTypes.any.isRequired,
  width: PropTypes.string,
};