import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API + "/api",
});

axiosInstance.interceptors.request.use(
  function (config) {
    if ((!!localStorage && localStorage.getItem("accessToken"))
      || (!!sessionStorage && sessionStorage.getItem("accessToken"))) {
      const token = localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");
      if (!!token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (response) => response,
  (error) => Promise.reject(error.response)
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.message === "jwt error: invalid signature") {
      if (localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
    }
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (error.message === "Network Error") {
      console.log(error.message, error.response)
      alert('Network error, please check your connection');
      return;
    }
    return Promise.reject(error)
  }
);

export default axiosInstance;
