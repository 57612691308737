import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
    height: "90%",
  },
  title: props => ({
    ...props.theme.typography.h6,
    color: props.theme.palette.secondary.main,
    margin: "8px 0 0 0"
  }),
  content: props => ({
    ...props.theme.typography.h1,
    color: props.theme.palette.secondary.main,
    margin: "16px 4px 0px 16px"
  }),
  datePicker: {
  }
}));
