import { useState, useEffect } from "react";
import { useParams } from "react-router";

import typeHelper from "utils/typeHelper";
import DocumentTable from "../DocumentTable";
import SectionTitle from "Common/SectionTitle";

import { documentHelper, edmsFileHelper } from "helpers";
import { useStyles } from "./style";

function DocumentHistoryLog({ data, docType }) {
  const classes = useStyles();
  const { type, contractId, docId } = useParams();
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    async function getHistoryLog() {
      try {
        const typeString = typeHelper.getCurrentType(type);
        const result = docType === "edms"
          ? await edmsFileHelper.getFileMetaHistory(docId)
          : await documentHelper.getDocumentHistory({ type: typeString, id: data.id, contractId });
        if (result.error) throw new Error('data not found');
        const extracted = typeHelper.extractDocumentObject(result, type)
        setHistoryData(extracted);
      } catch (e) {
        console.log(e)
        setHistoryData([])
      }
    }
    if (!!data.id) getHistoryLog();
  }, [data])

  return (
    <div className={classes.root}>
      <SectionTitle title={"History Log"} />
      {historyData.length > 0 && <DocumentTable data={historyData} isEDMS={docType === "edms" ? true : false} tableType={docType ?? "history"}/>}
      {historyData.length === 0 && <div className={classes.endlist}>End of list</div>}
    </div>
  );
}

DocumentHistoryLog.propTypes = {
};

export default DocumentHistoryLog;