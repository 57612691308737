import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Grid, Divider } from '@material-ui/core';
import moment from "moment";

import chartHelper from 'utils/chartHelper';
import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartHeader from 'Common/ChartHeader';
import InfoBox from 'RISC/RISCInfoBox';
import DailyAvgGraph from './dailyAvgGraph';
import AMWeatherPieChart from './amWeather';
import PMWeatherPieChart from './pmWeather';
import AMWeatherList from './amWeatherList';
import PMWeatherList from './pmWeatherList';
import { useStyles } from "./style";

export default function SiteDiaryCompliance({ data }) {
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));
  const [tableData, setTableData] = useState({
    am: [],
    pm: [],
  });
  const [pieData, setPieData] = useState({
    am: [],
    pm: [],
  })

  useEffect(() => {
    dispatch(setLoading(true));
    getDashboardData();
  }, [startDate, endDate]);

  async function getDashboardData() {
    try {
      const variable = {
        contractId,
        startDate: startDate.clone().format('YYYY-MM-DD'),
        endDate: endDate.clone().format('YYYY-MM-DD'),
      }
      const result = await Promise.all([
        dashboardHelper.getWeatherSummaryTable({ variable }),
        dashboardHelper.getWeatherSummaryPie({ variable }),
      ])
      setTableData({
        am: result[0].am,
        pm: result[0].pm
      });
      let coloredAM = chartHelper.setWeatherColorByKey(result[1].am, 'id');
      let coloredPM = chartHelper.setWeatherColorByKey(result[1].pm, 'id');
      if (!chartHelper.checkIsNotEmpty(coloredAM)) coloredAM = [];
      if (!chartHelper.checkIsNotEmpty(coloredPM)) coloredPM = [];
      setPieData({
        am: coloredAM,
        pm: coloredPM
      });
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={4}>
          <InfoBox title={"Total Site Diary"} value={data.noOfSiteDiary} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}>
          <ChartHeader
            title={''}
            filterType={"quarterRange"}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AMWeatherList tableData={tableData.am ?? []} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PMWeatherList tableData={tableData.pm ?? []} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AMWeatherPieChart pieData={pieData.am ?? []} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PMWeatherPieChart pieData={pieData.pm ?? []} />
        </Grid>
        <Grid item xs={12}>
          <DailyAvgGraph />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};