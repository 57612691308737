import axios from './apiHelper';

const edmsFileHelper = {
  getSubFolders: async ({ variable }) => {
    try {
      const result = await axios.get(`/files`, { params: variable });

      if (!result.data) {
        throw new Error(`Fail to get sub folder`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFolderFiles: async (fileId) => {
    try {
      const result = await axios.get(`/file/${fileId}`);

      if (!result.data) {
        throw new Error(`Fail to get folder files`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createFolderFile: async (variables) => {
    try {
      const result = await axios.post(`/file`, variables);

      if (!result.data) {
        throw new Error(`Fail to create folder file`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editFolderFile: async (id, variables) => {
    try {
      const result = await axios.post(`/file`, {
        id: Number(id),
        ...variables
      });

      if (!result.data) {
        throw new Error(`Fail to edit folder file`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  assignFileRemarks: async (variables) => {
    try {
      const result = await axios.post(`/file-label`, variables);

      if (!result.data) {
        throw new Error(`Fail to assign file remarks`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFileMeta: async (id) => {
    try {
      const result = await axios.get(`/file-meta/${id}`);

      if (!result.data) {
        throw new Error(`Fail to get file meta`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFileMetas: async (variable) => {
    try {
      const result = await axios.get(`/file-metas`, { params: variable });
      if (!result.data) {
        throw new Error(`Fail to get file metas`);
      }
      return result.data;
    } catch (e) {

      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
        if (e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRelatedDocFileMeta: async (id) => {
    try {
      const result = await axios.get(`/related-doc/file-meta/${id}`);

      if (!result.data) {
        throw new Error(`Fail to get related doc file meta`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFileMetaHistory: async (fileMetaId) => {
    try {
      const result = await axios.get(`/file-meta/${fileMetaId}/history`);

      if (!result.data) {
        throw new Error(`Fail to get file metas`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFileGroups: async (contractId) => {
    try {
      const result = await axios.get(`/file-categories`, { params: { contractId } });

      if (!result.data) {
        throw new Error(`Fail to get file group`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getFileGroupsName: async (contractId) => {
    try {
      const result = await axios.get(`/file-categories-name`, { params: { contractId } });

      if (!result.data) {
        throw new Error(`Fail to get file groups name`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createFileGroup: async (variables) => {
    try {
      const result = await axios.post(`/file-category`, variables);

      if (!result.data) {
        throw new Error(`Fail to create file group`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editFileGroup: async (id, variables) => {
    try {
      const result = await axios.patch(`/file-category/${id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to edit file group`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRemarksLabels: async (contractId) => {
    try {
      const result = await axios.get(`/labels`, { params: { contractId, orderBy: "id.asc" } });

      if (!result.data) {
        throw new Error(`Fail to get remarks`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createRemarksLabel: async (variables) => {
    try {
      const result = await axios.post(`/label`, variables);

      if (!result.data) {
        throw new Error(`Fail to create remark`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  editRemarksLabel: async (id, variables) => {
    try {
      const result = await axios.patch(`/label/${id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to edit remark`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  deleteRemarksLabel: async (id) => {
    try {
      const result = await axios.delete(`/label/${id}`);

      if (!result.data) {
        throw new Error(`Fail to delete remark`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  addRemarksToFile: async (variables) => {
    try {
      const result = await axios.post(`/file-label`, variables);

      if (!result.data) {
        throw new Error(`Fail to delete remark`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  uploadEDMSAttachment: async ({ id, variables }) => {
    try {
      let formData = new FormData();

      formData.append('description', variables.description);
      formData.append('isAttachment', '1');
      formData.append('file', variables.file);
      const result = await axios({
        method: 'post',
        url: `/edms/file-meta/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (!result.data) {
        throw new Error(`Fail to post the attachment`);
      }
      return result.data;
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  }
}

export default edmsFileHelper;