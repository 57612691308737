import { useStyles } from "./style";
import { useTheme } from '@material-ui/core/styles';

export default function LoginContainer({ children, constVar }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <a className={classes.logo} href={constVar?.homeUrl}>
        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="logo"/>
      </a>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          Welcome to {constVar?.displayName}
        </div>
      </div>
      {children}
    </div>
  );
}
