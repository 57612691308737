import { TextField, } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useStyles } from "./style";
export default function CustAutoComplete({
  label,
  field,
  options,
  defaultValue,
  hideLabel,
  isUppercase,
  multiple
}) {
  const classes = useStyles();

  function handleChange(e, newValue) {
    field.onChange(newValue);
  }
  function handleTextChange(e) {
    if (isUppercase) field.onChange(e.target.value.toUpperCase());
    else field.onChange(e.target.value);
  }
  function onClose(e) {
    if (typeof e.target.value === "string") field.onChange("");
  }
  return (
    <>
      {hideLabel ? null : <div className={classes.label}>{label}</div>}
      <Autocomplete
        multiple={multiple}
        defaultValue={defaultValue ?? undefined}
        options={options ?? []}
        disableClearable
        onClose={onClose}
        // autoSelect
        groupBy={(option) => option.type}
        getOptionLabel={(option) => option.label}
        onChange={(e, newValue) => handleChange(e, newValue)}
        onInput={(e) => handleTextChange(e)}
        renderInput={(params) =>
          <div style={{ display: "flex" }}>
            <TextField
              {...params}
              inputProps={
                {
                  ...params.inputProps,
                  style: isUppercase ? { textTransform: "uppercase" } : {}
                }
              }
            />
          </div>}
      />
    </>
  );
}