import ChipInput from "material-ui-chip-input";
import { useState } from "react";
import { useStyles } from "./style";

function CustChipInput({
  dense,
  label,
  field,
}) {
  const classes = useStyles();
  const [value, setValue] = useState([]);

  function handleChange(e) {
    field.onChange(e)
  }

  return (
    <>
      <div className={dense ? classes.denseLabel : classes.label}>{label}</div>
      <ChipInput
        onChange={(chips) => handleChange(chips)}
        className={classes.inputField}
      />
    </>
  );
}

export default CustChipInput;