export const attachmentFieldsData = [
  { id: "documentId", label: "ID" },
  { id: "filename", label: "File Name" },
  { id: "description", label: "Description" },
  { id: "createdBy", label: "Created by" },
  // { id: "submissionDatetime", label: "Created / Submitted Date", isDate: true },
  { id: "updatedAt", label: "Upload to Blockchain", isDate: true },
  { id: null, label: "" },
];

export const photoAttachmentFieldsData = [
  { id: null, label: "" },
  { id: "id", label: "ID" },
  { id: "filename", label: "File Name" },
  { id: "description", label: "Description" },
  { id: "isAttachment", label: "Attachment", isBool: true },
  { id: "createdBy", label: "Created by" },
  { id: "submissionDatetime", label: "Created / Submitted Date", isDate: true },
  { id: "updatedAt", label: "Upload to Blockchain Date", isDate: true },
  { id: null, label: "" },
]

export const photoFieldsData = [
  { id: null, label: "" },
  { id: "filename", label: "File Name" },
  { id: "takenDatetime", label: "Taken Date", isDate: true },
  { id: "takenBy", label: "Taken By", },
  {
    id: "type", label: "Purpose",
    render(item) {
      if (!item.type || item.type === "") return "-";
      if (item.type === "記錄用途") return "For Record";
      else if (item.type === "須跟進") return "For Attention";
      else return item.type;
    }
  },
  { id: null, label: "" },
  { id: null, label: "" }
];

export const historyFieldsData = [
  { id: "fileId", label: "File ID" },
  {
    id: "fileType", label: "Type",
    render(item) {
      if (!item.fileType && item.docType === "") return "-";
      return item.fileType || item.docType;
    }
  },
  { id: "fileStatus", label: "Status" },
  { id: "createdAt", label: "Upload to Blockchain Date", isDate: true },
  { id: null, label: "" },
  { id: null, label: "" }
];

export const fieldsData = [
  { id: "fileId", label: "File ID" },
  { id: "fileType", label: "Type" },
  { id: "fileStatus", label: "Status" },
  { id: "submissionDatetime", label: "Created / Submitted Date", isDate: true },
  { id: null, label: "" },
  { id: null, label: "" }
];

export const edmsFieldsData = [
  { id: "logNumber", label: "Bar Code / Log Number" },
  { id: "docType", label: "Type" },
  { id: "fileNumber", label: "Path" },
  { id: "createdAt", label: "Created / Submitted Date", isDate: true },
  { id: null, label: "" },
  { id: null, label: "" }
];

export const riskFieldsData = [
  { id: "riskIdReference", label: "Risk ID Ref" },
  { id: "issueDate", label: "Date of Issue / Receipt of EW", type: "Date" },
  { id: "status", label: "Status", render: (item) => {
    return item.status ?? item.riskRegisterStatus
  } },
  { id: "createdAt", label: "Created / Submitted Date", type: "Datetime" },
  { id: null, label: "" },
];
