import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgoround: theme.palette.primaryDark,
    position: 'relative',
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  lastRow: {
    padding: "4px",
    border: "none",
    fontWeight: 600
  }
}));
