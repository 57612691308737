import { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import moment from "moment";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
// import Icon from "@mdi/react";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  DateTimePicker,
  DatePicker
} from "@material-ui/pickers";

import { useStyles } from "./style";

function DatePickerUncontrol({
  label,
  type = "Date",
  field,
  format,
  views,
  hasInputValue,
  hideLabel,
  disabled,
}) {
  const theme = useTheme();
  const props = { theme }
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const handleChange = (input) => {
    if (format) {
      if (input) field.onChange(input.format(format));
      else field.onChange(input);
    }
    else field.onChange(input);
  };

  if (type === "Date") {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {!hideLabel && <div className={classes.label}>{label}</div>}
        <KeyboardDatePicker
          autoOk
          disabled={disabled}
          variant="inline"
          open={open}
          InputProps={{
            onClick: () => {
              if (!disabled) setOpen(true);
            },
          }}
          inputValue={hasInputValue && field.value}
          value={field.value ?? null}
          format={format ?? 'yyyy/MM/DD'}
          onClose={() => setOpen(false)}
          fullWidth
          onChange={handleChange}
          views={views}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (type === "Datetime")
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {!hideLabel && <div className={classes.label}>{label}</div>}
        <DateTimePicker
          disabled={disabled}
          autoOk
          variant="inline"
          open={open}
          InputProps={{
            onClick: () => {
              setOpen(true);
            },
          }}
          value={field.value ?? null}
          format="yyyy/MM/DD HH:mm"
          onClose={() => setOpen(false)}
          fullWidth
          onChange={handleChange}
        />
      </MuiPickersUtilsProvider>
    );
  else if (type === "Time")
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {!hideLabel && <div className={classes.label}>{label}</div>}
        <KeyboardTimePicker
          disabled={disabled}
          fullWidth
          ampm={false}
          variant="inline"
          value={field.value ? moment(field.value, 'HH:mm') : null}
          onChange={handleChange}
        />
      </MuiPickersUtilsProvider>
    );
  else if (type === "Year")
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {!hideLabel && <div className={classes.label}>{label}</div>}
        <KeyboardDatePicker
          views={["year"]}
          disabled={disabled}
          fullWidth
          autoOk
          variant="inline"
          format="yyyy"
          minDate={new Date('2000-01-01')}
          value={field.value ? moment(field.value, 'yyyy') : null}
          onClose={() => setOpen(false)}
          onChange={handleChange}
        />
      </MuiPickersUtilsProvider>
    );
}


DatePickerUncontrol.propTypes = {
  label: PropTypes.string,
  date: PropTypes.string,
  withIcon: PropTypes.bool
};

export default DatePickerUncontrol;