import fallback from 'static/fallback/notAvailable.png';

const photoHelper = {
  convertBase64(data) {
    console.log(data)
    const base64 = btoa(
      new Uint8Array(data).reduce(
        (dataArray, byte) => {
          return dataArray + String.fromCharCode(byte);
        },
        ''
      )
    );
    if (!base64) return fallback;
    else return `data:image/*;base64,${base64}`;
  },


}

export default photoHelper;