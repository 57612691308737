import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { edmsFileHelper } from 'helpers';
import EDMSFolderCreate from 'EDMS/EDMSFolderCreate';

import { useStyles } from "./style";

export default function EDMSFolderProperties() {
  const classes = useStyles();
  const { folderId } = useParams();
  const [folderInfo, setFolderInfo] = useState();
  useEffect(() => {
    init();
  }, [folderId]);

  async function init() {
    try {
      const result = await Promise.all([
        edmsFileHelper.getFolderFiles(folderId),
      ]);
      setFolderInfo(result[0]);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={classes.root}>
      {folderInfo && <EDMSFolderCreate mode="Edit" defaultData={folderInfo} />}
    </div>
  );
}