export const tabData = [
  {
    label: "Dashboard",
    key: 0,
    id: "dashboard",
    link: "dashboard"
  },
  {
    label: "Site Progress Photo",
    key: 1,
    id: "photo",
    link: "docs"
  },
]