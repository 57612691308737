import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  title: props => ({
    ...props.theme.typography.h6,
    color: props.theme.palette.secondary.main,
  }),
  content: props => ({
    ...props.theme.typography.h1,
    color: props.theme.palette.secondary.main,
  }),
}));
