import { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectContractData } from 'redux/slice/contractSlice';

import { setLoading } from 'redux/slice/loadingSlice';
import { photoHelper } from 'helpers';

import Modal from "Common/Modal";
import ConfirmButton from 'Common/ConfirmButton';
import EllipseButton from 'Common/EllipseButton';
import PhotoAdd from 'Photos/PhotoAdd';
import DocumentHeader from 'Document/DocumentHeader';
import DocumentHistoryLog from 'Document/DocumentHistoryLog';
import DocumentDecrypted from 'Photos/PhotoDecrypted';
import DocumentAttachment from "Document/DocumentAttachment";
import DocumentRelatedDocumentV2 from 'Document/DocumentRelatedDocumentV2';

import { useStyles } from "./style";

export default function PhotoDocument() {
  const contractData = useSelector(selectContractData);
  const dispatch = useDispatch();
  const { docId, contractId } = useParams();
  const [docType] = useState("photo");
  const [doc, setDoc] = useState({});
  const [decrypted, setDecrypted] = useState();
  const [modal, setModal] = useState({ data: null, open: false });
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  useEffect(() => {
    try {
      dispatch(setLoading(true));
      initData();
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e)
    }
  }, [docType, docId])

  useEffect(() => {
    if (doc.photo?.photoDocument && doc?.photo?.photoDocument?.length > 0) loadPhoto();
  }, [doc])

  async function initData() {
    const result = await Promise.all([
      photoHelper.getPhotoRecord(docId),
    ])
    setDoc(result[0]);
    dispatch(setLoading(false));
  }

  async function loadPhoto() {
    try {
      const result = await photoHelper.getPhotoThumbnail(doc.photo.photoDocument[doc.photo.photoDocument.length - 1].id);
      const file = new Blob([result]);
      const fileURL = URL.createObjectURL(file);//Open the URL on new Window
      setDecrypted(fileURL);
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
    }
  }

  async function handleDelete() {
    try {
      if (docId) {
        const result = await photoHelper.deletePhoto(docId);
        if (result.error) throw new Error(result.error);
        history.push(`/contract/${contractId}/photo?tab=docs`);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "history-log":
        return <DocumentHistoryLog data={doc[docType] ?? {}} />;
      case "attachments":
        return <DocumentAttachment data={doc[docType] ? doc[docType].photoDocument : []} reload={initData} />;
      case "related-documents":
        return <DocumentRelatedDocumentV2 data={doc[docType]} componentType={docType} />;
      default:
        return (
          <>
            <div className={classes.buttonsContainer}>
              {<EllipseButton onClick={() => setModal({ data: doc.photo, open: true })} label="Edit" />}
              {<ConfirmButton onClick={handleDelete} message={"Confirm to Delete this Photo?"}>Delete</ConfirmButton>}
            </div>
            <Modal open={modal.open} handleClose={() => setModal({ data: {}, open: false })}>
              <PhotoAdd handleClose={() => setModal({ data: {}, open: false })} contract={{ contractId }} reload={initData} defaultData={modal.data} type="edit" />
            </Modal>
            <DocumentDecrypted data={doc[docType] ?? {}} padding="20px 0 40px 0" photo={decrypted} />
          </>
        );
    }
  }

  return (
    <div className={classes.root}>
      <DocumentHeader data={doc[docType] ?? {}} type={docType} padding="0px 0px 20px 0" contractData={contractData} />
      {returnDocumentContent()}
    </div>
  );
}