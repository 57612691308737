export const dummyData = [
  {
    id: 1,
    name: "DC/2018/06 - IN",
    masterNumber: "5896",
  }
]

export const tableConfig = [
  {
    id: "placeholder",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "masterNumber",
    label: "Master Number",
  },
  {
    id: "placeholder",
  },
];