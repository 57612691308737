import { Fragment, useEffect } from "react";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { useForm, useFieldArray, useFormState } from "react-hook-form";
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from "@material-ui/core";

import { riskHelper, authHelper } from "helpers";

import EllipseButton from "Common/EllipseButton";
import SectionTitle from "Common/SectionTitle";
import RoundedButton from "Common/RoundedButton";
import fieldHelper from "utils/fieldHelper";
import arrayHelper from "utils/arrayHelper";
import { setLoading } from "redux/slice/loadingSlice";
import { setNotification } from "redux/slice/notificationSlice";

import { riskDetailsTableConfig } from './config';
import { useStyles } from "./style";

export default function RiskRegisterDetailsForm({ data, setIsEdit, riskRegisterId, reload }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { watch, register, handleSubmit, control, formState: { errors }, clearErrors } = useForm({
    defaultValues: {
      risk_reduction: data ?? []
    },
  });
  const { dirtyFields } = useFormState({
    control
  });

  async function onSubmit(formData) {
    try {
      dispatch(setLoading(true));
      let requestVariables;
      requestVariables = formData.risk_reduction.map((record, idx) => {
        if (dirtyFields.risk_reduction) {
          if (dirtyFields.risk_reduction[idx]) {
            return {
              userId: authHelper.getMyUserId(),
              updatedAt: new Date(),
              riskRegisterId: riskRegisterId,
              referenceNumber: record.referenceNumber,
              reductionMeetingDate: record.reductionMeetingDate,
              action: record.action,
              followUpParty: record.followUpParty,
              targetDueDate: record.targetDueDate,
              status: record.status,
              closureDate: record.closureDate,
              remark: record.remark,
            }
          }
        }
        return {
          userId: data[idx].userId,
          updatedAt: data[idx].updatedAt,
          riskRegisterId: riskRegisterId,
          referenceNumber: record.referenceNumber,
          reductionMeetingDate: record.reductionMeetingDate,
          action: record.action,
          followUpParty: record.followUpParty,
          targetDueDate: record.targetDueDate,
          status: record.status,
          closureDate: record.closureDate,
          remark: record.remark,
        }
      });
      if (formData.risk_reduction.length === 0) {
        requestVariables = [{
          riskRegisterId,
          action: null,
        }];
      }
      // console.log(requestVariables);
      await riskHelper.addRiskDecision(requestVariables);
      
      if (reload) reload();
      setIsEdit(false);
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      dispatch(setLoading(false));
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }


  const { fields, append, remove } = useFieldArray({
    control,
    name: "risk_reduction",
    keyName: "key"
  });

  function getDefaultValue(column, value) {
    if (column.type === "Date") {
      if (value && value[column.id]) return moment(value[column.id]);
    }
    return null;
  }

  function getNewRowValue() {
    let value = arrayHelper.convertArrayToObject(riskDetailsTableConfig.filter(item => item.key !== ""), 'key');
    value.status = "Open";
    value.targetDueDate = null;
    value.reductionMeetingDate = null;
    value.closureDate = null;
    return value;
  }

  const oneBtnStyle = { display: 'flex', width: "100%", justifyContent: "end", marginBottom: "16px" };
  const twoBtnStyle = { ...oneBtnStyle, justifyContent: "space-between", padding: "0 8px" };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <SectionTitle
        title={"Details"}
        fontStyle={theme.typography.h4}
        extraComponents={
          <div className={classes.editHeader}>
            <div style={{ marginRight: 8 }}>
              <EllipseButton
                label="Save"
                type="submit"
                backgroundColor="transparent"
              />
            </div>
            <EllipseButton
              label="Cancel"
              type="button"
              backgroundColor="transparent"
              onClick={() => setIsEdit(false)}
            />
          </div>
        }
      />
      <div className={classes.body}>
        <Grid container item xs={12} spacing={2}>
          {fields.map((field, index) => {
            return (
              <Fragment key={field.key}>
                {riskDetailsTableConfig.map((inputField, idx) =>
                  fieldHelper.renderField({ ...inputField, default: getDefaultValue(inputField, field) }, idx, register, control, errors, { prename: `risk_reduction.[${index}]`, index, name: inputField.key, })
                )}
                <Grid item xs={12}><Divider className={classes.divider} /></Grid>
              </Fragment>
            );
          })}

          <div style={twoBtnStyle}>
            <RoundedButton
              type="button"
              backgroundColor="#9A9A9A"
              onClick={() => {
                remove(fields.length - 1);
              }}
            >
              Remove Row
            </RoundedButton>

            <RoundedButton
              type="button"
              backgroundColor="#70BAD3"
              onClick={() => {
                append(getNewRowValue());
              }}
            >
              Add Row
            </RoundedButton>
          </div>
        </Grid>
      </div>
    </form>
  );
}
