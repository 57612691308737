import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message'
import { useHistory, useParams, useLocation } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { riskHelper } from "helpers";
import { setNotification } from "redux/slice/notificationSlice";
import fieldHelper from "utils/fieldHelper";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import Loading from "Common/Loading";

import CloseIcon from "static/icons/close.svg";

import { headerConfig, headerConfigSection2 } from "./RiskRegisterDetailsView/config";
import { useStyles } from "./RiskMeetingDetailsView/style";

export default function RiskRegisterAddForm({ mode, handleClose, reload, enableRedirection }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { watch, register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm({
    defaultValues: {
    }
  })
  const sp = new URLSearchParams(location.search);

  async function onSubmit(data) {
    try {
      setLoading(true);
      if (mode === "Add") {
        const requestVariables = { contractId, ...data };
        const result = await riskHelper.createRiskRegister(requestVariables);
        if (result.error) throw new Error(result.error);
        if (enableRedirection) history.push(`/contract/${contractId}/risk-register/document/${result.risk_register.id}`);
        else {
          const updated = sp.has('updated') ? sp.get('updated') : 1;
          history.push({ pathname: location.pathname, search: `${location.search}&updated=${Number(updated) + 1}` });
        }
      }

      handleClose();
      setLoading(false);
      dispatch(setNotification({ type: "success", message: "New Risk Created" }))
      if (reload) reload();
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${mode} Risk Discussed`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"close"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3} className={classes.section}>
            {headerConfig.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors))}

            {headerConfigSection2.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors, { size: 12 }))}
          </Grid>
        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          <Button children="Add" backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}