export const deploymentPieData = [
  {
    date: "2020-02-05",
    value: [
      {
        id: "labourer_male",
        label: "Labourer (Male)",
        value: 300
      },
      {
        id: "bar_bender_fixer",
        label: "Bar Bender & Fixer",
        value: 210
      },
      {
        id: "carpenter_formwork",
        label: "Carpenter (Formwork)",
        value: 220
      },
      {
        id: "general_welder",
        label: "General Welder",
        value: 60
      },
      {
        id: "labourer_female",
        label: "Labourer (Female)",
        value: 300
      },
      {
        id: "plant_equipment_operator_load_shift",
        label: "Plant and Equipment Operator (Load Shifting)",
        value: 210
      },
      {
        id: "metal_scaffolder",
        label: "Metal Scaffolder",
        value: 220
      },
      {
        id: "truck_driver",
        label: "Truck Driver",
        value: 60
      }
    ],
  },
  {
    date: "2020-06-07",
    value: [
      {
        id: "labourer_male",
        label: "Labourer (Male)",
        value: 200
      },
      {
        id: "bar_bender_fixer",
        label: "Bar Bender & Fixer",
        value: 60
      },
      {
        id: "carpenter_formwork",
        label: "Carpenter (Formwork)",
        value: 70
      },
      {
        id: "general_welder",
        label: "General Welder",
        value: 70
      },
      {
        id: "labourer_female",
        label: "Labourer (Female)",
        value: 100
      },
      {
        id: "plant_equipment_operator_load_shift",
        label: "Plant and Equipment Operator (Load Shifting)",
        value: 120
      },
      {
        id: "metal_scaffolder",
        label: "Metal Scaffolder",
        value: 50
      },
      {
        id: "truck_driver",
        label: "Truck Driver",
        value: 60
      }
    ],
  },
  {
    date: "2020-10-05",
    value: [
      {
        id: "labourer_male",
        label: "Labourer (Male)",
        value: 210
      },
      {
        id: "bar_bender_fixer",
        label: "Bar Bender & Fixer",
        value: 180
      },
      {
        id: "carpenter_formwork",
        label: "Carpenter (Formwork)",
        value: 120
      },
      {
        id: "general_welder",
        label: "General Welder",
        value: 50
      },
      {
        id: "labourer_female",
        label: "Labourer (Female)",
        value: 100
      },
      {
        id: "plant_equipment_operator_load_shift",
        label: "Plant and Equipment Operator (Load Shifting)",
        value: 70
      },
      {
        id: "metal_scaffolder",
        label: "Metal Scaffolder",
        value: 60
      },
      {
        id: "truck_driver",
        label: "Truck Driver",
        value: 20
      }
    ],
  },
  {
    date: "2020-12-01",
    value: [
      {
        id: "labourer_male",
        label: "Labourer (Male)",
        value: 120
      },
      {
        id: "bar_bender_fixer",
        label: "Bar Bender & Fixer",
        value: 50
      },
      {
        id: "carpenter_formwork",
        label: "Carpenter (Formwork)",
        value: 60
      },
      {
        id: "general_welder",
        label: "General Welder",
        value: 70
      },
      {
        id: "labourer_female",
        label: "Labourer (Female)",
        value: 60
      },
      {
        id: "plant_equipment_operator_load_shift",
        label: "Plant and Equipment Operator (Load Shifting)",
        value: 40
      },
      {
        id: "metal_scaffolder",
        label: "Metal Scaffolder",
        value: 23
      },
      {
        id: "truck_driver",
        label: "Truck Driver",
        value: 40
      }
    ],
  },
  {
    date: "2021-02-01",
    value: [
      {
        id: "labourer_male",
        label: "Labourer (Male)",
        value: 230
      },
      {
        id: "bar_bender_fixer",
        label: "Bar Bender & Fixer",
        value: 120
      },
      {
        id: "carpenter_formwork",
        label: "Carpenter (Formwork)",
        value: 100
      },
      {
        id: "general_welder",
        label: "General Welder",
        value: 50
      },
      {
        id: "labourer_female",
        label: "Labourer (Female)",
        value: 120
      },
      {
        id: "plant_equipment_operator_load_shift",
        label: "Plant and Equipment Operator (Load Shifting)",
        value: 100
      },
      {
        id: "metal_scaffolder",
        label: "Metal Scaffolder",
        value: 70
      },
      {
        id: "truck_driver",
        label: "Truck Driver",
        value: 60
      }
    ],
  },
  {
    date: "2021-05-01",
    value: [
      {
        id: "labourer_male",
        label: "Labourer (Male)",
        value: 250
      },
      {
        id: "bar_bender_fixer",
        label: "Bar Bender & Fixer",
        value: 150
      },
      {
        id: "carpenter_formwork",
        label: "Carpenter (Formwork)",
        value: 10
      },
      {
        id: "general_welder",
        label: "General Welder",
        value: 50
      },
      {
        id: "labourer_female",
        label: "Labourer (Female)",
        value: 140
      },
      {
        id: "plant_equipment_operator_load_shift",
        label: "Plant and Equipment Operator (Load Shifting)",
        value: 75
      },
      {
        id: "metal_scaffolder",
        label: "Metal Scaffolder",
        value: 50
      },
      {
        id: "truck_driver",
        label: "Truck Driver",
        value: 20
      }
    ],
  },
]

export const labourFormData = [
  {
    date: "2021-06-01",
    value: 3,
  },
  {
    date: "2021-06-02",
    value: 3,
  },
  {
    date: "2021-06-03",
    value: 6,
  },
  {
    date: "2021-06-04",
    value: 3,
  },
  {
    date: "2021-06-05",
    value: 4,
  },
  {
    date: "2021-06-06",
    value: 5,
  },
  {
    date: "2021-06-07",
    value: 2,
  },
];

export const pieData = [
  {
    id: 'labour',
    label: "Labour Return",
    value: 1105
  },
  {
    id: 'nonCompliance',
    label: "Non-Compliance",
    value: 24
  },
  {
    id: 'urgent',
    label: "Urgent RISC Form",
    value: 17
  }
];


export const wageData = [
  {
    "quarter": "2020Q1",
    "Bar Bender Fixer": 10,
    "Metal Scaffolder": 15,
    "Carpenter (Formwork)": 3,
    "General Welder": 6
  },
  {
    "quarter": "2020Q2",
    "Bar Bender Fixer": 7,
    "Metal Scaffolder": 8,
    "Carpenter (Formwork)": 6,
    "General Welder": 7
  },
  {
    "quarter": "2020Q3",
    "Bar Bender Fixer": 12,
    "Metal Scaffolder": 6,
    "Carpenter (Formwork)": 9,
    "General Welder": 10
  },
  {
    "quarter": "2020Q4",
    "Bar Bender Fixer": 8,
    "Metal Scaffolder": 2,
    "Carpenter (Formwork)": 15,
    "General Welder": 14
  }
]

export const deploymentData = {
  keys: [
    'Labourer (Male)', 
    'Bar Bender & Fixer',
    "Carpenter (Formwork)",
    "General Welder",
    "Labourer (Female)",
    "Plant and Equipment Operator (Load Shifting)",
  ],
  result: [
    {
      "quarter": "2020Q1",
      "Labourer (Male)": 300,
      "Bar Bender & Fixer": 210,
      "Carpenter (Formwork)": 220,
      "General Welder": 60,
      "Labourer (Female)": 300,
      "Plant and Equipment Operator (Load Shifting)": 210,
    },
    {
      "quarter": "2020Q2",
      "Labourer (Male)": 200,
      "Bar Bender & Fixer": 60,
      "Carpenter (Formwork)": 70,
      "General Welder": 70,
      "Labourer (Female)": 70,
      "Plant and Equipment Operator (Load Shifting)": 100,
    },
    {
      "quarter": "2020Q3",
      "Labourer (Male)": 230,
      "Bar Bender & Fixer": 100,
      "Carpenter (Formwork)": 150,
      "General Welder": 75,
      "Labourer (Female)": 90,
      "Plant and Equipment Operator (Load Shifting)": 25,
    },
    {
      "quarter": "2020Q4",
      "Labourer (Male)": 330,
      "Bar Bender & Fixer": 230,
      "Carpenter (Formwork)": 180,
      "General Welder": 120,
      "Labourer (Female)": 160,
      "Plant and Equipment Operator (Load Shifting)": 100,
    },
    {
      "quarter": "2021Q1",
      "Labourer (Male)": 230,
      "Bar Bender & Fixer": 120,
      "Carpenter (Formwork)": 100,
      "General Welder": 50,
      "Labourer (Female)": 120,
      "Plant and Equipment Operator (Load Shifting)": 100,
    },
    {
      "quarter": "2021Q2",
      "Labourer (Male)": 250,
      "Bar Bender & Fixer": 150,
      "Carpenter (Formwork)": 10,
      "General Welder": 50,
      "Labourer (Female)": 140,
      "Plant and Equipment Operator (Load Shifting)": 75,
    },
    {
      "quarter": "2021Q3",
      "Labourer (Male)": 0,
      "Bar Bender & Fixer": 0,
      "Carpenter (Formwork)": 0,
      "General Welder": 0,
      "Labourer (Female)": 0,
      "Plant and Equipment Operator (Load Shifting)": 0,
    },
    {
      "quarter": "2021Q4",
      "Labourer (Male)": 0,
      "Bar Bender & Fixer": 0,
      "Carpenter (Formwork)": 0,
      "General Welder": 0,
      "Labourer (Female)": 0,
      "Plant and Equipment Operator (Load Shifting)": 0,
    },
  ]
}