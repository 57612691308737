import { useTheme } from '@material-ui/core/styles';
import { Table, TableRow, TableBody, TableHead, TableCell } from '@material-ui/core';

import CustTableCell from "Common/TableCell";
import { useStyles } from "./style";

export default function LabourTable({ data }) {
  const theme = useTheme();
  const props = { theme };
  const classes = useStyles(props);

  function renderWorkerCount(item) {
    if (item.Worker_Count) return item.Worker_Count;
    if (item.calendar_day && item.calendar_day.length > 0) {
      let count = item.calendar_day.reduce((total, obj) => obj.worker_count + total, 0);
      return count;
    }
    return "-"
  }

  function renderMainTrade(item) {
    if (isNotEmpty(item.main_trade)) return item.main_trade;
    return '-';
  }

  function renderWorkerDesc(item) {
    if (isNotEmpty(item.worker_desc)) return item.worker_desc;
    else if (isNotEmpty(item.Worker_Desc)) return item.Worker_Desc;
    return "-"
  }

  function renderWorkerOrigCode(item) {
    if (isNotEmpty(item.orig_worker)) return item.orig_worker;
    return '-';
  }
  function renderWorkerCode(item) {
    if (isNotEmpty(item.worker_code)) return item.worker_code;
    else if (isNotEmpty(item.Worker_Code)) return item.Worker_Code;
    return '-';
  }

  function isNotEmpty(value) {
    if (value && value !== "---") return true;
    else return false;
  }

  function renderContent(data) {
    let currentMainTrade = "";
    let rowNum = 0;
    let isSubrow = false;

    return data.workers && data.workers.map((item, idx) => {
      if (currentMainTrade !== item.main_trade || item.main_trade === "" || !item.main_trade) {
        currentMainTrade = item.main_trade;
        rowNum += 1;
        isSubrow = false;
      } else {
        isSubrow = true;
      }
      return (
        (item.worker_desc || item.Worker_Desc) &&
        <TableRow key={idx} hover>
          <CustTableCell label={isSubrow ? "" : rowNum} />
          <CustTableCell label={isSubrow ? "" : renderMainTrade(item)} />
          <CustTableCell label={renderWorkerDesc(item)} />
          <CustTableCell label={renderWorkerOrigCode(item)} />
          <CustTableCell label={renderWorkerCode(item)} />
          <CustTableCell label={isSubrow ? "" : renderWorkerCount(item)} />
        </TableRow>
      )
    })
  }

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} className={classes.headerCell}>Item No.</TableCell>
          <TableCell rowSpan={2} colSpan={2} className={classes.headerCell}>Trade</TableCell>
          <TableCell colSpan={2} className={classes.headerCell} style={{ borderBottom: "none" }}>Trade List</TableCell>
          <TableCell rowSpan={2} className={classes.headerCell}>Total Man-days</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.headerCell}>Orig.</TableCell>
          <TableCell className={classes.headerCell}>Rev.</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderContent(data)}
      </TableBody>
    </Table>
  );
}