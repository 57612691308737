import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import PhotoBox from 'Photos/PhotoBox';
import { useStyles } from "./style";

export default function PhotoList({ data, photos, selectedData, handleSelect }) {
  const { contractId } = useParams();
  const theme = useTheme();
  const classes = useStyles(theme);

  const isSelected = (id) => {
    return !!selectedData ? selectedData.find((item) => !!item && item.id === id) : false;
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.body} spacing={3}>
        {data.map((picItem, index) =>
          <Grid key={index} item xs={12} sm={6} md={4}>
            {handleSelect
              ? <div><PhotoBox photo={photos[index]} details={picItem} isSelected={isSelected(picItem.id)} onClick={handleSelect.bind(this, picItem)} /></div>
              :
              <Link to={`/contract/${contractId}/photo/document/${picItem.id}`}>
                <PhotoBox photo={photos[index]} details={picItem} />
              </Link>

            }


          </Grid>
        )}
      </Grid>
    </div >
  );
};

PhotoList.propTypes = {
  data: PropTypes.array,
}