import { useParams, useLocation, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Grid } from '@material-ui/core';

import TextField from "Common/TextField";
import Select from "Common/Select";
import Button from "Common/RoundedButton";
import fieldHelper from 'utils/fieldHelper';
import permissionUtils from 'utils/permissionUtils';
import { edmsFileHelper, permissionHelper } from 'helpers';
import { setNotification } from 'redux/slice/notificationSlice';
import SectionTitle from 'Common/SectionTitle';
import { setLoading } from 'redux/slice/loadingSlice';

import PermissionField from './permissionField';
import CommonContainer from '../CommonContainer';
import { useStyles } from "./style";

export default function EDMSFolderCreate({ mode, defaultData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contractId, folderId } = useParams();
  const search = useLocation().search;
  const paramsFolderId = new URLSearchParams(search).get("folderId");
  const [parentName, setParentName] = useState("");
  const [fileGroup, setFileGroup] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [defaultPermission, setDefaultPermission] = useState();
  const { watch, register, handleSubmit, control, formState: { errors }, reset, setValue, } = useForm({
    defaultValues: {
      remarks: [],
      permission: [

      ],
    }
  });

  useEffect(() => {
    async function getFolderInfo() {
      try {
        const variable = { contractId: contractId, id: mode === "Add" ? paramsFolderId : folderId }
        if (paramsFolderId !== "undefined" || folderId !== "undefined") {
          const result = await edmsFileHelper.getSubFolders({ variable })
          // if (result[0].error) throw result[0].error;
          setParentName(`${result.file.code} - ${result.file.name}`);
        }
        else setParentName('/');
      } catch (e) {
        console.log(e);
      }
    }
    async function getInfo() {
      try {
        const result = await Promise.all([
          edmsFileHelper.getFileGroupsName(contractId),
          edmsFileHelper.getRemarksLabels(contractId),
        ]);
        setFileGroup(result[0].file_category);
        setRemarks(result[1].label);
      } catch (e) {
        console.log(e);
      }
    }

    async function initFormData() {
      try {
        const defaultFormValue = permissionUtils.transformDataToFormData(defaultData);
        setDefaultPermission(defaultFormValue);
        reset({
          code: defaultData.code,
          name: defaultData.name,
          group: defaultData.fileCategoryId,
          remarks: defaultData.labels.map(item => item.id),
          permission: defaultFormValue,
        })
      } catch (e) {
        console.log(e);
      }
    }

    getFolderInfo();
    getInfo();
    if (defaultData) {
      initFormData();
    }
  }, [folderId, paramsFolderId, defaultData])

  // useEffect(() => {
  //   init();
  // }, [defaultData]);


  async function onSubmit(data) {
    try {
      const fileRequest = {
        contractId,
        code: data.code,
        name: data.name,
        parentId: mode === "Add" ? paramsFolderId : defaultData.parentId,
        fileCategoryId: data.group,
        isFolder: true,
      }
      const fileResult = mode === "Add" ? await edmsFileHelper.createFolderFile(fileRequest) : await edmsFileHelper.editFolderFile(folderId, fileRequest);
      if (fileResult.error) throw new Error(fileResult.error);

      const remarksRequest = constructRemarksRequest(data, fileResult.file.id);
      const remarksResult = await edmsFileHelper.assignFileRemarks(remarksRequest);

      if (data.permission) {
        const permimssionRequests = permissionUtils.transformFormDataToRequestBody(data.permission, fileResult.file.id);
        const permissionResult = await Promise.all([
          permissionHelper.postFileUser(permimssionRequests.userRequest),
          permissionHelper.postFileGroup(permimssionRequests.groupRequest),
        ]);
      }
      if (mode === "Add") history.push(`/contract/${contractId}/edms/folder/${fileResult.file.id}/properties`);
      if (mode !== "Add") dispatch(setNotification({ type: 'success', message: "Information Updated" }));
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      // setLoading(false);
      console.log(e)
    }
  }

  function constructRemarksRequest(data, id) {
    let request = {};
    if (data.remarks.length > 0) {
      const remarksArr = data.remarks.map(item => { return { fileId: id, labelId: item } });
      request = {
        data: remarksArr
      };
    } else {
      request = {
        data: [{
          fileId: id,
          labelId: null
        }]
      };
    }
    return request;
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function renderMultipleValue(selected, options) {
    let renderArr = [];
    selected.forEach((selectedId, idx) => {
      const targetIndex = options.findIndex((option) => option.id === selectedId);
      if (targetIndex !== -1) renderArr.push(options[targetIndex].description);
    });
    return renderArr.join(', ');
  }

  return (
    <CommonContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            <SectionTitle title={mode === "Add" ? "Create Folder" : "Folder Properties"} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              rules={{ required: `Group is Required` }}
              name={'group'}
              control={control}
              defaultValue={defaultData && defaultData.fileCategoryId}
              render={({ field }) => <Select
                label={"Group *"}
                labelKey="name"
                valueKey="id"
                options={fileGroup}
                field={field}
              />}
            />
            <ErrorMessage
              errors={errors}
              name={"group"}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} sm={6}>
            {mode === "Add" && <TextField
              value={parentName}
              label={"Name"}
              disabled
            />}
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={4} sm={2}>
            <Controller
              rules={{ required: `Code is Required` }}
              name={"code"}
              control={control}
              render={({ field }) => <TextField
                placeholder={"Code"}
                hideLabel={mode === "Add"}
                defaultValue={""}
                label={"Name"}
                field={field}
              />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={"code"}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <Controller
              rules={{ required: `Description is Required` }}
              name={"name"}
              control={control}
              render={({ field }) => <TextField
                placeholder={"Description"}
                hideLabel={mode === "Add"}
                defaultValue={""}
                label={""}
                field={field}
              />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={"name"}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>


          <Grid item xs={12} sm={6}>
            <Controller
              rules={{}}
              name={"remarks"}
              control={control}
              defaultValue={defaultData ? defaultData.remarks : []}
              render={({ field }) => <Select
                defaultValue={defaultData && defaultData.labels}
                multiple
                renderMultipleValue={(selected) => renderMultipleValue(selected, remarks)}
                // multipleDisplay={(e) => e.map(item => item.description).join(', ')}
                labelKey={"description"}
                // checkboxKey={"id"}
                valueKey={"id"}
                label={"Remarks"}
                options={remarks}
                field={field}
              />}
            >
            </Controller>
            <ErrorMessage
              errors={errors}
              name={"remarks"}
              render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12}>
            <PermissionField
              control={control}
              register={register}
              errors={errors}
              name={"permission"}
              setValue={setValue}
              defaultValue={defaultPermission}
            />
          </Grid>

          <Grid item xs={12} sm={12}></Grid>
          <Grid container item justify="flex-end">
            <Button children="Save" backgroundColor="#70BAD3" type="submit" />
          </Grid>

        </Grid>
      </form>
    </CommonContainer>
  );
}