import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  Icon: {
    width: "30px"
  },
  drawer: {
    borderTopRightRadius: "30px",
    borderTopLeftRadius: "30px",
    backgroundColor: theme.palette.secondaryDark
  }
}));
