import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: 16,
  },
  editHeader: {
    marginBottom: 8,
    display: "flex",
  },
  riskContainer: {
    marginTop: 16,
    padding: "16px",
    borderRadius: 10,
    background: "#2F3441",
  },
  infoHeader: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    color: "#fff",
    padding: "20px 0px 20px 0",
    justifyContent: "start",
    // borderBottom: "1px solid #CACACA",
  },
  divider: {
    background: "grey"
  },
}));
