import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import { setLoading } from 'redux/slice/loadingSlice';
import { documentHelper, contractHelper } from 'helpers';
import DocumentHistoryLog from 'Document/DocumentHistoryLog';
import DocumentHeader from 'Document/DocumentHeader';
import DocumentDecrypted from 'Document/DocumentDecrypted';
import DocumentAttachment from "Document/DocumentAttachment";
import DocumentRelatedDocumentV2 from "Document/DocumentRelatedDocumentV2";
import DocumentActivity from "Document/DocumentActivity";

import { useStyles } from "./style";

export default function SiteDiaryDocument() {
  const contractData = useSelector(selectContractData);
  const dispatch = useDispatch();
  const { docId, type } = useParams();
  const [docType, _] = useState("site_diary")
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const theme = useTheme();
  const classes = useStyles(theme);
  const [doc, setDoc] = useState({});
  const [pdf, setPdf] = useState();

  useEffect(() => {
    try {
      dispatch(setLoading(true));
      initData();
    } catch (e) {
      dispatch(setLoading(false));
      console.log(e)
    }
  }, [docId])

  async function initData() {
    const result = await Promise.all([
      documentHelper.getDocument({ type: "site-diary", id: docId }),
    ])
    setDoc(result[0]);
    dispatch(setLoading(false));
  }

  useEffect(() => {
    async function getPreview() {
      if (doc.site_diary.siteDiaryDocument.length > 0) {
        const result = await documentHelper.decryptDocument(doc.site_diary.siteDiaryDocument[0].documentId)
        const file = new Blob(
          [result],
          { type: 'application/pdf' });

        const fileURL = URL.createObjectURL(file);//Open the URL on new Window
        setPdf(fileURL)
      }
    }
    if (doc.site_diary) {
      getPreview();
    }
  }, [doc])

  const returnDocumentContent = () => {
    switch (searchParam) {
      case "history-log":
        return (
          <DocumentHistoryLog data={doc[docType] ?? {}} />
        );
      case "attachments":
        return <DocumentAttachment data={doc[docType] ? doc[docType].siteDiaryDocument : []} reload={initData} />;
      case "related-documents":
        return <DocumentRelatedDocumentV2 data={doc[docType]} componentType={type} />;
      default:
        return (
          <>
            <DocumentDecrypted data={(doc[docType]) ?? {}} pdf={pdf} padding="20px 0 40px 0" />
            <DocumentActivity activities={(doc[docType] && doc[docType].activities) ?? []} />
          </>
        );
    }
  }



  return (
    <div className={classes.root}>
      <DocumentHeader data={doc[docType] ?? {}} type={docType} padding="0px 0px 20px 0" contractData={contractData} />
      {returnDocumentContent()}
    </div>
  );
}