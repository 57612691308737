import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';

import ConfirmButton from 'Common/ConfirmButton';
import CustTextField from 'Common/TextField';
import { permissionHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import { setNotification } from 'redux/slice/notificationSlice';

import fieldHelper from 'utils/fieldHelper';
import RoundedButton from 'Common/RoundedButton';

import { useStyles } from "../style";

export default function SafetyReportAction({ reportStatus, onSave, onDelete, hasActionPanel }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [userNGroup, setUserNGroup] = useState([]);
  const { register, handleSubmit, control, formState: { errors }, setValue, reset } = useForm({
    to: [],
    ccTo: [],
    comment: "",
  });

  useEffect(() => {
    async function getUserGroupInfo() {
      try {
        const result = await Promise.all([
          permissionHelper.getUsers(),
          permissionHelper.getGroups(),
        ]);

        const combinedArr = result[0].user.map(v => ({ id: v.id, type: "User", label: `${v.firstName} ${v.lastName}` }))
          .concat(result[1].group.map(v => ({ id: v.id, type: "Group", label: v.name })));
        setUserNGroup(combinedArr);
      } catch (e) {
        console.log(e);
      }
    }
    getUserGroupInfo();
  }, [])

  async function onSubmit(formData, action) {
    try {
      const metaVariables = constructRequestBody(formData);
      onSave(action, metaVariables)();
      reset({
        ccTo: [],
        comment: "",
      })
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      dispatch(setLoading(false));
      console.log(e)
    }
  }

  function constructRequestBody(data) {
    let userIdList = [];
    let groupIdList = [];

    if (data?.ccTo?.length) {
      data.ccTo.forEach((row, idx) => {
        if (row.type === "User") {
          userIdList.push(row.id);
        } else if (row.type === "Group") {
          groupIdList.push(row.id);
        }
      });
    }

    return { ccTo: { usersId: userIdList, groupsId: groupIdList }, comment: data.comment };
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  function onChange(e, newValue, field, name) {
    if (!newValue) {
      setValue(`${name}`, null)
    } else {
      setValue(`${name}`, newValue)
    }
  }

  function renderToMessage() {
    switch (reportStatus) {
      case "Reported": return "Please select Supervisor to verify";
      case "Endorsed": return "Please select Supervisor to counter-verify";
      case "Supervisor verified": return "Please select Project Manager to verify";
      case "Supervisor counter-verified": return "Please verify the report and comments";
      default: return "Please select Site Agent for endorsement";
    }
  }

  function setDefaultComment() {
    switch (reportStatus) {
      case "Reported":
      case "Endorsed":
      case "Supervisor verified":
        return "Please verify";
      case "Supervisor counter-verified": return "Safety Performance Report ready for QS to proceed";
      default: return "Please endorse";
    }
  }

  function renderSubmitText() {
    switch (reportStatus) {
      case "":
      case "Rejected":
      case null: return "Submit for Endorsement";
      case "Supervisor counter-verified": return "Confirm Verification";
      default: return "Submit for Verification";
    }
  }

  return (
    <div>
      {reportStatus !== "Closed - PM verified" &&
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container style={{ overflowX: "auto", minWidth: "350px" }} spacing={1}>
            <Grid item>{renderToMessage()}</Grid>
            {(reportStatus !== "Supervisor counter-verified") && <>
              <Grid item xs={8}>
                <div className={classes.label}>User/Group</div>
                <CustTextField disabled hideLabel value={hasActionPanel?.displayGroup ?? ""}></CustTextField>
              </Grid>
              <Grid item style={{ marginTop: 16 }} xs={12}>CC to</Grid>
              <Grid item xs={8}>
                <div className={classes.label}>User/Group</div>
                <Controller
                  name={`ccTo`}
                  control={control}
                  defaultValue={undefined}
                  render={({ field }) =>
                    <Autocomplete
                      multiple={true}
                      id="combo-box-demo"
                      defaultValue={undefined}
                      options={userNGroup ?? []}
                      groupBy={(option) => option.type}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, newValue) => onChange(e, newValue, field, `ccTo`)}
                      renderInput={(params) =>
                        <div style={{ display: "flex" }}>
                          <TextField
                            {...params}
                            className={classes.textField}
                          />
                        </div>}
                    />}
                />
                <ErrorMessage
                  errors={errors}
                  name={`ccTo`}
                  render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
                />
              </Grid>

              <Grid item xs={12}></Grid>
            </>
            }
            {fieldHelper.renderField(
              { label: reportStatus === "Endorsed" ? "Action Required" : "Comment", name: "comment", rows: 3, default: setDefaultComment()},
              3, register, control, errors, { size: 12 })
            }
            {(!reportStatus || reportStatus === "Rejected") &&
              <Grid item xs={12} sm={4} className={classes.buttonsContainer} style={{ flexWrap: "wrap" }}>
                <RoundedButton backgroundColor="#70BAD3" width={"unset"} onClick={onSave()}>Save</RoundedButton>
              </Grid>
            }
            {reportStatus === "Reported" &&
              <Grid item xs={12} sm={4} className={classes.buttonsContainer} style={{ flexWrap: "wrap" }}>
                <RoundedButton backgroundColor="red" width={"unset"} onClick={handleSubmit((d) => onSubmit(d, 'reject'))}>Reject</RoundedButton>
              </Grid>
            }
            {reportStatus !== "Reported" && reportStatus !== "Rejected" && reportStatus &&
              <Grid item xs={12} sm={4} className={classes.buttonsContainer} style={{ flexWrap: "wrap" }} />}
            <Grid item xs={12} sm={8} className={classes.buttonsContainer} style={{ justifyContent: "flex-end" }}>
              <RoundedButton backgroundColor="#70BAD3" width={"unset"} onClick={handleSubmit((d) => onSubmit(d, !reportStatus || reportStatus === "Rejected" ? "endorse" : "verify"))} >
                {renderSubmitText()}
              </RoundedButton>
            </Grid>

            {!reportStatus &&
              <Grid item xs={12} sm={4} className={classes.buttonsContainer} style={{ flexWrap: "wrap" }}>
                <ConfirmButton backgroundColor="red" width={"unset"} onClick={onDelete}>Delete</ConfirmButton>
              </Grid>
            }
          </Grid>
        </form>
      }
    </div >
  );
}