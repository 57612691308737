import { useState, Fragment } from "react";
import { Grid, TextField, IconButton, Popover } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTheme } from '@material-ui/core/styles';

import { edmsFileHelper } from "helpers";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import { useStyles } from "./style";

const filterAutocomplete = createFilterOptions();

function RemarksDrawer({
  setShowFilter,
  changeModalContent,
  remarks,
  reload,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [targetId, setTargetId] = useState(null);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTargetId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTargetId(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : "simple-popover";

  function renderRemarksRow(remarksData) {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={1}>
          <div className={classes.codeLabel}>{remarksData.code}</div>
        </Grid>
        <Grid item xs={8}>{remarksData.description}</Grid>
        <Grid item xs={3}>
          <IconButton onClick={() => changeModalContent("Remarks", "Edit", remarksData)} style={{ padding: 6 }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={(e) => handleClick(e, remarksData.id)} style={{ padding: 6 }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  async function deleteRemarks(id) {
    try {
      const result = await edmsFileHelper.deleteRemarksLabel(id);
      reload();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  }



  return (
    <div className={classes.root}>
      <div>
        <SectionTitle title="Remarks" />
        <div className={classes.gridContainer}>
          {remarks.map((item, idx) =>
            <Fragment key={idx}>
              {renderRemarksRow(item)}
            </Fragment>
          )}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          children="Add Remarks"
          backgroundColor={theme.palette.primaryLight}
          onClick={() => changeModalContent("Remarks", "Add")}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.popoverContainer}>
          <div style={{ fontWeight: 600 }}>Confirm to delete the remarks label?</div>
          <div style={{ marginTop: 8 }}>
            <Button
              children="Confirm"
              backgroundColor={theme.palette.primaryLight}
              onClick={() => deleteRemarks(targetId)}
              style={{}}
            />
            <Button
              children="Cancel"
              backgroundColor={theme.palette.primaryLight}
              onClick={() => handleClose()}
            />
          </div>
        </div>
      </Popover>
    </div >
  )
}

export default RemarksDrawer;