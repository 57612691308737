import axios from './apiHelper';

// temp route
const statInfoHelper = {
  getPhotos: async ({ variable }) => {
    try {
      // ?page=1&items=5&filter=contractId&keyword=1
      const result = await axios.get(`/photos`, { params: variable });

      if (!result.data) {
        throw new Error("Fail to get photos");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getRISC: async () => {
    try {
      const result = await axios.get(`/stat-info/riscs`);

      if (!result.data) {
        throw new Error("Fail to get photos");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSiteDiary: async () => {
    try {
      const result = await axios.get(`/stat-info/site-diary`);

      if (!result.data) {
        throw new Error("Fail to get photos");
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400)
          return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
}

export default statInfoHelper;