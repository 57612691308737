import PropTypes from "prop-types";
import { Backdrop, CircularProgress } from '@material-ui/core/';
import { useTheme } from "@material-ui/core";

import { useStyles } from "./style";


function Loading({ isLoading }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  
  return (
    <Backdrop className={classes.backdrop} open={!!isLoading} >
      <CircularProgress />
    </Backdrop>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.any,
};

export default Loading;