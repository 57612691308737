import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import TabPanel from 'Common/TabPanel';
import LinkTabs from 'Common/LinkTabs';
import PhotoDashboard from './PhotoDashboard';
import PhotoGallery from './PhotoGallery';
import { useStyles } from "./style";
import { tabData } from './config';
import CommonContainer from 'EDMS/CommonContainer';

export default function Photos() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { search } = useLocation();
  const searchParam = search.split("?tab=")[1];
  const [activeTab, setActiveTab] = useState(searchParam === "docs" ? 1 : 0);

  useEffect(() => {
    try {
      setActiveTab(searchParam === "docs" ? 1 : 0);
    } catch (e) {
      console.log(e);
    }
  }, [searchParam]);

  const returnContent = (idx) => {
    switch (idx) {
      case 1:
        return <PhotoGallery />;
      default:
        return <PhotoDashboard />;
    }
  }

  return (
    <CommonContainer>
      <div className={classes.body}>
        <LinkTabs data={tabData} activeTab={activeTab} />
        {tabData.map((item, index) =>
          <TabPanel key={item.id} value={activeTab} index={index}>
            {returnContent(index)}
          </TabPanel>
        )}
      </div>
    </CommonContainer>
  );
};