import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';

export default function SimpleMenu({ options, handleClose, anchorEl, anchorOrigin, transformOrigin }) {
  const groupHeader = {
    padding: "0 16px",
    lineHeight: "48px",
    color: "rgba(255, 255, 255, 0.7)",
    fontSize: "0.875rem",
    userSelect: "none",
  }
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={anchorOrigin ?? { vertical: "bottom", horizontal: "center" }}
      transformOrigin={transformOrigin ?? { vertical: 0, horizontal: 0, }}
    >
      {options.map((option, i) =>
        option.type === "group"
          ? <div key={i} style={groupHeader}>{option.render ?? option.name}</div>
          : <MenuItem key={i} onClick={() => option.onClick()} disabled={!option.onClick} style={{ marginLeft: (option.isSub ?? 0) * 4 }}>{option.render ?? option.name}</MenuItem>
      )}
    </Menu>
  );
}

SimpleMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
};