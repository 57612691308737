export const dwssOptions = [
  {
    name: "RISC Form",
    key: "risc",
    linkTo: (contractId) => {
      return `/contract/${contractId}/risc`
    },
    permissionKey: "risc",
  },
  {
    name: "Site Diary",
    key: "site-diary",
    linkTo: (contractId) => {
      return `/contract/${contractId}/site-diary`
    },
    permissionKey: "siteDiary",
  },
  {
    name: "Safety Inspection",
    key: "safety-inspection",
    linkTo: (contractId) => {
      return `/contract/${contractId}/safety-inspection`
    },
    permissionKey: "safety",
  },
  {
    name: "Cleansing Inspection",
    key: "cleansing-inspection",
    linkTo: (contractId) => {
      return `/contract/${contractId}/cleansing-inspection`
    },
    permissionKey: "cleansing",
  },
  {
    name: "Labour Return",
    key: "labour",
    linkTo: (contractId) => {
      return `/contract/${contractId}/labour`
    },
    permissionKey: "labour",
  },
]

export const documentLinkList = [
  {
    name: "Basic Information",
    to: (pathname) => {
      return `${pathname}?tab=basic-info`
    },
    level: 1,
    path: "basic-info"
  },
  {
    name: "History Log",
    to: (pathname) => {
      return `${pathname}?tab=history-log`
    },
    level: 4,
    path: "history-log"
  },
  {
    name: "Attachments",
    to: (pathname) => {
      return `${pathname}?tab=attachments`
    },
    level: 3,
    path: "attachments"
  },
  {
    name: "Related Documents",
    to: (pathname) => {
      return `${pathname}?tab=related-documents`
    },
    level: 2,
    path: "related-documents"
  }
]

export const riskMeetingLinkList = [
  {
    name: "Basic Information",
    to: (pathname) => {
      return `${pathname}?tab=basic-info`
    },
    path: "basic-info"
  },
  {
    name: "Add Risk Discussed",
    to: (pathname) => {
      return `${pathname}?tab=add-risk`
    },
    path: "add-risk"
  },
]
export const riskRegisterLinkList = [
  {
    name: "Basic Information",
    to: (pathname) => {
      return `${pathname}?tab=basic-info`
    },
    path: "basic-info"
  },
  {
    name: "Attachments",
    to: (pathname) => {
      return `${pathname}?tab=attachments`
    },
    path: "attachments"
  },
  {
    name: "Related Documents",
    to: (pathname) => {
      return `${pathname}?tab=related-documents`
    },
    path: "related-documents"
  }
]

const edmsDocTypeOptions = [
  {
    name: "Audit",
    key: "audit",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/audit`
    }
  },
  {
    name: "CE",
    key: "ce",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/ce`
    }
  },
  {
    name: "CP",
    key: "cp",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/cp`
    },
  },
  {
    name: "CSF",
    key: "csf",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/csf`
    }
  },
  {
    name: "EWN",
    key: "ewn",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/ewn`
    },
  },
  {
    name: "Letter",
    key: "letter",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/letter`
    },
  },
  {
    name: "MSF",
    key: "msf",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/msf`
    },
  },
  {
    name: "PMI",
    key: "pmi",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/pmi`
    },
  },
  {
    name: "Quotation",
    key: "quotation",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/quotation`
    },
  },
  {
    name: "RFI",
    key: "rfi",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/rfi`
    },
  },
  {
    name: "RR",
    key: "rr",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/rr`
    },
  },
];

const pmiDocTypeOptions = [
  {
    name: "PMI",
    key: "pmi",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms`
    },
  },
  {
    name: "CE",
    key: "ce",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms`
    },
  },
  {
    name: "Quotation",
    key: "quotation",
    type: "search",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms`
    },
  },
];

const administrationOptions = [
  {
    name: "User Management",
    key: "userManagementUrl",
    type: "external",
    linkTo: (link) => {
      return link;
    }
  },
  {
    name: "Group Management",
    key: "groupManagementUrl",
    type: "external",
    linkTo: (link) => {
      return link;
    }
  },
  {
    name: "Document Type Setup",
    key: "document-type",
    linkTo: (contractId) => {
      return `/contract/${contractId}/setup/document-type`
    },
  },
  // {
  //   name: "Dropdown Option Setup",
  //   key: "dropdown-option",
  //   linkTo: (contractId) => {
  //     return `/contract/${contractId}/setup/dropdown-option`
  //   },
  // },
  // {
  //   name: "Folios Setup",
  //   key: "folios",
  //   linkTo: (contractId) => {
  //     return `/contract/${contractId}/setup/folios`
  //   },
  // },
  // {
  //   name: "Master Number Setup",
  //   key: "master-number",
  //   linkTo: (contractId) => {
  //     return `/contract/${contractId}/setup/master-number`
  //   },
  // },
  // {
  //   name: "Profile Field Setup",
  //   key: "profile-field",
  //   linkTo: (contractId) => {
  //     return `/contract/${contractId}/setup/profile-field`
  //   },
  // },
  // {
  //   name: "Unlock Document",
  //   key: "unlock-document",
  //   linkTo: (contractId) => {
  //     return `/contract/${contractId}/admin/unlock-document`
  //   },
  // },
  {
    name: "Filing List",
    key: "folder-list",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/folder-list`
    },
  },
  {
    name: "Folder Security Report",
    key: "folder-security-report",
    type: "blank",
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms/folder-security-report`
    },
  },
]

const reportsOptions = [
  {
    name: "01. Daily Check Report",
    key: "report",
    linkTo: (contractId) => {
      return `/contract/${contractId}/report/daily-check-report`
    },
  },
  {
    name: "02. Action Report",
    key: "report",
    linkTo: (contractId) => {
      return `/contract/${contractId}/report/action-report`
    },
  },
  {
    name: "03. Correspondence Report",
    key: "report",
    linkTo: (contractId) => {
      return `/contract/${contractId}/report/correspondence-report`
    },
  }
];

export const navOptions = [
  {
    name: "Overview",
    key: "overview",
    linkTo: (contractId) => {
      return `/contract/${contractId}`
    },
  },
  {
    name: "DWSS",
    key: "dwss",
    type: "group",
    subGroup: dwssOptions,
    isModule: true,
  },
  {
    name: "EDMS",
    key: "edms",
    type: "group",
    subGroup: edmsDocTypeOptions,
    linkTo: (contractId) => {
      return `/contract/${contractId}/edms`
    },
    isModule: true,
    permissionKey: 'edms',
  },
  {
    name: "EW & Risk",
    key: "risk-register",
    linkTo: (contractId) => {
      return `/contract/${contractId}/risk-register`
    },
    isModule: true,
    permissionKey: 'risk',
  },
  {
    name: "NCE",
    key: "incident",
    permissionKey: "incident",
    linkTo: (contractId) => {
      return `/contract/${contractId}/incident`
    },
  },
  {
    name: "Safety Performance",
    key: "safety-performance",
    permissionKey: "safetyPerformance",
    linkTo: (contractId) => {
      return `/contract/${contractId}/safety-performance`
    },
  },
  {
    name: "Site Progress Photo",
    key: "photo",
    linkTo: (contractId) => {
      return `/contract/${contractId}/photo`
    },
    isModule: true,
    permissionKey: "photo",
  },
  {
    name: "BIM",
    key: "bim",
    type: "group",
    permissionKey: "bim",
    subGroup: [
      {
        name: "Project Status",
        key: "project-status",
        linkTo: (contractId) => {
          return `/contract/${contractId}/bim/project-status`;
        },
      },
      {
        name: "Document Management",
        key: "library",
        type: "docManagement",
        subGroup: [
          {
            name: "Document Management",
            key: "library",
            linkTo: (contractId, id) => {
              return `/contract/${contractId}/bim/document-manage/${id}`
            },
          },
        ],
      },
    ],
    isModule: true,
    linkTo: (contractId) => {
      return `/contract/${contractId}/bim`
    },
  },
  {
    name: "Document Library",
    key: "library",
    type: "docLib",
    subGroup: [
      {
        name: "Document Library",
        key: "library",
        linkTo: (contractId, id, isRoot) => {
          return `/contract/${contractId}/edms/folder/${id}`
        },
      },
    ],
    permissionKey: 'edms'
  },
  {
    name: "Administration",
    key: "admin",
    type: "group",
    subGroup: administrationOptions,
  }
];

export const keyList = [
  ...navOptions,
  ...dwssOptions,
  ...edmsDocTypeOptions,
  ...pmiDocTypeOptions,
  ...administrationOptions
]
