import { TableRow, TableCell, TableHead } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import SectionTitle from 'Common/SectionTitle';
import { safetyPerformanceHelper } from 'helpers';
import { safetyReportConfig } from './config';
import { useStyles } from "../style";
import { useEffect } from 'react';

export default function SafetyReportHeader({ safetyMeta }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  function getActionDetails(status) {
    const target = safetyMeta.find(item => item.type === status);
    return target;
  }

  const reported = getActionDetails('Reported');
  const endorsed = getActionDetails('Endorsed');
  const verified = getActionDetails('Supervisor verified');
  const counterVerified = getActionDetails('Supervisor counter-verified');
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headerCell}>
          <SectionTitle title={"Performance achievements in this Reporting Month (to be reported by the Contractor)"} fontStyle={theme.typography.h6} />
          {reported && <div>Reported by {reported.by} on {moment(reported.updatedAt).format('DD/MM/YYYY')}</div>}
          {endorsed && <div>Endorsed by {endorsed.by} on {moment(endorsed.updatedAt).format('DD/MM/YYYY')}</div>}
        </TableCell>
        <TableCell className={classes.headerCell} style={{ width: "20%" }}>
          <SectionTitle title={"Verified by the Supervisor"} fontStyle={theme.typography.h6} />
          {verified && <div>Verified by {verified.by} on {moment(verified.updatedAt).format('DD/MM/YYYY')}</div>}
        </TableCell>
        <TableCell className={classes.headerCell} style={{ width: "20%" }}>
          <SectionTitle title={"Counter-verified by the Supervisor"} fontStyle={theme.typography.h6} />
          {counterVerified && <div>Counter-verified by {counterVerified.by} on {moment(counterVerified.updatedAt).format('DD/MM/YYYY')}</div>}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}