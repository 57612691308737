import { makeStyles } from '@material-ui/core/styles';
import { PinDropSharp } from '@material-ui/icons';

export const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.primaryDark
  },
  container: (props) => ({
    paddingTop: props.padding ?? "100px"
  })
}));
