import PropTypes from "prop-types";
import moment from 'moment';
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useTheme } from '@material-ui/core/styles';

import { dashboardHelper } from "helpers";
import arrayHelper from "utils/arrayHelper";
import { typeSummaryListData, summaryTableConfig } from "./config";
import ChartHeader from "Common/ChartHeader";
import DashboardList from "Common/DashboardList/index";

import { useStyles } from "./style";

export default function TypeSummaryList({ startDate, endDate }) {
  const theme = useTheme();
  const props = { theme }
  const { contractId } = useParams();
  const classes = useStyles(props);
  const [data, setData] = useState([])
  const [sort, setSort] = useState({
    orderBy: "count",
    sortDirection: "desc"
  })
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    countData(data);
  }, [data]);

  async function getData() {
    const variable = {
      contractId,
      startDate: startDate.clone().format('YYYY-MM-DD'),
      endDate: endDate.clone().format('YYYY-MM-DD'),
    }
    const result = await dashboardHelper.getCleansingSummaryTable({ variable });
    sortData(sort, result);
  }

  useEffect(() => {
    try {
      sortData(sort, data);
    } catch (e) {
      console.log(e);
    }
  }, [sort])

  function sortData(sort, input) {
    let result = [...input];
    let isNumber = false;
    if (sort.orderBy === "count") isNumber = true;
    result = arrayHelper.sortByKey(result, sort.orderBy, sort.sortDirection, isNumber);

    setData(result);
  }

  function handleRequestSort(event, property) {
    const isAsc = sort.orderBy === property && sort.sortDirection === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSort({
      orderBy: property,
      sortDirection: direction,
    });
  };

  function countData(input) {
    const count = input.reduce((total, obj) => Number(obj.count) + total, 0);
    setTotal(count);
  }

  return (
    <div className={classes.dashboardList}>
      <div className={classes.title} ></div>
      <DashboardList
        data={data ?? []}
        handleSort={handleRequestSort}
        orderBy={sort.orderBy}
        sortDirection={sort.sortDirection}
        fieldsData={summaryTableConfig}
        total={total.toString()}
      />
    </div>
  );
};