import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

import { useStyles } from "./style";

function EllipseButton({
  label,
  startIcon,
  onClick,
  type,
  margin,
}) {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      startIcon={startIcon}
      onClick={onClick}
      className={classes.button}
      type={type ?? "button"}
      style={{ margin: margin }}
    >
      {label}
    </Button>
  );
}

EllipseButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startIcon: PropTypes.node,
  onClick: PropTypes.func
};

export default EllipseButton;