import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import Button from "Common/RoundedButton";
import { setLoading } from 'redux/slice/loadingSlice';
import { setNotification } from 'redux/slice/notificationSlice';
import fieldHelper from 'utils/fieldHelper';
import SecctionTitle from 'Common/SectionTitle';
import docTypeHelper from 'helpers/admin/docTypeHelper';

import { useStyles } from "./style";
import { inOutList } from './config';

export default function EDMSSearch() {
  const [docTypeList, setDocTypeList] = useState([]);

  useEffect(() => {
    async function init() {
      try {
        const result = await docTypeHelper.getdocTypes({ orderBy: "name.asc" });
        return result;
      } catch (e) {
        console.log(e);
      }
    }
    let isMounted = true;
    init().then((result) => {
      if (isMounted) setDocTypeList(result?.edms_document_type);
    });
    return () => { isMounted = false };
  }, [])

  return (
    <EDMSSearchForm docTypeList={docTypeList} />
  );
}


function EDMSSearchForm({ docTypeList }) {
  const history = useHistory();
  const classes = useStyles();
  const { contractId } = useParams();

  const dispatch = useDispatch();

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
    }
  })

  async function onSubmit(data) {
    try {
      const keys = Object.keys(data);
      const rangeFields = [{ name: "letterDate", type: "Date" }, { name: "receiveDate", type: "Date" }];
      let statePayload = {};
      keys.forEach(key => {
        if (data[key] === "" || !data[key]) return;
        if (key === "inOut" && data[key] === "All") return;
        if (key.includes(";")) return;
        else statePayload[key] = data[key];
      });

      rangeFields.forEach(field => {
        let keyValue = [null, null];
        const startKeyName = `${field.name};start`;
        const endKeyName = `${field.name};end`;
        if (data[startKeyName]) {
          keyValue[0] = field.type === "Date"
            ? data[startKeyName].replaceAll("/", ".")
            : data[startKeyName];
        }
        if (data[endKeyName]) {
          keyValue[1] = field.type === "Date"
            ? data[endKeyName].replaceAll("/", ".")
            : data[endKeyName];
        }
        if (keyValue[0] || keyValue[1]) {
          statePayload[field.name] = keyValue.join('.');
        }
      })

      statePayload.contractId = contractId;

      history.push({ pathname: `/contract/${contractId}/edms/search-result`, state: statePayload });

    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>

        <SecctionTitle title={`EDMS Search`} />

        <div className={clsx(classes.formBody)}>
          <Grid container spacing={3}>
            {fieldHelper.renderField(
              { label: "Log No.", name: "logNumber", },
              0, register, control, errors, { size: 3 })
            }
            {fieldHelper.renderField(
              { label: "Subject", name: "subject", },
              2, register, control, errors, { size: 6 })
            }
            {fieldHelper.renderField(
              { label: "Letter Date (From)", name: "letterDate;start", type: "Date" },
              3, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "Letter Date (To)", name: "letterDate;end", type: "Date" },
              4, register, control, errors)
            }
            <Grid item xs={3}></Grid>
            {fieldHelper.renderField(
              { label: "Receive Date (From)", name: "receiveDate;start", type: "Date" },
              5, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "Receive Date (To)", name: "receiveDate;end", type: "Date" },
              6, register, control, errors)
            }
            <Grid item xs={3}></Grid>
            {fieldHelper.renderField(
              { label: "Doc Type", name: "docType", type: "Select", options: docTypeList, labelKey: "name", valueKey: "code" },
              7, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "Letter Ref.", name: "letterRef", },
              8, register, control, errors)
            }
            <Grid item xs={3}></Grid>
            {fieldHelper.renderField(
              { label: "From Company", name: "fromCompany", },
              9, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "To Company", name: "toCompany", },
              10, register, control, errors)
            }
            <Grid item xs={3}></Grid>
            {fieldHelper.renderField(
              { label: "File No.", name: "toCompany", },
              11, register, control, errors)
            }
            {fieldHelper.renderField(
              { label: "In / Out", name: "inOut", type: "Select", options: inOutList },
              12, register, control, errors, { size: 2 })
            }
            <Grid container item justify="flex-start">
              <Button children="Submit" backgroundColor="#70BAD3" type="submit" />
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
}