import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import moment from 'moment';
import SectionTitle from 'Common/SectionTitle';
import bimHelper from 'helpers/bimHelper';

import CommonContainer from 'EDMS/CommonContainer';
import CommonDocumentList from 'EDMS/CommonDocumentList';

export default function BIMFolder() {
  const [folderDetails, setFolderDetails] = useState({});
  const { folderId } = useParams();
  const [isRestricted, setIsRestricted] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
  });
  const extraFilter = { type: "items", parentAutodeskId: folderId, };

  useEffect(() => {
    async function init() {
      try {
        const result = bimHelper.getDocumentManagementDirectory({ parentAutodeskId: folderId, type: "folder" })
      } catch (e) {
        console.log(e);
      }
    }
    init();
  }, [])

  useEffect(() => {
    setFilter({});
  }, [folderId])

  function getUpdatedDate() {
    let dateTime = moment().set("hour", 23);
    dateTime.set("minute", 0);
    if (moment() < dateTime) {
      dateTime.add("day", -1);
    }
    return dateTime.format("YYYY-MM-DD HH:mm");
  }

  async function handleFilterChange(e) {
    setPage(1);
    console.log(e);
    setFilter(e);
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };


  return (
    <CommonContainer>
      <SectionTitle
        title={folderDetails.code ?? ""}
        extraComponents={<div> <span style={{ fontWeight: 600, marginRight: 8 }}>Last Updated Time: {getUpdatedDate()}</span></div>}
      />
      {isRestricted
        ? isRestricted
        : <CommonDocumentList
          tableType={"bimFolder"}
          useFilter={true}
          filter={filter}
          page={page}
          getDataApi={bimHelper.getDocumentManagementDirectory}
          defaultOrderBy="lastModifiedTime"
          responseKey={"bim_file"}
          hideGoToButton
          extraFilter={extraFilter}
        />
      }
    </CommonContainer>
  );
}