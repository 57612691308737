import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectContractData } from 'redux/slice/contractSlice';
import SectionTitle from "Common/SectionTitle";
import SiteDiaryCompliance from 'SiteDiary/SiteDiaryCompliance';

import { useStyles } from "./style";

export default function SiteDiaryDashboard() {
  const contractData = useSelector(selectContractData);
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <SectionTitle title={"Site Diary Dashboard"} />
      <SiteDiaryCompliance data={contractData} />
    </div>
  );
};