import { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';

import { incidentHelper } from 'helpers';
import EllipseButton from 'Common/EllipseButton';
import IconButton from "Common/IconButton";
import SectionTitle from 'Common/SectionTitle';
import CommonDocumentList from 'EDMS/CommonDocumentList';
import FilterPage from 'Common/FilterPage';
import Modal from "Common/Modal";
import { siConfig } from 'EDMS/CommonDocumentList/config';

import SIAddForm from './SIAddForm';
import { useStyles } from "./style";

export default function SIList({ editPermission }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { contractId } = useParams();
  const history = useHistory();
  const [modal, setModal] = useState({ type: "", open: false, data: null });
  const [showFilter, setShowFilter] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
  });

  async function handleFilterChange(e) {
    setPage(1);
    setFilter(e);
  }

  const handleOpenFilter = () => {
    setShowFilter(!showFilter);
  };

  function handleGoToDetails(rowDetails) {
    if (rowDetails.incidentNumber) history.push(`/contract/${contractId}/incident/document/${rowDetails.incidentId}`);
    else history.push(`/contract/${contractId}/main-contractor/document/${rowDetails.id}`)
  }

  return (
    <>
      <SectionTitle
        title={"Main Contractor Document"}
        extraComponents={
          <div className={classes.buttonsContainer}>
            {editPermission && <EllipseButton onClick={() => setModal({ type: "Add", open: true })} label="+ New Document" />}
            <div style={{ marginLeft: "16px" }}>
              <IconButton
                icon={<FilterListIcon />}
                onClick={handleOpenFilter}
              />
            </div>
          </div>
        }
      />
      <CommonDocumentList
        tableType={"SI"}
        useFilter={useFilter}
        filter={filter}
        page={page}
        getDataApi={incidentHelper.getSiteInstructions}
        defaultOrderBy="siNumber"
        responseKey={"site_instruction"}
        handleAction={(item) => setModal({ type: "Edit", open: true, data: item })}
        actionIcon={editPermission && <EditIcon fontSize={"default"} />}
        handleGoTo={(item) => handleGoToDetails(item)}
      />
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => setShowFilter(false)}
        classes={{
          paperAnchorRight: classes.drawer
        }}
      >
        <FilterPage fieldsData={siConfig} setFilter={handleFilterChange} {...{ filter, setUseFilter, setShowFilter, setPage }} />
      </Drawer>
      <Modal open={modal.open} handleClose={() => setModal({ type: modal.type, open: false, data: null })}>
        <SIAddForm mode={modal.type} handleClose={() => setModal({ type: modal.type, open: false, data: null })} data={modal.data} enableRedirection={modal.type === "Add"} />
      </Modal>
    </>
  );
};