import { useState } from 'react';
import { useTheme } from "@material-ui/core";
import moment from 'moment';

import RoundedButton from "Common/RoundedButton";

import { useStyles } from "./style";
import CustTextField from 'Common/TextField';
import edmsRegisterHelper from 'helpers/edmsRegisterHelper';
import CustSelect from 'Common/Select';
import { authHelper } from 'helpers';

const statusList = [
  {
    label: "Outstanding",
    value: "Outstanding"
  },
  {
    label: "Processing",
    value: "Processing"
  },
  {
    label: "Finished",
    value: "Finished"
  },
]

function MessageCell({ data, type, reload }) {
  const classes = useStyles();
  const theme = useTheme();
  const [isExpand, setExpand] = useState(false);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(data.status ?? "");

  function init() {
    setStatus(data.status);
    setMessage("");
    setShowReplyBox(false);
    setExpand(false);
  }

  function renderMessage(idx) {
    return <>
      <div className={classes.latestMessage}>
        <span style={{ marginRight: "4px", fontWeight: 600 }}>{data.messages[idx].onBehalfOf}</span>
        <span>{data.messages[idx].content}</span>
      </div>
      <div style={{ color: "gray" }}> {moment(data.messages[idx].createdAt).format('yyyy-MM-DD HH:mm')}</div>
    </>
  }

  function renderAllMessage() {
    return data.messages.map((message, idx) => {
      if (idx === 0) return <></>;
      return renderMessage(idx);
    })
  }

  function handleNRR() {
    setShowReplyBox(true);
    setStatus("Finished");
    setMessage("Finished By NRR");
  }

  async function handleSubmit() {
    try {
      const userInfo = await authHelper.getMyInfo();
      if (userInfo.error || userInfo?.user?.length < 1) throw new Error({ message: "User Info not Found" });
      const requestBody = {
        onBehalfOf: `${userInfo.user[0].firstName} ${userInfo.user[0].lastName}`,
        actionRequestId: data.actionRequestId,
        status: status,
        content: message,
      };
      await edmsRegisterHelper.postMessage(requestBody);
      init();
      reload();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      {data?.messages?.[0] &&
        <div onClick={() => setExpand(!isExpand)} style={{ cursor: "pointer" }}>
          {renderMessage(0)}
        </div>
      }
      <div className={classes.buttonGroup}>
        <RoundedButton width={100} margin="4px" backgroundColor={theme.palette.primaryLight} onClick={handleNRR}>NRR</RoundedButton>
        <RoundedButton width={100} margin="4px" backgroundColor={theme.palette.primaryLight} onClick={() => setShowReplyBox(true)}>Reply</RoundedButton>
      </div>

      {showReplyBox &&
        <>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <span style={{ fontWeight: 600, marginRight: "8px" }}>To:</span><CustTextField disabled value={data.messages[0].onBehalfOf} hideLabel />
          </div>
          <CustTextField hideLabel rows={3} handleChange={(e) => setMessage(e.target.value)} value={message}></CustTextField>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <div style={{ width: "150px" }}><CustSelect value={status} options={statusList} handleChange={(e) => setStatus(e.target.value)}></CustSelect></div>
            <RoundedButton width={100} margin="4px" backgroundColor={theme.palette.primaryLight} onClick={handleSubmit}>Submit</RoundedButton>
          </div>
        </>
      }

      {isExpand && <div className={classes.oldMessage}>
        {data?.messages?.[1] && renderAllMessage()}
      </div>
      }
    </>
  );
}

export default MessageCell;