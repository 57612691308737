import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';

import chartHelper from 'utils/chartHelper';
import { dashboardHelper } from 'helpers';
import { setLoading } from 'redux/slice/loadingSlice';
import ChartContainer from 'Common/ChartContainer';
import ChartHeader from 'Common/ChartHeader';
import { Pie } from 'Common/Chart';
import { useStyles } from "./style";

export default function DocTypeSummaryPieChart({ startDate, endDate, hack, hackData }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { contractId } = useParams();
  const classes = useStyles(theme);
  const [data, setData] = useState([])

  useEffect(() => {
    getDashboardData();
  }, [startDate, endDate, hackData]);

  async function getDashboardData() {
    try {
      if (hack) {
        setData(hackData ?? []);
      } else {
        const variable = {
          contractId,
          startDate: startDate.clone().format('YYYY-MM-DD'),
          endDate: endDate.clone().format('YYYY-MM-DD'),
        }
        const result = await dashboardHelper.getCleansingSummaryPie({ variable });
        if (chartHelper.checkIsNotEmpty(result)) setData(result);
        else setData([]);
      }
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <ChartContainer elevation={0} height={"600px"}>
      <ChartHeader
        title={'EDMS Document Type Summary'}
        filterType={"none"}
      />
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {data.length === 0
          ? <div>No/Insufficient data for the time period</div>
          : <Pie
            data={data}
            colors={{ "scheme": "category10" }}
            innerRadius={0.6}
            enableArcLabels={true}
            enableArcLinkLabels={false}
            enablePercentageLabel={true}
            margin={{ top: 10, right: 50, bottom: 150, left: 50 }}
            legends={[
              {
                anchor: 'bottom',
                direction: 'column',
                justify: false,
                translateX: 25,
                translateY: 100,
                itemsSpacing: 0,
                itemWidth: 200,
                itemHeight: 18,
                itemTextColor: '#fff',
                itemDirection: 'left-to-right',
                itemOpacity: 0.9,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        }
      </div>
    </ChartContainer>
  );
}