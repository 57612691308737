import { useParams } from 'react-router';

import CommonContainer from './CommonContainer';
import EDMSDocumentList from './EDMSDocumentList';

export default function EDMS() {
  const { type } = useParams();

  return (
    <CommonContainer withInfoHeader={true}>
      <EDMSDocumentList type={type} />
    </CommonContainer>
  );
}