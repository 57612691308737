import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useLocation } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { incidentHelper, relatedDocHelper } from "helpers";
import { setNotification } from "redux/slice/notificationSlice";
import fieldHelper from "utils/fieldHelper";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";
import Loading from "Common/Loading";

import CloseIcon from "static/icons/close.svg";

import { incidentFieldConfig } from "./config";
import { useStyles } from "./style";
import ConfirmButton from "Common/ConfirmButton";

export default function IncidentAddForm({ mode, handleClose, reload, enableRedirection, data }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { contractId } = useParams();
  const [canChange, setCanChange] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      ...data
    }
  })

  const sp = new URLSearchParams(location.search);

  useEffect(() => {
    getStatus();
  }, []);

  async function getStatus() {
    try {
      if (data) {
        const result = await Promise.all([incidentHelper.getIncident(data.id), relatedDocHelper.getRelatedDocuments({ docType: "incident", docId: data.id })]);
        const siteDocNoStatus = result[0]?.incident?.siteInstructions.filter(item => item.status).length === 0;
        const noRelatedDoc = result[1]?.related_document?.relatedDocuments?.fileMetaId?.length === 0;
        if (siteDocNoStatus && noRelatedDoc) setCanChange(true);
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function onSubmit(data) {
    try {
      setLoading(true);
      const requestVariables = { contractId, ...data };
      if (mode === "Add") {
        var result = await incidentHelper.createIncident(requestVariables);
        if (result.error) throw new Error(result.error);
        dispatch(setNotification({ type: "success", message: "New Incident Created" }))
      } else {
        var result = await incidentHelper.editIncident(data.id, requestVariables);
        dispatch(setNotification({ type: "success", message: "Incident Updated" }))
      }

      if (enableRedirection) history.push(`/contract/${contractId}/incident/document/${result.incident.id}`);
      else {
        const updated = sp.has('updated') ? sp.get('updated') : 1;
        history.push({ pathname: location.pathname, search: `tab=incident&updated=${Number(updated) + 1}` });
      }
      handleClose();
      setLoading(false);
      if (reload) reload();
    } catch (e) {
      if (e.message) dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  async function handleDelete() {
    try {
      const result = await incidentHelper.deleteIncident(data.id);
      if (result.error) throw new Error(result.error);
      const updated = sp.has('updated') ? sp.get('updated') : 1;
      history.push({ pathname: location.pathname, search: `tab=incident&updated=${Number(updated) + 1}` });
      dispatch(setNotification({ type: "success", message: "Successfully delete incident" }));
      handleClose();
    } catch (error) {
      console.log(error);
      if (error.message) dispatch(setNotification({ type: "error", message: error.message }))
    }
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title={`${mode} Incident`}
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"close"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3} className={classes.section}>
            {incidentFieldConfig.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors, { size: inputField.size }))}
          </Grid>
        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          {canChange && <ConfirmButton children={"Delete"} type="button" message="Confirm to Delete?" onClick={handleDelete} />}
          {(canChange || mode === "Add") && <Button children={mode === "Add" ? "Add" : "Save"} backgroundColor="#70BAD3" type="submit" />}
        </DialogActions>
      </form>
    </>
  );
}