import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  Checkbox: {
    color: "#fff",
    borderRadius: "0",
    "&.MuiButtonBase-root:hover, &.MuiButtonBase-root:focus": {
      backgroundColor: "inherit",
    },
    "&.Mui-checked": {
      color: 'white',
      fill: "white"
    }
  },
  Checked: {
    color: "#fff !important"
  },
  indeterminate: {
    color: "#fff !important"
  }
});