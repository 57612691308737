export const location0603 = [
  {
    "label": "Portion A 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion A 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion A 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion A 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion A 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion A 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion A 132kV POWER SUBSTATION Basement (B/F)",
    "value": "Portion A 132kV POWER SUBSTATION Basement (B/F)"
  },
  {
    "label": "Portion A 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion A 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion A 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion A 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion A CHP BUILDING 1st Floor (1/F)",
    "value": "Portion A CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion A CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion A CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion A CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion A CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion A CHP BUILDING Basement (B/F)",
    "value": "Portion A CHP BUILDING Basement (B/F)"
  },
  {
    "label": "Portion A CHP BUILDING Ground Floor (G/F)",
    "value": "Portion A CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion A CHP BUILDING Roofing (R/F)",
    "value": "Portion A CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion A SEWAGE PUMPING STATION 1st Floor (1/F)",
    "value": "Portion A SEWAGE PUMPING STATION 1st Floor (1/F)"
  },
  {
    "label": "Portion A SEWAGE PUMPING STATION 2nd Floor (2/F)",
    "value": "Portion A SEWAGE PUMPING STATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion A SEWAGE PUMPING STATION 3rd Floor (3/F)",
    "value": "Portion A SEWAGE PUMPING STATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion A SEWAGE PUMPING STATION Basement (B/F)",
    "value": "Portion A SEWAGE PUMPING STATION Basement (B/F)"
  },
  {
    "label": "Portion A SEWAGE PUMPING STATION Ground Floor (G/F)",
    "value": "Portion A SEWAGE PUMPING STATION Ground Floor (G/F)"
  },
  {
    "label": "Portion A SEWAGE PUMPING STATION Roofing (R/F)",
    "value": "Portion A SEWAGE PUMPING STATION Roofing (R/F)"
  },
  {
    "label": "Portion A SLUDGE DEWATERING BUILDING 1st Floor (1/F)",
    "value": "Portion A SLUDGE DEWATERING BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion A SLUDGE DEWATERING BUILDING 2nd Floor (2/F)",
    "value": "Portion A SLUDGE DEWATERING BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion A SLUDGE DEWATERING BUILDING 3rd Floor (3/F)",
    "value": "Portion A SLUDGE DEWATERING BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion A SLUDGE DEWATERING BUILDING Basement (B/F)",
    "value": "Portion A SLUDGE DEWATERING BUILDING Basement (B/F)"
  },
  {
    "label": "Portion A SLUDGE DEWATERING BUILDING Ground Floor (G/F)",
    "value": "Portion A SLUDGE DEWATERING BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion A SLUDGE DEWATERING BUILDING Roofing (R/F)",
    "value": "Portion A SLUDGE DEWATERING BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Basement (B/F)",
    "value": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Basement (B/F)"
  },
  {
    "label": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion A SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement (B/F)",
    "value": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement (B/F)"
  },
  {
    "label": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Basement (B/F)",
    "value": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Basement (B/F)"
  },
  {
    "label": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion A UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion A WORKSHOP NO. 2 1st Floor (1/F)",
    "value": "Portion A WORKSHOP NO. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion A WORKSHOP NO. 2 2nd Floor (2/F)",
    "value": "Portion A WORKSHOP NO. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion A WORKSHOP NO. 2 3rd Floor (3/F)",
    "value": "Portion A WORKSHOP NO. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion A WORKSHOP NO. 2 Basement (B/F)",
    "value": "Portion A WORKSHOP NO. 2 Basement (B/F)"
  },
  {
    "label": "Portion A WORKSHOP NO. 2 Ground Floor (G/F)",
    "value": "Portion A WORKSHOP NO. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion A WORKSHOP NO. 2 Roofing (R/F)",
    "value": "Portion A WORKSHOP NO. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION Basement (B/F)",
    "value": "Portion B 132kV POWER SUBSTATION Basement (B/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B CHP BUILDING Basement (B/F)",
    "value": "Portion B CHP BUILDING Basement (B/F)"
  },
  {
    "label": "Portion B CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B CHP BUILDING Roofing (R/F)",
    "value": "Portion B CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION 1st Floor (1/F)",
    "value": "Portion B SEWAGE PUMPING STATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION 2nd Floor (2/F)",
    "value": "Portion B SEWAGE PUMPING STATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION 3rd Floor (3/F)",
    "value": "Portion B SEWAGE PUMPING STATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION Basement (B/F)",
    "value": "Portion B SEWAGE PUMPING STATION Basement (B/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION Ground Floor (G/F)",
    "value": "Portion B SEWAGE PUMPING STATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION Roofing (R/F)",
    "value": "Portion B SEWAGE PUMPING STATION Roofing (R/F)"
  },
  {
    "label": "Portion B SLUDGE DEWATERING BUILDING 1st Floor (1/F)",
    "value": "Portion B SLUDGE DEWATERING BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B SLUDGE DEWATERING BUILDING 2nd Floor (2/F)",
    "value": "Portion B SLUDGE DEWATERING BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B SLUDGE DEWATERING BUILDING 3rd Floor (3/F)",
    "value": "Portion B SLUDGE DEWATERING BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B SLUDGE DEWATERING BUILDING Basement (B/F)",
    "value": "Portion B SLUDGE DEWATERING BUILDING Basement (B/F)"
  },
  {
    "label": "Portion B SLUDGE DEWATERING BUILDING Ground Floor (G/F)",
    "value": "Portion B SLUDGE DEWATERING BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B SLUDGE DEWATERING BUILDING Roofing (R/F)",
    "value": "Portion B SLUDGE DEWATERING BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Basement (B/F)",
    "value": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Basement (B/F)"
  },
  {
    "label": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement (B/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement (B/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Basement (B/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Basement (B/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 1st Floor (1/F)",
    "value": "Portion B WORKSHOP NO. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 2nd Floor (2/F)",
    "value": "Portion B WORKSHOP NO. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 3rd Floor (3/F)",
    "value": "Portion B WORKSHOP NO. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 Basement (B/F)",
    "value": "Portion B WORKSHOP NO. 2 Basement (B/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 Ground Floor (G/F)",
    "value": "Portion B WORKSHOP NO. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 Roofing (R/F)",
    "value": "Portion B WORKSHOP NO. 2 Roofing (R/F)"
  },
  {
    "label": "Portion C 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion C 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion C 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion C 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion C 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion C 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion C 132kV POWER SUBSTATION Basement (B/F)",
    "value": "Portion C 132kV POWER SUBSTATION Basement (B/F)"
  },
  {
    "label": "Portion C 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion C 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion C 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion C 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion C CHP BUILDING 1st Floor (1/F)",
    "value": "Portion C CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion C CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion C CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion C CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion C CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion C CHP BUILDING Basement (B/F)",
    "value": "Portion C CHP BUILDING Basement (B/F)"
  },
  {
    "label": "Portion C CHP BUILDING Ground Floor (G/F)",
    "value": "Portion C CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion C CHP BUILDING Roofing (R/F)",
    "value": "Portion C CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion C SEWAGE PUMPING STATION 1st Floor (1/F)",
    "value": "Portion C SEWAGE PUMPING STATION 1st Floor (1/F)"
  },
  {
    "label": "Portion C SEWAGE PUMPING STATION 2nd Floor (2/F)",
    "value": "Portion C SEWAGE PUMPING STATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion C SEWAGE PUMPING STATION 3rd Floor (3/F)",
    "value": "Portion C SEWAGE PUMPING STATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion C SEWAGE PUMPING STATION Basement (B/F)",
    "value": "Portion C SEWAGE PUMPING STATION Basement (B/F)"
  },
  {
    "label": "Portion C SEWAGE PUMPING STATION Ground Floor (G/F)",
    "value": "Portion C SEWAGE PUMPING STATION Ground Floor (G/F)"
  },
  {
    "label": "Portion C SEWAGE PUMPING STATION Roofing (R/F)",
    "value": "Portion C SEWAGE PUMPING STATION Roofing (R/F)"
  },
  {
    "label": "Portion C SLUDGE DEWATERING BUILDING 1st Floor (1/F)",
    "value": "Portion C SLUDGE DEWATERING BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion C SLUDGE DEWATERING BUILDING 2nd Floor (2/F)",
    "value": "Portion C SLUDGE DEWATERING BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion C SLUDGE DEWATERING BUILDING 3rd Floor (3/F)",
    "value": "Portion C SLUDGE DEWATERING BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion C SLUDGE DEWATERING BUILDING Basement (B/F)",
    "value": "Portion C SLUDGE DEWATERING BUILDING Basement (B/F)"
  },
  {
    "label": "Portion C SLUDGE DEWATERING BUILDING Ground Floor (G/F)",
    "value": "Portion C SLUDGE DEWATERING BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion C SLUDGE DEWATERING BUILDING Roofing (R/F)",
    "value": "Portion C SLUDGE DEWATERING BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Basement (B/F)",
    "value": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Basement (B/F)"
  },
  {
    "label": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion C SLUDGE DIGESTERS & DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement (B/F)",
    "value": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement (B/F)"
  },
  {
    "label": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Basement (B/F)",
    "value": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Basement (B/F)"
  },
  {
    "label": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion C UV SYSTEM NO. 1 & EFFLUENT PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion C WORKSHOP NO. 2 1st Floor (1/F)",
    "value": "Portion C WORKSHOP NO. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion C WORKSHOP NO. 2 2nd Floor (2/F)",
    "value": "Portion C WORKSHOP NO. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion C WORKSHOP NO. 2 3rd Floor (3/F)",
    "value": "Portion C WORKSHOP NO. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion C WORKSHOP NO. 2 Basement (B/F)",
    "value": "Portion C WORKSHOP NO. 2 Basement (B/F)"
  },
  {
    "label": "Portion C WORKSHOP NO. 2 Ground Floor (G/F)",
    "value": "Portion C WORKSHOP NO. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion C WORKSHOP NO. 2 Roofing (R/F)",
    "value": "Portion C WORKSHOP NO. 2 Roofing (R/F)"
  }
]

export const location0704 = [
  {
    "label": "Portion B 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B Access Road AR3 1st Floor (1/F)",
    "value": "Portion B Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Access Road AR3 Roofing (R/F)",
    "value": "Portion B Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B CHP BUILDING Roofing (R/F)",
    "value": "Portion B CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B Chemical Storage Room Roofing (R/F)",
    "value": "Portion B Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B Contractors Storage Area Roofing (R/F)",
    "value": "Portion B Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B Diesel Tank 1st Floor (1/F)",
    "value": "Portion B Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Diesel Tank Ground Floor (G/F)",
    "value": "Portion B Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B Diesel Tank Roofing (R/F)",
    "value": "Portion B Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B Main Power House 1st Floor (1/F)",
    "value": "Portion B Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B Main Power House 2nd Floor (2/F)",
    "value": "Portion B Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Main Power House 3rd Floor (3/F)",
    "value": "Portion B Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Main Power House Basement 1 (B1/F)",
    "value": "Portion B Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Main Power House Basement 2 (B2/F)",
    "value": "Portion B Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Main Power House Ground Floor (G/F)",
    "value": "Portion B Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B Main Power House Roofing (R/F)",
    "value": "Portion B Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-1 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-1 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-1 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-1 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-1 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-1 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-1 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-1 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-1 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-1 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-1 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-1 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-1 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-1 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-1 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-1 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-1 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-1 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-1 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-1 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-1 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-1 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-1 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-1 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-1 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-1 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-1 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-1 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-1 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-1 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-1 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-1 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-1 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-1 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-1 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-1 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-1 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-1 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-1 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-1 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-1 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-1 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-1 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-1 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-1 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-1 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-1 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-1 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-1 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-1 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-1 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-1 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-1 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-1 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-1 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-1 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-1 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-1 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-1 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-1 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-1 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-1 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-1 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-1 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-1 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-1 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-1 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-1 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-1 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-1 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-1 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-1 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-1 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-1 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-1 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-1 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Diesel Tank Roofing (R/F)",
    "value": "Portion B-1 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-1 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-1 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-1 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-1 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-1 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-1 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-1 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-1 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-1 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-1 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-1 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-1 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-1 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-1 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-1 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-1 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-1 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-1 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-1 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-1 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-1 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-1 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-1 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-1 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-1 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-1 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Main Power House 1st Floor (1/F)",
    "value": "Portion B-1 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-1 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-1 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-1 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-1 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Main Power House Ground Floor (G/F)",
    "value": "Portion B-1 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Main Power House Roofing (R/F)",
    "value": "Portion B-1 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-1 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-1 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-1 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-1 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-1 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-1 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-1 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-1 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-1 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-1 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-1 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-1 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-1 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-1 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-1 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-1 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-1 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-1 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-1 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-1 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-1 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-1 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-1 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-1 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-1 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-1 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-1 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-1 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-1 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-1 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-1A 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-1A 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-1A 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-1A 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-1A 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-1A 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-1A 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-1A 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-1A Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-1A Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-1A Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-1A Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-1A Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-1A Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Access Road AR3 Roofing (R/F)",
    "value": "Portion B-1A Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-1A Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-1A Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-1A Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-1A Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-1A Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-1A Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-1A Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-1A Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-1A Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-1A Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-1A Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-1A Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-1A Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-1A Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-1A Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-1A Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-1A CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-1A CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-1A CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-1A CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-1A CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-1A CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-1A CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A CHP BUILDING Roofing (R/F)",
    "value": "Portion B-1A CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-1A Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-1A Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-1A Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-1A Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-1A Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-1A Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-1A Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-1A Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-1A Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-1A Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-1A Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-1A Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-1A Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-1A Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-1A Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-1A Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-1A Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-1A Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-1A Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-1A Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-1A Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-1A Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-1A Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-1A Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-1A Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-1A Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-1A Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-1A Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-1A Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-1A Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-1A Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-1A Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-1A Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-1A Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-1A Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-1A Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-1A Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Diesel Tank Roofing (R/F)",
    "value": "Portion B-1A Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-1A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-1A Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-1A Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-1A Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-1A Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-1A Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-1A Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-1A Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-1A Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-1A Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-1A Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-1A Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-1A Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-1A Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-1A Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-1A Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-1A Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-1A Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-1A Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-1A Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-1A Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-1A Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-1A Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-1A Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-1A Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-1A Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Main Power House 1st Floor (1/F)",
    "value": "Portion B-1A Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Main Power House 2nd Floor (2/F)",
    "value": "Portion B-1A Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Main Power House 3rd Floor (3/F)",
    "value": "Portion B-1A Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Main Power House Basement 1 (B1/F)",
    "value": "Portion B-1A Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Main Power House Basement 2 (B2/F)",
    "value": "Portion B-1A Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Main Power House Ground Floor (G/F)",
    "value": "Portion B-1A Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Main Power House Roofing (R/F)",
    "value": "Portion B-1A Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-1A Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-1A Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-1A PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-1A PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-1A PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-1A PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-1A PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-1A Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-1A Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-1A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-1A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-1A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-1A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-1A Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-1A Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-1A Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-1A Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-1A Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-1A Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-1A Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-1A Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-1A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-1A WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-1A WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-1A WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-1A WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-1A WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-1A WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-1A WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-1A WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-1A WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-1A WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-1A WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-1A WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-1A WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-1A WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-2 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-2 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-2 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-2 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-2 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-2 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-2 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-2 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-2 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-2 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-2 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-2 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-2 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-2 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-2 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-2 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-2 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-2 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-2 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-2 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-2 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-2 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-2 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-2 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-2 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-2 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-2 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-2 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-2 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-2 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-2 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-2 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-2 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-2 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-2 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-2 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-2 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-2 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-2 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-2 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-2 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-2 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-2 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-2 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-2 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-2 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-2 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-2 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-2 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-2 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-2 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-2 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-2 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-2 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-2 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-2 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-2 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-2 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-2 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-2 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-2 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-2 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-2 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-2 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-2 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-2 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-2 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-2 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-2 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-2 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-2 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-2 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-2 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-2 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-2 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-2 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Diesel Tank Roofing (R/F)",
    "value": "Portion B-2 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-2 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-2 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-2 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-2 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-2 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-2 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-2 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-2 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-2 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-2 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-2 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-2 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-2 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-2 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-2 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-2 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-2 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-2 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-2 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-2 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-2 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-2 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-2 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-2 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-2 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-2 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Main Power House 1st Floor (1/F)",
    "value": "Portion B-2 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-2 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-2 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-2 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-2 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Main Power House Ground Floor (G/F)",
    "value": "Portion B-2 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Main Power House Roofing (R/F)",
    "value": "Portion B-2 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-2 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-2 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-2 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-2 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-2 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-2 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-2 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-2 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-2 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-2 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-2 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-2 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-2 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-2 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-2 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-2 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-2 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-2 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-2 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-2 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-2 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-2 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-2 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-2 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-2 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-2 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-2 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-2 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-2 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-2 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-2A 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-2A 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-2A 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-2A 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-2A 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-2A 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-2A 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-2A 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-2A Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-2A Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-2A Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-2A Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-2A Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-2A Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Access Road AR3 Roofing (R/F)",
    "value": "Portion B-2A Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-2A Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-2A Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-2A Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-2A Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-2A Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-2A Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-2A Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-2A Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-2A Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-2A Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-2A Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-2A Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-2A Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-2A Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-2A Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-2A Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-2A CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-2A CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-2A CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-2A CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-2A CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-2A CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-2A CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A CHP BUILDING Roofing (R/F)",
    "value": "Portion B-2A CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-2A Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-2A Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-2A Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-2A Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-2A Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-2A Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-2A Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-2A Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-2A Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-2A Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-2A Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-2A Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-2A Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-2A Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-2A Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-2A Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-2A Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-2A Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-2A Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-2A Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-2A Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-2A Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-2A Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-2A Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-2A Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-2A Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-2A Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-2A Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-2A Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-2A Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-2A Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-2A Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-2A Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-2A Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-2A Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-2A Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-2A Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Diesel Tank Roofing (R/F)",
    "value": "Portion B-2A Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-2A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-2A Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-2A Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-2A Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-2A Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-2A Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-2A Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-2A Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-2A Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-2A Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-2A Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-2A Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-2A Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-2A Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-2A Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-2A Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-2A Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-2A Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-2A Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-2A Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-2A Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-2A Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-2A Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-2A Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-2A Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-2A Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Main Power House 1st Floor (1/F)",
    "value": "Portion B-2A Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Main Power House 2nd Floor (2/F)",
    "value": "Portion B-2A Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Main Power House 3rd Floor (3/F)",
    "value": "Portion B-2A Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Main Power House Basement 1 (B1/F)",
    "value": "Portion B-2A Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Main Power House Basement 2 (B2/F)",
    "value": "Portion B-2A Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Main Power House Ground Floor (G/F)",
    "value": "Portion B-2A Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Main Power House Roofing (R/F)",
    "value": "Portion B-2A Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-2A Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-2A Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-2A PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-2A PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-2A PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-2A PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-2A PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-2A Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-2A Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-2A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-2A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-2A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-2A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-2A Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-2A Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-2A Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-2A Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-2A Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-2A Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-2A Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-2A Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-2A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-2A WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-2A WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-2A WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-2A WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-2A WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-2A WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-2A WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-2A WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-2A WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-2A WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-2A WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-2A WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-2A WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-2A WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-3 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-3 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-3 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-3 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-3 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-3 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-3 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-3 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-3 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-3 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-3 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-3 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-3 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-3 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-3 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-3 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-3 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-3 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-3 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-3 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-3 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-3 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-3 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-3 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-3 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-3 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-3 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-3 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-3 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-3 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-3 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-3 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-3 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-3 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-3 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-3 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-3 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-3 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-3 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-3 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-3 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-3 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-3 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-3 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-3 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-3 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-3 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-3 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-3 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-3 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-3 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-3 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-3 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-3 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-3 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-3 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-3 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-3 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-3 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-3 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-3 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-3 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-3 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-3 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-3 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-3 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-3 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-3 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-3 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-3 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-3 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-3 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-3 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-3 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-3 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-3 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Diesel Tank Roofing (R/F)",
    "value": "Portion B-3 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-3 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-3 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-3 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-3 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-3 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-3 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-3 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-3 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-3 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-3 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-3 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-3 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-3 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-3 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-3 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-3 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-3 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-3 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-3 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-3 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-3 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-3 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-3 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-3 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-3 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-3 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Main Power House 1st Floor (1/F)",
    "value": "Portion B-3 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-3 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-3 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-3 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-3 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Main Power House Ground Floor (G/F)",
    "value": "Portion B-3 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Main Power House Roofing (R/F)",
    "value": "Portion B-3 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-3 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-3 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-3 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-3 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-3 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-3 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-3 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-3 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-3 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-3 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-3 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-3 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-3 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-3 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-3 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-3 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-3 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-3 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-3 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-3 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-3 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-3 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-3 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-3 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-3 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-3 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-3 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-3 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-3 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-3 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-3 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-3 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-3 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-3 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-3 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-3 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-3 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-4 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-4 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-4 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-4 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-4 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-4 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-4 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-4 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-4 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-4 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-4 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-4 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-4 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-4 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-4 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-4 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-4 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-4 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-4 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-4 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-4 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-4 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-4 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-4 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-4 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-4 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-4 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-4 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-4 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-4 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-4 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-4 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-4 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-4 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-4 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-4 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-4 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-4 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-4 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-4 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-4 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-4 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-4 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-4 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-4 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-4 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-4 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-4 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-4 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-4 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-4 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-4 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-4 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-4 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-4 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-4 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-4 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-4 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-4 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-4 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-4 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-4 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-4 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-4 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-4 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-4 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-4 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-4 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-4 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-4 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-4 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-4 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-4 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-4 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-4 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-4 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Diesel Tank Roofing (R/F)",
    "value": "Portion B-4 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-4 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-4 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-4 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-4 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-4 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-4 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-4 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-4 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-4 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-4 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-4 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-4 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-4 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-4 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-4 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-4 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-4 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-4 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-4 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-4 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-4 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-4 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-4 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-4 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-4 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-4 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Main Power House 1st Floor (1/F)",
    "value": "Portion B-4 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-4 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-4 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-4 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-4 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Main Power House Ground Floor (G/F)",
    "value": "Portion B-4 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Main Power House Roofing (R/F)",
    "value": "Portion B-4 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-4 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-4 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-4 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-4 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-4 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-4 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-4 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-4 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-4 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-4 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-4 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-4 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-4 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-4 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-4 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-4 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-4 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-4 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-4 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-4 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-4 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-4 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-4 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-4 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-4 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-4 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-4 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-4 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-4 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-4 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-4 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-4 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-4 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-4 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-4 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-4 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-4 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-5 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-5 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-5 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-5 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-5 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-5 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-5 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-5 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-5 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-5 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-5 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-5 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-5 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-5 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-5 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-5 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-5 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-5 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-5 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-5 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-5 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-5 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-5 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-5 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-5 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-5 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-5 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-5 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-5 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-5 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-5 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-5 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-5 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-5 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-5 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-5 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-5 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-5 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-5 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-5 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-5 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-5 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-5 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-5 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-5 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-5 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-5 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-5 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-5 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-5 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-5 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-5 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-5 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-5 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-5 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-5 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-5 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-5 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-5 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-5 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-5 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-5 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-5 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-5 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-5 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-5 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-5 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-5 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-5 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-5 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-5 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-5 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-5 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-5 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-5 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-5 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Diesel Tank Roofing (R/F)",
    "value": "Portion B-5 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-5 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-5 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-5 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-5 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-5 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-5 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-5 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-5 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-5 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-5 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-5 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-5 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-5 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-5 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-5 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-5 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-5 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-5 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-5 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-5 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-5 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-5 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-5 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-5 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-5 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-5 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Main Power House 1st Floor (1/F)",
    "value": "Portion B-5 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-5 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-5 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-5 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-5 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Main Power House Ground Floor (G/F)",
    "value": "Portion B-5 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Main Power House Roofing (R/F)",
    "value": "Portion B-5 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-5 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-5 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-5 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-5 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-5 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-5 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-5 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-5 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-5 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-5 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-5 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-5 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-5 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-5 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-5 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-5 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-5 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-5 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-5 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-5 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-5 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-5 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-5 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-5 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-5 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-5 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-5 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-5 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-5 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-5 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-5 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-5 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-5 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-5 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-5 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-5 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-5 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-6 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-6 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-6 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-6 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-6 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-6 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-6 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-6 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-6 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-6 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-6 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-6 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-6 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-6 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-6 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-6 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-6 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-6 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-6 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-6 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-6 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-6 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-6 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-6 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-6 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-6 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-6 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-6 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-6 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-6 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-6 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-6 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-6 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-6 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-6 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-6 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-6 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-6 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-6 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-6 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-6 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-6 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-6 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-6 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-6 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-6 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-6 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-6 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-6 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-6 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-6 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-6 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-6 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-6 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-6 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-6 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-6 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-6 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-6 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-6 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-6 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-6 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-6 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-6 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-6 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-6 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-6 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-6 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-6 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-6 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-6 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-6 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-6 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-6 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-6 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-6 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Diesel Tank Roofing (R/F)",
    "value": "Portion B-6 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-6 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-6 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-6 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-6 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-6 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-6 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-6 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-6 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-6 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-6 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-6 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-6 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-6 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-6 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-6 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-6 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-6 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-6 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-6 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-6 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-6 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-6 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-6 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-6 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-6 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-6 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Main Power House 1st Floor (1/F)",
    "value": "Portion B-6 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-6 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-6 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-6 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-6 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Main Power House Ground Floor (G/F)",
    "value": "Portion B-6 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Main Power House Roofing (R/F)",
    "value": "Portion B-6 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-6 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-6 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-6 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-6 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-6 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-6 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-6 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-6 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-6 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-6 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-6 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-6 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-6 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-6 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-6 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-6 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-6 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-6 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-6 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-6 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-6 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-6 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-6 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-6 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-6 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-6 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-6 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-6 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-6 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-6 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-6 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-6 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-6 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-6 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-6 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-6 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-6 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-7 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-7 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-7 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-7 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-7 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-7 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-7 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-7 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-7 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-7 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-7 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-7 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-7 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-7 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-7 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-7 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-7 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-7 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-7 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-7 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-7 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-7 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-7 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-7 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-7 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-7 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-7 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-7 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-7 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-7 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-7 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-7 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-7 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-7 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-7 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-7 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-7 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-7 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-7 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-7 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-7 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-7 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-7 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-7 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-7 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-7 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-7 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-7 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-7 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-7 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-7 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-7 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-7 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-7 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-7 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-7 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-7 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-7 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-7 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-7 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-7 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-7 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-7 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-7 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-7 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-7 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-7 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-7 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-7 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-7 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-7 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-7 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-7 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-7 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-7 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-7 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Diesel Tank Roofing (R/F)",
    "value": "Portion B-7 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-7 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-7 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-7 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-7 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-7 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-7 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-7 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-7 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-7 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-7 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-7 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-7 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-7 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-7 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-7 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-7 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-7 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-7 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-7 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-7 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-7 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-7 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-7 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-7 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-7 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-7 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Main Power House 1st Floor (1/F)",
    "value": "Portion B-7 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-7 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-7 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-7 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-7 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Main Power House Ground Floor (G/F)",
    "value": "Portion B-7 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Main Power House Roofing (R/F)",
    "value": "Portion B-7 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-7 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-7 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-7 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-7 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-7 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-7 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-7 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-7 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-7 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-7 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-7 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-7 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-7 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-7 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-7 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-7 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-7 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-7 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-7 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-7 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-7 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-7 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-7 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-7 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-7 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-7 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-7 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-7 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-7 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-7 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-7A 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-7A 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-7A 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-7A 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-7A 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-7A 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-7A 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-7A 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-7A Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-7A Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-7A Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-7A Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-7A Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-7A Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Access Road AR3 Roofing (R/F)",
    "value": "Portion B-7A Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-7A Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-7A Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-7A Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-7A Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-7A Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-7A Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-7A Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-7A Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-7A Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-7A Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-7A Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-7A Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-7A Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-7A Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-7A Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-7A Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-7A CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-7A CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-7A CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-7A CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-7A CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-7A CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-7A CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A CHP BUILDING Roofing (R/F)",
    "value": "Portion B-7A CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-7A Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-7A Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-7A Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-7A Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-7A Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-7A Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-7A Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-7A Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-7A Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-7A Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-7A Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-7A Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-7A Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-7A Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-7A Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-7A Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-7A Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-7A Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-7A Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-7A Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-7A Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-7A Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-7A Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-7A Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-7A Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-7A Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-7A Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-7A Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-7A Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-7A Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-7A Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-7A Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-7A Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-7A Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-7A Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-7A Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-7A Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Diesel Tank Roofing (R/F)",
    "value": "Portion B-7A Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-7A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-7A Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-7A Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-7A Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-7A Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-7A Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-7A Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-7A Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-7A Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-7A Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-7A Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-7A Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-7A Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-7A Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-7A Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-7A Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-7A Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-7A Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-7A Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-7A Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-7A Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-7A Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-7A Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-7A Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-7A Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-7A Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Main Power House 1st Floor (1/F)",
    "value": "Portion B-7A Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Main Power House 2nd Floor (2/F)",
    "value": "Portion B-7A Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Main Power House 3rd Floor (3/F)",
    "value": "Portion B-7A Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Main Power House Basement 1 (B1/F)",
    "value": "Portion B-7A Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Main Power House Basement 2 (B2/F)",
    "value": "Portion B-7A Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Main Power House Ground Floor (G/F)",
    "value": "Portion B-7A Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Main Power House Roofing (R/F)",
    "value": "Portion B-7A Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-7A Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-7A Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-7A PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-7A PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-7A PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-7A PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-7A PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-7A Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-7A Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-7A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-7A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-7A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-7A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-7A Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-7A Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-7A Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-7A Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-7A Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-7A Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-7A Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-7A Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-7A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-7A WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-7A WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-7A WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-7A WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-7A WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-7A WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-7A WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-7A WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-7A WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-7A WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-7A WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-7A WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-7A WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-7A WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-8 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-8 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-8 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-8 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-8 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-8 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-8 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-8 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-8 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-8 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-8 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-8 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-8 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-8 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-8 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-8 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-8 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-8 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-8 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-8 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-8 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-8 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-8 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-8 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-8 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-8 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-8 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-8 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-8 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-8 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-8 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-8 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-8 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-8 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-8 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-8 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-8 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-8 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-8 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-8 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-8 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-8 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-8 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-8 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-8 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-8 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-8 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-8 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-8 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-8 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-8 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-8 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-8 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-8 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-8 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-8 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-8 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-8 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-8 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-8 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-8 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-8 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-8 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-8 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-8 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-8 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-8 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-8 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-8 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-8 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-8 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-8 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-8 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-8 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-8 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-8 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Diesel Tank Roofing (R/F)",
    "value": "Portion B-8 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-8 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-8 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-8 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-8 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-8 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-8 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-8 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-8 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-8 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-8 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-8 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-8 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-8 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-8 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-8 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-8 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-8 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-8 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-8 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-8 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-8 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-8 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-8 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-8 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-8 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-8 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Main Power House 1st Floor (1/F)",
    "value": "Portion B-8 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-8 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-8 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-8 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-8 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Main Power House Ground Floor (G/F)",
    "value": "Portion B-8 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Main Power House Roofing (R/F)",
    "value": "Portion B-8 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-8 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-8 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-8 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-8 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-8 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-8 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-8 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-8 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-8 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-8 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-8 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-8 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-8 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-8 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-8 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-8 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-8 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-8 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-8 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-8 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-8 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-8 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-8 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-8 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-8 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-8 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-8 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-8 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-8 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-8 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-8A 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-8A 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-8A 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-8A 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-8A 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-8A 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-8A 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-8A 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-8A Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-8A Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-8A Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-8A Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-8A Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-8A Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Access Road AR3 Roofing (R/F)",
    "value": "Portion B-8A Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-8A Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-8A Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-8A Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-8A Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-8A Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-8A Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-8A Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-8A Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-8A Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-8A Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-8A Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-8A Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-8A Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-8A Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-8A Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-8A Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-8A CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-8A CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-8A CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-8A CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-8A CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-8A CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-8A CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A CHP BUILDING Roofing (R/F)",
    "value": "Portion B-8A CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-8A Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-8A Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-8A Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-8A Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-8A Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-8A Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-8A Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-8A Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-8A Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-8A Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-8A Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-8A Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-8A Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-8A Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-8A Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-8A Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-8A Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-8A Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-8A Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-8A Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-8A Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-8A Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-8A Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-8A Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-8A Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-8A Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-8A Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-8A Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-8A Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-8A Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-8A Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-8A Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-8A Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-8A Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-8A Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-8A Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-8A Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Diesel Tank Roofing (R/F)",
    "value": "Portion B-8A Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-8A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-8A Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-8A Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-8A Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-8A Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-8A Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-8A Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-8A Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-8A Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-8A Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-8A Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-8A Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-8A Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-8A Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-8A Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-8A Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-8A Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-8A Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-8A Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-8A Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-8A Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-8A Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-8A Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-8A Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-8A Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-8A Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Main Power House 1st Floor (1/F)",
    "value": "Portion B-8A Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Main Power House 2nd Floor (2/F)",
    "value": "Portion B-8A Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Main Power House 3rd Floor (3/F)",
    "value": "Portion B-8A Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Main Power House Basement 1 (B1/F)",
    "value": "Portion B-8A Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Main Power House Basement 2 (B2/F)",
    "value": "Portion B-8A Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Main Power House Ground Floor (G/F)",
    "value": "Portion B-8A Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Main Power House Roofing (R/F)",
    "value": "Portion B-8A Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-8A Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-8A Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-8A PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-8A PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-8A PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-8A PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-8A PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-8A Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-8A Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-8A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-8A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-8A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-8A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-8A Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-8A Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-8A Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-8A Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-8A Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-8A Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-8A Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-8A Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-8A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-8A WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-8A WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-8A WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-8A WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-8A WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-8A WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-8A WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-8A WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-8A WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-8A WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-8A WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-8A WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-8A WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-8A WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-9 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-9 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-9 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-9 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-9 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-9 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-9 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-9 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-9 Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-9 Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-9 Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-9 Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-9 Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-9 Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Access Road AR3 Roofing (R/F)",
    "value": "Portion B-9 Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-9 Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9 Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9 Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9 Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9 Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9 Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9 Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-9 Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-9 Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-9 Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-9 Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-9 Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-9 Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-9 Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-9 Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-9 Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-9 CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-9 CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-9 CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-9 CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-9 CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-9 CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-9 CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 CHP BUILDING Roofing (R/F)",
    "value": "Portion B-9 CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-9 Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-9 Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-9 Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-9 Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-9 Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-9 Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-9 Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-9 Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-9 Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-9 Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-9 Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-9 Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-9 Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-9 Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-9 Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-9 Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-9 Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-9 Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-9 Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-9 Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-9 Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-9 Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-9 Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-9 Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-9 Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-9 Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-9 Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-9 Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-9 Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-9 Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-9 Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-9 Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-9 Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-9 Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-9 Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-9 Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-9 Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Diesel Tank Roofing (R/F)",
    "value": "Portion B-9 Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-9 Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-9 Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-9 Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-9 Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-9 Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-9 Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-9 Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-9 Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-9 Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-9 Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9 Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-9 Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-9 Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-9 Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-9 Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-9 Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-9 Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-9 Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-9 Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9 Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9 Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9 Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9 Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9 Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9 Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-9 Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Main Power House 1st Floor (1/F)",
    "value": "Portion B-9 Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Main Power House 2nd Floor (2/F)",
    "value": "Portion B-9 Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Main Power House 3rd Floor (3/F)",
    "value": "Portion B-9 Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Main Power House Basement 1 (B1/F)",
    "value": "Portion B-9 Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Main Power House Basement 2 (B2/F)",
    "value": "Portion B-9 Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Main Power House Ground Floor (G/F)",
    "value": "Portion B-9 Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Main Power House Roofing (R/F)",
    "value": "Portion B-9 Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-9 Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-9 Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9 Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-9 PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-9 PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-9 PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-9 PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-9 PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9 Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-9 Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-9 Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-9 Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-9 SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-9 THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-9 Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-9 Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-9 Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-9 Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-9 Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-9 Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-9 Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-9 Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-9 UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-9 WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-9 WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9 WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-9 WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9 WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-9 WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9 WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-9 WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9 WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-9 WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9 WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-9 WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9 WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-9 WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-9A 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-9A 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-9A 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-9A 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-9A 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-9A 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-9A 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-9A 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-9A Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-9A Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-9A Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-9A Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-9A Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-9A Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Access Road AR3 Roofing (R/F)",
    "value": "Portion B-9A Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-9A Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9A Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9A Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9A Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9A Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9A Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9A Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-9A Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-9A Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-9A Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-9A Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-9A Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-9A Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-9A Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-9A Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-9A Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-9A CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-9A CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-9A CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-9A CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-9A CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-9A CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-9A CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A CHP BUILDING Roofing (R/F)",
    "value": "Portion B-9A CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-9A Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-9A Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-9A Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-9A Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-9A Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-9A Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-9A Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-9A Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-9A Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-9A Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-9A Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-9A Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-9A Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-9A Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-9A Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-9A Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-9A Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-9A Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-9A Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-9A Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-9A Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-9A Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-9A Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-9A Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-9A Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-9A Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-9A Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-9A Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-9A Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-9A Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-9A Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-9A Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-9A Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-9A Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-9A Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-9A Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-9A Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Diesel Tank Roofing (R/F)",
    "value": "Portion B-9A Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-9A Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-9A Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-9A Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-9A Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-9A Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-9A Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-9A Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-9A Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-9A Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-9A Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9A Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-9A Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-9A Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-9A Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-9A Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-9A Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-9A Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-9A Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-9A Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9A Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9A Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9A Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9A Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9A Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9A Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-9A Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Main Power House 1st Floor (1/F)",
    "value": "Portion B-9A Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Main Power House 2nd Floor (2/F)",
    "value": "Portion B-9A Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Main Power House 3rd Floor (3/F)",
    "value": "Portion B-9A Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Main Power House Basement 1 (B1/F)",
    "value": "Portion B-9A Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Main Power House Basement 2 (B2/F)",
    "value": "Portion B-9A Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Main Power House Ground Floor (G/F)",
    "value": "Portion B-9A Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Main Power House Roofing (R/F)",
    "value": "Portion B-9A Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-9A Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-9A Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9A Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-9A PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-9A PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-9A PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-9A PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-9A PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9A Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-9A Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-9A Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-9A Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-9A SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-9A THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-9A Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-9A Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-9A Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-9A Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-9A Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-9A Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-9A Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-9A Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-9A UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-9A WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-9A WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9A WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-9A WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9A WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-9A WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9A WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-9A WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9A WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-9A WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9A WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-9A WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9A WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-9A WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-9B 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-9B 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-9B 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-9B 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-9B 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-9B 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-9B 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-9B 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-9B Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-9B Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-9B Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-9B Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-9B Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-9B Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Access Road AR3 Roofing (R/F)",
    "value": "Portion B-9B Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-9B Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9B Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9B Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9B Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9B Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9B Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9B Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-9B Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-9B Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-9B Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-9B Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-9B Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-9B Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-9B Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-9B Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-9B Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-9B CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-9B CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-9B CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-9B CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-9B CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-9B CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-9B CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B CHP BUILDING Roofing (R/F)",
    "value": "Portion B-9B CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-9B Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-9B Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-9B Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-9B Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-9B Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-9B Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-9B Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-9B Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-9B Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-9B Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-9B Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-9B Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-9B Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-9B Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-9B Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-9B Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-9B Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-9B Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-9B Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-9B Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-9B Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-9B Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-9B Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-9B Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-9B Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-9B Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-9B Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-9B Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-9B Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-9B Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-9B Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-9B Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-9B Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-9B Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-9B Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-9B Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-9B Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Diesel Tank Roofing (R/F)",
    "value": "Portion B-9B Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-9B Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-9B Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-9B Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-9B Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-9B Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-9B Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-9B Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-9B Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-9B Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-9B Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9B Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-9B Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-9B Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-9B Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-9B Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-9B Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-9B Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-9B Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-9B Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9B Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9B Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9B Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9B Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9B Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9B Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-9B Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Main Power House 1st Floor (1/F)",
    "value": "Portion B-9B Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Main Power House 2nd Floor (2/F)",
    "value": "Portion B-9B Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Main Power House 3rd Floor (3/F)",
    "value": "Portion B-9B Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Main Power House Basement 1 (B1/F)",
    "value": "Portion B-9B Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Main Power House Basement 2 (B2/F)",
    "value": "Portion B-9B Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Main Power House Ground Floor (G/F)",
    "value": "Portion B-9B Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Main Power House Roofing (R/F)",
    "value": "Portion B-9B Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-9B Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-9B Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9B Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-9B PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-9B PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-9B PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-9B PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-9B PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9B Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-9B Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-9B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-9B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-9B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-9B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-9B Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-9B Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-9B Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-9B Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-9B Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-9B Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-9B Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-9B Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-9B UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-9B WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-9B WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9B WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-9B WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9B WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-9B WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9B WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-9B WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9B WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-9B WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9B WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-9B WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9B WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-9B WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Portion B-9C 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Portion B-9C 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Portion B-9C 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Portion B-9C 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Portion B-9C 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Portion B-9C 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Portion B-9C 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Portion B-9C 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Access Road AR3 1st Floor (1/F)",
    "value": "Portion B-9C Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Access Road AR3 2nd Floor (2/F)",
    "value": "Portion B-9C Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Access Road AR3 3rd Floor (3/F)",
    "value": "Portion B-9C Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Access Road AR3 Basement 1 (B1/F)",
    "value": "Portion B-9C Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Access Road AR3 Basement 2 (B2/F)",
    "value": "Portion B-9C Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Access Road AR3 Ground Floor (G/F)",
    "value": "Portion B-9C Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Access Road AR3 Roofing (R/F)",
    "value": "Portion B-9C Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Air Blower House No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Air Blower House No. 2 Roofing (R/F)",
    "value": "Portion B-9C Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9C Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9C Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9C Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9C Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9C Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9C Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Auxiliary Pumping Station Roofing (R/F)",
    "value": "Portion B-9C Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2 Roofing (R/F)",
    "value": "Portion B-9C Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Portion B-9C Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Portion B-9C Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Portion B-9C Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Portion B-9C Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Portion B-9C Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Portion B-9C Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Portion B-9C Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Portion B-9C CHP BUILDING 1st Floor (1/F)",
    "value": "Portion B-9C CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C CHP BUILDING 2nd Floor (2/F)",
    "value": "Portion B-9C CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C CHP BUILDING 3rd Floor (3/F)",
    "value": "Portion B-9C CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C CHP BUILDING Basement 1 (B1/F)",
    "value": "Portion B-9C CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C CHP BUILDING Basement 2 (B2/F)",
    "value": "Portion B-9C CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C CHP BUILDING Ground Floor (G/F)",
    "value": "Portion B-9C CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C CHP BUILDING Roofing (R/F)",
    "value": "Portion B-9C CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Chemical Storage Room 1st Floor (1/F)",
    "value": "Portion B-9C Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Chemical Storage Room 2nd Floor (2/F)",
    "value": "Portion B-9C Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Chemical Storage Room 3rd Floor (3/F)",
    "value": "Portion B-9C Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Chemical Storage Room Basement 1 (B1/F)",
    "value": "Portion B-9C Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Chemical Storage Room Basement 2 (B2/F)",
    "value": "Portion B-9C Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Chemical Storage Room Ground Floor (G/F)",
    "value": "Portion B-9C Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Chemical Storage Room Roofing (R/F)",
    "value": "Portion B-9C Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 1 Roofing (R/F)",
    "value": "Portion B-9C Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Chemical System No. 2 Roofing (R/F)",
    "value": "Portion B-9C Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Portion B-9C Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Portion B-9C Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Portion B-9C Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Portion B-9C Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Portion B-9C Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Portion B-9C Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Contractors Site Accomodation Roofing (R/F)",
    "value": "Portion B-9C Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Contractors Storage Area 1st Floor (1/F)",
    "value": "Portion B-9C Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Contractors Storage Area 2nd Floor (2/F)",
    "value": "Portion B-9C Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Contractors Storage Area 3rd Floor (3/F)",
    "value": "Portion B-9C Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Contractors Storage Area Basement 1 (B1/F)",
    "value": "Portion B-9C Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Contractors Storage Area Basement 2 (B2/F)",
    "value": "Portion B-9C Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Contractors Storage Area Ground Floor (G/F)",
    "value": "Portion B-9C Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Contractors Storage Area Roofing (R/F)",
    "value": "Portion B-9C Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 1 Roofing (R/F)",
    "value": "Portion B-9C Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Portion B-9C Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Portion B-9C Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Portion B-9C Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Portion B-9C Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Portion B-9C Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Portion B-9C Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Deodorisation System No. 3A Roofing (R/F)",
    "value": "Portion B-9C Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Diesel Tank 1st Floor (1/F)",
    "value": "Portion B-9C Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Diesel Tank 2nd Floor (2/F)",
    "value": "Portion B-9C Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Diesel Tank 3rd Floor (3/F)",
    "value": "Portion B-9C Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Diesel Tank Basement 1 (B1/F)",
    "value": "Portion B-9C Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Diesel Tank Basement 2 (B2/F)",
    "value": "Portion B-9C Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Diesel Tank Ground Floor (G/F)",
    "value": "Portion B-9C Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Diesel Tank Roofing (R/F)",
    "value": "Portion B-9C Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Portion B-9C Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Portion B-9C Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Portion B-9C Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Portion B-9C Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Portion B-9C Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Portion B-9C Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Portion B-9C Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Portion B-9C Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Portion B-9C Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Portion B-9C Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Portion B-9C Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Inlet Works No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Inlet Works No. 1 Roofing (R/F)",
    "value": "Portion B-9C Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Leachate Pump Pit 1st Floor (1/F)",
    "value": "Portion B-9C Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Portion B-9C Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Portion B-9C Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Portion B-9C Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Portion B-9C Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Leachate Pump Pit Ground Floor (G/F)",
    "value": "Portion B-9C Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Leachate Pump Pit Roofing (R/F)",
    "value": "Portion B-9C Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Leachate Pumping Station 1st Floor (1/F)",
    "value": "Portion B-9C Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Portion B-9C Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Portion B-9C Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Portion B-9C Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Portion B-9C Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Leachate Pumping Station Ground Floor (G/F)",
    "value": "Portion B-9C Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Leachate Pumping Station Roofing (R/F)",
    "value": "Portion B-9C Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Main Power House 1st Floor (1/F)",
    "value": "Portion B-9C Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Main Power House 2nd Floor (2/F)",
    "value": "Portion B-9C Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Main Power House 3rd Floor (3/F)",
    "value": "Portion B-9C Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Main Power House Basement 1 (B1/F)",
    "value": "Portion B-9C Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Main Power House Basement 2 (B2/F)",
    "value": "Portion B-9C Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Main Power House Ground Floor (G/F)",
    "value": "Portion B-9C Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Main Power House Roofing (R/F)",
    "value": "Portion B-9C Main Power House Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Portion B-9C Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Portion B-9C Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9C Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Portion B-9C PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Portion B-9C PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Portion B-9C PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Portion B-9C PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Portion B-9C PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Portion B-9C Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Portion B-9C Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Portion B-9C Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Portion B-9C Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Portion B-9C SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Portion B-9C THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Portion B-9C Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Portion B-9C Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Portion B-9C Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Portion B-9C Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Portion B-9C Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Portion B-9C Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Portion B-9C Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C Temporary Chemical Dosing Roofing (R/F)",
    "value": "Portion B-9C Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Portion B-9C UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Portion B-9C WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Portion B-9C WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Portion B-9C WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Portion B-9C WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Portion B-9C WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Portion B-9C WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Portion B-9C WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Portion B-9C WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Portion B-9C WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Portion B-9C WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Portion B-9C WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Portion B-9C WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Portion B-9C WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Portion B-9C WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Works Area WA1-D 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Works Area WA1-D 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Works Area WA1-D 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Works Area WA1-D 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Works Area WA1-D 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Works Area WA1-D 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Works Area WA1-D 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Access Road AR3 1st Floor (1/F)",
    "value": "Works Area WA1-D Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Access Road AR3 2nd Floor (2/F)",
    "value": "Works Area WA1-D Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Access Road AR3 3rd Floor (3/F)",
    "value": "Works Area WA1-D Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Access Road AR3 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Access Road AR3 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Access Road AR3 Ground Floor (G/F)",
    "value": "Works Area WA1-D Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Access Road AR3 Roofing (R/F)",
    "value": "Works Area WA1-D Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Air Blower House No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Air Blower House No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Works Area WA1-D Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Works Area WA1-D Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Works Area WA1-D Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Works Area WA1-D Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Works Area WA1-D Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Works Area WA1-D Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Auxiliary Pumping Station Roofing (R/F)",
    "value": "Works Area WA1-D Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Works Area WA1-D Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Works Area WA1-D Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Works Area WA1-D Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Works Area WA1-D Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Works Area WA1-D Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Works Area WA1-D Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Works Area WA1-D Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D CHP BUILDING 1st Floor (1/F)",
    "value": "Works Area WA1-D CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D CHP BUILDING 2nd Floor (2/F)",
    "value": "Works Area WA1-D CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D CHP BUILDING 3rd Floor (3/F)",
    "value": "Works Area WA1-D CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D CHP BUILDING Basement 1 (B1/F)",
    "value": "Works Area WA1-D CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D CHP BUILDING Basement 2 (B2/F)",
    "value": "Works Area WA1-D CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D CHP BUILDING Ground Floor (G/F)",
    "value": "Works Area WA1-D CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D CHP BUILDING Roofing (R/F)",
    "value": "Works Area WA1-D CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Chemical Storage Room 1st Floor (1/F)",
    "value": "Works Area WA1-D Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Chemical Storage Room 2nd Floor (2/F)",
    "value": "Works Area WA1-D Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Chemical Storage Room 3rd Floor (3/F)",
    "value": "Works Area WA1-D Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Chemical Storage Room Basement 1 (B1/F)",
    "value": "Works Area WA1-D Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Chemical Storage Room Basement 2 (B2/F)",
    "value": "Works Area WA1-D Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Chemical Storage Room Ground Floor (G/F)",
    "value": "Works Area WA1-D Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Chemical Storage Room Roofing (R/F)",
    "value": "Works Area WA1-D Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Chemical System No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Works Area WA1-D Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Works Area WA1-D Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Works Area WA1-D Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Works Area WA1-D Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Works Area WA1-D Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Works Area WA1-D Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Site Accomodation Roofing (R/F)",
    "value": "Works Area WA1-D Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Storage Area 1st Floor (1/F)",
    "value": "Works Area WA1-D Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Storage Area 2nd Floor (2/F)",
    "value": "Works Area WA1-D Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Storage Area 3rd Floor (3/F)",
    "value": "Works Area WA1-D Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Storage Area Basement 1 (B1/F)",
    "value": "Works Area WA1-D Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Storage Area Basement 2 (B2/F)",
    "value": "Works Area WA1-D Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Storage Area Ground Floor (G/F)",
    "value": "Works Area WA1-D Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Contractors Storage Area Roofing (R/F)",
    "value": "Works Area WA1-D Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Works Area WA1-D Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Works Area WA1-D Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Works Area WA1-D Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Works Area WA1-D Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Works Area WA1-D Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Works Area WA1-D Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Deodorisation System No. 3A Roofing (R/F)",
    "value": "Works Area WA1-D Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Diesel Tank 1st Floor (1/F)",
    "value": "Works Area WA1-D Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Diesel Tank 2nd Floor (2/F)",
    "value": "Works Area WA1-D Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Diesel Tank 3rd Floor (3/F)",
    "value": "Works Area WA1-D Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Diesel Tank Basement 1 (B1/F)",
    "value": "Works Area WA1-D Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Diesel Tank Basement 2 (B2/F)",
    "value": "Works Area WA1-D Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Diesel Tank Ground Floor (G/F)",
    "value": "Works Area WA1-D Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Diesel Tank Roofing (R/F)",
    "value": "Works Area WA1-D Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Works Area WA1-D Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Works Area WA1-D Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Works Area WA1-D Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Works Area WA1-D Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Works Area WA1-D Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Works Area WA1-D Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Works Area WA1-D Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Works Area WA1-D Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Works Area WA1-D Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Works Area WA1-D Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Works Area WA1-D Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Inlet Works No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Inlet Works No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pump Pit 1st Floor (1/F)",
    "value": "Works Area WA1-D Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Works Area WA1-D Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Works Area WA1-D Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Works Area WA1-D Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Works Area WA1-D Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pump Pit Ground Floor (G/F)",
    "value": "Works Area WA1-D Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pump Pit Roofing (R/F)",
    "value": "Works Area WA1-D Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pumping Station 1st Floor (1/F)",
    "value": "Works Area WA1-D Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Works Area WA1-D Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Works Area WA1-D Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Works Area WA1-D Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Works Area WA1-D Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pumping Station Ground Floor (G/F)",
    "value": "Works Area WA1-D Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Leachate Pumping Station Roofing (R/F)",
    "value": "Works Area WA1-D Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Main Power House 1st Floor (1/F)",
    "value": "Works Area WA1-D Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Main Power House 2nd Floor (2/F)",
    "value": "Works Area WA1-D Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Main Power House 3rd Floor (3/F)",
    "value": "Works Area WA1-D Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Main Power House Basement 1 (B1/F)",
    "value": "Works Area WA1-D Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Main Power House Basement 2 (B2/F)",
    "value": "Works Area WA1-D Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Main Power House Ground Floor (G/F)",
    "value": "Works Area WA1-D Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Main Power House Roofing (R/F)",
    "value": "Works Area WA1-D Main Power House Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Works Area WA1-D PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Works Area WA1-D Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Works Area WA1-D Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Works Area WA1-D SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Works Area WA1-D THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Works Area WA1-D Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Works Area WA1-D Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Works Area WA1-D Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Works Area WA1-D Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Works Area WA1-D Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Works Area WA1-D Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D Temporary Chemical Dosing Roofing (R/F)",
    "value": "Works Area WA1-D Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Works Area WA1-D UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Works Area WA1-D WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Works Area WA1-D WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA1-D WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Works Area WA1-D WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA1-D WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Works Area WA1-D WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA1-D WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Works Area WA1-D WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA1-D WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Works Area WA1-D WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA1-D WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Works Area WA1-D WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA1-D WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Works Area WA1-D WORKSHOP NO. 2 System Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B 132kV POWER SUBSTATION 1st Floor (1/F)",
    "value": "Works Area WA2-B 132kV POWER SUBSTATION 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B 132kV POWER SUBSTATION 2nd Floor (2/F)",
    "value": "Works Area WA2-B 132kV POWER SUBSTATION 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B 132kV POWER SUBSTATION 3rd Floor (3/F)",
    "value": "Works Area WA2-B 132kV POWER SUBSTATION 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B 132kV POWER SUBSTATION Basement 1 (B1/F)",
    "value": "Works Area WA2-B 132kV POWER SUBSTATION Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B 132kV POWER SUBSTATION Basement 2 (B2/F)",
    "value": "Works Area WA2-B 132kV POWER SUBSTATION Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B 132kV POWER SUBSTATION Ground Floor (G/F)",
    "value": "Works Area WA2-B 132kV POWER SUBSTATION Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B 132kV POWER SUBSTATION Roofing (R/F)",
    "value": "Works Area WA2-B 132kV POWER SUBSTATION Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Access Road AR3 1st Floor (1/F)",
    "value": "Works Area WA2-B Access Road AR3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Access Road AR3 2nd Floor (2/F)",
    "value": "Works Area WA2-B Access Road AR3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Access Road AR3 3rd Floor (3/F)",
    "value": "Works Area WA2-B Access Road AR3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Access Road AR3 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Access Road AR3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Access Road AR3 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Access Road AR3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Access Road AR3 Ground Floor (G/F)",
    "value": "Works Area WA2-B Access Road AR3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Access Road AR3 Roofing (R/F)",
    "value": "Works Area WA2-B Access Road AR3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Air Blower House No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Air Blower House No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Air Blower House No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Air Blower House No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Air Blower House No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Air Blower House No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Air Blower House No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Air Blower House No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Air Blower House No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Air Blower House No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Air Blower House No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Air Blower House No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Air Blower House No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Air Blower House No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Auxiliary Pumping Station 1st Floor (1/F)",
    "value": "Works Area WA2-B Auxiliary Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Auxiliary Pumping Station 2nd Floor (2/F)",
    "value": "Works Area WA2-B Auxiliary Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Auxiliary Pumping Station 3rd Floor (3/F)",
    "value": "Works Area WA2-B Auxiliary Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Auxiliary Pumping Station Basement 1 (B1/F)",
    "value": "Works Area WA2-B Auxiliary Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Auxiliary Pumping Station Basement 2 (B2/F)",
    "value": "Works Area WA2-B Auxiliary Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Auxiliary Pumping Station Ground Floor (G/F)",
    "value": "Works Area WA2-B Auxiliary Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Auxiliary Pumping Station Roofing (R/F)",
    "value": "Works Area WA2-B Auxiliary Pumping Station Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Bioreactor No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Bioreactor No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Bioreactor No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Bioreactor No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Bioreactor No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Bioreactor No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Bioreactor No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2A & 2B 1st Floor (1/F)",
    "value": "Works Area WA2-B Bioreactor No. 2A & 2B 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2A & 2B 2nd Floor (2/F)",
    "value": "Works Area WA2-B Bioreactor No. 2A & 2B 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2A & 2B 3rd Floor (3/F)",
    "value": "Works Area WA2-B Bioreactor No. 2A & 2B 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2A & 2B Basement 1 (B1/F)",
    "value": "Works Area WA2-B Bioreactor No. 2A & 2B Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2A & 2B Basement 2 (B2/F)",
    "value": "Works Area WA2-B Bioreactor No. 2A & 2B Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2A & 2B Ground Floor (G/F)",
    "value": "Works Area WA2-B Bioreactor No. 2A & 2B Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Bioreactor No. 2A & 2B Roofing (R/F)",
    "value": "Works Area WA2-B Bioreactor No. 2A & 2B Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B CHP BUILDING 1st Floor (1/F)",
    "value": "Works Area WA2-B CHP BUILDING 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B CHP BUILDING 2nd Floor (2/F)",
    "value": "Works Area WA2-B CHP BUILDING 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B CHP BUILDING 3rd Floor (3/F)",
    "value": "Works Area WA2-B CHP BUILDING 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B CHP BUILDING Basement 1 (B1/F)",
    "value": "Works Area WA2-B CHP BUILDING Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B CHP BUILDING Basement 2 (B2/F)",
    "value": "Works Area WA2-B CHP BUILDING Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B CHP BUILDING Ground Floor (G/F)",
    "value": "Works Area WA2-B CHP BUILDING Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B CHP BUILDING Roofing (R/F)",
    "value": "Works Area WA2-B CHP BUILDING Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Chemical Storage Room 1st Floor (1/F)",
    "value": "Works Area WA2-B Chemical Storage Room 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Chemical Storage Room 2nd Floor (2/F)",
    "value": "Works Area WA2-B Chemical Storage Room 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Chemical Storage Room 3rd Floor (3/F)",
    "value": "Works Area WA2-B Chemical Storage Room 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Chemical Storage Room Basement 1 (B1/F)",
    "value": "Works Area WA2-B Chemical Storage Room Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Chemical Storage Room Basement 2 (B2/F)",
    "value": "Works Area WA2-B Chemical Storage Room Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Chemical Storage Room Ground Floor (G/F)",
    "value": "Works Area WA2-B Chemical Storage Room Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Chemical Storage Room Roofing (R/F)",
    "value": "Works Area WA2-B Chemical Storage Room Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Chemical System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Chemical System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Chemical System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Chemical System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Chemical System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Chemical System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Chemical System No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Chemical System No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Chemical System No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Chemical System No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Chemical System No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Chemical System No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Chemical System No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Chemical System No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Chemical System No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Site Accomodation 1st Floor (1/F)",
    "value": "Works Area WA2-B Contractors Site Accomodation 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Site Accomodation 2nd Floor (2/F)",
    "value": "Works Area WA2-B Contractors Site Accomodation 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Site Accomodation 3rd Floor (3/F)",
    "value": "Works Area WA2-B Contractors Site Accomodation 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Site Accomodation Basement 1 (B1/F)",
    "value": "Works Area WA2-B Contractors Site Accomodation Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Site Accomodation Basement 2 (B2/F)",
    "value": "Works Area WA2-B Contractors Site Accomodation Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Site Accomodation Ground Floor (G/F)",
    "value": "Works Area WA2-B Contractors Site Accomodation Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Site Accomodation Roofing (R/F)",
    "value": "Works Area WA2-B Contractors Site Accomodation Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Storage Area 1st Floor (1/F)",
    "value": "Works Area WA2-B Contractors Storage Area 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Storage Area 2nd Floor (2/F)",
    "value": "Works Area WA2-B Contractors Storage Area 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Storage Area 3rd Floor (3/F)",
    "value": "Works Area WA2-B Contractors Storage Area 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Storage Area Basement 1 (B1/F)",
    "value": "Works Area WA2-B Contractors Storage Area Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Storage Area Basement 2 (B2/F)",
    "value": "Works Area WA2-B Contractors Storage Area Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Storage Area Ground Floor (G/F)",
    "value": "Works Area WA2-B Contractors Storage Area Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Contractors Storage Area Roofing (R/F)",
    "value": "Works Area WA2-B Contractors Storage Area Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Deodorisation System No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Deodorisation System No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Deodorisation System No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Deodorisation System No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Deodorisation System No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Deodorisation System No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Deodorisation System No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 3A 1st Floor (1/F)",
    "value": "Works Area WA2-B Deodorisation System No. 3A 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 3A 2nd Floor (2/F)",
    "value": "Works Area WA2-B Deodorisation System No. 3A 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 3A 3rd Floor (3/F)",
    "value": "Works Area WA2-B Deodorisation System No. 3A 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 3A Basement 1 (B1/F)",
    "value": "Works Area WA2-B Deodorisation System No. 3A Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 3A Basement 2 (B2/F)",
    "value": "Works Area WA2-B Deodorisation System No. 3A Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 3A Ground Floor (G/F)",
    "value": "Works Area WA2-B Deodorisation System No. 3A Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Deodorisation System No. 3A Roofing (R/F)",
    "value": "Works Area WA2-B Deodorisation System No. 3A Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Diesel Tank 1st Floor (1/F)",
    "value": "Works Area WA2-B Diesel Tank 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Diesel Tank 2nd Floor (2/F)",
    "value": "Works Area WA2-B Diesel Tank 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Diesel Tank 3rd Floor (3/F)",
    "value": "Works Area WA2-B Diesel Tank 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Diesel Tank Basement 1 (B1/F)",
    "value": "Works Area WA2-B Diesel Tank Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Diesel Tank Basement 2 (B2/F)",
    "value": "Works Area WA2-B Diesel Tank Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Diesel Tank Ground Floor (G/F)",
    "value": "Works Area WA2-B Diesel Tank Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Diesel Tank Roofing (R/F)",
    "value": "Works Area WA2-B Diesel Tank Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)",
    "value": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)",
    "value": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)",
    "value": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)",
    "value": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)",
    "value": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)",
    "value": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)",
    "value": "Works Area WA2-B Emergency Generator cum FS & Sprinkler Pump Room Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 3 1st Floor (1/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 3 2nd Floor (2/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 3 3rd Floor (3/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 3 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 3 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 3 Ground Floor (G/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 3 Roofing (R/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 4 1st Floor (1/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 4 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 4 2nd Floor (2/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 4 3rd Floor (3/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 4 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 4 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 4 Ground Floor (G/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Final Sedimentation Tank No. 4 Roofing (R/F)",
    "value": "Works Area WA2-B Final Sedimentation Tank No. 4 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Final Tank Distribution Chamber 1st Floor (1/F)",
    "value": "Works Area WA2-B Final Tank Distribution Chamber 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Final Tank Distribution Chamber 2nd Floor (2/F)",
    "value": "Works Area WA2-B Final Tank Distribution Chamber 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Final Tank Distribution Chamber 3rd Floor (3/F)",
    "value": "Works Area WA2-B Final Tank Distribution Chamber 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Final Tank Distribution Chamber Basement 1 (B1/F)",
    "value": "Works Area WA2-B Final Tank Distribution Chamber Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Final Tank Distribution Chamber Basement 2 (B2/F)",
    "value": "Works Area WA2-B Final Tank Distribution Chamber Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Final Tank Distribution Chamber Ground Floor (G/F)",
    "value": "Works Area WA2-B Final Tank Distribution Chamber Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Final Tank Distribution Chamber Roofing (R/F)",
    "value": "Works Area WA2-B Final Tank Distribution Chamber Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 3 1st Floor (1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 3 2nd Floor (2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 3 3rd Floor (3/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 3 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 3 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 3 Ground Floor (G/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 3 Roofing (R/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 4 1st Floor (1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 4 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 4 2nd Floor (2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 4 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 4 3rd Floor (3/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 4 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 4 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 4 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 4 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 4 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 4 Ground Floor (G/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 4 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Flowmeter Chamber No. 4 Roofing (R/F)",
    "value": "Works Area WA2-B Flowmeter Chamber No. 4 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 3 1st Floor (1/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 3 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 3 2nd Floor (2/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 3 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 3 3rd Floor (3/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 3 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 3 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 3 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 3 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 3 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 3 Ground Floor (G/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 3 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Gate Valve Chamber No. 3 Roofing (R/F)",
    "value": "Works Area WA2-B Gate Valve Chamber No. 3 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Inlet Works No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Inlet Works No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Inlet Works No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Inlet Works No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Inlet Works No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Inlet Works No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Inlet Works No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Inlet Works No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Inlet Works No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Inlet Works No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Inlet Works No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Inlet Works No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Inlet Works No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Inlet Works No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pump Pit 1st Floor (1/F)",
    "value": "Works Area WA2-B Leachate Pump Pit 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pump Pit 2nd Floor (2/F)",
    "value": "Works Area WA2-B Leachate Pump Pit 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pump Pit 3rd Floor (3/F)",
    "value": "Works Area WA2-B Leachate Pump Pit 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pump Pit Basement 1 (B1/F)",
    "value": "Works Area WA2-B Leachate Pump Pit Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pump Pit Basement 2 (B2/F)",
    "value": "Works Area WA2-B Leachate Pump Pit Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pump Pit Ground Floor (G/F)",
    "value": "Works Area WA2-B Leachate Pump Pit Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pump Pit Roofing (R/F)",
    "value": "Works Area WA2-B Leachate Pump Pit Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pumping Station 1st Floor (1/F)",
    "value": "Works Area WA2-B Leachate Pumping Station 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pumping Station 2nd Floor (2/F)",
    "value": "Works Area WA2-B Leachate Pumping Station 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pumping Station 3rd Floor (3/F)",
    "value": "Works Area WA2-B Leachate Pumping Station 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pumping Station Basement 1 (B1/F)",
    "value": "Works Area WA2-B Leachate Pumping Station Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pumping Station Basement 2 (B2/F)",
    "value": "Works Area WA2-B Leachate Pumping Station Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pumping Station Ground Floor (G/F)",
    "value": "Works Area WA2-B Leachate Pumping Station Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Leachate Pumping Station Roofing (R/F)",
    "value": "Works Area WA2-B Leachate Pumping Station Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Main Power House 1st Floor (1/F)",
    "value": "Works Area WA2-B Main Power House 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Main Power House 2nd Floor (2/F)",
    "value": "Works Area WA2-B Main Power House 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Main Power House 3rd Floor (3/F)",
    "value": "Works Area WA2-B Main Power House 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Main Power House Basement 1 (B1/F)",
    "value": "Works Area WA2-B Main Power House Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Main Power House Basement 2 (B2/F)",
    "value": "Works Area WA2-B Main Power House Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Main Power House Ground Floor (G/F)",
    "value": "Works Area WA2-B Main Power House Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Main Power House Roofing (R/F)",
    "value": "Works Area WA2-B Main Power House Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Membrane Facilities Building No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Membrane Facilities Building No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Channel No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Mixed Liquor Channel No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Channel No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Mixed Liquor Channel No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Channel No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Mixed Liquor Channel No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Channel No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Mixed Liquor Channel No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Channel No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Mixed Liquor Channel No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Channel No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Mixed Liquor Channel No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Channel No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Mixed Liquor Channel No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Mixed Liquor Distribution Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B PUMPING STATION NO. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B PUMPING STATION NO. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B PUMPING STATION NO. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B PUMPING STATION NO. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B PUMPING STATION NO. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B PUMPING STATION NO. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B PUMPING STATION NO. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B PUMPING STATION NO. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B PUMPING STATION NO. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B PUMPING STATION NO. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B PUMPING STATION NO. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B PUMPING STATION NO. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B PUMPING STATION NO. 1 Roofing (R/F)",
    "value": "Works Area WA2-B PUMPING STATION NO. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Plug Valve Chamber No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Plug Valve Chamber No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 1 1st Floor (1/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 1 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 1 2nd Floor (2/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 1 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 1 3rd Floor (3/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 1 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 1 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 1 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 1 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 1 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 1 Ground Floor (G/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 1 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 1 Roofing (R/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 1 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 2 1st Floor (1/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 2 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 2 2nd Floor (2/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 2 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 2 3rd Floor (3/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 2 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 2 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 2 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 2 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 2 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 2 Ground Floor (G/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 2 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tank No. 2 Roofing (R/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tank No. 2 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)",
    "value": "Works Area WA2-B Primary Sedimentation Tanks No. Primary Sludge Pump Pit 1 - 4 Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)",
    "value": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)",
    "value": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)",
    "value": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)",
    "value": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)",
    "value": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)",
    "value": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)",
    "value": "Works Area WA2-B Primary Sludge Thickeners RAS Channel No. 1 SAS Pumping Station Septic Tank Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)",
    "value": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)",
    "value": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)",
    "value": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)",
    "value": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)",
    "value": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)",
    "value": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)",
    "value": "Works Area WA2-B SEWAGE PUMPING STATION SLUDGE DEWATERING BUILDING SLUDGE DIGESTERS & Street Fire Hydrant Pump Room DISTRIBUTION CHAMBER Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)",
    "value": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)",
    "value": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)",
    "value": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)",
    "value": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)",
    "value": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)",
    "value": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)",
    "value": "Works Area WA2-B THERNAL HYDROLYSIS PRETREATMENT FACILITIES (THP) Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B Temporary Chemical Dosing 1st Floor (1/F)",
    "value": "Works Area WA2-B Temporary Chemical Dosing 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B Temporary Chemical Dosing 2nd Floor (2/F)",
    "value": "Works Area WA2-B Temporary Chemical Dosing 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B Temporary Chemical Dosing 3rd Floor (3/F)",
    "value": "Works Area WA2-B Temporary Chemical Dosing 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B Temporary Chemical Dosing Basement 1 (B1/F)",
    "value": "Works Area WA2-B Temporary Chemical Dosing Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B Temporary Chemical Dosing Basement 2 (B2/F)",
    "value": "Works Area WA2-B Temporary Chemical Dosing Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B Temporary Chemical Dosing Ground Floor (G/F)",
    "value": "Works Area WA2-B Temporary Chemical Dosing Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B Temporary Chemical Dosing Roofing (R/F)",
    "value": "Works Area WA2-B Temporary Chemical Dosing Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)",
    "value": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)",
    "value": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)",
    "value": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)",
    "value": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)",
    "value": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)",
    "value": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)",
    "value": "Works Area WA2-B UV SYSTEM NO. 1 & EFFLUENT  Roofing (R/F)"
  },
  {
    "label": "Works Area WA2-B WORKSHOP NO. 2 System 1st Floor (1/F)",
    "value": "Works Area WA2-B WORKSHOP NO. 2 System 1st Floor (1/F)"
  },
  {
    "label": "Works Area WA2-B WORKSHOP NO. 2 System 2nd Floor (2/F)",
    "value": "Works Area WA2-B WORKSHOP NO. 2 System 2nd Floor (2/F)"
  },
  {
    "label": "Works Area WA2-B WORKSHOP NO. 2 System 3rd Floor (3/F)",
    "value": "Works Area WA2-B WORKSHOP NO. 2 System 3rd Floor (3/F)"
  },
  {
    "label": "Works Area WA2-B WORKSHOP NO. 2 System Basement 1 (B1/F)",
    "value": "Works Area WA2-B WORKSHOP NO. 2 System Basement 1 (B1/F)"
  },
  {
    "label": "Works Area WA2-B WORKSHOP NO. 2 System Basement 2 (B2/F)",
    "value": "Works Area WA2-B WORKSHOP NO. 2 System Basement 2 (B2/F)"
  },
  {
    "label": "Works Area WA2-B WORKSHOP NO. 2 System Ground Floor (G/F)",
    "value": "Works Area WA2-B WORKSHOP NO. 2 System Ground Floor (G/F)"
  },
  {
    "label": "Works Area WA2-B WORKSHOP NO. 2 System Roofing (R/F)",
    "value": "Works Area WA2-B WORKSHOP NO. 2 System Roofing (R/F)"
  }
]