import PropTypes from "prop-types";

import { Pagination } from "@material-ui/lab";

import { useStyles } from "./style";

function CustPagination({
  count,
  page,
  onChange,
  justifyContent = "flex-end"
}) {
  const classes = useStyles({ justifyContent });
  return (
    <Pagination
      {...{ count, page, onChange }}
      classes={{
        root: classes.root,
        ul: classes.ul
      }}
      size="large"
      siblingCount={3}
    />
  );
}

CustPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func,
  justifyContent: PropTypes.string,
};

export default CustPagination;