import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px"
  },
  noResult: {
    textAlign: "center",
    marginTop: "40px",
    color: theme.palette.notFoundBrown,
    ...theme.typography.h5
  }
}));
