export const dwssItems = [
  {
    name: "Total Number of RISC From",
    key: "noOfRisc",
    slug: "risc"
  },
  {
    name: "Total Number of Site Diary",
    key: "noOfSiteDiary",
    slug: "site-diary",
  },
  {
    name: "Total Number of Safety Inspection",
    key: "noOfSafety",
    slug: "safety-inspection",
  },
  {
    name: "Total Number of Cleansing Inspection",
    key: "noOfCleansing",
    slug: "cleansing-inspection",
  },
  {
    name: "Total Number of Labour Return",
    key: "noOfLabour",
    slug: "labour",
  },
];

export const phaseTwoItems = [
  {
    name: "Total Number of EDMS",
    key: "edmsCount",
    slug: "edms",
  },
  {
    name: "Total Number of Risk Meeting / Risk Register",
    key: "riskCount",
    slug: "risk-register",
  },
  {
    name: "Total Number of Incident",
    key: "incidentCount",
    slug: "incident",
  },
  {
    name: "Total Number of Safety Performance",
    key: "safetyCount",
    slug: "safety-performance",
  },
  {
    name: "Total Number of Site Progress Photos",
    key: "photos",
    slug: "photo",
  },
  {
    name: "Total Managed / Total Published in BIM",
    key: "bimCount",
    slug: "bim",
  },
]