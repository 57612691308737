import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

import { useStyles } from "./style";

function CustIconButton({
  icon,
  onClick
}) {
  const classes = useStyles();
  return(
    <IconButton
      className={classes.iconButtons}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  );
}

CustIconButton.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func
};

export default CustIconButton;