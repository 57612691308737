import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message'
import { useParams } from "react-router";
import { DialogTitle, DialogContent, DialogActions, IconButton, Grid, Divider } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDispatch } from "react-redux";
import { useState } from "react";

import Loading from "Common/Loading";
import { setNotification } from "redux/slice/notificationSlice";
import SectionTitle from "Common/SectionTitle";
import Button from "Common/RoundedButton";

import CloseIcon from "static/icons/close.svg";
import FileIcon from "static/icons/viewFile.svg";

import fieldHelper from "utils/fieldHelper";
import documentHelper from "helpers/documentHelper";
import { fileInfoFields, fileMetaFields } from './config';
import { useStyles } from "./style";
import { useEffect } from "react";

function SafetyAdd({ contract, handleClose, reload }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { watch, register, handleSubmit, control, formState: { errors }, reset } = useForm({
    defaultValues: {
      file: null,
      safety: {
        contract_id: contractId,
        file_id: "",
        previous_file_id: "",
        file_source: "Manual",
        file_type: "SSR",
        file_status: "Created",
        file_tags: [],
        file_remark: "",
        version_no: null,
        no_of_revisions: null,

        inspection_type: "Safety",
        description: "",
        inspection_location: "",
        near_miss_ssr: false,
        incident_ssr: false,
        nc_ssr: false,
        compliance: false,
        safe_convict_ssr: false,
        safety_works_category_desc: "",

        submission_datetime: null,
        submission_by: "",
        inspection_datetime: null,
        inspection_by: "",
        signed_datetime: null,
        signed_by: "",
        manhours: null,
        number_of_workers: null,
      }
    }
  })

  const watchFile = watch("file", false);
  const watchFileStatus = watch("safety.file_status")
  const watchAllFields = watch();

  useEffect(() => {
    // clearErrors();
    reset({
      ...watchAllFields
    },
      {
        keepValues: true,
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false
      });
  }, [watchFileStatus])

  async function onSubmit(data) {
    try {
      setLoading(true)
      let file = data.file[0];
      let postData = data.safety;
      const result = await documentHelper.createDocument({ type: 'safety', variable: postData });
      if (result.error) throw new Error(result.error);
      await documentHelper.uploadDocument({ type: "safety", id: result.safety.id, variable: { file: file } });
      dispatch(setNotification({ type: "success", message: "New Safety Inspection submitted" }));
      reload();
      handleClose();
    } catch (e) {
      dispatch(setNotification({ type: "error", message: e.message }));
      setLoading(false);
      console.log(e)
    }
  }

  function onError(data) {
    dispatch(setNotification({ type: "error", message: "Some field(s) are missing." }))
  }

  return (
    <>
      <DialogTitle>
        <SectionTitle
          title="Add Safety Inspection Form"
          extraComponents={
            <IconButton onClick={handleClose}>
              <img src={CloseIcon} className={classes.Icon} alt={"document"} />
            </IconButton>
          }
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Button
                varient="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                backgroundColor="#70BAD3"
              >
                Upload a file
                <input
                  {...register('file', { required: "File is required." })}
                  type="file"
                  hidden
                  accept="application/pdf"
                />
              </Button>
              {watchFile &&
                <div className={classes.fileInfo}>
                  <img className={classes.docImg} src={FileIcon}></img>
                  {`Uploaded:  ${watchFile[0].name}`}
                </div>
              }
              <ErrorMessage
                errors={errors}
                name="file"
                render={({ message }) => <p style={{ color: 'red', marginLeft: "16px" }}>{message}</p>}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="File Info" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            {fileInfoFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors))}
          </Grid>
          <Divider className={classes.divider} />

          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              <SectionTitle title="File Meta" fontStyle={{ fontSize: "20px" }} />
            </Grid>
            {fileMetaFields.map((inputField, idx) => fieldHelper.renderField(inputField, idx, register, control, errors, { status: watchFileStatus }))}
          </Grid>
          <Divider className={classes.divider} />
        </DialogContent>
        <Loading isLoading={isLoading} />

        <DialogActions>
          <Button children="Add" backgroundColor="#70BAD3" type="submit" />
        </DialogActions>
      </form>
    </>
  );
}

SafetyAdd.propTypes = {
  handleClose: PropTypes.func
};

export default SafetyAdd;