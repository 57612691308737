import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgoround: theme.palette.primaryDark,
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: 'relative',
  },
  section: {
    marginTop: "16px",
  },
  body: {
    marginTop: "32px",
  },
  divider: {
    margin: "32px 0",
    background: theme.palette.secondary.main,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  drawer: {
    backgroundColor: theme.palette.secondaryDark,
    overflowX: "hidden"
  },
  img: {
    height: 25,
    width: 25
  },
  a: {
    height: 25
  },
  middleCell: {
    minWidth: 130,
    borderRight: "1px solid rgba(81, 81, 81, 1)",
  },
  label: {
    color: theme.palette.primaryGrey,
    marginBottom: "8px",
    ...theme.typography.h6,
  }
}));
