import { Fragment } from 'react';

import { folderGroupMenuOptions } from 'config/rightClickMenuOptions';
import FolderRow from './folderRow';
import ParentFolder from './parentFolder';
import { useStyles } from "./style";

export default function FolderGroup({ group, setAnchorEl, setMenuOptions, changeModalContent }) {
  const classes = useStyles();

  async function onContextMenu(e, group) {
    e.preventDefault();
    setMenuOptions(await folderGroupMenuOptions(handleClose, group, changeModalContent));
    setAnchorEl(e.target);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.box}>
      <div className={classes.groupTitle} onContextMenu={(e) => onContextMenu(e, group)}>{`${group.name} Group`}</div>
      {group.files.map((folder, idx) =>
        <Fragment key={idx}>
          {folder.children.length > 0
            ? <ParentFolder
              folder={folder}
              setAnchorEl={setAnchorEl}
              setMenuOptions={setMenuOptions}
            />
            : <FolderRow
              folder={folder}
              setAnchorEl={setAnchorEl}
              setMenuOptions={setMenuOptions}
            />
          }
          {/* {folder.children.length === 0 && <Divider className={classes.divider} />} */}
        </Fragment>
      )}
      {(!group.files || group.files.length === 0) && "No Folder"}
    </div>
  );
}