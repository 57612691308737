import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, TableRow, TableBody, useTheme } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import typeHelper from "utils/typeHelper";
import { documentHelper, photoHelper } from "helpers";
import photoUtils from "utils/photoHelper";
import { setLoading } from "redux/slice/loadingSlice";
import TableCell from "Common/TableCell";
import TableHeader from "Common/TableHeader";
import decryptHelper from "utils/decryptHelper";
import ViewFile from "static/icons/viewFile.svg";
import { useStyles } from "./style";
import { attachmentFieldsData, fieldsData, photoAttachmentFieldsData, photoFieldsData, historyFieldsData, edmsFieldsData, riskFieldsData } from "./config";

function DocumentTable({ data, tableType, enableDelete, handleSelect, isEDMS, moduleType, hidePdfBtn }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const classes = useStyles();
  const [thumbnails, setThumbnails] = useState([])

  function handleOnClick(rowData) {
    try {
      dispatch(setLoading(true));
      let documentId;
      if (isEDMS) documentId = getEDMSDocumentId(rowData);
      else if (tableType === "history") documentId = getHistoryDocumentId(rowData, rowData.fileType.toLowerCase());
      else if (tableType === "relatedDoc") documentId = getHistoryDocumentId(rowData, rowData.fileType.toLowerCase());
      else if (tableType === "attachment") documentId = rowData.documentId;
      else if (tableType === "photoAttachment") documentId = rowData.id;
      else if (tableType === "photo") documentId = getPhotoDocumentId(rowData);
      else if (tableType === "edms") documentId = getEDMSDocumentId(rowData);
      else documentId = null;
      if (!!documentId && (tableType === "photoAttachment" || tableType === "photo")) openPhotoInBlankPage(documentId);
      else if (!!documentId && tableType !== "photoAttachment") openDocInBlankPage(documentId);

      else throw new Error('not found');
    } catch (e) {
      alert('File not found');
      dispatch(setLoading(false));
      console.log(e);
    }
  }

  function getEDMSDocumentId(data) {
    if (data.documents && data.documents.length > 0) {
      const target = data.documents[data.documents.length - 1].documentId;
      return target;
    }
    return null;
  }

  function getHistoryDocumentId(data, type) {
    let targetArr = [];
    if (type === "risc") targetArr = data.riscDocument;
    else if (type === "site diary" || type === "site_diary") targetArr = data.siteDiaryDocument;
    else if (type === "cic") targetArr = data.cleansingDocument;
    else if (type === "ssr") targetArr = data.safetyDocument;
    else if (type === "lrr") targetArr = data.labourDocument;
    return targetArr.length > 0 ? targetArr[targetArr.length - 1].documentId : null;
  }

  function getPhotoDocumentId(data) {
    let targetArr = data.photoDocument;
    return targetArr.length > 0 ? targetArr[targetArr.length - 1].id : null;
  }

  async function openPhotoInBlankPage(docId) {
    const blob = await photoHelper.getOriginPhoto(docId);
    var url = URL.createObjectURL(blob);
    window.open(url, '_blank', 'noopener noreferrer');
    dispatch(setLoading(false));
  }

  async function openDocInBlankPage(docId) {
    try {
      const blob = await documentHelper.decryptDocument(docId)
      var url = URL.createObjectURL(blob);
      window.open(url, '_blank', 'noopener noreferrer');
      dispatch(setLoading(false));
    } catch (e) {
      alert('File Not Found');
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    async function getPhotosInfo(isAttachment) {
      let photoDocIdArr = [];
      if (isAttachment) {
        photoDocIdArr = data.map(photo => photoHelper.getPhotoThumbnail(photo.id, 200))
      } else {
        photoDocIdArr = data.map(photo => photo.photoDocument[0] ? photoHelper.getPhotoThumbnail(photo.photoDocument[0].id, 300) : null)
      }
      let result = await Promise.all(photoDocIdArr);
      result = result.map(item => photoUtils.convertBase64(item))
      setThumbnails(result);
      dispatch(setLoading(false));
    }
    try {
      if (tableType === "photo" || tableType === "photoAttachment") {
        dispatch(setLoading(true));
        let isAttachment = tableType === "photoAttachment";
        if (data.length > 0) getPhotosInfo(isAttachment);
      }
    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
    }
  }, [data]);

  function selectConfig(tableType) {
    let config = ""
    switch (tableType) {
      case "attachment":
        config = attachmentFieldsData;
        break;
      case "photoAttachment":
        config = photoAttachmentFieldsData;
        break;
      case "photo":
        config = photoFieldsData;
        break;
      case "edms":
        config = edmsFieldsData;
        break;
      case "history":
        config = historyFieldsData;
        break;
      case "risk-register":
        config = riskFieldsData;
        break;
      default:
        config = fieldsData;
        break;
    }
    return config;
  }

  const handleGotoDetails = (item) => {
    if (tableType === "edms") {
      const typeSlug = item.docType.toLowerCase();
      const newWindow = window.open(`/contract/${contractId}/edms/${typeSlug}/document/${item.id}`, '_blank');
      if (newWindow) newWindow.opener = null;
    } else if (tableType === "risk-register") {
      const newWindow = window.open(`/contract/${contractId}/risk-register/document/${item.id}`, '_blank');
      if (newWindow) newWindow.opener = null;
    } else {
      const typeSlug = typeHelper.getDocumentTypeSlug(item.fileType ?? null);
      const newWindow = window.open(`/contract/${item.contractId}/${typeSlug}/document/${item.id}`, '_blank');
      if (newWindow) newWindow.opener = null;
    }

  };

  return (
    <Table>
      <TableHeader fields={selectConfig(tableType)} />
      <TableBody>
        {data.map((item, idx) => (
          <TableRow key={idx} hover>
            {(tableType === "photo" || tableType === "photoAttachment") ?
              <TableCell>
                <img alt="-" src={thumbnails[idx]} style={{ height: "200px" }} />
              </TableCell>
              : null
            }
            {selectConfig(tableType).map((field, idx) => field.id && <TableCell label={decryptHelper.renderLabel(field, item)} key={idx} />)}
            {(!hidePdfBtn) &&
              <TableCell
                onClick={() => handleOnClick(item)}
                icon={<div className={classes.a}>
                  <img
                    className={classes.img}
                    src={ViewFile}
                    alt={"document"}
                  />
                </div>}
              />}
            {enableDelete
              && <TableCell
                onClick={() => handleSelect(item)}
                icon={<DeleteIcon />}
              />

            }
            {(!enableDelete && tableType !== "attachment" && tableType !== "photoAttachment")
              && <TableCell
                icon={<ChevronRightIcon />}
                onClick={() => handleGotoDetails(item)}
                iconColor={theme.palette.primaryBlueLight}
              />
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

DocumentTable.propTypes = {
  data: PropTypes.array,
};

export default DocumentTable;
