import axios from './apiHelper';

const edmsRegisterHelper = {
  getUnassignedDocument: async (variables) => {
    try {
      const result = await axios.get(`/action-request-for`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get unassigned document`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getUnassignedDocuments: async (variables) => {
    try {
      const result = await axios.get(`/unassigned-file-metas`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get unassigned documents`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getAllRootFolders: async (variables) => {
    try {
      const result = await axios.get(`/files/get-all-root-folders`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get all root folders`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getSubFolders: async (variables) => {
    try {
      const result = await axios.get(`/files/get-sub-folders`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get all root folders`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  generateLogNumber: async (variables) => {
    try {
      const result = await axios.get(`/edms/generate-log-number`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get log number`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  createEDMSDocuments: async (variables) => {
    try {
      const result = await axios.post(`/edms/file-meta-manual-submission`, variables);

      if (!result.data) {
        throw new Error(`Fail to create EDMS document`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  updateEDMSDocumentMeta: async (id, variables) => {
    try {
      const result = await axios.patch(`/file-meta/${id}`, variables);

      if (!result.data) {
        throw new Error(`Fail to update EDMS document`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  uploadDocumentFile: async (id, variables) => {
    // not to the blockchain
    try {
      let formData = new FormData();
      formData.append('file', variables.file);
      formData.append('folder', true);
      const result = await axios({
        method: 'post',
        url: `/edms/file-meta/${id}/document`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (!result.data) {
        throw new Error(`Fail to upload the doc`);
      }
      return result.data;
    } catch (e) {
      console.log(e);
      if (e.response) {
        if (e.response.status === 400) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getPersons: async (variables) => {
    try {
      const result = await axios.get(`/persons`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get persons`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getCompanies: async (variables) => {
    try {
      const result = await axios.get(`/companies`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get companies`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getActionRequests: async (variables) => {
    try {
      const result = await axios.get(`/action-request-fors`, { params: variables });

      if (!result.data) {
        throw new Error(`Fail to get action request`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getActionRequest: async (id) => {
    try {
      const result = await axios.get(`/action-request-for/${id}`);

      if (!result.data) {
        throw new Error(`Fail to get action request`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getActionRequestDetails: async (id) => {
    try {
      const result = await axios.get(`/action-request-for/${id}/details`);

      if (!result.data) {
        throw new Error(`Fail to get action request`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  getLetterRef: async (variables) => {
    try {
      const result = await axios.get(`/file-meta-letter-refs`, {params: variables});

      if (!result.data) {
        throw new Error(`Fail to get letter ref.`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postActionRequest: async (variables) => {
    try {
      const result = await axios.post(`/action-request`, variables);

      if (!result.data) {
        throw new Error(`Fail to post action request`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  markAsRead: async (variables) => {
    try {
      const result = await axios.patch(`/action-request-for`, variables);

      if (!result.data) {
        throw new Error(`Fail to update seen status`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  },
  postMessage: async (variables) => {
    try {
      const result = await axios.post(`/action-request-message`, variables);

      if (!result.data) {
        throw new Error(`Fail to post message`);
      }
      return result.data;
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400 || e.response.status === 500) return { error: e.response.data.message };
      }
      return { error: "error" };
    }
  }
}

export default edmsRegisterHelper;