import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';

import { bimHelper } from 'helpers';
import ChartHeader from 'Common/ChartHeader';
import RISCInfoBox from "RISC/RISCInfoBox";
import SectionTitle from 'Common/SectionTitle';
import BIMTrend from './BIMTrend';

import { useStyles } from "../style";

export default function BIMDashboard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { contractId } = useParams();
  const [data, setData] = useState([{}, {}]);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));

  useEffect(() => {
    async function initData() {
      try {
        const result = await bimHelper.getBimFileCount({ contractId });
        return result;
      } catch (e) {
        console.log(e)
      }
    }
    let isMounted = true;
    initData().then((result) => {
      if (isMounted) setData(result);
    });
    return () => { isMounted = false };
  }, [])

  return (
    <>
      <SectionTitle title="BIM Dashboard" />
      <Grid container className={classes.body} spacing={2}>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"Total Managed"} value={data?.managedCount ?? 0} withDate={false} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RISCInfoBox title={"Total Published"} value={data?.publishedCount ?? 0} withDate={false} />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
      <Grid item xs={12} md={12}>
        <div className={classes.bottomRightWrapper}>
          <ChartHeader
            title={''}
            filterType={"quarterRange"}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <BIMTrend startDate={startDate} endDate={endDate} />
        </Grid>
      </Grid>
    </>
  );
};