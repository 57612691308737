import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { DSDTabs, DSDTab, useStyles } from "./style";

function SimpleTabs({ data, activeTab, handleChange, isSubTab, onParentChange, action }) {
  const classes = useStyles();
  const [value, setValue] = useState(activeTab ?? 0);

  const handleOnChange = (event, newValue) => {
    handleChange(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (isSubTab) handleOnChange(null, activeTab);
  }, [activeTab]);

  return (
    <div className={classes.root}>
      <DSDTabs value={value} onChange={handleOnChange} aria-label="simple tabs" action={action}>
        {data.map(item =>
          <DSDTab label={item.label} key={item.id} {...a11yProps(item.id)} />
        )}
      </DSDTabs>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

SimpleTabs.propTypes = {
  data: PropTypes.PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  activeTab: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SimpleTabs;
