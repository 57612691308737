import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  infoHeader: props => ({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    color: "#fff",
    padding: props.padding,
    margin: props.margin,
    justifyContent: props.justify ?? "space-between",
    borderBottom: "1px solid #CACACA",
  }),
  rowItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "64px",
  },
  logoWrapper: {
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      height: "unset",
    }
  },
  logo: {
    width: "32px",
    marginRight: "20px",
  },
  title: {
    color: theme.palette.primaryGrey,
    marginRight: "20px",
  },
  special: {
    color: theme.palette.primaryLight,
  },
  headerText: {
    color: theme.palette.primaryGrey,
    maxWidth: "300px",
    minWidth: "150px"
  },
  rowHeaderText: {
    color: theme.palette.primaryGrey,
  }
}));
